import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions";
import {FormInput} from "shards-react";

class FaceCropProperty extends Component {
    changeFaceId(e) {
          this.props.pages[0].canvasElements.forEach((item) => {
            if (this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    faceId: e.target.value * 1,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    render() {
        return <div
            className={clsx("designer-right-panel-item")}>
            <div className={"designer-right-panel-item__titleFlex"}>
                <span className={"text"}>ID</span>
                <div className={"designer-right-panel-inputNumber"}>
                    <FormInput size="sm"
                               onChange={this.changeFaceId.bind(this)}
                               value={this.props.activeElement.attrs.faceId ?? 0}
                               type="number"/>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        selectedElement: state.glb.selectedElement,
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceCropProperty);
