import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions";
import Translate from "react-translate-component";

class FaceCropProperty extends Component {
    render() {
        if (this.props.activeElement?.attrs?.elementRepeat || this.props.activeElement?.attrs?.elementRepeatOval) {
            return <div
                className={clsx("designer-right-panel-item")}>
                <div className={"designer-right-panel-item__titleFlex"}
                     onClick={this.props.showFaceCropPopup}>
                    <FontAwesomeIcon icon="image"/>
                    <span className={"text"}>
                        <Translate content={"Face crop"}/>
                    </span>

                </div>
            </div>
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceCropProperty);
