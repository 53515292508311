import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import {Button, FormInput} from "shards-react";
import Translate from "react-translate-component";
import {DESKTOP_WIDTH} from "../../../config";
import clsx from "clsx";

class PositionProperty extends Component {

    fontSizeInput = React.createRef();

    state = {
        fontSizeDropdown: false,
        currentFontSize: (this.props.activeElement.attrs.fontSize && this.props.activeElement.attrs.fontSize >= 1) ? this.props.activeElement.attrs.fontSize : '1',
        newFontSize: (this.props.activeElement.attrs.fontSize && this.props.activeElement.attrs.fontSize >= 1) ? this.props.activeElement.attrs.fontSize : '1',

    }

    handleFontSizeChange(e) {
        this.props.stage.children[this.props.activePage].children.forEach((item) => {
            if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.attrs.name)) {
                this.props.onEditElement({
                    fontSize: Math.abs(parseFloat(e.target.value)),
                    element: item.attrs.name,
                    activePage: this.props.activePage,
                });

                setTimeout(() => {
                    switch (item.attrs.align) {
                        case "right":
                            item.x(item.x() + item.width() - item.offsetX())
                            item.offsetX(item.width());
                            break;
                        case "center":
                            item.x(item.x() + (item.width() / 2 - item.offsetX()))
                            item.offsetX(item.width() / 2);
                            break;
                        default:
                            item.offsetX(0);
                            break;
                    }
                }, 50)
            }
        });
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
        //TODO Refactor
        let parentWrapper = document.querySelectorAll(".properties-column")[0];
        let parentWrapperActive = document.querySelectorAll(".properties-column.active");
        if (!parentWrapperActive.length) {
            if (this.state.fontSizeDropdown) {
                parentWrapper.scrollTo(0, 0);
                parentWrapper.classList.add("active")
            } else {
                parentWrapper.classList.remove("active")
            }
        }

    }

    componentWillUnmount() {
        document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }

    setProperties(attrs) {
        if (this.fontSizeInput.current) this.fontSizeInput.current.value = attrs.fontSize;
    }

    saveFontSize = () => {
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    closeFontSize = () => {
        const {currentFontSize} = this.state;
        this.props.onEditElement({
            fontSize: Math.abs(parseFloat(currentFontSize)),
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    };

    changeFontSize = (type) => {
        const {newFontSize} = this.state;
        const updateFontSize = type ? +newFontSize + 1 : +newFontSize - 1;
        this.props.onEditElement({
            fontSize: updateFontSize,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage,
        });
        this.setState({
            newFontSize: typeof updateFontSize === "number" ? Math.abs(updateFontSize) : updateFontSize,
        })


    }


    render() {
        const {newFontSize} = this.state;
        return (
            <div
                className={clsx("designer-right-panel-item", "designer-right-panel-item_fontSize", this.state.fontSizeDropdown && "active")}>
                <div className={"designer-right-panel-item__titleFlex"}
                     onClick={this.props.toggleClass.bind(this, "fontSizeDropdown")}>
                    <FontAwesomeIcon icon="text-height"/>
                    <span className={"text"}>
                        <Translate content={"Size"}/>
                    </span>
                </div>

                {
                    window.innerWidth <= DESKTOP_WIDTH ? (
                        <div className={"dropdownContent"}>
                            <div className="dropdownContentBtn">
                                <Button outline
                                        theme="secondary"
                                        onClick={this.closeFontSize}
                                >
                                    <Translate content={"Cancel"}/>
                                </Button>
                                <Button theme="secondary"
                                        onClick={this.saveFontSize}
                                >
                                    OK
                                </Button>
                            </div>

                            <div className="dropdownContentBlock">
                                <button className="dropdownContentBlockBtn"
                                        onClick={() => this.changeFontSize(false)}>
                                    <FontAwesomeIcon icon='minus'/>
                                </button>
                                <div className="dropdownContentBlockValue">
                                    {newFontSize}
                                </div>
                                <button className="dropdownContentBlockBtn"
                                        onClick={() => this.changeFontSize(true)}>
                                    <FontAwesomeIcon icon='plus'/>
                                </button>
                            </div>

                        </div>
                    ) : (
                        <div className={"designer-right-panel-inputNumber"}>
                            <FormInput
                                size="sm"
                                type="number"
                                defaultValue={this.props.activeElement.attrs.fontSize}
                                innerRef={this.fontSizeInput}
                                onChange={this.handleFontSizeChange.bind(this)}/>
                        </div>
                    )
                }


            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        pages: state.glb.pages,
        selectedElements: state.glb.selectedElements,
        stage: state.glb.stage,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);

