import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";
import { FormInput } from "shards-react";

class Position extends Component {

    positionInputX = React.createRef();
    positionInputY = React.createRef();

    state = {
        positionDropdown: false
    };

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.positionInputX.current) this.positionInputX.current.value = attrs.x;
        if (this.positionInputY.current) this.positionInputY.current.value = attrs.y;
    }

    handlePositionXChange(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if (this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    x: parseFloat(e.target.value) || 0,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    handlePositionYChange(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if (this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    y: parseFloat(e.target.value) || 0,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    onKeyDown(e) {
        if (e.keyCode === 188 && window.navigator.userAgent.indexOf('Firefox') !== -1) {
            e.target.value = e.target.value + ".";
            e.preventDefault();
        }
    }

    render() {
        if (this.props.activeElement.attrs.user_transformable || this.props.mode === "ADMIN") {
            return (
                <div
                    className={clsx("designer-right-panel-item", "designer-right-panel-item_positions", this.state.positionDropdown && "active")}>
                    <div className={"designer-right-panel-item__titleFlex"}
                         onClick={this.props.toggleClass.bind(this, "positionDropdown")}>
                        <FontAwesomeIcon icon="arrows-alt"/>
                        <span className={"text"}>
                        <Translate content={"Position"}/>
                    </span>
                    </div>
                    <div className={"dropdownContent"}>
                        <div className={"designer-right-panel-positions"}>
                            <div className="designer-right-panel-inputNumber_title">
                                X :
                                <FormInput type="number"
                                           size="sm"
                                           lang="en"
                                           step={10}
                                           onKeyDown={this.onKeyDown}
                                           defaultValue={this.props.activeElement.attrs.x}
                                           onChange={this.handlePositionXChange.bind(this)}
                                           innerRef={this.positionInputX}
                                />

                            </div>
                            <div className="designer-right-panel-inputNumber_title">
                                Y :
                                <FormInput type="number"
                                           size="sm"
                                           step={10}
                                           onKeyDown={this.onKeyDown}
                                           defaultValue={this.props.activeElement.attrs.y}
                                           onChange={this.handlePositionYChange.bind(this)}
                                           innerRef={this.positionInputY}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null
        }

    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
        selectedElements: state.glb.selectedElements,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Position)
