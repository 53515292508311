import * as actionTypes from './actionTypes';


export const showPreview = ( image ) => {
	return  {
		type: actionTypes.GENERATE_PREVIEW,
		image: image
	}
}

export const disableTransformer = () => {
	return  {
		type: actionTypes.SET_ACTIVE_CANVAS_ELEMENT,
		element: ''
	}
};

export const copiedCanvasElement = (element) => {
	return  {
		type: actionTypes.COPIED_CANVAS_ELEMENT,
		element
	}
};

export const addElement = ( elementType, shape, stage, imageUrl, width, height, background, editable, renderers, changeable, quickEdit, transformable, itIsCopy) => {
	return {
		type: actionTypes.ADD_CANVAS_ELEMENT,
		itIsCopy: !!itIsCopy,
		elementType: elementType,
		shape: shape,
		text: shape,
		imageUrl: imageUrl,
		width: width,
		height: height,
		background: background,
		editable: editable,
		transformable: transformable,
		changeable: changeable,
		quickEdit: quickEdit
	}
};

export const addBackground = (elementType, shape, stage, imageUrl, width, height, background, editable, renderers, changeable, quickEdit, transformable) => {
	return dispatch => {
		dispatch(addElement(elementType, shape, stage, imageUrl, width, height, background, editable, renderers, changeable, quickEdit, transformable));
	}
};

export const getCanvasElement = () => {
	return  {
		type: actionTypes.GET_CANVAS_ELEMENT
	}
};

export const updateCanvasElement = (data) => {
	return  {
		type: actionTypes.UPDATE_CANVAS_ELEMENT,
		data,
	}
};

export const setActiveCanvasElement = (element) => {
	return  {
		type: actionTypes.SET_ACTIVE_CANVAS_ELEMENT,
		element: element
	}
};
