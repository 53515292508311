export default {
    "Revert": "Ongedaan",
    "Retry": "Opnieuw",
    "Face crop":"Verwijder achtergrond",
    "Remove background":"Remove background",
    "Loading":"Loading",
    "Background not recognized":"Background not recognized",
    "Background already removed":"Background already removed",
    "settings": "Instellingen",
    "gridlines": "Richtlijnen",
    "layers": "Lagen",
    "image": "Foto",
    "Text": "Tekst",
    "shape": "Elementen",
    "emoji": "Emoji",
    "done": "Klaar",
    "view": "Voorkant",
    "Custom Text": "Tekst",
    "Close": "Sluiten",
    "Position": "Positie",
    "Rotate": "Draai",
    "Change Image": "Afbeelding wijzigen",
    "Crop Image": "Bijsnijden",
    "Upload Images": "Afbeeldingen uploaden",
    "Add Text": "Voeg tekst toe",
    "Cancel": "Annuleren",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Klik op lets in het design om het aan te passen",
    "Shapes": "Configureerbare vormen",
    "Add a line": "Voeg lijn toe",
    "Add a rectangle": "Voeg rechthoek toe",
    "Add a circle": "Voeg Ovaal toe",
    "Add a triangle": "Voeg driehoek toe",
    "Color": "Kleur",
    "Edit Text": "Tekst wijzigen",
    "Font type": "Font",
    "Font Style": "Style",
    "Size": "Grootte",
    "Justification": "Uitlijning",
    "Letter spacing": "Letterafstand",
    "Flip": "Omdraaien",
    "Save" : "Opslaan",
    "Preview": "Preview",
    "Editor": "Editor",
    "Uploads": "Uploads",
    "Letter limit": "Maximum aantal karakters",
    "Recently Used Colors": "Recentelijk gebruikte kleuren",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed" : "Du har placeret et objekt uden for det printbare område. Bekræft venligst, at du er indforstået med, at det kan printes delvist eller ukorrekt.",
    "There was error during getting files!": "Er liep iets fout bij het uploaden van je afbeeldingen. Gelieve de pagina te vernieuwen en het nogmaals te proberen.",
    "Your Image is too big to upload! The max file size is 8 MB": "Je afbeelding is te groot om te uploaden! De maximale bestandsgrootte is 8 MB.",
    "Your image is too small": "Je afbeelding is te klein om geprint te worden in hoge kwaliteit. Gelieve een andere te selecteren.",
    "Reset changes": "Reset",
	"Stroke": "omtrek",
	"Stroke color": "omtrek kleur",
	"Delete": "Verwijderen",
    "BackgroundsTools": "Achtergrond",
    "ColorsTools": "Kleuren",
    "ImagesTools": "Afbeeldingen",
    "Shadow color": "Schaduw kleur",
    "Shadow": "Schaduw",
    "Information before ordering": "Controleer je ontwerp goed en geef je akkoord, want zo wordt het gedrukt. Lees meer in onze FAQ.",
    "Add to cart": "In winkelwagentje",
    "More": "Meer",
    "edit Image": "Aanpassen",
    "Add_Image": "Extra foto",
    "Cut": "Bijsnijden",
    "Change": "Upload een foto",
    "Drag and drop your images here!": "Drag en drop je foto's hier",
    "Edit": "Tekst wijzigen",
    "Font": "Font",
    "Font Size": "Grootte",
    "Letter Space": "Spatiëring",
    "Text Color": "Kleur",
    "New text": "Voeg tekst toe",
    "Align": "Uitlijning",
    "Flip X": "Omdraaien",
    "Oops, didn't you forget something?": "Whoeps, ben je niet iets vergeten? Het lijkt erop dat je niet alles hebt aangepast en gepersonaliseerd. Kijk daarom het product na en vervang de standaard afbeeldingen (tenzij je ze mooi vindt natuurlijk!).",
    "Use original image": "Gebruik origineel beeld",
    "Continue": "Ga verder",
    "Wird freigestellt": "Verwijder achtergrond...",
    "texts_lang": 'Tekst aanpassen',
    "Design_anpassen_lang": "Design aanpassen",
    "Hintergundfarbe_anpassen_lang": "Achtergrond aanpassen",
    "Farbe_anpassen_lang": "Achtergrond aanpassen",
    "Weiter_lang": "Ga verder",
    "Text_anpassen_lang":"Tekst aanpassen",
    "Mehr_anpassen_lang" :"Elementen aanpassen",
    "Bild_hinzufüge_lang":"Extra foto",
    "alert_error_message": "O jee! Helaas wordt dit afbeeldingsformaat niet ondersteund. Gebruik een van de volgende formaten: jpg, png, jpeg of jfif.",
    "back_button": "Terug",
    "textarea_popup_text": " Om de tekst te bewerken klik je gewoon in het tekstveld. Door op het potloodje te klikken kun je de tekst opmaken.",
    "bild_popup_text": "Gebruik deze knop om je foto's te uploaden.",
    "Color_Btn_Text": "Kleur",
    "Shadow_Btn_Text": "Schaduw",
    "Stroke_Btn_Text": "Omtrek",
    "upload_photos_Btn_Text": "Foto's uploaden",
    "Upload_Btn_Text": "Uploaden",
    "Upload_More_Photos_Btn_Text": "Meer foto's uploaden",
    "Edit_Text_Btn_Text": "Tekst bewerken",
    "Save_Btn_Text": "Opslaan",
    "Change_Text_Btn_Text": "Tekst bewerken",
    "Design_Btn_Text": "Design",
    "Face_cropper_loading_text": "Bezig met laden...",
    
}