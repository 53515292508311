import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Translate from "react-translate-component";
import { Button } from "shards-react";
import close_btn from "../../assets/images/icons_designer_24.png";
import removeElementIcon from "../../assets/images/icons_designer_12.png";
import pencilIcon from "../../assets/images/icons_designer_26_black.png";
import addTextIcon from "../../assets/images/addTextIcon.png";
import stl from "../Tools/ToolsViews/ToolsViews.module.scss";
import textTransformer from "../../helpes/textTransformer";

import queryString from 'query-string';
const params = queryString.parse(window.location.search);

class TextareaMobilePopup extends Component {
    state = {
        textValue: 0,
        cursorLocationEnd: true,
       
    }

    getElements() {
        if (this.props.pages && this.props.pages[this.props.activePage]) {
            let canvasElements = this.props.pages[this.props.activePage]?.canvasElements;

            canvasElements = canvasElements.filter(i => {
                return i.changeable && i.type === 'text' && i.data !== "undefined"
            })
            return canvasElements
        }
    }

    changeText(event){ 
        this.setState(
            {textValue : event.target.value}
        );
    }

    changeText(name, text) {
        if (text.target.value?.length > 0) {

            this.props.stage.children[this.props.activePage].children.forEach((item) => {

                if ((item?.attrs.elementId === this.props.activeElement?.attrs?.elementId
                    && item?.attrs?.elementId !== 0
                    && item?.attrs?.elementId !== undefined) || this.props?.selectedElements?.includes(item.attrs.name)) {

                        let oldlines = this.props.activeElement?.attrs?.text.split('\n');
                        let newlines = text.target.value.split('\n');
                        let lastkey = text.target.value.slice(-1);
                        let oldLength = this.props.activeElement?.attrs?.text.length;
                        let newLength = text.target.value.length;    
    
                        let y;
                        if(newlines.length > oldlines.length){
                            y = parseFloat(this.props.activeElement?.attrs?.y) + parseFloat(this.props.activeElement?.attrs?.fontSize);
                        } else if(newlines.length < oldlines.length){
                            y = parseFloat(this.props.activeElement?.attrs?.y) - parseFloat(this.props.activeElement?.attrs?.fontSize);
                        } else {
                            y = this.props.activeElement?.attrs?.y;
                        }

                    this.props.onEditElement({
                        newText: this.textChangeHandle(text).trimLeft(),
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                        y:y,
                    });


                    if (this.props.activeElement) {
                        setTimeout(() => {
                            if (item?.attrs?.textMaxWidth && this.props.activeElement) item.width(item.attrs.textMaxWidth);
                            let align = item.attrs.align;
                            this.props.onEditElement({
                                align: "left",
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            this.props.onEditElement({
                                align: align,
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            switch (align) {
                                case "right":
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    break;
                            }
                        }, 50)
                    }
                }
            });
        } else {
            this.props.onEditElement({
                newText: " ",
                element: name,
                activePage: this.props.activePage,
            });
        }
    }

    decodeTextData(item) {
        // console.log('item--*********>>',item.data)
        let text = " ";
        try {
            text = textTransformer(decodeURI(item.data || item.text), item.user_textTransform?.value) ?? " ";
        } catch (e) {
            text = textTransformer((item.data || item.text), item.user_textTransform?.value) ?? " "
        }

        if(text !== 'undefined' && text !== 'UNDEFINED') return text
        return " ";
    }


    addTextInput() {
        let layerData = [];
        let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];

        let datas = this.props.pages[this.props.activePage]?.layerDatas.canvasMaskElements;
        
        

        if (parseInt(params.robot) === 1) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }
        if (parseInt(params.robot) === 0) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }
        const layerDatas = localStorage.getItem('layer_data');
        const createObj = {
            type: 'text',
            data: ' ',
            store_view_values: this.getStoreViewValues(),
            activePage: this.props.activePage
        };

        const { activePage, pages } = this.props;
        if (pages && pages.length && pages[activePage]) {
            const { customFonts = {} } = pages[activePage];
            const objectKey = Object.keys(customFonts);
            if (objectKey.length > 0) {
                createObj.fontFamily = customFonts[objectKey[0]]['font_family'];
                createObj.fontSize = 32;
                createObj.layer_allocation_data = layerDatas;
            }
        }
        this.props.addTextToPage(createObj);

        setTimeout(() => {
            let canvasElements = this.props.pages[0].canvasElements;
            canvasElements = canvasElements.filter(i => {
                return i.deletable && i.type === 'text'
            });

            let name = canvasElements[canvasElements.length - 1].name;
            // let name = this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name

            this.props.onSelectElement(name)
            this.props.onEditElement({
                align: "center",
                element: name,
                deletable: true,
                activePage: this.props.activePage,
            });
            // for ADMIN and other product
            if (this.props.activeElement) {
                this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
            }


            // for PRODUCTION
            if (this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1) {

                let layerX = [];
                let layerY = [];
                for (let i = 1; i <= 10; i++) {
                    switch (i) {
                        case 1:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 2:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 3:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 4:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 5:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 6:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        default:
                            break;
                    }
                }

                // Accessing the values:
                let layer1X = layerX[1]; let layer1Y = layerY[1];
                let layer2X = layerX[2]; let layer2Y = layerY[2];
                let layer3X = layerX[3]; let layer3Y = layerY[3];
                let layer4X = layerX[4]; let layer4Y = layerY[4];
                let layer5X = layerX[5]; let layer5Y = layerY[5];
                let layer6X = layerX[6]; let layer6Y = layerY[6];

                const layerDataCoordinates = {
                    ...(layerData[1] && { [layerData[1]]: { x: layer1X, y: layer1Y } }),
                    ...(layerData[2] && { [layerData[2]]: { x: layer2X, y: layer2Y } }),
                    ...(layerData[3] && { [layerData[3]]: { x: layer3X, y: layer3Y } }),
                    ...(layerData[4] && { [layerData[4]]: { x: layer4X, y: layer4Y } }),
                    ...(layerData[5] && { [layerData[5]]: { x: layer5X, y: layer5Y } }),
                    ...(layerData[6] && { [layerData[6]]: { x: layer6X, y: layer6Y } }),
                };

                if (layerDatas in layerDataCoordinates) {
                    const { x, y } = layerDataCoordinates[layerDatas];
                    this.props.activeElement.x(x);
                    this.props.activeElement.y(y);
                }
            }
            console.log(name, "names");
        }, 0)
    }

    getStoreViewValues() {

        let storeViewValues = [];
        let inputs = document.getElementsByClassName("text-form-popup-content-store");
        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            storeViewValues.push({store_id: storeId, label: inputs[i].value});
        }
        return storeViewValues;
    }

    // remove white space from last
    validateText(name, text){
        if (text.target.value?.length > 0) {
            let newlines = text.target.value.split('\n');
            newlines.reverse();
            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                if(item?.attrs.name == name){

                    let y = item.attrs?.y;
                    for (let i = 0; i < newlines.length; i++) {
                      if (newlines[i] != '' ) { break; }
                      y = parseFloat(y) - parseFloat(item.attrs?.fontSize);
                    }
                    let trimtxtz = "";
                    if(this.textChangeHandle(text).length<=1){
                        trimtxtz = this.textChangeHandle(text);
                    }else{
                        trimtxtz = this.textChangeHandle(text).trim();
                    }

                    this.props.onEditElement({
                        newText: trimtxtz,
                        element: name,
                        activePage: this.props.activePage,
                        y:y,
                    });
                    
                    if (this.props.activeElement) {
                        setTimeout(() => {
                            if (item?.attrs?.textMaxWidth && this.props.activeElement) item.width(item.attrs.textMaxWidth);
                            let align = item.attrs.align;
                            this.props.onEditElement({
                                align: "left",
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            this.props.onEditElement({
                                align: align,
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            switch (align) {
                                case "right":
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    break;
                            }
                        }, 50)
                    }
                }
            });
        }
    }

    textChangeHandle(newText) {
        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
        return textTransformer(this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.target.value : newText.target.value.replace(new RegExp(ranges, 'g'), ''), this.props.activeElement?.attrs.user_textTransform?.value);
    }

    deleteTextElement(item) {
        this.props.deleteSelectedElement({
            element: item.key || item.name,
            activePage: this.props.activePage,
        });
        // Added to remove the delete icon(RS-2389)
        this.props.setSelectedElements([item.name]);
        // this.props.onSelectElement(item.name);

        setTimeout(() => {
            if (this.props.pages && this.props.pages[this.props.activePage]) {
                let canvasElements = this.props.pages[this.props.activePage].canvasElements;
                canvasElements = canvasElements.filter(i => {
                    return i.deletable && i.type === 'text'
                })

                if (canvasElements.length >= 1) {
                    this.props.onSelectElement(canvasElements[0].name);
                } else {
                    this.props.onSelectElement("");
                }
            }
        }, 0)
    }
    

    render() {
        
        let layerDataActive = localStorage.getItem('layer_data');
        // console.log(this.props?.activeElement?.attrs?.name);
        let fontSizevar = 0;
        if(this.props.activeElement?.attrs?.fontSize > 0){
            fontSizevar = this.props.activeElement?.attrs?.fontSize 
        }

        // show cursor at the last of element
        setTimeout(() => {
            let name = this.props?.selectedElement;
            this.props.onSelectElement(name);
            const elements = document.getElementsByName(name);
            if(elements.length === 1 && this.state.cursorLocationEnd){
                this.setState({cursorLocationEnd: false});
                const ActiveElementId = elements[0].getAttribute('id');
                const ActiveElementFocus = document.getElementById(ActiveElementId);
                const endMove = ActiveElementFocus.value.length;
                ActiveElementFocus.setSelectionRange(endMove, endMove);
                ActiveElementFocus.focus();
            }
        }, 10);
        // show cursor at the last of element end

        if (this.props.isShowTextareaMobilePopup) {
            return (
                <div className="text-form-popup-content textArea_popup_cust">
                    <div className="text-form-popup-content-inner">
                        {/* Popup heading content */}
                        <div className="text-form-popup-content-title__wrap cstm_image_upload_heading">
                            <div className="text-form-popup-content-title">
                                <Translate content={"Edit_Text_Btn_Text"}/>
                            </div>
                            <div className="text-form-popup-close"
                                onClick={() => {
                                    this.props.hidePopup();
                                }}>
                                <img src={close_btn} alt=""/>
                            </div>
                        </div>

                        {/* Popup content */}
                        <div className="textarea_content_wrapper">
                            <div className={stl.textMenu_elementsList + " textAreaSpacing cstm_textareaSpacing"}>                                        
                            {this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1 ? (
                                        this.getElements()
                                            .filter(i => {
                                                return i?.layer_allocation_data === layerDataActive;
                                            })
                                            .map((i, index) => (
                                                <div key={index} className={stl.textMenu_listItem}>
                                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                        <img src={removeElementIcon} alt="" onClick={this.deleteTextElement.bind(this, i)} />
                                                    )}

                                                    <textarea
                                                        rows="1"
                                                        cols="3"
                                                        id={"elementtxtid_" + index}
                                                        name={i.name}
                                                        value={this.decodeTextData(i)}
                                                        maxLength={i.textLimit || undefined}
                                                        onClick={() => {
                                                            this.props.setSelectedElements([i.name]);
                                                            this.props.onSelectElement(i.name);
                                                        }}
                                                        onFocus={() => {
                                                            this.props.setSelectedElements([i.name]);
                                                            this.props.onSelectElement(i.name);
                                                        }}
                                                        onChange={this.changeText.bind(this, i.name)}
                                                        onBlur={this.validateText.bind(this, i.name)}
                                                        style={i.name === this.props?.activeElement?.attrs?.name ? { borderColor: "#000000", borderWidth: "2px" } : {}}
                                                        className={i.name === this.props?.activeElement?.attrs?.name ? "active_element_textarea" : ""}
                                                        autoFocus={i.name === this.props?.activeElement?.attrs?.name ? true : false}
                                                    />

                                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only ? (
                                                        <input type="hidden" value={i.name} id={"HDNelementtxtid_" + index}></input>
                                                    ) : (
                                                        ""
                                                    )}

                                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                        <img
                                                            src={pencilIcon}
                                                            className="pencil_icon_new"
                                                            alt=""
                                                            onClick={() => {
                                                                this.props.setSelectedElements([i.name]);
                                                                this.props.onSelectElement(i.name);
                                                            }}
                                                        />
                                                    )}
                                                </div> 
                                            ))
                                    ) : (
                                        this.getElements().map((i, index) => (
                                            <div key={index} className={stl.textMenu_listItem}>
                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                    <img src={removeElementIcon} alt="" onClick={this.deleteTextElement.bind(this, i)} />
                                                )}

                                                <textarea
                                                    rows="1"
                                                    cols="3"
                                                    id={"elementtxtid_" + index}
                                                    name={i.name}
                                                    value={this.decodeTextData(i)}
                                                    maxLength={i.textLimit || undefined}
                                                    onClick={() => {
                                                        this.props.setSelectedElements([i.name]);
                                                        this.props.onSelectElement(i.name);
                                                    }}
                                                    onFocus={() => {
                                                        this.props.setSelectedElements([i.name]);
                                                        this.props.onSelectElement(i.name);
                                                    }}
                                                    onChange={this.changeText.bind(this, i.name)}
                                                    onBlur={this.validateText.bind(this, i.name)}
                                                    style={i.name === this.props?.activeElement?.attrs?.name ? { borderColor: "#000000", borderWidth: "2px" } : {}}
                                                    className={i.name === this.props?.activeElement?.attrs?.name ? "active_element_textarea" : ""}
                                                    autoFocus={i.name === this.props?.activeElement?.attrs?.name ? true : false}
                                                />

                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? (
                                                    <input type="hidden" value={i.name} id={"HDNelementtxtid_" + index}></input>
                                                ) : (
                                                    ""
                                                )}

                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                    <img
                                                        src={pencilIcon}
                                                        className="pencil_icon_new"
                                                        alt=""
                                                        onClick={() => {
                                                            this.props.setSelectedElements([i.name]);
                                                            this.props.onSelectElement(i.name);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))
                                    )}
                                <div className={stl.textMenu_elementAdd}>
                                    {this.props.pages[this.props.activePage].multiface_elements_admin_only && parseInt(params.robot)==0 ?
                                        <Button className="prvForDesktop" outline theme="secondary"
                                            style={{ 
                                                display: 'flex',
                                                border: '1px solid #000',
                                                fontWeight: '600',
                                                padding: '16px',
                                                fontSize: '16px',  
                                                borderRadius: '100px',
                                                maxWidth: 'unset',
                                            }}
                                            onClick={() => {
                                                if(document.getElementById("loaderdv_id")){
                                                    document.getElementById("loaderdv_id").style.display="flex";
                                                }
                                                if(document.getElementById("prv_id")){
                                                    document.getElementById("prv_id").style.opacity="0.5";
                                                }
                                                setTimeout(() => {
                                                    this.generatePreview();
                                                }, 0)
                                            }}>
                                            <Translate content={"Preview"}/>
                                        </Button> 
                                    :
                                        <img src={addTextIcon} alt="" onClick={this.addTextInput.bind(this)}/>
                                    }
                                </div>
                            </div> 
                        </div>
                        
                        {/* Popup bottom content */}
                        <div className="text_area_info_btn_wrapper">
                            <div className="cstm_upload_btn_outer">
                                <div className="content_wrap_upload_btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
                                    </svg>
                                    <Translate content={"textarea_popup_text"}/>
                                </div>
                            </div>
                            <button className="btn btn-outline-secondary"
                                onClick={() => {
                                    this.props.hidePopup();
                                }}>
                                <Translate content={"Save_Btn_Text"}/>
                            </button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        mode: state.glb.mode,
        pages: state.glb.pages,
        isShowTextareaMobilePopup: state.tol.ShowTextareaMobilePopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        selectedElements: state.glb.selectedElements,
        selectedElement: state.glb.selectedElement,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        hidePopup: () => {
            dispatch(actionCreators.hidePopup());
        },
        setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            // dispatch(actionCreators.hidePopup());
        },
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextareaMobilePopup);
