import Translate from "react-translate-component";
import ReactDOMServer from 'react-dom/server';
import React from 'react';

export default function TranslateForAlert(content) {
    let msg = (ReactDOMServer.renderToStaticMarkup(
        <Translate content={content}/>
    )).toString().slice(6, -7);
    if (msg.length > 1) alert(msg);
    return null
}