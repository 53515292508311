import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import ImgUploader from "../Tools/ToolsViews/ImgUploader";
import Translate from "react-translate-component";
import { Button } from "shards-react";
import close_btn from "../../assets/images/icons_designer_24.png";

class ImageChangePopup extends Component {
    render() {
        if (this.props.isShowImageChangePopup) {

            return (<div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={ () => this.props.pages[this.props.activePage].multiface_elements_admin_only !==1 ? this.props.hidePopup() : ''}/>
                {/*<div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>*/}
                <div  className={`text-form-popup-content-inner ${this.props.pages[this.props.activePage].multiface_elements_admin_only !== 1  ? '' : 'faceCropMultifaceWrap'}`}>
                {/*<div  className={`text-form-popup-content-inner`}>*/}

                    {this.props.pages[this.props.activePage].multiface_elements_admin_only !==  1 ? <div className="text-form-popup-content-title__wrap cstm_image_upload_heading">
                        <div className="text-form-popup-back"></div>
                        <div className="text-form-popup-content-title">
                            <Translate content={"upload_photos_Btn_Text"}/>
                        </div>
                        <div className="text-form-popup-close"
                             onClick={() => this.props.hidePopup()}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div> : null}

                    {/* Straightlined Editor Heading*/}
                    {/* <div className={stl.uploader}> */}
                    <div className="straightlined-editor">
                        {this.props.pages[this.props.activePage ?? 0]?.multiface_elements_admin_only === 1 ?
                            <div className="text-form-popup-content-title__wrap cstm_image_upload_heading">
                                <div className="text-form-popup-back"/>
                                <div className="text-form-popup-content-title">
                                    <Translate content={"upload_photos_Btn_Text"}/>
                                </div>
                                <div className="text-form-popup-close"
                                        onClick={() => {window.parent.postMessage({datatype: "hide_designer"}, "*"); this.props.hidePopup();}}>
                                    <img src={close_btn} alt=""/>
                                </div>
                            </div> : <div className="text-form-popup-content-title__wrap">
                                {this.props.isShowImageChangePopup ? null :
                                    <>
                                        <div className="text-form-popup-back">
                                        </div>
                                        <div className="text-form-popup-content-title">
                                            <Translate content={"Uploads"}/>
                                        </div>
                                        <div className="text-form-popup-close"
                                                onClick={() => {
                                                    this.setState({
                                                        newImgUploader: false
                                                    })
                                                }}>
                                            <img src={close_btn} alt=""/>
                                        </div>
                                    </>
                                }
                            </div>
                        }
                    </div>
                    {/* Straightlined Editor Heading End */}
                    

                    <div className='imgUploaderScrollCls'>
                        <ImgUploader uploaderUrl={this.props.uploaderUrl} hidePopup={this.props.hidePopup}/>
                    </div>
                    {/* {
                        this.props.pages[this.props.activePage]?.multiface_elements_admin_only !== 1 || this.props.mode === "ADMIN" ? <div className="text-form-popup-content-buttons">
                            <Button outline theme="secondary" onClick={this.props.hidePopup}>
                                <Translate content={"Cancel"}/>
                            </Button>
                        </div> : null
                    } */}

                </div>
            </div>)
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        mode: state.glb.mode,
        pages: state.glb.pages,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        hidePopup: () => {
            dispatch(actionCreators.hidePopup());
        },

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageChangePopup);
