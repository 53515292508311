import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import stl from "./FaceCropPopup.module.scss"

import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'

import {Button} from "shards-react";

import Translate from "react-translate-component";

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { SketchPicker } from 'react-color';
import color_picker_icon from "../../assets/images/icons_designer_32.png";


class CommonBackgroundChangePopup1 extends Component {
    render(){
        if(isMobile){
           return (
                <CustomScroll className="customscrollbar-7" heightRelativeToParent="660px">
                    {this.props.children}
                </CustomScroll>
            )
        }else{
            return (
                <Scrollbars style={{ height: 660 }} className="scrollbar-7">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}

class CommonBackgroundChangePopup2 extends Component {
    render(){
        if(isMobile){
           return (
                <CustomScroll className="customscrollbar-8" heightRelativeToParent="660px">
                    {this.props.children}
                </CustomScroll>
            )
        }else{
            return (
                <Scrollbars style={{ height: 660 }} className="scrollbar-8">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}


class BackgroundChangePopup extends Component {
    state = {
        colorsShow: {
            values: [],
            show: false,
        },
        designShow: {
            values: [],
            show: false,
        },
        whatShow: 'shop',
        firstLoad: true,
        background: '#fff',
        bgColor: null,
        textColorsListMobile: ["#f44336","#e81e63","#9c27b0","#673ab7","#3f51b5","#2196f3","#03a9f4",
        "#00bcd4","#009688","#4caf50","#8bc34a","#cddc39","#ffeb3b","#ffc107","#ff9800","#ff5722",
        "#795548","#9e9e9e","#607d8b","#000000","#ffffff"],
        ColorPicker: false,

    };
   
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
        console.log("COLOR: ", this.state.background)
        // this.handleColorChange(color)
        this.changeBackgroundColor(color)        
    };

    // Colorpicker slider
    handleChangeComplete_new = (color) => {
        this.setState({ background: color });
        console.log("COLOR--****---: ", color, this.state.background)
        this.addBackgroundColor_new(color)
    };

    componentDidMount() {
        
        this.generatePreview();
        let activePage = this.props.pages[this.props.activePage];

        let colors = [];
        for (let key in activePage.backgrounds) {
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
        }

        let backgrounds = [];
        let pageBackgrounds = activePage.designs;

        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            let bck = pageBackgrounds[key];
            backgrounds.push({key: key, background_image: bck.image, smallBackground_image: bck.small_image});
        }

        console.log({
            colorsShow: {
                values: colors,
                show: colors.length > 0,
            },
            designShow: {
                values: backgrounds,
                show: backgrounds.length > 0,
            }
        })

        this.setState({
            colorsShow: {
                values: colors,
                show: colors.length > 0,
            },
            designShow: {
                values: backgrounds,
                show: backgrounds.length > 0,
            },
            whatShow: backgrounds.length>0 ? 'design' : (colors.length>0 ? 'color' : 'shop'),
        });
    }

    generatePreview() {
        if (this.props.stage) {
            console.log(this.props.isItemDownloading);
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                //if(this.state.firstLoad && document.getElementById('backgroundImgID_0')){
                //    document.getElementById('backgroundImgID_0').click();
                //}else{
                    let stage = this.props.stage.clone();
                    this.props.generatePreview({
                        pages: this.props.pages,
                        activePage: this.props.activePage,
                        stage: stage,
                        previewUrl: this.props.previewUrl
                    });
                    this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
                    setTimeout(() => {
                        if(document.getElementById("loaderdv_id")){
                            document.getElementById("loaderdv_id").style.display="none";
                        }
                        var myEle = document.getElementById("prv_id");
                        if(myEle){
                            myEle.style.opacity="1";
                        }
                    }, 3000); //20000
                //}
            }
        }
    }


    changeDesign(elem) {
        this.state.firstLoad = false;
        console.log('changeDesign');
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;

        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                let backgroundItem = item;
                backgroundItem.data = elem.background_image;
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem,
                    bgColor: false,
                    historyMode: true,
                    element: item.name,
                    smallBackground_image: elem.smallBackground_image,
                    activePage: this.props.activePage,
                });
            }
        });
        if(document.getElementById("loaderdv_id")){
            document.getElementById("loaderdv_id").style.display="flex";
        }
        if(document.getElementById("prv_id")){
            document.getElementById("prv_id").style.opacity="0.5";
        }
        //this.props.setPreviewUpdateStatus({previewShouldBeChanged: true});

        setTimeout(()=>{
            this.generatePreview();
        }, 0)

    }

    // changeBackgroundColor(elem) {
    //     console.log('changeBackgroundColor');
    //     let page = this.props.pages[this.props.activePage];
    //     let pageElements = page.canvasElements;
    //     pageElements.forEach((item) => {
    //         if (item.name === 'Background2') {
    //             this.props.onEditImageElement({
    //                 // backgroundItem : '',
    //                 data: undefined,
    //                 bgColor: '#' + elem,
    //                 historyMode: true,
    //                 element: item.name,
    //                 activePage: this.props.activePage,
    //             });
    //         }
    //     });
    //     document.getElementById("loaderdv_id").style.display="flex";
    //     document.getElementById("prv_id").style.opacity="0.5";
    //     //this.props.setPreviewUpdateStatus({previewShouldBeChanged: true});
    //     setTimeout(()=>{
    //         this.generatePreview();
    //     }, 0)

    // }


    changeBackgroundColor(elem) {

        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background2') {
                this.props.onEditImageElement({
                    // backgroundItem : '',
                    data: undefined,
                    // bgColor: '#' + elem,
                    bgColor: elem.hex,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });

                // this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                data: undefined,
                // bgColor: '#' + elem,
                bgColor: elem.hex,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background2'
            });
        }
            document.getElementById("loaderdv_id").style.display="flex";
            document.getElementById("prv_id").style.opacity="0.5";
            //this.props.setPreviewUpdateStatus({previewShouldBeChanged: true});
            setTimeout(()=>{
                this.generatePreview();
            }, 0)

    }

    // Colorpicker slider
    addBackgroundColor_new(color) {
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background2') {
                this.props.onEditImageElement({
                    data: undefined,
                    bgColor: color,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                data: undefined,
                bgColor: color,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background2'
            });
        }
        document.getElementById("loaderdv_id").style.display="flex";
        document.getElementById("prv_id").style.opacity="0.5";
        setTimeout(()=>{
            this.generatePreview();
        }, 0)
    }
    // Colorpicker slider End

    render() {
        console.log(this.state.background, "this.props?.Color::::>>>", this.props?.activeElement.colorKey)
        //console.log(this.state,'<========----->',this.props);
        if(this.props.displaytype=='design'){
            this.state.whatShow = 'design';
        }else if(this.props.displaytype=='color'){
            this.state.whatShow = 'color';
        }else{}

        const {previews: previewsProps} = this.props;
        let previews = previewsProps.map((item) => {
            return (
                <img id="prv_id" key={item.name} src={item.image} alt={''}/>
            )
        });

        return (
            
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                    onClick={this.props.hidePopup}/>
                <div
                    className={'text-form-popup-content-inner'}>
                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"
                            onClick={() => {
                                let IsTextPopup = false;
                                this.props.pages[0].canvasElements.forEach((i)=>{
                                    if(i.type === "text" && i.changeable){
                                        IsTextPopup = true
                                    }
                                });
                                if(this.state.whatShow =='design' || this.props.displaytype == 'design'){
                                    this.props.hidePopup();
                                    if(IsTextPopup){
                                        this.props.onShowTextForm();
                                        this.props.showTextChangePopup();
                                    }else{
                                        this.props.showFaceCropPopup();
                                    }
                                    this.props.WhichPopUp({ displaytype: 'shop' })
                                    this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                }else if(this.state.whatShow =='color' || this.props.displaytype == 'color'){
                                    if(this.state.designShow.values.length > 0){
                                        this.setState({
                                            whatShow: 'design',
                                        });
                                        if(this.props.displaytype == 'color'){
                                            this.props.hidePopup()
                                            this.props.showBackgroundChangePopup({ displaytype: 'design' })
                                            //this.props.WhichPopUp({ displaytype: 'color' })
                                        }
                                        this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                    }else if(IsTextPopup){
                                        this.props.hidePopup();
                                        this.props.onShowTextForm();
                                        this.props.showTextChangePopup();
                                        this.props.WhichPopUp({ displaytype: 'shop' })
                                        this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                    }else{
                                        this.props.hidePopup();
                                        this.props.showFaceCropPopup();
                                        this.props.WhichPopUp({ displaytype: 'shop' })
                                        this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                    }
                                }
                            }}>
                            <img src={back_btn} alt=""/>
                        </div>
                        <div className="text-form-popup-content-title">
                            {/* {this.state.colorsShow.values.length > 0 && this.state.colorsShow.show ? 'Background' : 'Design'} */}
                            {this.state.whatShow == 'color' ? <Translate content={"Hintergundfarbe_anpassen_lang"}/> : <Translate content={"Design_anpassen_lang"}/>}
                        </div>
                        <div className="text-form-popup-close"
                            onClick={() => {
                                window.parent.postMessage({datatype: "hide_designer"}, "*")
                                this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                window.onbeforeunload = null;
                            }}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className={'bgChangePopup'}>
                        <div className={'bgChangePopup__preview'}>
                            {this.props?.pages[this.props.activePage]?.renderers?.length > 0 ? (previews?.length > 0 && !this.props.previewShouldBeChanged ? previews :
                                <Translate content={"Loading"}/>) : "preview not fount"}
                            <div id="loaderdv_id" style={{display:"none"}}><Translate content={"Loading"}/></div>
                        </div>
                        {/* {this.state.colorsShow.values.length > 0 && this.state.colorsShow.show ? ( */}
                        {this.state.colorsShow.values.length > 0 && this.state.whatShow == 'color' ? 
                            <div className="colorpicker_slider_btn_wrapper">
                                <div className={ window.innerWidth > 616 ? "special_color_btn_slide_with_colors cstm_shape_color_slider desktop" : "special_color_btn_slide_with_colors cstm_shape_color_slider remove_margin_mobile"}>
                                    {this.state.textColorsListMobile.map((item) => {
                                        return (
                                            <div className={this.state.background == item ? "color_text_wrapper active" : "color_text_wrapper"}>
                                                <div key={item}
                                                    className="color_text"
                                                    style={{ 'backgroundColor': item }}
                                                    onClick={() => { this.handleChangeComplete_new(item) }}
                                                    >
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className={this.state.ColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}> 
                                        <img src={color_picker_icon}
                                            onClick={()=>{
                                                this.setState({
                                                    ColorPicker:!this.state.ColorPicker
                                                })
                                            }}>
                                        </img>
                                    </div>
                                </div>
                                {this.state.ColorPicker ? <>
                                    <SketchPicker 
                                    color={this.state.background}
                                    // color={this.state.background ?? this.props?.activeElement.colorKey}
                                        onChangeComplete={ this.handleChangeComplete }
                                    />                                

                                    {/* <CommonBackgroundChangePopup1>
                                        <div className="designer-tools-backgrounds-list cs-desk-view">
                                            <div className={"designer-tools-backgrounds-list-colors"}>
                                                <div className={"colorSwitchList"}>
                                                    {this.state.colorsShow.values.map((item, index) => {
                                                        return (
                                                            <Button key={index}
                                                                    onClick={this.changeBackgroundColor.bind(this, item)}
                                                                    // className={`colorSwitchBtn ${ activeColor && activeColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                                                    style={{'backgroundColor': '#' + item}}>
                                                            </Button>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </CommonBackgroundChangePopup1> */}
                                </> : null }
                            </div>
                        : null }

                        {/* {this.state.designShow.values.length > 0 && this.state.designShow.show && !this.state.colorsShow.show ? ( */}
                        {this.state.designShow.values.length > 0 && this.state.whatShow == 'design' ? (
                            <div>
                            <CommonBackgroundChangePopup2>
                                <div className="designer-tools-backgrounds-list cs-desk-view designer-tools-backgrounds-list__popup">
                                    {this.state.designShow.values.map((image, i) => {
                                        return (
                                            <img key={i}
                                                id={`backgroundImgID_${i}`}
                                                className="background-image"
                                                src={image.smallBackground_image || image.background_image}
                                                alt="alt"
                                                onClick={this.changeDesign.bind(this, image)}/>
                                        );
                                    })}
                                </div>
                                </CommonBackgroundChangePopup2>
                            </div>
                        ) : null}
                    </div>
                    <Button outline theme="secondary"
                            style={{
                                margin: '30px auto',
                                display: 'flex',
                                border: 'none',
                                fontWeight: '600',
                                padding: '16px',
                                fontSize: '16px',
                                color: '#FFFFFF',
                                background: '#333333',
                                borderRadius: '100px',
                            }}
                            onClick={() => {

                                let shouldTextPopupOpen = false;
                                this.props.pages[0].canvasElements.forEach((i)=>{
                                    if(i.type === "text" && i.changeable){
                                        shouldTextPopupOpen = true
                                    }
                                });
                            
                                // if(shouldTextPopupOpen){
                                //     this.props.onShowTextForm();
                                //     this.props.showTextChangePopup();
                                // } else {
                                    if(this.props.displaytype == 'design' || this.props.displaytype == 'color'){
                                        setTimeout(() => {
                                            this.props.showOrderPreviewPopup()
                                        }, 0)
                                    }else{
                                        if(this.state.whatShow=='color' || this.state.whatShow == 'shop'){
                                            setTimeout(() => {
                                                this.props.showOrderPreviewPopup()
                                            }, 0)
                                        }else{
                                            if(this.state.colorsShow.values.length>0){
                                                this.setState({
                                                    whatShow: this.state.whatShow =='design' ? 'color' : 'shop',
                                                });
                                            }else{
                                                setTimeout(() => {
                                                    this.props.showOrderPreviewPopup()
                                                }, 0)
                                            }
                                        }
                                    }
                                // }
                                console.log("shouldTextPopupOpen", shouldTextPopupOpen);


                            }}
                    >
                        {/* <Translate content={"done"}/> */}
                        <Translate content={"Continue"}/>
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        pages: state.glb.pages,
        previewUrl: state.glb.previewUrl,
        previews: state.prv.previews,
        isItemDownloading: state.glb.isItemDownloading,
        displaytype: state.glb.displaytype,
        //previewShouldBeChanged: state.glb.previewShouldBeChanged,


        uploadedImages: state.glb.uploadedImages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showDesignsForm: state.tol.showDesignsForm,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            //dispatch(actionCreators.hidePopup());
        },
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({data: data})),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },

        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        deleteSelectedElement: (params) => dispatch(actionCreators.deleteSelectedElement(params)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),

        showTextChangePopup: () => dispatch(actionCreators.showTextChangePopup()),
        onShowTextForm: () => {
            dispatch(actionCreators.showTextForm());
        },
        showBackgroundChangePopup: (data) => {
            dispatch(actionCreators.showBackgroundChangePopup(data));
        },
        WhichPopUp: (data) => {
            dispatch(actionCreators.WhichPopUp(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundChangePopup);
