export default {
    "Revert": "Deshacer",
    "Face crop":"Recortar imagen",
    "Remove background":"Eliminar fondo",
    "Loading":"Cargando...",
    "settings": "Ajustes",
    "gridlines": "Pautas",
    "layers": "Niveles",
    "image": "Imagen",
    "Text": "Texto",
    "shape": "Forma",
    "emoji": "Emoji",
    "done": "Hecho",
    "Custom Text": "Texto",
    "Close": "Cerrar",
    "Position": "Posición",
    "Rotate": "Rotar",
    "Change Image": "Cambiar imagen",
    "Crop Image": "Recortar imagen",
    "Upload Images": "Descargar imagen",
    "Add Text": "Añadir texto",
    "Cancel": "Cancelar",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Cambia el diseño a tu gusto haciendo click sobre el contenido de la izquierda",
    "Shapes": "Formas configurables",
    "Add a line": "Añadir línea",
    "Add a rectangle": "Añadir rectángulo",
    "Add a circle": "Añadir cruz",
    "Add a triangle": "Añadir triángulo ",
    "Color": "Color",
    "Edit Text": "Modificar texto",
    "Font type": "Fuente",
    "Font Style": "Estilo",
    "Size": "Tamaño",
    "Justification": "Alineación",
    "Letter spacing": "Espacio entre letras",
    "Flip": "Dar la vuelta",
    "Save": "Guardar",
    "Preview": "Vista previa",
    "Editor": "Editor",
    "Uploads": "Imágenes descargadas",
    "Letter limit": "Máximo de caracteres",
    "Recently Used Colors": "Colores usados recientemente",
    "Drag and drop your images here!": "Arrastra y suelta tus imágenes aquí",
    "view": "Frontal",
    "Reset changes": "Volver atrás",
    "Delete": "Borrar",
    "Retry": "De nuevo",
    "Background not recognized":"Fondo no identificado",
    "Background already removed":"Fondo eliminado",
    "You placed an element outside the printable area.": "Hay un elemento fuera del área imprimible.",
    "There was error during getting files!": "¡Hubo un error al cargar el archivo!",
    "Your Image is too big to upload! The max file size is 8 MB": "Tu imagen es demasiado grande. El tamaño máximo del archivo es de 8 MB",
    "Your image is too small": "Tu imagen es demasiado pequeña. Por favor selecciona otra.",
    "Stroke": "Contorno",
    "Stroke color": "Color del contorno",
    "BackgroundsTools": "Fondo",
    "ColorsTools": "Color",
    "ImagesTools" : "Imagen",
    "Shadow color": "Color del sombreado",
    "Shadow": "Sombreado",
    "Information before ordering": "Antes de continuar comprueba el diseño con atención y confirma, porque así es como se va a imprimir. Más información en nuestras preguntas frecuentes FAQs.",
    "Add to cart": "Añadir al carrito de la compra",
    "edit Image": "Modificar",
    "Add_Image": "Foto adicional",
    "Cut": "Recortar imagen",
    "Change": "Subir Foto",
    "More": "Más",
    "Edit": "Modificar texto",
    "Font": "Fuente",
    "Font Size": "Tamaño",
    "Letter Space": "Espacio",
    "Text Color": "Color",
    "New text": "Añadir texto",
    "Align": "Alineación",
    "Flip X": "Dar la vuelta",
    "Oops, didn't you forget something?": "¡Vaya!, ¿has olvidado algo?Parece que no has completado toda tu personalización, por favor revisa tu producto y reemplaza la imagen predefinida (excepto si te gusta tal y como aparece ahora).",
    "Use original image": "Usar imagen original",
    "Continue": "Continuar",
    "Wird freigestellt": "Eliminando el fondo...",

    "texts_lang": 'Modifica el texto',
    "Design_anpassen_lang": "Modifica el diseño",
    "Hintergundfarbe_anpassen_lang": "Modifica el fondo",
    "Farbe_anpassen_lang": "Modifica el fondo",
    "Weiter_lang": "Continuar",
    "Text_anpassen_lang":"Modifica el texto",
    "Mehr_anpassen_lang" :"Modifica los elementos",
    "Bild_hinzufüge_lang":"Foto adicional",
    "alert_error_message": "¡Oh, vaya! Parece que este formato de imagen no es compatible. Utiliza por favor alguno de los siguientes formatos: jpg, png, jpeg o jfif.",
    "back_button": "Atrás",
    "textarea_popup_text": " Para cambiar el texto, basta con hacer clic en el campo de texto. Haciendo clic en el lápiz puedes formatear el texto.",
    "bild_popup_text": "Utiliza este botón para subir tus imágenes.",
    "Color_Btn_Text": "Color",
    "Shadow_Btn_Text": "Sombreado",
    "Stroke_Btn_Text": "Contorno",
    "upload_photos_Btn_Text": "Subir foto",
    "Upload_Btn_Text": "Cargar",
    "Upload_More_Photos_Btn_Text": "Subir más fotos",
    "Edit_Text_Btn_Text": "Modificar el texto",
    "Save_Btn_Text": "Guardar",
    "Change_Text_Btn_Text": "Cambiar el texto",
    "Design_Btn_Text": "Diseño",
    "Face_cropper_loading_text": "Cargando...",

}