import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from "../../store/actions";
import "./Properties.scss"

import Position from "./PropertiesComponents/Position";

import ChangeSize from "./PropertiesComponents/ChangeSize";
import DeleteBtn from "./PropertiesComponents/DeleteBtn";
import {FormInput} from "shards-react";

class PropertiesResolver extends Component {
    constructor(props) {
        super(props);
        this.COMMON_PROPERTIES = [DeleteBtn, Position, ChangeSize];
    }

    changeBorderLimit(val, type) {
        this.props.setBorderLimitEditingStatus({
            isBorderLimitEditing: {
                status: this.props.isBorderLimitEditing.status,
                x: type === 'x' ? val * 1 : (this.props.isBorderLimitEditing?.x || this.props.pages[this.props.activePage].borderLimit?.x || 0),
                y: type === 'y' ? val * 1 : (this.props.isBorderLimitEditing?.y || this.props.pages[this.props.activePage].borderLimit?.y || 0),
                width: type === 'width' ? val * 1 : this.props.isBorderLimitEditing?.width || this.props.pages[this.props.activePage].borderLimit?.width || 592,
                height: type === 'height' ? val * 1 : this.props.isBorderLimitEditing?.height || this.props.pages[this.props.activePage].borderLimit?.height || 592,
                scaleX: 1,
                scaleY: 1,
            }
        });
    }

    customMaskChange(e, prop, scale, name) {
        let canvasMaskElements = this.props.canvasMaskElements.map((item) => {
            if (item.name === name && item.type !== 'line') {

                // Check if Multipart Template is active
                if(this.props.pages[this.props.activePage]?.multipartTemplate === 1 && prop === 'layer_name'){
                    item[prop] = e;
                }else{
                    item[prop] = e * 1;
                }

                if (scale) item[scale] = 1;

            } else if (item.type === 'line') {
                 item.points = item.points.map((i, index)=>{
                     return index === prop ? e*1 : i
                 })
            }

            return item
        });

        canvasMaskElements = JSON.stringify(canvasMaskElements)

        this.props.setCustomMaskEditingStatus({
            isCustomMaskEditing: {
                status: this.props?.isCustomMaskEditing?.status,
                canvasMaskElements: canvasMaskElements
            }
        });
    }

    borderLimitChange(type, val){
        let canvasMaskElements = this.props.canvasMaskElements.map((item) => {
            if (item.name === 'borderLimit') {
                if(type === 'x' || type === 'x4'){
                    let diff = item.x - val.target.value*1;
                    item.width = item.width * item.scaleX + diff;
                    item.scaleX = 1

                    item.x = val.target.value*1
                } else if (type === 'x2' || type === 'x3'){
                    item.width = val.target.value*1 * item.scaleX  - item.x;
                    item.scaleX = 1
                    // item.x = val.target.value*1
                } else if(type === 'y' || type === 'y2'){
                    let diff = item.y - val.target.value*1;
                    item.height = item.height * item.scaleY + diff;
                    item.scaleY = 1

                    item.y = val.target.value*1
                }else if(type === 'y3' || type === 'y4'){
                    item.height = val.target.value*1 * item.scaleY  - item.y;
                    item.scaleY = 1
                } else if(type === 'width'){
                    item.width = val.target.value*1;
                    item.scaleX = 1
                } else if(type === 'height'){
                    item.height = val.target.value*1
                    item.scaleY = 1
                }
            }
            return item
        });


        canvasMaskElements = JSON.stringify(canvasMaskElements)

        this.props.setCustomMaskEditingStatus({
            isCustomMaskEditing: {
                status: this.props?.isCustomMaskEditing?.status,
                canvasMaskElements: canvasMaskElements
            }
        });
    }


    render() {console.log('this.props.canvasMaskElements',this.props);
        let canvasMaskElements = this.props.canvasMaskElements.map((item) => {
            switch (item.type) {
                case 'line':
                    if (item.points.length >= 2) {
                        return <div>
                            <br/> <b>{item.name}:</b>
                            {item.points.map((item, index) => {
                                const even = n => !(n % 2);
                                return <div style={{"width": '50%', "display": 'inline-block'}}>
                                    {even(index) ? 'x' : 'y'} : <FormInput type="number"
                                                                           size="sm"
                                                                           disabled={this.props.isMaskDrawMode}
                                                                           onChange={(e) => this.customMaskChange(e.target.value, index, undefined, item.name)}
                                                                           value={Math.round(item)}
                                />
                                </div>
                            })}
                        </div>
                    }

                    break
                case 'square':
                    if (item.name !== 'borderLimit') {
                        return <div>                                                        
                            {this.props.pages[this.props.activePage]?.multipartTemplate === 1 ?
                                <>
                                    <br/><strong>Name:</strong>
                                    <FormInput type="text"
                                        size="sm"
                                        className="name-input"
                                        disabled={this.props.isMaskDrawMode}
                                        onChange={(e) => this.customMaskChange(e.target.value, 'layer_name', 'undefined', item.name)}
                                        value={item.layer_name}
                                    /> 
                                    <br/>
                                </>
                            : 
                                <>
                                    <br/><b>{ item.type }</b>
                                </>
                            }

                            Width : <FormInput type="number"
                                               size="sm"
                                               disabled={this.props.isMaskDrawMode}
                                               onChange={(e) => this.customMaskChange(e.target.value, 'width', 'scaleX', item.name)}
                                               value={Math.round(item.width * item.scaleX)}
                        />
                            <br/>
                            Height : <FormInput type="number"
                                                size="sm"
                                                disabled={this.props.isMaskDrawMode}
                                                onChange={(e) => this.customMaskChange(e.target.value, 'height', 'scaleY', item.name)}
                                                value={Math.round(item.height * item.scaleY)}
                        />
                            <br/>
                            X : <FormInput type="number"
                                           size="sm"
                                           disabled={this.props.isMaskDrawMode}
                                           onChange={(e) => this.customMaskChange(e.target.value, 'x', undefined, item.name)}
                                           value={Math.round(item.x)}
                        />
                            <br/>
                            Y : <FormInput type="number"
                                           size="sm"
                                           disabled={this.props.isMaskDrawMode}
                                           onChange={(e) => this.customMaskChange(e.target.value, 'y', undefined, item.name)}
                                           value={Math.round(item.y)}
                        />
                        </div>
                    } else {
                        return <>
                            <br/>
                            <b>Border Limit:</b>
                            <span>width </span>
                            <FormInput type="number"
                                       size="sm"
                                       disabled={this.props.isMaskDrawMode}
                                       onChange={(e) => this.borderLimitChange('width', e)}
                                       value={Math.round(item.width * item.scaleX) }
                            />
                            <br/>
                            <span>height </span>
                            <FormInput type="number"
                                       size="sm"
                                       disabled={this.props.isMaskDrawMode}
                                       onChange={(e) => this.borderLimitChange('height', e)}
                                       value={Math.round(item.height * item.scaleY)}
                            />
                            <br/>
                            <span>x 1 </span>
                            <FormInput type="number"
                                       size="sm"
                                       disabled={this.props.isMaskDrawMode}
                                       onChange={(e) => this.borderLimitChange('x', e)}
                                       value={Math.round(item.x)}
                            />
                            <br/>
                            <span>y 1 </span>
                            <FormInput type="number"
                                       size="sm"
                                       disabled={this.props.isMaskDrawMode}
                                       onChange={(e) => this.borderLimitChange('y', e)}
                                       value={Math.round(item.y)}
                            />
                            <br/>
                            <span>x 2 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.width * item.scaleX) + Math.round(item.x)}
                                       onChange={(e) => this.borderLimitChange('x2', e)}
                            />
                            {/*           value={Math.round(this.props?.isBorderLimitEditing?.width || this.props.pages[this.props.activePage].borderLimit?.width || width) + Math.round(this.props?.isBorderLimitEditing?.x || this.props.pages[this.props.activePage].borderLimit?.x || x)}*/}
                            <br/>
                            <span>y 2 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.y)}
                                       onChange={(e) => this.borderLimitChange('y2', e)}
                            />

                            <br/>
                            <span>x 3 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.width * item.scaleX) + Math.round(item.x)}
                                       onChange={(e) => this.borderLimitChange('x3', e)}
                            />
                            <br/>
                            <span>y 3 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.height * item.scaleY) + Math.round(item.y)}
                                       onChange={(e) => this.borderLimitChange('y3', e)}
                            />
                            <br/>
                            <span>x 4 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.x)}
                                       onChange={(e) => this.borderLimitChange('x4', e)}
                            />
                            <br/>
                            <span>y 4 </span>
                            <FormInput type="number"
                                       size="sm"
                                       // disabled
                                       value={Math.round(item.height * item.scaleY) + Math.round(item.y)}
                                       onChange={(e) => this.borderLimitChange('y4', e)}
                            />
                        </>
                    }

                    // break
                default :
                    return <div>
                        {this.props.pages[this.props.activePage]?.multipartTemplate === 1 ?
                            <>
                                <br/><strong>Name:</strong>
                                <FormInput type="text"
                                    size="sm"
                                    className="name-input"
                                    disabled={this.props.isMaskDrawMode}
                                    onChange={(e) => this.customMaskChange(e.target.value, 'layer_name', 'undefined', item.name)}
                                    value={item.layer_name}
                                /> 
                                <br/>
                            </>
                        : 
                            <>
                                <br/><b>{ item.type }</b>
                            </>
                        }
                        
                        Width : <FormInput type="number"
                                           size="sm"
                                           disabled
                                           value={Math.round(item.radius * 2 * item.scaleX)}
                    />
                        <br/>
                        ScaleX : <FormInput type="number"
                                            size="sm"
                                            disabled={this.props.isMaskDrawMode}
                                            onChange={(e) => this.customMaskChange(e.target.value, 'scaleX', undefined, item.name)}
                                            value={item.scaleX}
                    />
                        <br/>
                        Height: <FormInput type="number"
                                           size="sm"
                                           disabled
                                           value={Math.round(item.radius * 2 * item.scaleY)}
                    />
                        <br/>
                        ScaleY : <FormInput type="number"
                                            size="sm"
                                            disabled={this.props.isMaskDrawMode}
                                            onChange={(e) => this.customMaskChange(e.target.value, 'scaleY', undefined, item.name)}
                                            value={item.scaleY}
                    />
                        <br/>
                        X: <FormInput type="number"
                                      size="sm"
                                      disabled={this.props.isMaskDrawMode}
                                      onChange={(e) => this.customMaskChange(e.target.value, 'x', undefined, item.name)}
                                      value={Math.round(item.x)}
                    />
                        <br/>
                        Y: <FormInput type="number"
                                      size="sm"
                                      disabled={this.props.isMaskDrawMode}
                                      onChange={(e) => this.customMaskChange(e.target.value, 'y', undefined, item.name)}
                                      value={Math.round(item.y)}
                    />
                    </div>
            }
        })

        return <div className={"propMask"}>
            {this.props.isBorderLimitEditing.status ? <div>
            </div> : <div>
                {canvasMaskElements.length > 0 ? canvasMaskElements : null}
            </div>}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        selectedElement: state.glb.selectedElement,
        stage: state.glb.stage,
        isMaskDrawMode: state.cnvMask.isMaskDrawMode,

        activePage: state.glb.activePage,
        activeElement: state.glb.activeElement,
        storeViews: state.glb.storeViews,
        mode: state.glb.mode,
        pages: state.glb.pages,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserAllowedProperties: (data) => {
            dispatch(actionCreators.userAllowedProperties(data));
        },
        setCustomMaskEditingStatus: (data) => dispatch(actionCreators.setCustomMaskEditingStatus(data)),
        setBorderLimitEditingStatus: (data) => dispatch(actionCreators.setBorderLimitEditingStatus(data)),
        setNewElementIndex: (data) => dispatch(actionCreators.setNewElementIndex(data)),
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onShowQuickEditLabelsPopup: () => dispatch(actionCreators.showQuickEditLabelsPopup()),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertiesResolver);
