import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Translate from "react-translate-component";
import { Button } from "shards-react";
import textTransformer from "../../helpes/textTransformer";

class TextEditPopup extends Component {
    PopupEditTextInput = React.createRef();
    state = {
        update: false,
        text: this.PopupEditTextInput.current?.value
    };

    updateValues = () => {
        this.setState({update: !this.state.update});
        let
            quickEditStoreViewValues = {},
            inputs = document.getElementsByClassName("text-form-popup-content-store");

        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            quickEditStoreViewValues[storeId] = inputs[i].value;
        }
        this.props.updateElementStoreViewValues(quickEditStoreViewValues);
    }

    textChangeHandle(newText) {
        const selectionStart = this.PopupEditTextInput.current.selectionStart
        const selectionEnd = this.PopupEditTextInput.current.selectionEnd

        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
        let text = textTransformer(this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.target.value : newText.target.value.replace(new RegExp(ranges, 'g'), ''), this.props.activeElement?.attrs.user_textTransform?.value);
        this.setState({
            text: text
        });

        this.PopupEditTextInput.current.value = text

        if (this.PopupEditTextInput?.current) {
            this.PopupEditTextInput.current.selectionStart = selectionStart;
            this.PopupEditTextInput.current.selectionEnd = selectionEnd;
        }
        return text
    }

    deleteElement() {
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            this.props.deleteSelectedElement({
                element: this.props.activeElement?.attrs.name,
                activePage: this.props.activePage,
            });
            this.props.onSelectElement("");
        }
    }

    changeText() {
        let quickEditStoreViewValues = [];

        let inputs = document.getElementsByClassName("text-form-popup-content-store");
        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            quickEditStoreViewValues.push({store_id: storeId, label: inputs[i].value});
        }

        if (this.PopupEditTextInput?.current?.value?.length > 0) {

            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                    && this.props.activeElement.attrs.elementId !== 0
                    && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.attrs.name)) {
                    this.props.onEditElement({
                        store_view_values: quickEditStoreViewValues,
                        newText: this.PopupEditTextInput.current.value,
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                    });
                }
            });



            // TODO: delete setTimeout
            setTimeout(() => {
                if (this.props.activeElement?.attrs.textMaxWidth) this.props.activeElement.width(this.props.activeElement.attrs.textMaxWidth)
                let {name, align} = this.props.activeElement.attrs;

                this.props.onEditElement({
                    align: "left",
                    element: name,
                    historyMode: false,
                    activePage: this.props.activePage,
                });

                this.props.onEditElement({
                    align: align,
                    element: name,
                    historyMode: false,
                    activePage: this.props.activePage,
                });

                switch (this.props.activeElement.attrs.align) {
                    case "right":
                        this.props.activeElement.offsetX(this.props.activeElement.width());
                        break;
                    case "center":
                        this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                        break;
                    default:
                        break;
                }

            }, 0)
        } else {
            this.deleteElement();
            this.props.hidePopup();
        }
    }

    render() {
        let storeViewsFields = this.props.storeViews.map((item) => {
            if (item.id !== 0) {
                return <div key={item.id} className="quick-edit-label-field">
                    <div className="quick-edit-label-field-label">
                        <label>{item.store_name}:</label>
                    </div>
                    <div className="quick-edit-label-field-input">
                        <textarea rows="2" className="text-form-popup-content-store"
                                  name={'text-form-popup-content-store-' + item.id}
                                  onChange={(event) => this.updateValues(event)}>{this.props.selectedElementStoreViewValues[item.id]}</textarea>
                    </div>
                </div>
            }
            return null
        });

        return (this.props.showTextEditPopup ? (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>
                <div className="text-form-popup-content-inner">
                    <div className="text-form-popup-content-title">
                        <Translate content={"Edit Text"}/>
                    </div>
                    <textarea ref={this.PopupEditTextInput} className="text-form-popup-input" rows="5"
                              cols="3"
                              defaultValue={this.props.activeElement?.attrs?.text}
                              onChange={this.textChangeHandle.bind(this)}
                              maxLength={this.props?.activeElement?.attrs?.textLimit || undefined}
                              autoFocus={true}
                    />
                    <div className="text-form-popup-content-buttons textareaPopupEditText">
                        <Button theme="secondary" onClick={this.changeText.bind(this)}>
                            OK
                        </Button>
                        <Button outline theme="secondary" onClick={() => this.props.hidePopup()}>
                            <Translate content={"Cancel"}/>
                        </Button>

                    </div>
                    {((this.props.mode === 'ADMIN') ?
                        <div className="text-form-popup-content-store-values">
                            {storeViewsFields}
                        </div>
                        : null)}
                </div>
            </div>
        ) : null)
    }
}

const mapStateToProps = state => {
    return {
        stage: state.glb.stage,
        activePage: state.glb.activePage,
        selectedElement: state.glb.selectedElement,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        activeElement: state.glb.activeElement,
        showTextEditPopup: state.tol.showTextEditPopup,
        storeViews: state.glb.storeViews,
        selectedElementStoreViewValues: state.glb.selectedElementStoreViewValues,
        mode: state.glb.mode,
        pages: state.glb.pages,
        selectedElements: state.glb.selectedElements,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        updateElementStoreViewValues: (data) => {
            dispatch(actionCreators.setElementStoreViewValues(data));
        },
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        hidePopup: () => dispatch(actionCreators.hidePopup())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextEditPopup);
