import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import { FormInput } from "shards-react";

class PositionProperty extends Component {

    textMaxWidth = React.createRef();

    handleFontSizeChange(e) {
        const { stage, activePage, activeElement } = this.props;
        const { value } = e.target;

        this.props.onEditElement({
			textMaxWidth: parseInt(value) > 50 ? parseInt(value) : 50,
			element: activeElement.attrs.name,
			activePage: activePage,
        });

		if (stage) {
            stage.children[activePage].children.map((item) => {
                if (item.attrs.name === activeElement.attrs.name && item.attrs.type === "text") {
                    if( item.attrs.textMaxWidth) {
                        item.width(parseInt(value) > 50 ? parseInt(value) : 50);
                        item.scaleX(1);
                    }
                }
                return null
            });
        }
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.textMaxWidth.current) this.textMaxWidth.current.value = attrs.textMaxWidth || 0;
    }

    render() {
        return (
            <div className="designer-right-panel-item designer-right-panel-item__fontStyle">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>Text Max-Width</span>
                </div>
                <div className={"designer-right-panel-inputNumber"}>
                    <FormInput type="number"
                               size="sm"
                               defaultValue={(this.textMaxWidth.current ? this.textMaxWidth.current.value : (this.props.activeElement.attrs.textMaxWidth || 0))}
                               innerRef={this.textMaxWidth}
                               onChange={this.handleFontSizeChange.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
