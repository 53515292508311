// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".popups_canvas-crop-popup__1_yXV {\n  margin: 0 auto; }\n  @media (max-width: 768px) {\n    .popups_canvas-crop-popup__1_yXV {\n      overflow: hidden; } }\n", ""]);
// Exports
exports.locals = {
	"canvas-crop-popup": "popups_canvas-crop-popup__1_yXV"
};
module.exports = exports;
