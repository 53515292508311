import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Translate from "react-translate-component";
import { Button } from "shards-react";
import main_original_face from "../../assets/images/main_original_face.png";
import default_face from "../../assets/images/default_face.jpg";
import sketch_face from "../../assets/images/sketch_face.jpg";
import stl from "../Tools/ToolsViews/ToolsViews.module.scss";
import URLImage from '../Canvas/URLImage/URLImage';
import {post} from "axios";

import queryString from 'query-string';
const params = queryString.parse(window.location.search);

class FaceCutoutPopup extends Component {

    state = {
        loading_wait: false,
        original_image: true,
        comic_image: false,
    };

    CutOutFaceOriginal(){
        console.log('Original');
        this.setState({
            original_image: true,
            comic_image: false,
        });
    }

    CutOutFaceComic(){
        this.setState({
            loading_wait: true
        });

        const check_comic_image = localStorage.getItem("FaceCropCutoutImageComic");

        if (this.props.activeElement?.attrs?.image?.src && check_comic_image == 0) {console.log(1111);
            let config = { headers: { 'content-type': 'multipart/form-data' } };
            let data   = JSON.stringify({ imageUrl: this.props.activeElement?.attrs?.image?.src, 
                cartoonType: this.props?.activeElement?.attrs?.cartoonTypeValue });
            post( 
                'https://uploader2.radbag.de/cartoonSelfie', data, config).then(response => {
                    if (response.data.error) {
                        /* Show error */
                        console.error(response.data.data);
                    } else {console.log(2222);
                        // this.props.onEditElement({
                        //     imageWithoutBg: response.data.imageUrl,
                        //     element: this.props.activeElement.attrs.name,
                        //     activePage: this.props.activePage,
                        // });

                        console.log('Response',response.data,this);
                        
                        fetch(response.data.imageUrl)
                        .then(response => {
                            return response.blob();
                        }) 
                        .then((response) => {
                            this.setState({
                                imgSource: URL.createObjectURL(response),
                            });
                        });
                            
                    }
                    // Set Image path in local storage
                    localStorage.setItem("FaceCropCutoutImageComic", response.data.imageUrl);
                    const cutout_image = localStorage.getItem("FaceCropCutoutImageComic");
                    console.log('cutout_image',cutout_image);
                    
                    this.setState({
                        loading_wait: false,
                        original_image: false,
                        comic_image: true,
                    });
                    
                }).catch(() => {
                    // this.props.onEditElement({
                    //     imageWithoutBg: false,
                    //     element: this.props.activeElement.attrs.name,
                    //     activePage: this.props.activePage,
                    // });
                    this.setState({
                        loading_wait: false,
                    });
                });
                
        }else{
            this.setState({
                loading_wait: false,
                original_image: false,
                comic_image: true,
            });
        }
    }
    
    render() {        
        // let canvasElements = this.props.pages[this.props.activePage].canvasElements;
        //     canvasElements.map((item) => {
        //         if(item.type == 'image' && item.isCutoutPro == true && item.cartoonTypeValue > 0){
        //             console.log(11111);
        //             if (item.width === undefined || item.height === undefined) {
        //                 item.width = 100;
        //                 item.height = 100;
        //             } 
        //             return (<URLImage/>);
        //         }else{
        //             console.log(2222222);
        //         }
        //     });
            console.log('props', this);
            var show_cutout_image = main_original_face;
            if(this.state.original_image){console.log('original');
                show_cutout_image = localStorage.getItem("FaceCropCutoutImageOriginal");
            }else if(this.state.comic_image){console.log('comic');
                show_cutout_image = localStorage.getItem("FaceCropCutoutImageComic");
            }else{console.log('default');
                show_cutout_image = main_original_face;
            }

        if (this.props.isShowFaceCutoutPopup) {
            return (
                <div className="text-form-popup-content face-cutout-popup-wrapper">
                    {/* Show loader till get API response */}
                    {
                        this.state.loading_wait ?
                            <div className="loading-cust-wait">
                                <div className="loading-text">
                                    <Translate content={"Loading"} />
                                </div>
                            </div>
                        : null
                    }
                    <div className="text-form-popup-content-inner">
                        {/* Face croped image section */}
                        <div className="main_Image_wrapper">
                           <img src={show_cutout_image} alt="" className="img-fluid" />
                        </div>
                        <div className="two_facecut_Images_wrapper">
                            <div className="two_facecut_Image">
                                <img src={default_face} alt="" className={this.state.original_image ? "active img-fluid" : "img-fluid"} 
                                    onClick={this.CutOutFaceOriginal.bind(this)} />
                                <p>original cutout</p>
                            </div>
                            <div className="two_facecut_Image">
                               <img src={sketch_face} alt="" className={this.state.comic_image ? "active img-fluid" : "img-fluid"} 
                                onClick={this.CutOutFaceComic.bind(this)} />
                                <p>comic cutout</p>
                            </div>
                        </div>
                        {/* Button section */}
                        <div className="text-form-popup-content-buttons facecut_button_wrapper">
                            <Button outline theme="secondary" onClick={() => {                            
                                this.props.hidePopup();
                                this.props.showImageCropPopup();
                            }}>
                                <span>Edit</span>
                            </Button>
                            <Button className={'custom-secondary'} theme="secondary" 
                                onClick={() => {                            
                                    this.props.onEditImageElement({
                                        element: 'element-0-3',
                                        activePage: this.props.activePage,
                                        //imageCrop: cropImage(cW, cH, width, height),
                                        newImgData: {
                                            imageUrl: show_cutout_image,
                                            //width: 1000,
                                            //height: 1000,
                                        },
                                        // isCroped :false
                                    });
                                    setTimeout(()=>{
                                        this.props.hidePopup();    
                                    }, 1500);
                                }}>
                                <span>OK</span>
                            </Button>                              
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        mode: state.glb.mode,
        pages: state.glb.pages,
        isShowFaceCutoutPopup: state.tol.ShowFaceCutoutPopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        selectedElements: state.glb.selectedElements,
        selectedElement: state.glb.selectedElement,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        hidePopup: () => {
            dispatch(actionCreators.hidePopup());
        },
        setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            // dispatch(actionCreators.hidePopup());
        },
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        showImageCropPopup: () => dispatch(actionCreators.showImageCropPopup()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FaceCutoutPopup);
