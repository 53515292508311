export default {
    "Revert": "Indietro",
    "Retry": "Riprova",
    "Face crop": "Elimina sfondo",
    "Remove background": "Rimuovi sfondo",
    "Loading": "Caricamento...",
    "Background not recognized": "Sfondo non riconosciuto",
    "Background already removed": "Sfondo già rimosso",
    "settings": "Impostazioni",
    "gridlines": "Linee guida",
    "layers": "Livelli",
    "image": "Immagine",
    "Text": "Testo",
    "shape": "Forma",
    "emoji": "Emoticon",
    "done": "Fatto",
    "view": "Fronte (Hinterseite: Retro)",
    "Custom Text": "Testo inserito",
    "Close": "Chiudi",
    "Position": "Posizione",
    "Rotate": "Ruota",
    "Change Image": "Cambia immagine",
    "Crop Image": "Ritaglia immagine",
    "Upload Images": "Carica immagini",
    "Add Text": "Aggiungi testo",
    "Cancel": "Annulla",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Inizia a creare il tuo capolavoro scegliendo tra i contenuti a sinistra.",
    "Shapes": "Forme",
    "Add a line": "Aggiungi una linea",
    "Add a rectangle": "Aggiungi un rettangolo",
    "Add a circle": "Aggiungi un cerchio",
    "Add a triangle": "Aggiungi un triangolo",
    "Color": "Colore",
    "Edit Text": "Testo",
    "Font type": "Font",
    "Font Style": "Stile",
    "Size": "Grandezza",
    "Justification": "Allineamento",
    "Letter spacing": "Distanza tra le lettere",
    "Flip": "Capovolgi",
    "Save": "Salva",
    "Preview": "Anteprima",
    "Editor": "Editor",
    "Uploads": "Immagini caricate",
    "Letter limit": "Limite di caratteri",
    "Recently Used Colors": "Colori usati recentemente",
    "Drag and drop your images here!": "Trascina & rilascia le tue immagini qui",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed": "Hai posizionato un elemento al di fuori della superficie di stampa. Per favore, conferma di aver compreso che tale elemento non sarà stampato completamente o correttamente",
    "There was error during getting files!": "Si è verificato un errore durante il caricamento delle immagini. Si prega di ricaricare la pagina e riprovare.",
    "Your Image is too big to upload! The max file size is 8 MB": "La tua immagine è troppo grande per essere caricata! Le dimensioni massime consentite sono 8 MB",
    "Your image is too small": "L'immagine selezionata è troppo piccola per essere stampata in alta qualità. Per favore, seleziona un'altra immagine.",
    "Reset changes": "Elimina modifiche",
    "Delete": "Rimuovere",
    "Stroke": "Contorno",
    "Stroke color": "Colore contorno",
    "BackgroundsTools": "Sfondi",
    "ColorsTools": "Colori",
    "ImagesTools": "Immagini",
    "Shadow color": "Colore ombra",
    "Shadow": "Ombra",
    "Information before ordering": "Vi preghiamo di controllare e confermare tutto con attenzione, perché poi viene stampato così. Per saperne di più, consultate le nostre FAQ.",
    "Add to cart": "Aggiungi al carrello",
    "More": "Più",
    "edit Image": "Modifica",
    "Add_Image": "Aggiungi immagine",
    "Cut": "Ritaglia immagine",
    "Change": "Carica immagine",
    "Edit": "Modifica testo",
    "Font": "Font",
    "Font Size": "Grandezza",
    "Letter Space": "Spaziatura",
    "Text Color": "Colore",
    "New text": "Aggiungi testo",
    "Align": "Allineamento",
    "Flip X": "Capovolgi",
    "Oops, didn't you forget something?": "Ops, non hai dimenticato qualcosa? Sembra che tu non abbia personalizzato tutto il necessario. Ti preghiamo di controllare il prodotto e di sostituire le immagini predefinite (a meno che non ti piacciano così come sono!).",
    "Use original image": "Utilizza immagine originale ",
    "Continue": "Continua",
    "Wird freigestellt": "Elimino lo sfondo...",

    "texts_lang": 'Modificare il testo',
    "Design_anpassen_lang": "Modificare il design",
    "Hintergundfarbe_anpassen_lang": "Modificare lo sfondo",
    "Farbe_anpassen_lang": "Modificare lo sfondo",
    "Weiter_lang": "Continua",
    "Text_anpassen_lang":"Modifier le texte",
    "Mehr_anpassen_lang" :"Modificare gli elementi",
    "Bild_hinzufüge_lang":"Carica immagine",
    "alert_error_message": "Oh, no! Purtroppo questo formato di immagine non è supportato. Preghiamo di utilizzare uno dei seguenti.",
    "back_button": "Torna indietro",
    "textarea_popup_text": "Per modificare il testo, è sufficiente cliccare nel campo del testo. Facendo clic sulla matita si può formattare il testo.",
    "bild_popup_text": "Utilizzare questo pulsante per caricare le immagini.",
    "Color_Btn_Text": "Colore",
    "Shadow_Btn_Text": "Ombra",
    "Stroke_Btn_Text": "Contorno",
    "upload_photos_Btn_Text": "Carica Foto",
    "Upload_Btn_Text": "Caricare",
    "Upload_More_Photos_Btn_Text": "Caricare altre Foto",
    "Edit_Text_Btn_Text": "Modificare il Testo",
    "Save_Btn_Text": "Salva le modifiche",
    "Change_Text_Btn_Text": "Modificare il Testo",
    "Design_Btn_Text": "Design",
    "Face_cropper_loading_text": "Caricamento...",

}