// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/upload-image.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Dropzone {\n    margin-top: 20px;\n    height: 210px;\n    width: 100%;\n    text-align: center;\n    /*background-color: #fff;*/\n    font-size: 16px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-position: center center;\n\n}\n\n.Dropzone .dropzone-info {\n    display: block;\n    padding-top: 95px;\n    color: #ccc;\n    font-size: 14px;\n}\n\n.DropzoneFaces {\n    background: none;\n    height: auto;\n}\n\n.Dropzone .dropzone-upload-button {\n    margin: 10px 0;\n}\n\n.Dropzone .dropzone-upload-button-faceUpload  .btn-outline-secondary{\n    border-radius: 100px;\n    margin: 40px auto;\n}\n\n/*.Dropzone .dropzone-upload-button button {*/\n/*    border: 1px dashed #868686;*/\n/*    background-color: transparent;*/\n/*    padding: 11px 45px;*/\n/*    border-radius: 4px;*/\n/*    cursor: pointer;*/\n/*}*/\n\n/*.Dropzone .dropzone-upload-button button:hover {*/\n/*    background-color: #e6f5f8;*/\n/*    color: #059dc1*/\n/*}*/\n\n.Highlight {\n    border: 1px dashed #868686;\n    background-color: #dbf2ff !important;\n}\n\n.Dropzone.Hidden {\n    height: 60px;\n    background-image: none;\n}\n\n.Dropzone.Hidden .dropzone-info {\n    display: none;\n}\n\n.FileInput {\n    display: none;\n}\n\n.ProgressBar {\n    background-color: #dbf2ff !important;\n    width: 85% !important;\n    margin-right: 5px;\n}\n\n.Progress {\n    background-color: #059dc1 !important\n}\n\n.Files .Row .Filename {\n    font-size: 13px;\n}\n\n", ""]);
// Exports
module.exports = exports;
