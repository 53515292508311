export default {
    "Revert": "Rückgängig",
    "Retry": "Nochmal",
    "Face crop":"Bild freistellen",
    "Remove background":"Hintergrund entfernen",
    "Loading":"Lädt…",
    "Background not recognized":"Hintergrund nicht identifiziert",
    "Background already removed":"Hintergrund bereits entfernt",
    "settings": "Einstellungen",
    "gridlines": "Gitterlinien",
    "layers": "Ebenen",
    "image": "Bild",
    "Text": "Text",
    "shape": "Elemente",
    "emoji": "Emoji",
    "done": "Fertig",
    "view": "Vorderseite",
    "Custom Text": "Text",
    "Close": "Schließen",
    "Position": "Position",
    "Rotate": "Drehen",
    "Change Image": "Bild anpassen",
    "Crop Image": "Zuschneiden",
    "Upload Images": "Bilder hochladen",
    "Add Text": "Text hinzufügen",
    "Cancel": "Abbrechen",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Klicke auf etwas in dem Design, um es zu ändern.",
    "Shapes": "Konfigurierbare Formen",
    "Add a line": "Linie hinzufügen",
    "Add a rectangle": "Rechteck hinzufügen",
    "Add a circle": "Kreis hinzufügen",
    "Add a triangle": "Dreieck hinzufügen",
    "Color": "Farbe",
    "Edit Text": "Text bearbeiten",
    "Font type": "Schriftart",
    "Font Style": "Stil",
    "Size": "Größe",
    "Justification": "Ausrichtung",
    "Letter spacing": "Buchstabenabstand",
    "Flip": "Spiegeln",
    "Save": "Speichern",
    "Preview": "Vorschau",
    "Editor": "Editor",
    "Uploads": "Hochgeladene Bilder",
    "Letter limit": "Max. Zeichenanzahl",
    "Recently Used Colors": "Zuletzt verwendete Farben",
    "Drag and drop your images here!": "Drag & Drop deine Bilder hierher",
    "You placed an element outside the printable area.": "Du hast ein Element außerhalb des druckbaren Bereiches platziert. Bitte bestätige, dass du verstanden hast, dass dies nicht vollständig bzw. korrekt abgedruckt wird.",
    "There was error during getting files!": "Es gab einen Fehler beim hochladen deiner Bilder. Bitte aktualisiere deine Seite und versuch es nochmal.",
    "Your Image is too big to upload! The max file size is 8 MB": "Dein Bild ist zu groß zum hochladen! Die max. Dateigröße beträgt 8 MB",
	"Upload not possible, only image format jpg, png, jpeg, jfif allowed.": "Upload not possible, only image format jpg, png, jpeg, jfif allowed.",
    "Reset changes": "Zurücksetzen",
    "Your image is too small": "Dein Bild ist leider zu klein, um in annehmbarer Qualität gedruckt zu werden. Bitte wähle ein anderes aus.",
	"Stroke": "Umriss",
	"Stroke color": "Umriss Farbe",
	"Delete": "Löschen",
    "BackgroundsTools": "Hintergründe",
    "ColorsTools": "Farben",
    "ImagesTools" : "Bilder",
    "Shadow color": "Schattenfarbe",
    "Shadow": "Schatten",
    "Information before ordering": "Bitte alles genau überprüfen und bestätigen, denn so wird's gedruckt. Mehr dazu in unseren FAQs.",
    "Add to cart": "In den Warenkorb",
    "More": "Mehr",
    "edit Image": "Anpassen",
    "Add_Image": "zusätzliches Bild",
    "Cut": "Zuschneiden",
    "Change": "Bild hochladen",
    "Edit": "ändern",
    "Font": "Schriftart",
    "Font Size": "Größe",
    "Letter Space": "Abstand",
    "Text Color": "Schriftfarbe",
    "New text": "neuer Text",
    "Align": "Ausrichtung",
    "Flip X": "Spiegeln",
    "Oops, didn't you forget something?": "Ups, hast du nicht was vergessen? Du hast nicht alles personalisiert, schau dir dein Produkt nochmal an und ersetze die Platzhalter (außer sie gefallen dir so gut).",
    "Use original image": "Originalbild verwenden",
    "Continue": "Weiter",
    "Wird freigestellt": "Wird freigestellt...",
    "Change Design" : "Design anpassen",
    "Change Color" : "Farbe anpassen",

    "texts_lang": 'Text anpassen',
    "Design_anpassen_lang": "Design anpassen",
    "Hintergundfarbe_anpassen_lang": "Hintergrund anpassen",
    "Farbe_anpassen_lang": "Hintergrund anpassen",
    "Hintergrund_anpassen_lang": "Hintergrund anpassen",
    "Weiter_lang": "Weiter",
    "Mehr_anpassen_lang" :"Elemente anpassen",
    "Bild_hinzufüge_lang":"Bild hinzufügen",
    "alert_error_message": "Oh je! Dieses Bildformat wird leider nicht unterstützt. Bitte verwende eines der folgenden Formate: jpg, png, jpeg oder jfif.",
    "back_button": "Zurück",
    "textarea_popup_text": "Um den Text zu ändern, einfach ins Textfeld klicken. Bei Klick auf den Bleistift kannst du den Text formatieren.",   
    "bild_popup_text": "Verwende diesen Button, um deine Bilder hochzuladen.",
    "Color_Btn_Text": "Farbe",
    "Shadow_Btn_Text": "Schatten",
    "Stroke_Btn_Text": "Umriss",
    "upload_photos_Btn_Text": "Fotos hochladen",
    "Upload_Btn_Text": "Hochladen",
    "Upload_More_Photos_Btn_Text": "Weitere Fotos hochladen",
    "Edit_Text_Btn_Text": "Text bearbeiten",
    "Save_Btn_Text": "Übernehmen",
    "Change_Text_Btn_Text": "Text ändern",
    "Design_Btn_Text": "Design",
    "Face_cropper_loading_text": "Lädt...",

}