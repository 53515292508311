import * as actionTypes from './actionTypes';

export const getMasksElements = () => {
    return {
        type: actionTypes.GET_MASKS_ELEMENTS,
    }
};

export const editMasksElements = (name) => {
    return {
        type: actionTypes.EDIT_MASKS_ELEMENTS,
        name
    }
};

export const setMasksElements = (data) => {
    return {
        type: actionTypes.SET_MASKS_ELEMENTS,
        data
    }
};


export const setActiveMaskElement = (data) => {
    return {
        type: actionTypes.SET_ACTIVE_MAKS_ELEMENT,
        activeMaskElement : data
    }
};

export const toggleMaskDrawMode = (data) => {
    return {
        type: actionTypes.TOGGLE_MASK_DRAW_MODE,
        isMaskDrawMode : data
    }
};

export const addMasksElement = (type) => {
    return {
        type: actionTypes.ADD_MASKS_ELEMENT,
        shape: type
    }
};
