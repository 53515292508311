// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CanvasAdditionalLayers_canvas-guideline__ytFDe {\n  width: 100%;\n  position: absolute !important;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  left: 50%;\n  top: 50%;\n  z-index: 1; }\n  .CanvasAdditionalLayers_canvas-guideline__ytFDe .CanvasAdditionalLayers_konvajs-content__2FQDh {\n    margin: 0 auto; }\n\n.CanvasAdditionalLayers_canvas-custom-mask__srO3O {\n  opacity: 0.5;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n\n.CanvasAdditionalLayers_hide-custom-mask__3JLpo {\n  opacity: 0; }\n", ""]);
// Exports
exports.locals = {
	"canvas-guideline": "CanvasAdditionalLayers_canvas-guideline__ytFDe",
	"konvajs-content": "CanvasAdditionalLayers_konvajs-content__2FQDh",
	"canvas-custom-mask": "CanvasAdditionalLayers_canvas-custom-mask__srO3O",
	"hide-custom-mask": "CanvasAdditionalLayers_hide-custom-mask__3JLpo"
};
module.exports = exports;
