import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Translate from "react-translate-component";

import {DESKTOP_WIDTH} from "../../config";
import { Button } from "shards-react";

class TextEditPopup extends Component {
    PopupAddTextInput = React.createRef();

    state = {
        newText: undefined
    }

    getStoreViewValues() {
        let storeViewValues = [];
        let inputs = document.getElementsByClassName("text-form-popup-content-store");
        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            storeViewValues.push({store_id: storeId, label: inputs[i].value});
        }
        return storeViewValues;
    }

    textChangeHandle(newText) {

        if (this.PopupEditTextInput?.current) {
            this.PopupEditTextInput.current.selectionEnd = newText.target.selectionStart;
        }

        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug
        this.PopupAddTextInput.current.value = this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.target.value : newText.target.value.replace(new RegExp(ranges, 'g'), '')

    }

    addTextToPage() {
        if (this.PopupAddTextInput.current?.value?.length > 0) {
            const createObj = {
                type: 'text',
                data: this.PopupAddTextInput.current.value,
                store_view_values: this.getStoreViewValues(),
                activePage: this.props.activePage
            };

            const { activePage, pages } = this.props;
            if (pages && pages.length && pages[activePage]) {
                const { customFonts = {} } = pages[activePage];
                const objectKey = Object.keys(customFonts);
                if (objectKey.length > 0) {
                    createObj.fontFamily = customFonts[objectKey[0]]['font_family'];
                }
            }
            this.props.addTextToPage(createObj);

            setTimeout(()=>{
                let name = this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length-2].attrs.name
                console.log(name)
                this.props.setActiveElement(name)
                    this.props.onEditElement({
                        align: "center",
                        element: name,
                        activePage: this.props.activePage,
                    });

                if(this.props.activeElement){
                    this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                    this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
                }

            }, 50)


        } else {
            this.props.hidePopup();
        }

        if (window.innerWidth <= DESKTOP_WIDTH) {
            this.props.hideToolsMenu();
            this.props.toolsMenuVisibleToggle({mode: false});
        }
    }

    render() {
        let storeViewsFields = this.props.storeViews.map((item) => {
            if (item.id !== 0) {
                return <div key={item.id} className="quick-edit-label-field">
                    <div className="quick-edit-label-field-label">
                        <label>{item.store_name}:</label>
                    </div>
                    <div className="quick-edit-label-field-input">
                        <textarea rows="2" className="text-form-popup-content-store" name={'text-form-popup-content-store-' + item.id} placeholder="Fill store view text"></textarea>
                    </div>
                </div>
            }
            return null
        });

        return (this.props.showTextPopup ?
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>
                <div className={`text-form-popup-content-inner text`}>
                    <div className="text-form-popup-content-title">
                        <Translate content={"Add Text"}/>
                        {/*(Default Store View)*/}
                    </div>
                    <textarea onChange={this.textChangeHandle.bind(this)} ref={this.PopupAddTextInput}
                              className="text-form-popup-input" rows="5"
                              autoFocus={true}
                              cols="3"/>
                    <div className="text-form-popup-content-buttons textareaPopupEditText">
                        <Button theme="secondary" onClick={this.addTextToPage.bind(this)}>
                            OK
                        </Button>
                        <Button outline theme="secondary" onClick={() => this.props.hidePopup()}>
                            <Translate content={"Cancel"}/>
                        </Button>
                        {((this.props.mode === 'ADMIN') ?
                            <div className="text-form-popup-content-store-values">
                                {storeViewsFields}
                            </div>
                            : null)}
                    </div>
                </div>
            </div>
            : null)
    }
}

const mapStateToProps = state => {
    return {
        selectedElement: state.glb.selectedElement,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        showTextEditPopup: state.tol.showTextEditPopup,
        showTextPopup: state.tol.showTextPopup,
        storeViews: state.glb.storeViews,
        mode: state.glb.mode,
        stage: state.glb.stage,
        pages: state.glb.pages,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            dispatch(actionCreators.hidePopup());
        },
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextEditPopup);
