import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import smart from "../../../assets/images/emojis/smart.png";
import smile from "../../../assets/images/emojis/smile.png";
import angry from "../../../assets/images/emojis/angry.png";
import angry1 from "../../../assets/images/emojis/angry-1.png";
import bored from "../../../assets/images/emojis/bored.png";
import bored1 from "../../../assets/images/emojis/bored-1.png";
import bored2 from "../../../assets/images/emojis/bored-2.png";
import confused from "../../../assets/images/emojis/confused.png";
import confused1 from "../../../assets/images/emojis/confused-1.png";
import crying from "../../../assets/images/emojis/crying.png";
import crying1 from "../../../assets/images/emojis/crying-1.png";
import embarrassed from "../../../assets/images/emojis/embarrassed.png";
import emoticons from "../../../assets/images/emojis/emoticons.png";
import happy from "../../../assets/images/emojis/happy.png";
import happy1 from "../../../assets/images/emojis/happy-1.png";
import happy2 from "../../../assets/images/emojis/happy-2.png";
import happy3 from "../../../assets/images/emojis/happy-3.png";
import happy4 from "../../../assets/images/emojis/happy-4.png";
import ill from "../../../assets/images/emojis/ill.png";
import inlove from "../../../assets/images/emojis/in-love.png";
import kissing from "../../../assets/images/emojis/kissing.png";
import mad from "../../../assets/images/emojis/mad.png";
import nerd from "../../../assets/images/emojis/nerd.png";
import ninja from "../../../assets/images/emojis/ninja.png";
import quiet from "../../../assets/images/emojis/quiet.png";
import sad from "../../../assets/images/emojis/sad.png";
import secret from "../../../assets/images/emojis/secret.png";
import smiling from "../../../assets/images/emojis/smiling.png";
import surprised from "../../../assets/images/emojis/surprised.png";
import surprised1 from "../../../assets/images/emojis/surprised-1.png";
import suspicious from "../../../assets/images/emojis/suspicious.png";
import suspicious1 from "../../../assets/images/emojis/suspicious-1.png";
import tongueOut from "../../../assets/images/emojis/tongue-out.png";
import tongueOut1 from "../../../assets/images/emojis/tongue-out-1.png";
import unhappy from "../../../assets/images/emojis/unhappy.png";
import wink from "../../../assets/images/emojis/wink.png";
import heartIcon from "../../../assets/images/emojis/heart_emoji.png";
import heartIcon1 from "../../../assets/images/emojis/heart_emoji1.png";
import heartIcon2 from "../../../assets/images/emojis/heart_emoji2.png";
import heartIcon3 from "../../../assets/images/emojis/heart_emoji3.png";
import stl from "./ToolsViews.module.scss";

import down_arrow_btn from "../../../assets/images/dropdown.svg";

import { DESKTOP_WIDTH } from "../../../config";

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import { BrowserView, MobileView, isBrowser, isMobile, isTablet } from 'react-device-detect';
import queryString from 'query-string';



const params = queryString.parse(window.location.search);

class CommonEmoji extends Component {
    render() {
        if (isMobile) {
            return (
                <CustomScroll className="customscrollbar-10" heightRelativeToParent="560px">
                    {this.props.children}
                </CustomScroll>
            )
        } else {
            return (
                <Scrollbars style={{ height: 400 }} className="scrollbar-10">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}


class Emoji extends Component {


    addElement(imgUrl) {
        let layerData = [];
        let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];

        let datas = this.props.pages[this.props.activePage]?.layerDatas.canvasMaskElements;
        
        

        if (parseInt(params.robot) === 1) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }
        if (parseInt(params.robot) === 0 || parseInt(params.robot2) === 1) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }

        const layerDatas = localStorage.getItem('layer_data');
        this.props.addElementToPage({
            type: 'icon',
            data: imgUrl,
            activePage: this.props.activePage
        });

        setTimeout(() => {
            // this.props.setActiveElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)

            let canvasElements = this.props.pages[0].canvasElements;
            canvasElements = canvasElements.filter(i => {
                return i;
            });

            let name = canvasElements[canvasElements.length - 1].name;
            this.props.setActiveElement(name)
            // for PRODUCTION
            if (this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1) {

                let layerX = [];
                let layerY = [];
                for (let i = 1; i <= 10; i++) {
                    switch (i) {
                        case 1:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 2:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 3:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 4:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 5:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 6:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        default:
                            break;
                    }
                }

                // Accessing the values:
                let layer1X = layerX[1]; let layer1Y = layerY[1];
                let layer2X = layerX[2]; let layer2Y = layerY[2];
                let layer3X = layerX[3]; let layer3Y = layerY[3];
                let layer4X = layerX[4]; let layer4Y = layerY[4];
                let layer5X = layerX[5]; let layer5Y = layerY[5];
                let layer6X = layerX[6]; let layer6Y = layerY[6];

                const layerDataCoordinates = {
                    ...(layerData[1] && { [layerData[1]]: { x: layer1X, y: layer1Y } }),
                    ...(layerData[2] && { [layerData[2]]: { x: layer2X, y: layer2Y } }),
                    ...(layerData[3] && { [layerData[3]]: { x: layer3X, y: layer3Y } }),
                    ...(layerData[4] && { [layerData[4]]: { x: layer4X, y: layer4Y } }),
                    ...(layerData[5] && { [layerData[5]]: { x: layer5X, y: layer5Y } }),
                    ...(layerData[6] && { [layerData[6]]: { x: layer6X, y: layer6Y } }),
                };

                if (layerDatas in layerDataCoordinates) {
                    const { x, y } = layerDataCoordinates[layerDatas];
                    if (this.props.activeElement) {
                        this.props.activeElement.x(x);
                        this.props.activeElement.y(y);
                        // console.log(isTablet, "istablet")
                        // console.log(isMobile, "isMobile")
                        if (isTablet === true) {
                            // console.log(isTablet, "istablet")
                            this.props.activeElement.x(x);
                            this.props.activeElement.y(y);
                        } else if (isMobile === true) {
                            // console.log(isMobile, "isMobile")
                            this.props.activeElement.x(x);
                            this.props.activeElement.y(y);
                        }
                    }
                }
            }

        }, 50)

        if (window.innerWidth <= DESKTOP_WIDTH) {
            this.props.hideToolsMenu();
            this.props.toolsMenuVisibleToggle({ mode: false });
        }
    }

    state = {
        icons: [smart, smile, angry, angry1, bored, bored1, bored2,
            confused, confused1, crying, crying1, embarrassed,
            emoticons, happy, happy1, happy2, happy3, happy4, ill,
            inlove, kissing, mad, nerd, ninja, quiet, sad, secret,
            smiling, surprised, surprised1, suspicious, suspicious1,
            tongueOut, tongueOut1, unhappy, wink, heartIcon, heartIcon1,
            heartIcon2, heartIcon3]
    };

    render() {
        //console.log('EditorType====>',this.props.EditorType);
        return (this.props.showEmojiForm ?
            <CommonEmoji>
                <div className="emoji-main-wrapper">

                    {window.innerWidth <= 616 ? <>
                        <div className={"submenu_back_arrow"}>
                            {
                                this.props.EditorType === 1 ? <>
                                    <img src={down_arrow_btn} alt=""
                                        onClick={() => {
                                            this.props.hideToolsMenu()
                                            this.setState({
                                                toolsMoreShow: true
                                            });
                                        }} />
                                </> : <>
                                    <img src={down_arrow_btn} alt=""
                                        onClick={() => {
                                            document.querySelector('.BackBtnMobileMehr').click();
                                        }} />
                                </>
                            }
                        </div>
                    </> : null}

                    <div className="designer-tools-uploader emoji_outer_wrapper">
                        <div className={stl["tools-icons"] + ' emoji_inner_wrapper'}>
                            {
                                this.state.icons.map((icon, i) => {
                                    return (
                                        <button key={i} className={stl["tools-icons__item"]}
                                            onClick={this.addElement.bind(this, icon)}>
                                            <img src={icon} alt="smart" />
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </CommonEmoji>
            : null
        )
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
        mode: state.glb.mode,
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        showEmojiForm: state.tol.showEmojiForm,
        activePage: state.glb.activePage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Emoji);
