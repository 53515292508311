import React, {Component} from 'react';
import stl from "./ToolsViews.module.scss";
import {connect} from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import Translate from "react-translate-component";
import {Button} from "shards-react";

import queryString from "query-string";

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import { SketchPicker } from 'react-color';

const params = queryString.parse(window.location.search);

class CommonBackgrounds extends Component {
    render(){
        if(isMobile){
           return (
                <CustomScroll className="customscrollbar-5" heightRelativeToParent="500px">
                    {this.props.children}
                </CustomScroll>
            )
        }else{
            return (
                <Scrollbars style={{ height: 500 }} className="scrollbar-5">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}

class Backgrounds extends Component {

    state = {
        background: '#fff',
    }
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
        console.log("COLOR: ", this.state.background)
        this.addBackgroundColor(color)
    };

    addBackground(elem) {
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        //Check if background element already exist and if yes then remove
        let backgroundExist = false;
        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                let backgroundItem = item;
                backgroundItem.data = elem.background_image;
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem,
                    bgColor: false,
                    smallBackground_image: elem.smallBackground_image,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });
                //this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                bgColor: false,
                data: elem.background_image,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                smallBackground_image: elem.smallBackground_image,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background'
            });
        }
    }

    addBackgroundColor(elem) {

        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;

        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                this.props.onEditImageElement({
                    // backgroundItem : '',
                    data: undefined,
                    bgColor: elem.hex,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });

                // this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });
        
        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                data: undefined,
                bgColor: '#' + elem,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background'
            });
        }

    }

    addBackgroundAdmin(elem, forwhat) {
        
        let page = this.props.pages[this.props.activePage];
        let pageElements = page.canvasElements;
        let stage = this.props.stage;
        let stageWidth = stage.attrs.width;
        let stageHeight = stage.attrs.height;

        // Check if background element already exist and if yes then remove
        let backgroundExist = false;
        pageElements.forEach((item) => {
            if (item.name === 'Background') {
                let backgroundItem = item;
                backgroundItem.data = elem.background_image;
                this.props.onEditImageElement({
                    backgroundItem,
                    data: backgroundItem,
                    bgColor: false,
                    smallBackground_image: forwhat=='small' ? elem.smallBackground_image : elem.background_image,
                    historyMode: true,
                    element: item.name,
                    activePage: this.props.activePage,
                });
                //this.props.hideToolsMenu();
                backgroundExist = true;
            }
        });

        if (!backgroundExist) {
            this.props.addElementToPage({
                type: 'image',
                bgColor: false,
                data: elem.background_image,
                imgSize: {width: stageWidth, height: stageHeight},
                width: stageWidth,
                height: stageHeight,
                smallBackground_image: elem.smallBackground_image,
                activePage: this.props.activePage,
                x: (stageWidth / 2),
                y: (stageHeight / 2),
                name: 'Background'
            });
        }
    }

    render() {
        
        // console.log("Props::>>", this.props)
        let activePage = this.props.pages[this.props.activePage];
        let backgrounds = [];

        let pageBackgrounds = activePage.designs;
        // console.log(activePage)
        
        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            let bck = pageBackgrounds[key];
            backgrounds.push({key: key, background_image: bck.image, smallBackground_image: bck.small_image });
        }
        
        let IsBckIMG =  false;
        let IsSmallIMG =  false;

        backgrounds.map((image, i) => {
            if(IsSmallIMG == false && image.smallBackground_image!=""){
                IsSmallIMG = true;
            }
            if(IsBckIMG == false && image.background_image!=""){
                IsBckIMG = true;
            }
        })
        // console.log("this.props.showDesignsForm:", this.props.showDesignsForm)
        return (this.props.showDesignsForm ? (

            // <SketchPicker 
            //     color={ this.state.background }
            //     onChangeComplete={ this.handleChangeComplete }
            // />

            <CommonBackgrounds>
                <div className="designer-tools-uploader cust-border-slider">
                    {/*TODO translate*/}
                    <div className="designer-tools-backgrounds-list design_frame_sec">
                        {backgrounds.length > 0 ? (
                        <div className={stl.uploader}>
                            <span className={stl.uploader__title} style={{"padding": "10px"}}>
                                {/*Design*/}
                            </span>
                        </div>
                        ) : null}

                        {params.sid == 'ADMIN' ? (
                                <>
                                    {IsBckIMG && <div  className='mb-30'> 
                                        <h6>Background Image</h6>
                                        {backgrounds.map((image, i) => {
                                            return (
                                                <img key={i}
                                                    className="background-image"
                                                    src={image.background_image}
                                                    alt="alt" width="100"
                                                    onClick={this.addBackgroundAdmin.bind(this, image, 'backgimg')}/>
                                            );
                                        })}
                                    </div>}
                                    {IsSmallIMG && <div  className='mb-30'> 
                                        <h6>Small Background Image</h6>
                                        {backgrounds.map((image, i) => {
                                            return (
                                                <img key={i}
                                                    className="background-image"
                                                    src={image.smallBackground_image}
                                                    alt="alt" width="100"
                                                    onClick={this.addBackgroundAdmin.bind(this, image, 'small')}/>
                                            );
                                        })}
                                    </div>}                                    
                                </>
                            ) : (
                                backgrounds.map((image, i) => {
                                    return (
                                        <img key={i}
                                            className="background-image"
                                            src={image.smallBackground_image || image.background_image}
                                            alt="alt" width="100"
                                            onClick={this.addBackground.bind(this, image)}/>
                                    );
                                })
                            )
                        }
                        
                        {/* {backgrounds.map((image, i) => {
                            return (
                                <img key={i}
                                     className="background-image"
                                     src={image.smallBackground_image || image.background_image}
                                     alt="alt" width="100"
                                     onClick={this.addBackground.bind(this, image)}/>
                            );
                        })} */}

                    </div>
                    {/*<div className={stl.tools__btn}>*/}
                    {/*    <button className="btn btn-dark btn-sm" onClick={this.props.hideToolsMenu}>*/}
                    {/*        <Translate content={"Close"}/>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
                </CommonBackgrounds>
            ) : null
        )
    }
}

const mapStateToProps = state => {
    // console.log("state.tol", state.tol)
    return {
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        showDesignsForm: state.tol.showDesignsForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        deleteSelectedElement: (params) => dispatch(actionCreators.deleteSelectedElement(params)),
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        onShowDesignsForm: () => {
            dispatch(actionCreators.showDesignsForm());
        },
        onShowBackgroundsForm: () => {
            dispatch(actionCreators.showBackgroundsForm());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Backgrounds);
