import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ChangeSize extends Component {
    constructor(props) {
        super(props);
        this.clearInt = this.clearInt.bind(this);
    }


    handleSizeChange(val) {
        let {type} = this.props.activeElement.attrs;
        if (type === 'text') {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    fontSize: Math.abs(parseFloat(this.props.activeElement.attrs.fontSize + val)),
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage
                });

                let item = this.props.activeElement;
                setTimeout(() => {
                    switch (item.attrs.align) {
                        case "right":
                            item.x(item.x() + item.width() - item.offsetX())
                            item.offsetX(item.width());
                            break;
                        case "center":
                            item.x(item.x() + (item.width() / 2 - item.offsetX()))
                            item.offsetX(item.width() / 2);
                            break;
                        default:
                            item.offsetX(0);
                            break;
                    }
                    console.log(this.props.activeElement.attrs + "it's text attrs")
                }, 50)

            }, 50);

        } else if (type === 'icon' || type === 'image') {

            this.interval = setInterval(() => {
                this.props.pages[0].canvasElements.forEach((item) => {
                    if ((item.faceId === this.props.activeElement.attrs.faceId
                        && this.props.activeElement.attrs.faceId !== 0
                        && this.props.activeElement.attrs.elementRepeatOval !== true
                        && this.props.activeElement.attrs.elementRepeat !== true
                        && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                        this.props.onEditElement({
                            width: (parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) > 25 ? parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) : 25),
                            height: (parseFloat(this.props.activeElement.attrs.height + val) > 25 ? parseFloat(this.props.activeElement.attrs.height + val) : 25),
                            element: item.name,
                            activePage: this.props.activePage,
                        })
                    }
                });
            }, 50);
        } else {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    scaleX: this.props.activeElement.attrs.scaleX + val / 10,
                    scaleY: this.props.activeElement.attrs.scaleX + val / 10,
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage,
                })
                console.log(this.props.activeElement.attrs + "it's else case")
            }, 50);
        }
    }

    clearInt(interval) {
        return clearInterval(interval);
    }

    render() {

        if (!this.props.propertyFaceCropZoomAllowed && (this.props.activeElement.attrs.elementRepeat || this.props.activeElement.attrs.elementRepeatOval) ) return null
        return (
            <div className="designer-right-panel-item designer-right-panel-item_changeSize">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>
                        Zoom
                    </span>
                </div>
                <div className={"designer-right-panel-item__checkboxes"}>
                    <button onMouseDown={this.handleSizeChange.bind(this, -1)}
                            onMouseUp={this.clearInt(this.interval)}
                            className={"designer-right-panel-btn designer-right-panel-btn_size"}>
                        {/*<img src={minusIcon} alt=""/>*/}
                        <FontAwesomeIcon icon="minus"/>
                    </button>
                    <button onMouseDown={this.handleSizeChange.bind(this, 1)}
                            onMouseUp={this.clearInt(this.interval)}
                            className={"designer-right-panel-btn designer-right-panel-btn_size"}>
                        {/*<img src={plusIcon} alt=""/>*/}
                        <FontAwesomeIcon icon="plus"/>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
        pages: state.glb.pages,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangeSize);
