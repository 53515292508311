import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import clsx from "clsx";

import colorIcon from '../../../assets/images/color.svg';
import Translate from "react-translate-component";
import {DESKTOP_WIDTH} from "../../../config";
import { Button } from "shards-react";
import expand_more from "../../../assets/images/expand-more.png";
import { SketchPicker } from 'react-color';

class PositionProperty extends Component {

    shadowColor = React.createRef();

    state = {
        textColorsListRecently: [],
        textColorsListMobile: ["#f44336","#e81e63","#9c27b0","#673ab7","#3f51b5","#2196f3","#03a9f4",
        "#00bcd4","#009688","#4caf50","#8bc34a","#cddc39","#ffeb3b","#ffc107","#ff9800","#ff5722",
        "#795548","#9e9e9e","#607d8b","#000000","#ffffff"],
        embroideryColours: ["#ffdf00","#f76800","#d51c29","#a51c38","#ffbacd","#632763","#624130",
        "#d6ae59","#a5b1bd","#6C7584","#0f519b","#95baef","#006043","#389e31","#ebebff","#222421"],
        colorShadowDropdown: this.props.mode !== "ADMIN" ? true : false,//window.innerWidth > 616 ? false : true,
        currentShadowColor: this.props.activeElement.attrs.shadowColor,
        newShadowColor: '',
        background: '#fff',
    };
   
    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
        console.log("COLOR: ", this.state.background)
        this.handleColorChange(color)
    };

    // Color slider for Mobile
    handleChangeCompleteMobile = (color) => {
        this.setState({ background: color });
        console.log("COLOR Mobile: ", this.state.background)
        this.handleColorChangeMobile(color)
    }
    // Color slider for Mobile End

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        // this.setProperties(this.props.activeElement.attrs);
        // let parentWrapper = document.querySelectorAll(".properties-column")[0];
        // let parentWrapperActive = document.querySelectorAll(".properties-column.active");
        // if ( !parentWrapperActive.length ) {
        //     if (this.state.colorDropdown) {
        //         parentWrapper.scrollTo(0, 0);
        //         parentWrapper.classList.add("active")
        //     } else {
        //         parentWrapper.classList.remove("active")
        //     }
        // }
    }

    componentWillUnmount() {
        // document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }

    setProperties(attrs) {
        if (this.shadowColor.current) this.shadowColor.current.style.backgroundColor = attrs.shadowColor;
    }

    addColorToRecantlyList(color) {
        console.log("Recent: ", color.hex)
        let colorsList = this.state.textColorsListRecently;
        console.log("colorsList: ", colorsList, colorsList.length)
        if (colorsList.indexOf(color) === -1) {
            this.setState({
                textColorsListRecently: [color.hex, ...colorsList.length >= 8 ? colorsList.slice(0, -1) : colorsList]
            });
        }
    }

    handleColorChange(color) {

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    shadowColor: color.hex,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });


        this.addColorToRecantlyList(color);
        this.shadowColor.current.style.backgroundColor = color.hex;
        this.setState( {
            newShadowColor: color.hex
        })
    }

    // Color slider for Mobile
    handleColorChangeMobile(color){
        console.log(this.props.activeElement.attrs.name);
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name) || this.props.activeElement.attrs.name.includes(item.name)) {
                this.props.onEditElement({
                    shadowColor: color,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        this.setState( {
            newShadowColor: color
        })
    }
    // Color slider for Mobile End

    handleColorChangeRecent(color) {

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    shadowColor: color,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });


        this.addColorToRecantlyList(color);
        this.shadowColor.current.style.backgroundColor = color;
        this.setState( {
            newShadowColor: color
        })
    }

    


    saveShadowColor = () => {
        if (window.innerWidth <= DESKTOP_WIDTH) {
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    closeShadowColor = () => {
        const { currentShadowColor } = this.state;
        const replaceCurrentShadowColor = currentShadowColor ? currentShadowColor.replace('#', '') : currentShadowColor
        this.props.onEditElement({
            shadowColor: currentShadowColor ? `#${replaceCurrentShadowColor}` : '',
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        this.shadowColor.current.style.backgroundColor = currentShadowColor ? `#${replaceCurrentShadowColor}` : '';
        if(window.innerWidth <=DESKTOP_WIDTH){
            let name = this.props.activeElement.attrs.name;
            this.props.onSelectElement("");
            setTimeout(()=>{
                this.props.onSelectElement(name);
            }, 0)
        }
    }

    render() {

        if (( this.props.propertyTextShadowColorAllowed === false ) && this.props.mode !== 'ADMIN') {
            return null
        }

        const { newShadowColor, currentShadowColor } = this.state;
        let activePage = this.props.pages[this.props.activePage];
        let colors = [];
        for (let key in activePage.colors) {
            if (!activePage.colors.hasOwnProperty(key)) continue;
            let color = activePage.colors[key];
            colors.push(color.color_code);
        }
        const replaceCurrentShadowColor = currentShadowColor ? currentShadowColor.replace('#', '') : currentShadowColor

        const activeShadowColor = newShadowColor || replaceCurrentShadowColor;

        return (
            <>
                { !this.props.shadowColorPicker && this.props.mode !== "ADMIN" ? 
                    <>
                        {
                            this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 1 ?                        
                                this.state.embroideryColours.map((item) => {
                                    return ( 
                                        <div className={this.props?.activeElement?.attrs.shadowColor == item ? "color_text_wrapper active" : "color_text_wrapper"}>
                                            <div key={item}
                                                className="color_text" 
                                                style={{'backgroundColor': item}} 
                                                onClick={ () => {this.handleChangeCompleteMobile(item)}}>                                    
                                            </div>
                                        </div>
                                    )
                                })
                            :
                                this.state.textColorsListMobile.map((item) => {
                                    return ( 
                                        <div className={this.props?.activeElement?.attrs.shadowColor == item ? "color_text_wrapper active" : "color_text_wrapper"}>
                                            <div key={item}
                                                className="color_text" 
                                                style={{'backgroundColor': item}} 
                                                onClick={ () => {this.handleChangeCompleteMobile(item)}}>                                    
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </>
                :  <>
                        <div
                            className={clsx("designer-right-panel-item", "designer-right-panel-item__colorFont border_bottom_remove", this.state.colorShadowDropdown && "active")}>
                            <div className={"designer-right-panel-item__color"} id={this.props.shadowColorPicker && this.props.mode !== "ADMIN" ? 'mobile_data_hide_new' : null}
                                onClick={()=>{
                                    this.setState({
                                        colorShadowDropdown: !this.state.colorShadowDropdown
                                    })
                                }}>
                                <div className={"designer-right-panel-item__titleFlex"}>
                                    <span className={"text"}>
                                        <Translate content={"Shadow color"}/>
                                        <img className={clsx("arrow", this.state.colorShadowDropdown && "active")} src={expand_more} alt=""/>
                                    </span>
                                </div>
                                <div className={"designer-right-panel-colorBox"}
                                    ref={this.shadowColor}/>
                            </div>
                            <div className={"dropdownContent"}>
                                {/*<div className="dropdownContentBtn">*/}
                                {/*    <Button outline theme="secondary" onClick={this.closeShadowColor}>*/}
                                {/*        <Translate content={"Cancel"}/>*/}
                                {/*    </Button>*/}
                                {/*    <Button theme="secondary" onClick={this.saveShadowColor}>*/}
                                {/*        OK*/}
                                {/*    </Button>*/}
                                {/*</div>*/}

                                <div className={"colorSwitchList"}>
                                    {
                                    <SketchPicker 
                                    // color={this.state.background}
                                    color={this.props?.activeElement?.attrs.shadowColor}
                                        onChangeComplete={ this.handleChangeComplete }
                                    />
                                    // colors.map((item) => {
                                    //     return (
                                    //         <button
                                    //             key={item}
                                    //             onClick={this.handleColorChange.bind(this, item)}
                                    //             className={`colorSwitchBtn ${ activeShadowColor && activeShadowColor.toUpperCase() === item.toUpperCase() ? 'colorSwitchBtnActive' : ''}`}
                                    //             style={{'backgroundColor': '#' + item}}>
                                    //         </button>
                                    //     )
                                    // })
                                    }
                                </div>
                                {this.state.textColorsListRecently.length > 0 ?
                                    <div className={"colorSwitchList__recently"}>
                                        <div>
                                            <Translate content={"Recently Used Colors"}/>
                                        </div>
                                        <div className={"colorSwitchList"}>
                                            {this.state.textColorsListRecently.map((item) => {
                                                return (
                                                    <button
                                                        key={item}
                                                        onClick={this.handleColorChangeRecent.bind(this, item)}
                                                        className={'colorSwitchBtn'}
                                                        data-color={item}
                                                        style={{'backgroundColor':item}}>
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div> : null
                                }
                            </div>
                        </div>
                    </>
                }   
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
