import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import Select from 'react-select'

const options = [
    {value: 'none', label: 'None'},
    {value: 'uppercase', label: 'Uppercase'},
    {value: 'lowercase', label: 'Lowercase'},
    {value: 'capitalize', label: 'Capitalize '},
]

class TextTransform extends Component {

    state = {
        format: this.props.activeElement.attrs.user_textTransform
    }

    handleFontTransformChanged(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    user_textTransform: e,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
        this.setState({
            format: this.props.activeElement.attrs.user_textTransform
        });
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        this.user_textTransform = attrs.user_textTransform || options[0];
    }

    render() {
        return (
            <div className="designer-right-panel-item designer-right-panel-item__fontTransform">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>
                        Formatting
                    </span>
                </div>
                <div className={"designer-right-panel-inputNumber"}>
                    <Select options={options} onChange={this.handleFontTransformChanged.bind(this)}
                            value={this.props.activeElement.attrs.user_textTransform}/>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        pages: state.glb.pages,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(TextTransform);

