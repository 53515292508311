import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';
import stl from "./ToolsViews.module.scss";
import Translate from "react-translate-component";
import Switch from "react-switch/index";
import { FormInput } from "shards-react";
import font_icon from "../../../assets/images/icons_designer_09.png";
import fontSize_icon from "../../../assets/images/icons_designer_10.png";
import letterSpace_icon from "../../../assets/images/icons_designer_06.png";
import colorText_icon from "../../../assets/images/colorFont_icon.svg";

import textAlign_left from "../../../assets/images/icons_designer_15.png";
import textAlign_center from "../../../assets/images/icons_designer_14.png";
import textAlign_right from "../../../assets/images/icons_designer_11.png";


import { Button } from "shards-react";

import textFlip_icon from "../../../assets/images/textFlip_icon.svg";
import FontFamily from "../../Properties/PropertiesComponents/FontFamily";
import close_btn from "../../../assets/images/iconfinder_Close_6351931 2.png";
import Color from "../../Properties/PropertiesComponents/Color";
import FontStroke from "../../Properties/PropertiesComponents/FontStroke";
import ColorFontStroke from "../../Properties/PropertiesComponents/ColorFontStroke";
import ShadowTextColor from "../../Properties/PropertiesComponents/ShadowTextColor";
import clsx from "clsx";
import minus_img_icon from "../../../assets/images/icons_designer_30_cropped.png";
import plus_img_icon from "../../../assets/images/icons_designer_31_cropped.png";
import addTextIcon from "../../../assets/images/addTextIcon.png";
import removeElementIcon from "../../../assets/images/icons_designer_12.png";
import changeStyleTextIcon from "../../../assets/images/icons_designer_16.png";
import pencilIcon from "../../../assets/images/icons_designer_26_black.png";
import down_arrow_btn from "../../../assets/images/dropdown.svg";

import shadowTextIcon from "../../../assets/images/icons_designer_18.png";
import outlineTextIcon from "../../../assets/images/icons_designer_19.png";
import color_picker_icon from "../../../assets/images/icons_designer_32.png";

import textTransformer from "../../../helpes/textTransformer";

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import rotate_img_icon from "../../../assets/images/Frame 134.svg";

import queryString from 'query-string';
import { SketchPicker } from 'react-color';
const params = queryString.parse(window.location.search);

class CommonText extends Component {
    render() {
        if (isMobile) {
            return (
                <CustomScroll className="customscrollbar-3" heightRelativeToParent="500px">
                    {this.props.children}
                </CustomScroll>
            )
        } else {
            return (
                <Scrollbars className='scrollbar-3' style={{ height: 500 }}>
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}


class Text extends Component {

    state = {
        fontFamily_show: false,
        fontColor_show: false,
        fontLetterSpaceShow: false,
        fontSizeShow: false,
        fontAlightText: false,
        showTextProps: false,
        interval: null,
        elRotationOpened: false,
        textValue: 0,
        outlineTextShow: false,
        shadowTextShow: false,
        fontColorPicker: false,
        shadowColorPicker: false,
        outlineColorPicker: false,

    }
    changeText(event) {
        this.setState(
            { textValue: event.target.value }
        );
    }

    toggleClass(property) {
        this.setState({
            [property]: !this.state[property]
        });
    }

    componentDidMount() {
        const {
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextShowAllowed,
            propertyTextFontAllowed
        } = this.props.pages[this.props?.activePage ?? 0]


        this.props.setUserAllowedProperties({
            'propertyTextStrokeAllowed': propertyTextStrokeAllowed || false,
            'propertyTextStrokeColorAllowed': propertyTextStrokeColorAllowed || false,
            'propertyTextShadowAllowed': propertyTextShadowAllowed || false,
            'propertyTextShadowColorAllowed': propertyTextShadowColorAllowed || false,
            'propertyTextColorAllowed': propertyTextColorAllowed || false,
            'propertyTextShowAllowed': propertyTextShowAllowed || false,
            'propertyTextFontAllowed': propertyTextFontAllowed || false,
        });
        if (this.props.pages[this.props.activePage].multiface_elements_admin_only) {
            if (document.getElementById("elementtxtid_0")) {
                // this.props.setSelectedElements([document.getElementById("HDNelementtxtid_0").value]);
                // this.props.onSelectElement(document.getElementById("HDNelementtxtid_0").value);
            }
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {


        if (!this.props.showTextForm
            &&
            (this.state.fontColor_show
                || this.state.fontFamily_show
                || this.state.fontSizeShow
                || this.state.fontLetterSpaceShow
                || this.state.showTextProps
            )) {
            this.setState({
                fontColor_show: false,
                fontFamily_show: false,
                fontSizeShow: false,
                fontLetterSpaceShow: false,
                showTextProps: false,
            })
        }
    }


    showTextEditPopup(name) {
        this.props.onSelectElement(name);
        this.props.onShowTextEditPopup();
    }

    deleteTextElement(item) {
        // added for RS-2517
        this.setState({
            fontColor_show: false
        });

        this.props.deleteSelectedElement({
            element: item.key || item.name,
            activePage: this.props.activePage,
        });
        // Added to remove the delete icon(RS-2389)
        this.props.setSelectedElements([item.name]);
        // this.props.onSelectElement(item.name);

        setTimeout(() => {
            if (this.props.pages && this.props.pages[this.props.activePage]) {
                let canvasElements = this.props.pages[this.props.activePage].canvasElements;
                canvasElements = canvasElements.filter(i => {
                    return i.deletable && i.type === 'text'
                })

                if (canvasElements.length >= 1) {
                    this.props.onSelectElement(canvasElements[0].name);
                } else {
                    this.props.onSelectElement("");
                }
            }
        }, 0)
    }

    showTextProps(name, index) {

        if (document.getElementById("elementtxtid_" + index)) {
            const textareaFirst = document.getElementById("elementtxtid_" + index);
            const endMove = textareaFirst.value.length;
            textareaFirst.setSelectionRange(endMove, endMove);
            textareaFirst.focus();
        }
        this.props.onSelectElement(name);
        this.setState({
            showTextProps: !this.state.showTextProps
        })
    }

    getElements() {

        if (this.props.pages && this.props.pages[this.props.activePage]) {
            let canvasElements = this.props.pages[this.props.activePage]?.canvasElements;

            canvasElements = canvasElements.filter(i => {
                return i.changeable && i.type === 'text' && i.data !== "undefined"
            })

            return canvasElements
        }
    }

    handleLetterSpacingChangeByInput(e, val = 0) {

        // this.state.interval = setInterval(() => {
        // Updated code
        let letterspVar = parseInt(e)
        let item = this.props.activeElement;
        let name = item.attrs.name;
        this.props.onEditElement({
            letterSpacing: letterspVar,
            element: item.attrs.name,
            activePage: this.props.activePage,
        });
        setTimeout(() => {
            this.props.onSelectElement(name);
            let newTextWidth = item.width() >= 1 ? item.width() : 1;

            switch (item.attrs.align) {
                case "right":
                    item.offsetX(newTextWidth);
                    break;
                case "center":
                    item.offsetX(newTextWidth / 2);
                    break;
                default:
                    return
            }
        })
        document.getElementById("inputletterspacing").value = letterspVar;



        // }, 35)
    }


    handleLetterSpacingChange(e, val = 0) {

        let dd = 0;

        this.state.interval = setInterval(() => {
            // Updated code
            dd = dd + 1; console.log('--dd-->', dd)
            if (dd >= 20) {
                this.setState({ interval: clearInterval(this.state.interval) })
                dd = 0;
            }
            let item = this.props.activeElement;
            let name = item.attrs.name;

            this.props.pages[0].canvasElements.forEach((item) => {
                if ((item.elementId === this.props.activeElement.attrs.elementId
                    && this.props.activeElement.attrs.elementId !== 0
                    && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name) || this.props.activeElement.attrs.name.includes(item.name)) {
                    this.props.onEditElement({
                        letterSpacing: this.props.activeElement.attrs.letterSpacing + e,
                        element: item.name,
                        activePage: this.props.activePage,
                    });
                }
            });

            //-------------------------------------
            // this.props.onEditElement({
            //     letterSpacing:this.props.activeElement.attrs.letterSpacing + e,
            //     element: item.attrs.name,
            //     activePage: this.props.activePage,
            // });
            //-------------------------------------

            setTimeout(() => {

                this.props.onSelectElement(name);
                let newTextWidth = item.width() >= 1 ? item.width() : 1;

                switch (item.attrs.align) {
                    case "right":
                        item.offsetX(newTextWidth);
                        break;
                    case "center":
                        item.offsetX(newTextWidth / 2);
                        break;
                    default:
                        return
                }
            })
            document.getElementById("inputletterspacing").value = this.props.activeElement.attrs.letterSpacing;




            // Old code
            // this.props.stage.children[this.props.activePage].children.forEach((item) => {
            //     if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
            //             && this.props.activeElement.attrs.elementId !== 0
            //             && this.props.activeElement.attrs.elementId !== undefined)
            //         || this.props.selectedElements.includes(item.attrs.name)) {

            //         let name = item.attrs.name;                    
            //     }
            // })
        }, 70)
    }

    propertyChange(propertyName, e) {
        this.props.stage.children[this.props.activePage].children.forEach((item) => {
            if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.attrs.name) || this.props.activeElement.attrs.name.includes(item.attrs.name)) {

                this.props.onEditElement({
                    [propertyName]: e,
                    faceId: item.attrs.faceId ?? 0,
                    element: item.attrs.name,
                    activePage: this.props.activePage
                });

                // TODO: remove setTimeout
                if (propertyName === 'elementRepeat' || propertyName === 'elementRepeatOval') {
                    if (e) {
                        let saveName = item.attrs.name
                        this.props.onSelectElement("");
                        setTimeout(() => {
                            this.props.onSelectElement(saveName);
                            this.props.showFaceCropPopup();
                        }, 0);
                    } else {
                        let saveName = item.attrs.name
                        this.props.onSelectElement("");
                        setTimeout(() => {
                            this.props.onSelectElement(saveName);
                        }, 0);
                    }
                }
            }
        });


    }

    handlePropertyChange(e, status) {

        this.props.setUserAllowedProperties({
            [e]: status
        });
    }

    deleteElement() {
        if (this.props.mode === "ADMIN" || this.props.activeElement?.attrs?.deletable) {
            this.props.deleteSelectedElement({
                element: this.props.activeElement?.attrs.name,
                activePage: this.props.activePage,
            });
            this.props.onSelectElement("");
        }
    }

    handleAlightTextChange(align) {
        this.props.onEditElement({
            align: align,
            element: this.props.activeElement?.attrs?.name,
            activePage: this.props.activePage,
            // x: this.props.activeElement?.getPosition().x
        });

        if (this.props.activeElement) {
            switch (align) {
                // In this case we recieve wrong position X. Thats why we recieve bad position in backend
                case "right":
                    this.props.activeElement.offsetX(this.props.activeElement.width());
                    this.props.activeElement.x(this.props.stage.width() / this.props.stage.attrs.scaleX);
                    // this.props.activeElement.offsetX(0);
                    // this.props.activeElement.x(this.props.stage.width() - this.props.activeElement.width());
                    break;
                case "center":
                    this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                    this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
                    // this.props.activeElement.offsetX(0);
                    // this.props.activeElement.x(this.props.stage.width() / 2 - this.props.activeElement.width() / 2)
                    break;
                default:
                    this.props.activeElement.offsetX(0).x(0);
            }
        }
    }

    generatePreview() {

        if (this.props.stage) {
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({ previewShouldBeChanged: false })
                setTimeout(() => {
                    if (document.getElementById("loaderdv_id")) {
                        document.getElementById("loaderdv_id").style.display = "none";
                    }
                    var myEle = document.getElementById("prv_id");
                    if (myEle) {
                        myEle.style.opacity = "1";
                    }
                }, 3000); //35000
            }
        }
    }

    getStoreViewValues() {

        let storeViewValues = [];
        let inputs = document.getElementsByClassName("text-form-popup-content-store");
        for (let i = 0; i < inputs.length; i++) {
            let storeId = inputs[i].name.replace('text-form-popup-content-store-', '');
            storeViewValues.push({ store_id: storeId, label: inputs[i].value });
        }
        return storeViewValues;
    }

    getInterval(e, fn, timeInterval) {
        return e.target
            ? fn()
            : this.setState({ interval: setInterval(() => fn(), timeInterval) })
    }



    handleFontSizeChange(event, val) {

        event.preventDefault()
        event.stopPropagation()
        let newfontsizeVar = parseInt(val)
        let item = this.props.activeElement;

        if (event.button === 0 || event.button === undefined) {
            let dd = 0;
            this.getInterval.call(this, newfontsizeVar, () => {
                // Updated code

                dd = dd + 1; console.log('--dd-->', dd)
                if (dd >= 20) {
                    this.setState({ interval: clearInterval(this.state.interval) })
                    dd = 0;
                }

                this.props.pages[0].canvasElements.forEach((item) => {
                    if ((item.elementId === this.props.activeElement.attrs.elementId
                        && this.props.activeElement.attrs.elementId !== 0
                        && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name) || this.props.activeElement.attrs.name.includes(item.name)) {
                        this.props.onEditElement({
                            fontSize: Math.abs(parseFloat(this.props.activeElement.attrs.fontSize + val)),
                            element: item.name,
                            activePage: this.props.activePage,
                        });
                    }
                });

                //-------------------------------------
                // let item = this.props.activeElement;
                // this.props.onEditElement({
                //     fontSize: Math.abs(parseFloat(this.props.activeElement.attrs.fontSize + val)),
                //     element: item.attrs.name,
                //     activePage: this.props.activePage,
                // });
                //-------------------------------------

                setTimeout(() => {
                    switch (item.attrs.align) {
                        case "right":
                            item.x(item.x() + item.width() - item.offsetX())
                            item.offsetX(item.width());
                            break;
                        case "center":
                            item.x(item.x() + (item.width() / 2 - item.offsetX()))
                            item.offsetX(item.width() / 2);
                            break;
                        default:
                            item.offsetX(5);
                            break;
                    }
                }, 55)
                document.getElementById("inputfontsize").value = this.props.activeElement.attrs.fontSize;

                // Old code
                // this.props.stage.children[this.props.activePage].children.forEach((item) => {
                //     if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                //             && this.props.activeElement.attrs.elementId !== 0
                //             && this.props.activeElement.attrs.elementId !== undefined)
                //         || this.props.selectedElements.includes(item.attrs.name)) {                    
                //     }
                // });
            }, 70)

        }
    }
    validateInput(inputid, val) {

        // let inputlenght = parseInt(val)
        if (val.length > 3) {

            let test = val.slice(0, 3)
            document.getElementById(inputid).value = test

        }

    }
    handleFontSizeChangeInput(event, val) {

        event.preventDefault()
        event.stopPropagation()

        let newfontsizeVar = parseInt(val)
        let item = this.props.activeElement;
        if (event.button === 0 || event.button === undefined) {
            let dd = 0;
            this.getInterval.call(this, newfontsizeVar, () => {
                // Updated code
                dd = dd + 1; console.log('---->', dd)
                if (dd >= 20) {
                    this.setState({ interval: clearInterval(this.state.interval) })
                    dd = 0;
                }
                this.props.onEditElement({
                    fontSize: newfontsizeVar,
                    element: item.attrs.name,
                    activePage: this.props.activePage,
                });
                setTimeout(() => {
                    switch (item.attrs.align) {
                        case "right":
                            item.x(item.x() + item.width() - item.offsetX())
                            item.offsetX(item.width());
                            break;
                        case "center":
                            item.x(item.x() + (item.width() / 2 - item.offsetX()))
                            item.offsetX(item.width() / 2);
                            break;
                        default:
                            item.offsetX(0);
                            break;
                    }
                    document.getElementById("inputfontsize").value = this.props.activeElement.attrs.fontSize;
                }, 55)

                // Old code
                // this.props.stage.children[this.props.activePage].children.forEach((item) => {
                //     if ((item.attrs.elementId === this.props.activeElement.attrs.elementId
                //             && this.props.activeElement.attrs.elementId !== 0
                //             && this.props.activeElement.attrs.elementId !== undefined)
                //         || this.props.selectedElements.includes(item.attrs.name)) {                    
                //     }
                // });
            }, 70)
        }
    }

    // add text on layer are start
    addTextInput() {
        let layerData = [];
        let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];

        let datas = this.props.pages[this.props.activePage]?.layerDatas.canvasMaskElements;



        if (parseInt(params.robot) === 1) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
            }
        }
        if (parseInt(params.robot) === 0) {
            if (typeof datas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
            }
        }
        const layerDatas = localStorage.getItem('layer_data');
        const createObj = {
            type: 'text',
            data: ' ',
            store_view_values: this.getStoreViewValues(),
            activePage: this.props.activePage
        };

        const { activePage, pages } = this.props;
        if (pages && pages.length && pages[activePage]) {
            const { customFonts = {} } = pages[activePage];
            const objectKey = Object.keys(customFonts);
            if (objectKey.length > 0) {
                createObj.fontFamily = customFonts[objectKey[0]]['font_family'];
                createObj.fontSize = 32;
                createObj.layer_allocation_data = layerDatas;
            }
        }
        this.props.addTextToPage(createObj);

        setTimeout(() => {
            let canvasElements = this.props.pages[0].canvasElements;
            canvasElements = canvasElements.filter(i => {
                return i.deletable && i.type === 'text'
            });

            let name = canvasElements[canvasElements.length - 1].name;
            // let name = this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name

            this.props.onSelectElement(name)
            this.props.onEditElement({
                align: "center",
                element: name,
                deletable: true,
                activePage: this.props.activePage,
            });
            // for ADMIN and other product
            if (this.props.activeElement) {
                this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
            }


            // for PRODUCTION
            if (this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1) {

                let layerX = [];
                let layerY = [];
                for (let i = 1; i <= 10; i++) {
                    switch (i) {
                        case 1:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 2:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 3:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 4:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 5:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 6:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        default:
                            break;
                    }
                }

                // Accessing the values:
                let layer1X = layerX[1]; let layer1Y = layerY[1];
                let layer2X = layerX[2]; let layer2Y = layerY[2];
                let layer3X = layerX[3]; let layer3Y = layerY[3];
                let layer4X = layerX[4]; let layer4Y = layerY[4];
                let layer5X = layerX[5]; let layer5Y = layerY[5];
                let layer6X = layerX[6]; let layer6Y = layerY[6];

                const layerDataCoordinates = {
                    ...(layerData[1] && { [layerData[1]]: { x: layer1X, y: layer1Y } }),
                    ...(layerData[2] && { [layerData[2]]: { x: layer2X, y: layer2Y } }),
                    ...(layerData[3] && { [layerData[3]]: { x: layer3X, y: layer3Y } }),
                    ...(layerData[4] && { [layerData[4]]: { x: layer4X, y: layer4Y } }),
                    ...(layerData[5] && { [layerData[5]]: { x: layer5X, y: layer5Y } }),
                    ...(layerData[6] && { [layerData[6]]: { x: layer6X, y: layer6Y } }),
                };

                if (layerDatas in layerDataCoordinates) {
                    const { x, y } = layerDataCoordinates[layerDatas];
                    this.props.activeElement.x(x);
                    this.props.activeElement.y(y);
                }
            }
            // console.log(name, "names");
        }, 0)
    }

    // add text on layer are end

    textChangeHandle(newText) {

        const ranges = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug

        return textTransformer(this.props.pages[this.props.activePage].toolEmojiAllowed !== false ? newText.target.value : newText.target.value.replace(new RegExp(ranges, 'g'), ''), this.props.activeElement?.attrs.user_textTransform?.value);
    }

    // remove white space from last
    validateText(name, text) {
        if (text.target.value?.length > 0) {
            let newlines = text.target.value.split('\n');
            newlines.reverse();
            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                if (item?.attrs.name == name) {

                    let y = item.attrs?.y;
                    for (let i = 0; i < newlines.length; i++) {
                        if (newlines[i] != '') { break; }
                        y = parseFloat(y) - parseFloat(item.attrs?.fontSize);
                    }
                    let trimtxtz = "";
                    if (this.textChangeHandle(text).length <= 1) {
                        trimtxtz = this.textChangeHandle(text);
                    } else {
                        trimtxtz = this.textChangeHandle(text).trim();
                    }

                    this.props.onEditElement({
                        newText: trimtxtz,
                        element: name,
                        activePage: this.props.activePage,
                        y: y,
                    });

                    if (this.props.activeElement) {
                        setTimeout(() => {
                            if (item?.attrs?.textMaxWidth && this.props.activeElement) item.width(item.attrs.textMaxWidth);
                            let align = item.attrs.align;
                            this.props.onEditElement({
                                align: "left",
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            this.props.onEditElement({
                                align: align,
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            switch (align) {
                                case "right":
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    break;
                            }
                        }, 50)
                    }
                }
            });
        }
    }

    changeText(name, text) {
        // console.log("++++++++++++==>>")
        // console.log('text 1236-->>', text.target);
        //console.log('text', text);
        //console.log('name', name);
        if (text.target.value?.length > 0) {
            this.props.stage.children[this.props.activePage].children.forEach((item) => {

                if ((item?.attrs.elementId === this.props.activeElement?.attrs?.elementId
                    && item?.attrs?.elementId !== 0
                    && item?.attrs?.elementId !== undefined) || this.props?.selectedElements?.includes(item.attrs.name)) {

                    let oldlines = this.props.activeElement?.attrs?.text.split('\n');
                    let newlines = text.target.value.split('\n');
                    let lastkey = text.target.value.slice(-1);
                    let oldLength = this.props.activeElement?.attrs?.text.length;
                    let newLength = text.target.value.length;


                    let y;
                    if (newlines.length > oldlines.length) {
                        y = parseFloat(this.props.activeElement?.attrs?.y) + parseFloat(this.props.activeElement?.attrs?.fontSize);
                    } else if (newlines.length < oldlines.length) {
                        y = parseFloat(this.props.activeElement?.attrs?.y) - parseFloat(this.props.activeElement?.attrs?.fontSize);
                    } else {
                        y = this.props.activeElement?.attrs?.y;
                    }

                    this.props.onEditElement({
                        newText: this.textChangeHandle(text).trimLeft(),
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                        y: y,
                    });


                    if (this.props.activeElement) {
                        setTimeout(() => {
                            if (item?.attrs?.textMaxWidth && this.props.activeElement) item.width(item.attrs.textMaxWidth);
                            let align = item.attrs.align;
                            this.props.onEditElement({
                                align: "left",
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            this.props.onEditElement({
                                align: align,
                                element: item.attrs.name,
                                historyMode: false,
                                activePage: this.props.activePage,
                            });

                            switch (align) {
                                case "right":
                                    item.offsetX(item.width());
                                    break;
                                case "center":
                                    item.offsetX(item.width() / 2);
                                    break;
                                default:
                                    break;
                            }
                        }, 50)
                    }
                }
            });

            // TODO: delete setTimeout

        } else {
            this.props.onEditElement({
                newText: " ",
                element: name,
                activePage: this.props.activePage,
            });
        }
    }

    decodeTextData(item) {
        // console.log('item--*********>>',item.data)
        let text = " ";
        try {
            text = textTransformer(decodeURI(item.data || item.text), item.user_textTransform?.value) ?? " ";
        } catch (e) {
            text = textTransformer((item.data || item.text), item.user_textTransform?.value) ?? " "
        }

        if (text !== 'undefined' && text !== 'UNDEFINED') return text
        return " ";
    }

    render() {
        let elem_inputfontsize = document.getElementById("inputfontsize")
        let elem_inputletterspacing = document.getElementById("inputletterspacing")
        if (typeof (elem_inputfontsize) != 'undefined' && elem_inputfontsize != null) {
            document.getElementById("inputfontsize").value = this.props.activeElement?.attrs?.fontSize
        }
        if (typeof (elem_inputletterspacing) != 'undefined' && elem_inputletterspacing != null) {
            document.getElementById("inputletterspacing").value = this.props.activeElement?.attrs?.letterSpacing
        }
        let alignIcon;
        let align = 'left'
        if (this.props.activeElement) {
            align = this.props.activeElement?.attrs.align
        }

        switch (align) {
            case "left":
                alignIcon = textAlign_left
                break;
            case "center":
                alignIcon = textAlign_center
                break;
            default:
                alignIcon = textAlign_right
                break;
        }
        let multifaceclass = "";
        if (this.props.pages[this.props.activePage].multiface_elements_admin_only == 1) {
            multifaceclass = 'multifaceTextpopCls';
        }

        if (this.props.pages[this.props.activePage].multiface_elements_admin_only) {
            if (document.getElementById("elementtxtid_0")) {
                // this.props.setSelectedElements([document.getElementById("HDNelementtxtid_0").value]);
                // this.props.onSelectElement(document.getElementById("HDNelementtxtid_0").value);
            }
        }

        let letterSpacingvar = '';
        let fontSizevar = 0;
        if (this.props.activeElement?.attrs?.letterSpacing > 0) {
            letterSpacingvar = this.props.activeElement?.attrs?.letterSpacing
        }
        if (this.props.activeElement?.attrs?.fontSize > 0) {
            fontSizevar = this.props.activeElement?.attrs?.fontSize
        }

        // Check the text Element length to show add text button
        let TextElementLength = this.props.pages[this.props.activePage].canvasElements;
        TextElementLength = TextElementLength.filter(i => {
            return i.deletable && i.type === 'text'
        });


        let layerDataActive = localStorage.getItem('layer_data');
        //         const filteredElements = this.getElements().filter(i => i.layer_allocation_data === layerDataActive);
        // console.log(filteredElements);

        return (this.props.showTextForm ?
            <>
                {/*{window.innerWidth >= DESKTOP_WIDTH ? (*/}
                <>
                    {/* <CustomScroll   heightRelativeToParent="180px"> */}
                    <CommonText>
                        <div className={stl.textMenu + ' cs-text-width cust_sub_menu ' + multifaceclass}>
                            {window.innerWidth > 616 || (!this.state.showTextProps || (this.state.fontColor_show || this.state.fontFamily_show || this.state.fontSizeShow || this.state.fontLetterSpaceShow)) ? null :
                                <div className={stl.textMenu__closeBtn}
                                >
                                    <img src={close_btn} alt="" onClick={() => {
                                        this.setState({ showTextProps: false })
                                    }} />
                                </div>
                            }

                            {/* Make condition to show an option to add textare in popup if no textarea exists in mobile */}
                            {
                                (!this.props.activeElement?.attrs.type && window.innerWidth <= 616) || (this.props.activeElement?.attrs.type == "undefined" && window.innerWidth <= 616) || (TextElementLength.length === 0 && window.innerWidth <= 616) ?
                                    <>
                                        <div className="add_textarea_wrapper">
                                            <button onClick={() => {
                                                this.props.ShowTextareaMobilePopup();
                                            }}>
                                                <img src={pencilIcon} alt="" />
                                                <Translate content={"Change_Text_Btn_Text"} />
                                            </button>
                                        </div>
                                    </> : null
                            }

                            {/* {this.props.activeElement?.attrs.type === "text" && (this.state.showTextProps || window.innerWidth > 616) ? <> */}
                            {this.props.activeElement?.attrs.type === "text" ? <>

                                {/* Font Family Mobile in submenu lang=de */}
                                {this.state.fontFamily_show && window.innerWidth <= 616 && !this.props.enable_submenu_var ?
                                    <>
                                        <div className={stl.textMenu__closeBtn + " submenu_back_arrow"}>
                                            <img src={down_arrow_btn} alt="" onClick={() => {
                                                this.setState({ fontFamily_show: false })
                                            }} />
                                        </div>
                                        <FontFamily />
                                    </> : null
                                }
                                {/* Font Family Mobile in submenu lang=de End */}

                                {/* Show text align options in mobile */}
                                {this.state.fontAlightText && !this.props.enable_submenu_var ? <>
                                    {
                                        window.innerWidth <= 616 ? <>
                                            <div className={stl.textMenu__closeBtn + " submenu_back_arrow"}
                                            >
                                                <img src={down_arrow_btn} alt="" onClick={() => {
                                                    this.setState({ fontAlightText: false })
                                                }} />
                                            </div>
                                            <div className={stl.activeFontSize + " text_align_menu_submenu"}>
                                                <img src={textAlign_left} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('left')
                                                    }}
                                                />
                                                <img src={textAlign_center} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('center')
                                                    }}
                                                />
                                                <img src={textAlign_right} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('right')
                                                    }}
                                                />
                                            </div>
                                        </> : null
                                    }
                                </> : null}

                                {/* Farbe button mobile color slider design in submenu lang=de */}
                                {this.state.fontColor_show && window.innerWidth <= 616 && this.props.activeElement.attrs.quick_edit_color === true && !this.props.enable_submenu_var ?
                                    <>
                                        <div className={clsx(stl.textMenu__closeBtn) + " submenu_back_arrow"}>
                                            <img src={down_arrow_btn} alt="" onClick={() => {
                                                this.setState({
                                                    fontColor_show: false,
                                                    fontColorPicker: false
                                                })
                                            }} />
                                        </div>
                                        <div className="special_color_btn_slide_with_colors">
                                            <Color toggleClass={this.toggleClass.bind(this)} />
                                            {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                                <div className={this.state.fontColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                                    <img src={color_picker_icon}
                                                        onClick={() => {
                                                            this.setState({
                                                                fontColorPicker: !this.state.fontColorPicker
                                                            })
                                                        }}>
                                                    </img>
                                                </div>
                                                : null}
                                        </div>
                                    </> : null
                                }
                                {/* Farbe button mobile color slider design in submenu lang=de end */}

                                {/* Show font size options in mobile */}
                                {this.state.fontSizeShow && !this.props.enable_submenu_var ? <>
                                    {
                                        window.innerWidth <= 616 ? <>
                                            <div className={stl.textMenu__closeBtn + " submenu_back_arrow"}
                                            >
                                                <img src={down_arrow_btn} alt="" onClick={() => {
                                                    this.setState({ fontSizeShow: false })
                                                }} />
                                            </div>
                                            <div className={stl.activeFontSize + " font_size_mobile_cust"}>
                                                <img
                                                    draggable="false"
                                                    src={minus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={(e) => { e.stopPropagation(); this.handleFontSizeChange(e, -1) }}
                                                    onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                    onMouseDown={(e) => this.handleFontSizeChange(e, -1)}
                                                    onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                // onMouseLeave={() => this.setState({interval: clearInterval(this.state.interval)})}
                                                />
                                                <input
                                                    className="form-control form-control-sm cs-number-input cst-num-input"
                                                    type="number"
                                                    id="inputfontsize"
                                                    defaultValue={fontSizevar}
                                                    // placeholder={fontSizevar}
                                                    // value={fontSizevar ?? ''}   
                                                    onFocus={(e) => {
                                                        (fontSizevar = e.target.value);
                                                        (e.target.value = '');
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value == '') {
                                                            // e.target.value = fontSizevar
                                                        }
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.handleFontSizeChangeInput(e, e.target.value))

                                                    }}
                                                    onKeyDown={(e) => {
                                                        (e.key === 'Enter' && this.handleFontSizeChangeInput(e, e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.validateInput("inputfontsize", e.target.value))
                                                    }}
                                                />

                                                <img
                                                    draggable="false"
                                                    src={plus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={(e) => { e.stopPropagation(); this.handleFontSizeChange(e, 1) }}
                                                    onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                    onMouseDown={(e) => this.handleFontSizeChange(e, 1)}
                                                    onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                // onMouseLeave={() => this.setState({interval: clearInterval(this.state.interval)})}
                                                />
                                            </div>
                                        </> : null
                                    }
                                </> : null}
                                {/* Show font size options in mobile End */}


                                {/* Show letter spacing options in mobile */}
                                {this.state.fontLetterSpaceShow && !this.props.enable_submenu_var ? <>
                                    {
                                        window.innerWidth <= 616 ? <>
                                            <div className={stl.textMenu__closeBtn + " submenu_back_arrow"}
                                            >
                                                <img src={down_arrow_btn} alt="" onClick={() => {
                                                    this.setState({ fontLetterSpaceShow: false })
                                                }} />
                                            </div>
                                            <div className={stl.activeFontSize + " letter_spacing_mobile_cust"}>
                                                <img
                                                    draggable="false"
                                                    src={minus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={this.handleLetterSpacingChange.bind(this, -1)}
                                                    onTouchEnd={() => clearInterval(this.state.interval)}
                                                    onMouseDown={() => this.handleLetterSpacingChange(-1)}
                                                    onMouseUp={() => clearInterval(this.state.interval)}
                                                    onMouseLeave={() => clearInterval(this.state.interval)}
                                                />
                                                <input
                                                    className='form-control form-control-sm cs-number-input cst-num-input'
                                                    type="number"
                                                    id="inputletterspacing"
                                                    defaultValue={this.props.activeElement?.attrs?.letterSpacing}
                                                    // placeholder={this.props.activeElement?.attrs?.letterSpacing}
                                                    // value={ this.props.activeElement?.attrs?.letterSpacing ?? 0}
                                                    onFocus={(e) => {
                                                        // letterSpacingvar = e.target.value;
                                                        (e.target.value = '')
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value == '') {
                                                            e.target.value = letterSpacingvar
                                                        }
                                                        this.handleLetterSpacingChangeByInput(e.target.value)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        (e.key === 'Enter' && this.handleLetterSpacingChangeByInput(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.validateInput("inputletterspacing", e.target.value))
                                                    }}
                                                />

                                                {/* <span type="text" className='form-control form-control-sm cs-number-input'>
                                                    {this.props.activeElement?.attrs?.letterSpacing}
                                                </span> */}
                                                <img
                                                    draggable="false"
                                                    src={plus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={this.handleLetterSpacingChange.bind(this, 1)}
                                                    onTouchEnd={() => clearInterval(this.state.interval)}
                                                    onMouseDown={() => this.handleLetterSpacingChange(1)}
                                                    onMouseUp={() => clearInterval(this.state.interval)}
                                                    onMouseLeave={() => clearInterval(this.state.interval)}
                                                />
                                            </div>
                                        </> : null
                                    }
                                </> : null}
                                {/* Show letter spacing options in mobile end */}

                                {/* Schatten(shadowTex) button mobile color slider design in submenu lang=de */}
                                {this.state.shadowTextShow && window.innerWidth <= 616 && !this.props.enable_submenu_var ?
                                    <>
                                        <div className={clsx(stl.textMenu__closeBtn) + " submenu_back_arrow"}>
                                            <img src={down_arrow_btn} alt="" onClick={() => {
                                                this.setState({
                                                    shadowTextShow: false,
                                                    shadowColorPicker: false
                                                })
                                            }} />
                                        </div>
                                        <div className="shadow_text_section_mobile_wrap color_selector_btn_wrapper">
                                            {((this.props.mode === 'ADMIN') || (this.props.propertyTextShadowAllowed) && this.props.activeElement?.attrs.type === 'text') ?
                                                <div className="designer-right-panel-item_shadow">
                                                    <Switch className={"custom-checkbox"}
                                                        onChange={this.propertyChange.bind(this, "shadowEnabled")}
                                                        checked={(!!(this.props.activeElement.attrs.shadowEnabled))} />
                                                    {/* <Translate content={"Shadow"}/> */}
                                                </div>
                                                : null}
                                        </div>
                                        <div className="special_color_btn_slide_with_colors shadow">
                                            <ShadowTextColor toggleClass={this.toggleClass.bind(this)} />
                                            {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                                <div className={this.state.shadowColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                                    <img src={color_picker_icon}
                                                        onClick={() => {
                                                            this.setState({
                                                                shadowColorPicker: !this.state.shadowColorPicker
                                                            })
                                                        }}>
                                                    </img>
                                                </div>
                                                : null}
                                        </div>
                                    </> : null
                                }
                                {/* Schatten(shadowTex) button mobile color slider design in submenu lang=de end */}

                                {/* Umriss(outlineText) button mobile color slider design in submenu lang=de */}
                                {this.state.outlineTextShow && window.innerWidth <= 616 && !this.props.enable_submenu_var ?
                                    <>
                                        <div className={clsx(stl.textMenu__closeBtn) + " submenu_back_arrow"}>
                                            <img src={down_arrow_btn} alt="" onClick={() => {
                                                this.setState({
                                                    outlineTextShow: false,
                                                    outlineColorPicker: false
                                                })
                                            }} />
                                        </div>
                                        <div className="outline_text_section_mobile_wrap outline_selector_btn_wrapper">
                                            <FontStroke toggleClass={this.toggleClass.bind(this)} />
                                        </div>

                                        <div className="special_color_btn_slide_with_colors outline_text">
                                            <ColorFontStroke toggleClass={this.toggleClass.bind(this)} />
                                            {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                                <div className={this.state.outlineColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                                    <img src={color_picker_icon}
                                                        onClick={() => {
                                                            this.setState({
                                                                outlineColorPicker: !this.state.outlineColorPicker
                                                            })
                                                        }}>
                                                    </img>
                                                </div>
                                                : null}
                                        </div>
                                    </> : null
                                }
                                {/* Umriss(outlineText) button mobile color slider design in submenu lang=de end */}


                                {((this.state.fontFamily_show && window.innerWidth <= 616) || (this.state.fontAlightText && window.innerWidth <= 616) || (this.state.fontSizeShow && window.innerWidth <= 616) || (this.state.fontLetterSpaceShow && window.innerWidth <= 616) || (this.state.fontColor_show && window.innerWidth <= 616) || (this.state.shadowTextShow && window.innerWidth <= 616) || (this.state.outlineTextShow && window.innerWidth <= 616)) && !this.props.enable_submenu_var ? null : <>
                                    {/*<button onClick={() => {*/}
                                    {/*    this.props.onShowTextEditPopup()*/}
                                    {/*}}>*/}
                                    {/*    <img src={pencil_icon} alt=""/>*/}
                                    {/*    <Translate content={"Edit"}/>*/}

                                    {/*</button>*/}

                                    {
                                        window.innerWidth <= 616 ? <>
                                            <button onClick={() => {
                                                this.props.ShowTextareaMobilePopup();
                                            }}>
                                                <img src={pencilIcon} alt="" />
                                                <Translate content={"Change_Text_Btn_Text"} />
                                            </button>
                                        </>
                                            : null}

                                    <div className={clsx(this.state.fontAlightText && stl.active, stl.fontAlightText) + " cstm_button_align_wrap"}>
                                        <button
                                            onClick={() => {
                                                this.setState({
                                                    fontColor_show: false,
                                                    fontFamily_show: false,
                                                    fontSizeShow: false,
                                                    fontLetterSpaceShow: false,
                                                    fontAlightText: window.innerWidth > 616 ? !this.state.fontAlightText : true,
                                                    elRotationOpened: false,
                                                    outlineTextShow: false,
                                                    shadowTextShow: false,
                                                    fontColorPicker: false,
                                                    shadowColorPicker: false,
                                                    outlineColorPicker: false
                                                })
                                                this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                            }}>

                                            {
                                                window.innerWidth > 616 ? <img src={alignIcon} alt="" /> : <img src={alignIcon} alt="" />

                                                // <>
                                                //     <img src={textAlign_left} alt=""
                                                //          onClick={() => {
                                                //              this.handleAlightTextChange('left')
                                                //          }}
                                                //     />
                                                //     <img src={textAlign_center} alt=""
                                                //          onClick={() => {
                                                //              this.handleAlightTextChange('center')
                                                //          }}
                                                //     />
                                                //     <img src={textAlign_right} alt=""
                                                //          onClick={() => {
                                                //              this.handleAlightTextChange('right')
                                                //          }}
                                                //     />
                                                // </>
                                            }
                                            <Translate content={"Align"} />
                                        </button>
                                        {!this.props.enable_submenu_var ?
                                            // window.innerWidth < 616 ? null : 
                                            <div className={stl.activeFontSize}>
                                                <img src={textAlign_left} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('left')
                                                    }}
                                                />
                                                <img src={textAlign_center} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('center')
                                                    }}
                                                />
                                                <img src={textAlign_right} alt=""
                                                    onClick={() => {
                                                        this.handleAlightTextChange('right')
                                                    }}
                                                />
                                            </div>
                                            : null
                                        }

                                    </div>

                                    <button onClick={() => {
                                        if (this.props.activeElement && this.props.activeElement.attrs.type != "text") {
                                            if (document.getElementById("elementtxtid_0")) {
                                                const textareaFirst = document.getElementById("elementtxtid_0");
                                                const endMove = textareaFirst.value.length;
                                                textareaFirst.setSelectionRange(endMove, endMove);
                                                textareaFirst.focus();
                                            }
                                        }
                                        this.setState({
                                            fontColor_show: false,
                                            fontFamily_show: window.innerWidth > 616 ? !this.state.fontFamily_show : true,
                                            fontSizeShow: false,
                                            fontLetterSpaceShow: false,
                                            elRotationOpened: false,
                                            fontAlightText: false,
                                            outlineTextShow: false,
                                            shadowTextShow: false,
                                            fontColorPicker: false,
                                            shadowColorPicker: false,
                                            outlineColorPicker: false
                                        })
                                        this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                    }}>
                                        <img src={font_icon} alt="" />
                                        <Translate content={"Font"} />

                                    </button>


                                    {(!!this.props.propertyTextColorAllowed && this.props.activeElement.attrs.quick_edit_color === true) || (this.props.mode === "ADMIN" && this.props.activeElement.attrs.quick_edit_color === true) ?
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: window.innerWidth > 616 ? !this.state.fontColor_show : true,
                                                fontFamily_show: false,
                                                fontSizeShow: false,
                                                fontLetterSpaceShow: false,
                                                elRotationOpened: false,
                                                fontAlightText: false,
                                                outlineTextShow: false,
                                                shadowTextShow: false,
                                                fontColorPicker: false,
                                                shadowColorPicker: false,
                                                outlineColorPicker: false
                                            })
                                            this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                        }}>
                                            <img src={changeStyleTextIcon} alt="" />
                                            <Translate content={"Color_Btn_Text"} />
                                        </button> : null
                                    }

                                    <div className={clsx(this.state.fontSizeShow && stl.active)}>
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: window.innerWidth > 616 ? !this.state.fontSizeShow : true,
                                                fontLetterSpaceShow: false,
                                                elRotationOpened: false,
                                                fontAlightText: false,
                                                outlineTextShow: false,
                                                shadowTextShow: false,
                                                fontColorPicker: false,
                                                shadowColorPicker: false,
                                                outlineColorPicker: false
                                            })
                                            this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                        }}>
                                            <img src={fontSize_icon} alt="" />
                                            <Translate content={"Font Size"} />
                                        </button>

                                        {!this.props.enable_submenu_var ?
                                            <div className={stl.activeFontSize + " input_field_cust"}>
                                                <img
                                                    draggable="false"
                                                    src={minus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={(e) => { e.stopPropagation(); this.handleFontSizeChange(e, -1) }}
                                                    onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                    onMouseDown={(e) => this.handleFontSizeChange(e, -1)}
                                                    onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                // onMouseLeave={() => this.setState({interval: clearInterval(this.state.interval)})}
                                                />
                                                <input
                                                    className="form-control form-control-sm cs-number-input cst-num-input"
                                                    type="number"
                                                    id="inputfontsize"
                                                    defaultValue={fontSizevar}
                                                    // placeholder={fontSizevar}
                                                    // value={fontSizevar ?? ''}   
                                                    onFocus={(e) => {
                                                        (fontSizevar = e.target.value);
                                                        (e.target.value = '');
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value == '') {
                                                            // e.target.value = fontSizevar
                                                        }
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.handleFontSizeChangeInput(e, e.target.value))

                                                    }}
                                                    onKeyDown={(e) => {
                                                        (e.key === 'Enter' && this.handleFontSizeChangeInput(e, e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.validateInput("inputfontsize", e.target.value))
                                                    }}



                                                />
                                                {/* <FormInput
                                                    className={stl.activeFontSizeInput}
                                                    size="sm"
                                                    type="number"
                                                    value={this.props.activeElement?.attrs?.fontSize || 0}
                                                    innerRef={this.fontSizeInput}
                                                    onKeyDown={(e) => e.preventDefault()}
                                                    onChange={(e) => {
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.handleFontSizeChange.call( e, e.target.value))
                                                    }}            
                                                /> */}

                                                <img
                                                    draggable="false"
                                                    src={plus_img_icon}
                                                    alt=""
                                                    // onMouseLeave={(e) => {
                                                    //     (e.target.value =500);
                                                    //     console.log("werwerwerwerwerwerwerwerwerwe")

                                                    // }}
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={(e) => { e.stopPropagation(); this.handleFontSizeChange(e, 1) }}
                                                    onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                    onMouseDown={(e) => this.handleFontSizeChange(e, 1)}
                                                    onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                // onMouseLeave={() => this.setState({interval: clearInterval(this.state.interval)})}
                                                />
                                            </div>
                                            : null}
                                    </div>

                                    {/*{this.props.toolTextAllowed || this.props.mode === "ADMIN" ?*/}
                                    {/*    <button onClick={() => {*/}
                                    {/*        this.props.onShowTextPopup();*/}
                                    {/*    }}>*/}
                                    {/*        <img src={addText_icon} alt=""/>*/}
                                    {/*        <Translate content={"New text"}/>*/}

                                    {/*    </button>*/}
                                    {/*    : null}*/}

                                    {/* RS-2275 comment */}
                                    {/* <button onClick={() => {
                                        this.props.onEditElement({
                                            newText: this.props.activeElement?.attrs?.text.split("").reverse().join(""),
                                            flipX: this.props.activeElement.attrs.flipX === undefined ? true : !!!this.props.activeElement.attrs.flipX,
                                            element: this.props.activeElement.attrs.name,
                                            activePage: this.props.activePage
                                        });

                                    }}>
                                        <img src={textFlip_icon} alt=""/>
                                        <Translate content={"Flip X"}/>
                                    </button> */}
                                    {/* RS-2275 comment end */}


                                    <div className={clsx(this.state.fontLetterSpaceShow && stl.active)}>
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: false,
                                                fontLetterSpaceShow: window.innerWidth > 616 ? !this.state.fontLetterSpaceShow : true,
                                                elRotationOpened: false,
                                                fontAlightText: false,
                                                outlineTextShow: false,
                                                shadowTextShow: false,
                                                fontColorPicker: false,
                                                shadowColorPicker: false,
                                                outlineColorPicker: false
                                            })
                                            this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                        }}>
                                            <img src={letterSpace_icon} alt="" />
                                            <Translate content={"Letter Space"} />
                                        </button>

                                        {!this.props.enable_submenu_var ?
                                            <div className={stl.activeFontSize + " input_field_cust"}>
                                                <img
                                                    draggable="false"
                                                    src={minus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={this.handleLetterSpacingChange.bind(this, -1)}
                                                    onTouchEnd={() => clearInterval(this.state.interval)}
                                                    onMouseDown={() => this.handleLetterSpacingChange(-1)}
                                                    onMouseUp={() => clearInterval(this.state.interval)}
                                                    onMouseLeave={() => clearInterval(this.state.interval)}
                                                />

                                                <input
                                                    className='form-control form-control-sm cs-number-input cst-num-input'
                                                    type="number"
                                                    id="inputletterspacing"
                                                    defaultValue={this.props.activeElement?.attrs?.letterSpacing}
                                                    // placeholder={this.props.activeElement?.attrs?.letterSpacing}
                                                    // value={ this.props.activeElement?.attrs?.letterSpacing ?? 0}
                                                    onFocus={(e) => {
                                                        // letterSpacingvar = e.target.value;
                                                        (e.target.value = '')
                                                    }}
                                                    onBlur={(e) => {
                                                        if (e.target.value == '') {
                                                            e.target.value = letterSpacingvar
                                                        }
                                                        this.handleLetterSpacingChangeByInput(e.target.value)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        (e.key === 'Enter' && this.handleLetterSpacingChangeByInput(e.target.value))
                                                    }}
                                                    onChange={(e) => {
                                                        (e.target.value.replace(/ +/g, ' ') >= 0 && this.validateInput("inputletterspacing", e.target.value))
                                                    }}
                                                />

                                                {/* <span type="text" className='form-control form-control-sm cs-number-input'>
                                                    {this.props.activeElement?.attrs?.letterSpacing}
                                                </span> */}
                                                <img
                                                    draggable="false"
                                                    src={plus_img_icon}
                                                    alt=""
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    onTouchStart={this.handleLetterSpacingChange.bind(this, 1)}
                                                    onTouchEnd={() => clearInterval(this.state.interval)}
                                                    onMouseDown={() => this.handleLetterSpacingChange(1)}
                                                    onMouseUp={() => clearInterval(this.state.interval)}
                                                    onMouseLeave={() => clearInterval(this.state.interval)}
                                                />
                                            </div>
                                            : null}
                                    </div>

                                    {/* Schatten button */}
                                    {((this.props.mode === 'ADMIN') || (this.props.propertyTextShadowAllowed) && this.props.activeElement?.attrs.type === 'text') ?
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: false,
                                                fontLetterSpaceShow: false,
                                                elRotationOpened: false,
                                                fontAlightText: false,
                                                outlineTextShow: false,
                                                shadowTextShow: window.innerWidth > 616 ? !this.state.shadowTextShow : true,
                                                fontColorPicker: false,
                                                shadowColorPicker: false,
                                                outlineColorPicker: false
                                            })
                                            this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                        }}>
                                            <img src={shadowTextIcon} alt="" />
                                            <Translate content={"Shadow_Btn_Text"} />
                                        </button>
                                        : null}

                                    {/* Umriss button */}
                                    {(this.props.activeElement.attrs.quick_edit_stroke === true) || this.props.mode === 'ADMIN' ?
                                        <button onClick={() => {
                                            this.setState({
                                                fontColor_show: false,
                                                fontFamily_show: false,
                                                fontSizeShow: false,
                                                fontLetterSpaceShow: false,
                                                elRotationOpened: false,
                                                fontAlightText: false,
                                                outlineTextShow: window.innerWidth > 616 ? !this.state.outlineTextShow : true,
                                                shadowTextShow: false,
                                                fontColorPicker: false,
                                                shadowColorPicker: false,
                                                outlineColorPicker: false
                                            })
                                            this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                        }}>
                                            <img src={outlineTextIcon} alt="" />
                                            <Translate content={"Stroke_Btn_Text"} />
                                        </button>
                                        : null}
                                </>}
                            </> : null}
                        </div>


                        {/* Font Family button desktop lang=de */}
                        {this.state.fontFamily_show && window.innerWidth > 616 ?
                            <>
                                {/* <div className={stl.textMenu__closeBtn}>
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({fontFamily_show: false})
                                        }}/>
                                    </div> */}
                                <FontFamily />
                            </>
                            : null
                        }
                        {/* Font Family button desktop lang=de end */}

                        {/* Farbe button color slider desktop lang=de */}
                        {this.state.fontColor_show && window.innerWidth > 616 && this.props.activeElement.attrs.quick_edit_color === true ?
                            <>
                                <div className="special_color_btn_slide_with_colors desktop_color_slider">
                                    <Color toggleClass={this.toggleClass.bind(this)} />
                                    {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                        <div className={this.state.fontColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                            <img src={color_picker_icon}
                                                onClick={() => {
                                                    this.setState({
                                                        fontColorPicker: !this.state.fontColorPicker,
                                                    })
                                                }}>
                                            </img>
                                        </div>
                                        : null}
                                </div>
                            </>
                            : null
                        }
                        {/* Farbe button color slider desktop lang=de end */}

                        {/* Schatten(shadowTex) button color slider desktop lang=de */}
                        {this.state.shadowTextShow && window.innerWidth > 616 ?
                            <>
                                <div className="desktop_outline_shadow_wrapper">
                                    <div className="shadow_text_section_mobile_wrap color_selector_btn_wrapper">
                                        {((this.props.mode === 'ADMIN') || (this.props.propertyTextShadowAllowed) && this.props.activeElement?.attrs.type === 'text') ?
                                            <div className="designer-right-panel-item_shadow">
                                                <Switch className={"custom-checkbox"}
                                                    onChange={this.propertyChange.bind(this, "shadowEnabled")}
                                                    checked={(!!(this.props.activeElement.attrs.shadowEnabled))} />
                                                {/* <Translate content={"Shadow"}/> */}
                                            </div>
                                            : null}
                                    </div>
                                    <div className="special_color_btn_slide_with_colors shadow desktop_color_slider">
                                        <ShadowTextColor toggleClass={this.toggleClass.bind(this)} />
                                        {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                            <div className={this.state.shadowColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                                <img src={color_picker_icon}
                                                    onClick={() => {
                                                        this.setState({
                                                            shadowColorPicker: !this.state.shadowColorPicker
                                                        })
                                                    }}>
                                                </img>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </> : null
                        }
                        {/* Schatten(shadowTex) button color slider desktop lang=de end */}

                        {/* Umriss(outlineText) button color slider desktop lang=de */}
                        {this.state.outlineTextShow && window.innerWidth > 616 ?
                            <>
                                <div className="desktop_outline_shadow_wrapper2">
                                    <div className="outline_text_section_mobile_wrap outline_selector_btn_wrapper">
                                        <FontStroke toggleClass={this.toggleClass.bind(this)} />
                                    </div>
                                    <div className="special_color_btn_slide_with_colors outline_text desktop_color_slider">
                                        <ColorFontStroke toggleClass={this.toggleClass.bind(this)} />
                                        {this.props.pages[this.props.activePage]?.onlyEmbroideryColors === 0 ?
                                            <div className={this.state.outlineColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                                <img src={color_picker_icon}
                                                    onClick={() => {
                                                        this.setState({
                                                            outlineColorPicker: !this.state.outlineColorPicker
                                                        })
                                                    }}>
                                                </img>
                                            </div>
                                            : null}
                                    </div>
                                </div>
                            </> : null
                        }
                        {/* Umriss(outlineText) button color slider desktop lang=de end */}

                        {/* Custom font Colour picker code mobile and desktop */}
                        {this.state.fontColorPicker ?
                            <>
                                {/* Check if desktop then no need to show cross btn */}
                                {window.innerWidth > 616 ? null :
                                    <div className={clsx(stl.textMenu__closeBtn) + " cross_btn_mobile_cust"}>
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({ fontColorPicker: false })
                                        }} />
                                    </div>
                                }
                                <Color toggleClass={this.toggleClass.bind(this)}
                                    fontColorPicker={this.state.fontColorPicker} />
                            </>
                            : null}
                        {/* Custom font Colour picker code mobile and desktop end */}

                        {/* Custom shadow Colour picker code mobile */}
                        {this.state.shadowColorPicker ?
                            <>
                                {/* Check if desktop then no need to show cross btn */}
                                {window.innerWidth > 616 ? null :
                                    <div className={clsx(stl.textMenu__closeBtn) + " cross_btn_mobile_cust"}>
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({ shadowColorPicker: false })
                                        }} />
                                    </div>
                                }
                                <ShadowTextColor toggleClass={this.toggleClass.bind(this)}
                                    shadowColorPicker={this.state.shadowColorPicker} />
                            </>
                            : null}
                        {/* Custom shadow Colour picker code mobile end */}

                        {/* Custom outline Colour picker code mobile */}
                        {this.state.outlineColorPicker ?
                            <>
                                {/* Check if desktop then no need to show cross btn */}
                                {window.innerWidth > 616 ? null :
                                    <div className={clsx(stl.textMenu__closeBtn) + " cross_btn_mobile_cust"}>
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({ outlineColorPicker: false })
                                        }} />
                                    </div>
                                }
                                <ColorFontStroke toggleClass={this.toggleClass.bind(this)}
                                    outlineColorPicker={this.state.outlineColorPicker} />
                            </>
                            : null}
                        {/* Custom outline Colour picker code mobile end */}

                        {/* Textboxes */}
                        {/* {!this.state.showTextProps || window.innerWidth > 616 ? <> old code*/}
                        {window.innerWidth > 616 ? <>
                            {(this.state.fontColorPicker && window.innerWidth <= 616) || (this.state.shadowColorPicker && window.innerWidth <= 616) || (this.state.outlineColorPicker && window.innerWidth <= 616) ? null :
                                <div className={stl.textMenu_elementsList + " textAreaSpacing cstm_textareaSpacing"}>
                                    {this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1 && parseInt(params.robot) !== 1 ? (
                                        <>
                                            {
                                                layerDataActive ? (
                                                    this.getElements()
                                                        .filter(i => {
                                                            return i?.layer_allocation_data?.startsWith(layerDataActive.slice(0, 5));
                                                        })
                                                        .slice(0, 5)
                                                        .map((i, index) => (
                                                            <div key={index} className={stl.textMenu_listItem}>
                                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                                    <img src={removeElementIcon} alt="" onClick={this.deleteTextElement.bind(this, i)} />
                                                                )}

                                                                <textarea
                                                                    rows="1"
                                                                    cols="3"
                                                                    id={"elementtxtid_" + index}
                                                                    name={i.name}
                                                                    value={this.decodeTextData(i)}
                                                                    maxLength={i.textLimit || undefined}
                                                                    onClick={() => {
                                                                        this.props.setSelectedElements([i.name]);
                                                                        this.props.onSelectElement(i.name);
                                                                    }}
                                                                    onFocus={() => {
                                                                        this.props.setSelectedElements([i.name]);
                                                                        this.props.onSelectElement(i.name);
                                                                    }}
                                                                    onChange={this.changeText.bind(this, i.name)}
                                                                    onBlur={this.validateText.bind(this, i.name)}
                                                                    style={i.name === this.props?.activeElement?.attrs?.name ? { borderColor: "#000000", borderWidth: "2px" } : {}}
                                                                    className={i.name === this.props?.activeElement?.attrs?.name ? "active_element_textarea" : ""}
                                                                    autoFocus={i.name === this.props?.activeElement?.attrs?.name ? true : false}
                                                                />

                                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? (
                                                                    <input type="hidden" value={i.name} id={"HDNelementtxtid_" + index}></input>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                                    <img
                                                                        src={pencilIcon}
                                                                        className="pencil_icon_new"
                                                                        alt=""
                                                                        onClick={() => {
                                                                            this.props.setSelectedElements([i.name]);
                                                                            this.props.onSelectElement(i.name);
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        ))
                                                ) : (
                                                    this.getElements().map((i, index) => (
                                                        <div key={index} className={stl.textMenu_listItem}>
                                                            {!this.props.pages[this.props.activePage].multiface_elements_admin_only && (
                                                                <img src={removeElementIcon} alt="" onClick={() => this.deleteTextElement(i)} />
                                                            )}

                                                            <textarea
                                                                rows="1"
                                                                cols="3"
                                                                id={"elementtxtid_" + index}
                                                                name={i.name}
                                                                value={this.decodeTextData(i)}
                                                                maxLength={i.textLimit || undefined}
                                                                onClick={() => this.handleElementClick(i.name)}
                                                                onFocus={() => this.handleElementClick(i.name)}
                                                                onChange={(e) => this.changeText(i.name, e)}
                                                                onBlur={() => this.validateText(i.name)}
                                                                style={
                                                                    i.name === this.props?.activeElement?.attrs?.name
                                                                        ? { borderColor: "#000000", borderWidth: "2px" }
                                                                        : {}
                                                                }
                                                                className={i.name === this.props?.activeElement?.attrs?.name ? "active_element_textarea" : ""}
                                                                autoFocus={i.name === this.props?.activeElement?.attrs?.name}
                                                            />

                                                            {this.props.pages[this.props.activePage].multiface_elements_admin_only ? (
                                                                <input type="hidden" value={i.name} id={"HDNelementtxtid_" + index} />
                                                            ) : (
                                                                ""
                                                            )}

                                                            {!this.props.pages[this.props.activePage].multiface_elements_admin_only && (
                                                                <img
                                                                    src={pencilIcon}
                                                                    className="pencil_icon_new"
                                                                    alt=""
                                                                    onClick={() => this.handleElementClick(i.name)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))
                                                )
                                            }

                                        </>
                                    ) : (
                                        this.getElements().map((i, index) => (
                                            <div key={index} className={stl.textMenu_listItem}>
                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                    <img src={removeElementIcon} alt="" onClick={this.deleteTextElement.bind(this, i)} />
                                                )}

                                                <textarea
                                                    rows="1"
                                                    cols="3"
                                                    id={"elementtxtid_" + index}
                                                    name={i.name}
                                                    value={this.decodeTextData(i)}
                                                    maxLength={i.textLimit || undefined}
                                                    onClick={() => {
                                                        this.props.setSelectedElements([i.name]);
                                                        this.props.onSelectElement(i.name);
                                                    }}
                                                    onFocus={() => {
                                                        this.props.setSelectedElements([i.name]);
                                                        this.props.onSelectElement(i.name);
                                                    }}
                                                    onChange={this.changeText.bind(this, i.name)}
                                                    onBlur={this.validateText.bind(this, i.name)}
                                                    style={i.name === this.props?.activeElement?.attrs?.name ? { borderColor: "#000000", borderWidth: "2px" } : {}}
                                                    className={i.name === this.props?.activeElement?.attrs?.name ? "active_element_textarea" : ""}
                                                    autoFocus={i.name === this.props?.activeElement?.attrs?.name ? true : false}
                                                />

                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? (
                                                    <input type="hidden" value={i.name} id={"HDNelementtxtid_" + index}></input>
                                                ) : (
                                                    ""
                                                )}

                                                {this.props.pages[this.props.activePage].multiface_elements_admin_only ? null : (
                                                    <img
                                                        src={pencilIcon}
                                                        className="pencil_icon_new"
                                                        alt=""
                                                        onClick={() => {
                                                            this.props.setSelectedElements([i.name]);
                                                            this.props.onSelectElement(i.name);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))
                                    )}


                                    <div className={stl.textMenu_elementAdd}>
                                        {this.props.pages[this.props.activePage].multiface_elements_admin_only &&
                                            parseInt(params.robot) === 0 ? (
                                            <Button
                                                className="prvForDesktop"
                                                outline
                                                theme="secondary"
                                                style={{
                                                    display: 'flex',
                                                    border: '1px solid #000',
                                                    fontWeight: '600',
                                                    padding: '16px',
                                                    fontSize: '16px',
                                                    borderRadius: '100px',
                                                    maxWidth: 'unset',
                                                }}
                                                onClick={() => {
                                                    if (document.getElementById("loaderdv_id")) {
                                                        document.getElementById("loaderdv_id").style.display = "flex";
                                                    }
                                                    if (document.getElementById("prv_id")) {
                                                        document.getElementById("prv_id").style.opacity = "0.5";
                                                    }
                                                    setTimeout(() => {
                                                        this.generatePreview();
                                                    }, 0);
                                                }}
                                            >
                                                <Translate content={"Preview"} />
                                            </Button>
                                        ) : (
                                            this.props.mode !== 'ADMIN' &&
                                                params.sid !== "ADMIN" &&
                                                !this.props.propertyTextShowAllowed ? (
                                                null
                                            ) : <img src={addTextIcon} alt="" onClick={this.addTextInput.bind(this)} />
                                        )}
                                    </div>

                                </div>
                            }
                        </> : null}
                        {/* Textboxes Ends */}
                        {/* </CustomScroll> */}
                    </CommonText>
                </>
            </>
            : null
        )
    }
}

const
    mapStateToProps = state => {
        return {
            activeElement: state.glb.activeElement,
            pages: state.glb.pages,
            showTextForm: state.tol.showTextForm,
            mode: state.glb.mode,
            activePage: state.glb.activePage,
            toolTextAllowed: state.tol.toolTextAllowed,
            stage: state.glb.stage,
            propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
            propertyTextShowAllowed: state.tol.propertyTextShowAllowed,
            propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
            propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
            propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
            selectedElements: state.glb.selectedElements,
            previewUrl: state.glb.previewUrl,
            previews: state.prv.previews,
            isItemDownloading: state.glb.isItemDownloading,
            isShowImageChangePopup: state.tol.showImageChangePopup,
            isShowTextareaMobilePopup: state.tol.ShowTextareaMobilePopup,
            enable_submenu_var: state.glb.enable_submenu_var,
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {
            setUserAllowedProperties: (data) => {
                dispatch(actionCreators.userAllowedProperties(data));
            },
            setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),

            onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
            onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
            onShowTextEditPopup: () => dispatch(actionCreators.showTextEditPopup()),
            onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
            hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
            deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
            addTextToPage: (canvasElement) => {
                dispatch(actionCreators.addElementToPage(canvasElement));
                dispatch(actionCreators.hidePopup());
            },
            setPreviewUpdateStatus: (data) => {
                dispatch(actionCreators.setPreviewUpdateStatus(data));
            },
            generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
            showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
            ShowTextareaMobilePopup: () => dispatch(actionCreators.ShowTextareaMobilePopup()),

            MenuBtnEnableSubmenu: (data) => {
                dispatch(actionCreators.MenuBtnEnableSubmenu(data))
            },
        };
    }
    ;

export default connect(mapStateToProps, mapDispatchToProps)(Text);