import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";

import FlipXIcon from '../../../assets/images/iconsSVG/FlipXSVG';
// import FlipYIcon from '../../../assets/images/iconsSVG/FlipYSVG';
import Translate from "react-translate-component";

class FlipText extends Component {
    flipX() {
        this.props.onEditElement({
            newText: this.props.activeElement.attrs.text.split("").reverse().join(""),
            flipX: this.props.activeElement.attrs.flipX === undefined ? true : !!!this.props.activeElement.attrs.flipX,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
    }

    flipY() {
        this.props.onEditElement({
            flipY: this.props.activeElement.attrs.flipY === undefined ? true : !!!this.props.activeElement.attrs.flipY,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
    }

    render() {
        return (
            <div className="designer-right-panel-item designer-right-panel-item_justification">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>
                        <Translate content={"Flip"}/>
                    </span>
                </div>
                <div className={"designer-right-panel-item__checkboxes"}>
                    {/*<button onClick={this.flipY.bind(this)}*/}
                            {/*className={"designer-right-panel-btn"}>*/}
                        {/*<FlipYIcon/>*/}
                    {/*</button>*/}
                    <button onClick={this.flipX.bind(this)}
                            className={"designer-right-panel-btn"}>
                        <FlipXIcon/>
                    </button>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FlipText);
