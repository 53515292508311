import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";
import Switch from "react-switch";
import { FormInput } from "shards-react";

class Position extends Component {

    positionInputWidth = React.createRef();
    positionInputHeight = React.createRef();

    state = {
        positionDropdown: false
    };

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.positionInputWidth.current !== null) {
            this.positionInputWidth.current.value = attrs.width;
            this.positionInputHeight.current.value = attrs.height;
        }
    }

    handlePositionWidthChange(e) {
        if (this.props.activeElement.attrs?.keepRatio) {
            this.keepRatioResize(e, "width")
        } else {
            this.props.onEditElement({
                width: parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25,
                element: this.props.activeElement.attrs.name,
                activePage: this.props.activePage,
            });

            this.props.pages[0].canvasElements.forEach((item) => {
                if ((item.faceId === this.props.activeElement.attrs.faceId
                    && this.props.activeElement.attrs.faceId !== 0
                    && this.props.activeElement.attrs.elementRepeatOval !== true
                    && this.props.activeElement.attrs.elementRepeat !== true
                    && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                    this.props.onEditElement({
                        width: parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25,
                        element: item.name,
                        activePage: this.props.activePage,
                    });
                }
            });

        }
    }

    keepRatioResize(e, side) {
        let
            {height, width} = this.props.activeElement.attrs;

        let
            newWidth,
            newHeight;

        if (side === 'width') {
            newWidth = (parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25);
            newHeight = newWidth * (height / width);
        } else {
            newHeight = (parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25);
            newWidth = newHeight * (width / height)
        }

        this.props.onEditElement({
            width: newWidth,
            height: newHeight,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage,
        });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    width: newWidth,
                    height: newHeight,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    handleKeepRatioClick(e) {
        this.props.onEditElement({
            keepRatio: e,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    keepRatio: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    handlePositionHeightChange(e) {
        if (this.props.activeElement.attrs?.keepRatio) {
            this.keepRatioResize(e, "height")
        } else {
            this.props.onEditElement({
                height: parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25,
                element: this.props.activeElement.attrs.name,
                activePage: this.props.activePage,
            });

            this.props.pages[0].canvasElements.forEach((item) => {
                if ((item.faceId === this.props.activeElement.attrs.faceId
                    && this.props.activeElement.attrs.faceId !== 0
                    && this.props.activeElement.attrs.elementRepeatOval !== true
                    && this.props.activeElement.attrs.elementRepeat !== true
                    && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                    this.props.onEditElement({
                        height: parseFloat(e.target.value) > 25 ? parseFloat(e.target.value) : 25,
                        element: item.name,
                        activePage: this.props.activePage,
                    });
                }
            });

        }
    }

    onKeyDown(e) {
        if (e.keyCode === 188 && window.navigator.userAgent.indexOf('Firefox') !== -1) {
            e.target.value = e.target.value + ".";
            e.preventDefault();
        }
    }


    render() {
        if (!this.props.propertyFaceCropSizeAllowed && (this.props.activeElement.attrs.elementRepeat || this.props.activeElement.attrs.elementRepeatOval)) return null
        return (
            <div
                className={clsx("designer-right-panel-item", "designer-right-panel-item_positions", this.state.positionDropdown && "active")}>
                <div className={"designer-right-panel-item__titleFlex"}
                     onClick={this.props.toggleClass.bind(this, "positionDropdown")}>
                    {/*<FontAwesomeIcon icon="arrows-alt"/>*/}
                    <span className={"text"}>
                        <Translate content={"Size"}/>
                    </span>
                </div>
                <div className={"dropdownContent"}>
                    <div className={"designer-right-panel-positions"}>
                        <div className="designer-right-panel-inputNumber_title">
                            W :
                            <FormInput type="number"
                                       size="sm"
                                       step={10}
                                       onKeyDown={this.onKeyDown}
                                       defaultValue={this.props.activeElement.attrs.width}
                                       onChange={this.handlePositionWidthChange.bind(this)}
                                       innerRef={this.positionInputWidth}/>
                        </div>
                        <div className="designer-right-panel-inputNumber_title">
                            H :
                            <FormInput type="number"
                                       size="sm"
                                       step={10}
                                       onKeyDown={this.onKeyDown}
                                       defaultValue={this.props.activeElement.attrs.height}
                                       onChange={this.handlePositionHeightChange.bind(this)}
                                       innerRef={this.positionInputHeight}/>
                        </div>
                    </div>
                    {this.props.mode === "ADMIN" ?
                        <div className={"designer-right-panel-item__titleFlex"}
                             style={{paddingLeft: 0, paddingRight: 0}}>
                            <Switch className={"custom-checkbox"}
                                    onChange={this.handleKeepRatioClick.bind(this)}
                                    checked={(!!(this.props.activeElement?.attrs?.keepRatio))}/>
                            Keep aspect ratio
                        </div> : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        selectedElements: state.glb.selectedElements,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Position)
