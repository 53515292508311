import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import stl from "./FaceCropPopup.module.scss"

import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import back_btn from '../../assets/images/expand-more.png'

import {Button} from "shards-react";

import Translate from "react-translate-component";
import Text from "../Tools/ToolsViews/Text";


class BackgroundChangePopup extends Component {
    state = {

    };


    componentDidMount() { 
        this.generatePreview();

    }

    generatePreview() {
        if (this.props.stage) {
            console.log(this.props.isItemDownloading);
            if (this.props.isItemDownloading > 0) {
                setTimeout(() => {
                    this.generatePreview()
                }, 1000);
            } else {
                let stage = this.props.stage.clone();
                this.props.generatePreview({
                    pages: this.props.pages,
                    activePage: this.props.activePage,
                    stage: stage,
                    previewUrl: this.props.previewUrl
                });
                this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
                setTimeout(() => {
                    if(document.getElementById("loaderdv_id")){
                        document.getElementById("loaderdv_id").style.display="none";
                    }
                    var myEle = document.getElementById("prv_id");
                    if(myEle){
                        myEle.style.opacity="1";
                    }
                }, 3000); //37000
            }
        }
    }


    render() {
        //console.log(this.state,'<========----->',this.props);
        //console.log('-->>>',this.props?.pages[this.props.activePage].canvasElements)
        const {previews: previewsProps} = this.props;
        let previews = previewsProps.map((item) => {
            return (
                <img id="prv_id" key={item.name} src={item.image} alt={''}/>
            )
        });

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay"
                     onClick={this.props.hidePopup}/>
                <div
                    className={'text-form-popup-content-inner'}>
                    <div className="text-form-popup-content-title__wrap">
                        <div className="text-form-popup-back"
                             onClick={() => {
                                 this.props.hidePopup();
                                 this.props.showFaceCropPopup();
                                 this.props.WhichPopUp({ displaytype: 'shop' })
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                             }}>
                            <img src={back_btn} alt=""/>
                        </div>
                        <div className="text-form-popup-content-title">
                            {/* Texts */}
                            <Translate content={"texts_lang"}/>
                        </div>
                        <div className="text-form-popup-close"
                             onClick={() => {
                                 window.parent.postMessage({datatype: "hide_designer"}, "*")
                                 this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
                                 window.onbeforeunload = null;
                             }}>
                            <img src={close_btn} alt=""/>
                        </div>
                    </div>

                    <div className={'bgChangePopup'}>
                        <div className={'bgChangePopup__preview'}>
                            {this.props?.pages[this.props.activePage]?.renderers?.length > 0 ? (previews?.length > 0 && !this.props.previewShouldBeChanged ? previews :
                                <Translate content={"Loading"}/>) : "preview not found"}
                            <div id="loaderdv_id" style={{display:"none"}}><Translate content={"Loading"}/></div>
                        </div>
                        <div className={'textChangePopup cs-scroll1'}>
                            <Text/>
                        </div>
                    </div>
                    <div className={'prev-cont_cls'}>
                        <Button className="prvForMob" outline theme="secondary"
                            style={{ 
                                display: 'flex',
                                border: '1px solid #000',
                                fontWeight: '600',
                                padding: '16px',
                                fontSize: '16px', 
                                borderRadius: '100px',
                                maxWidth: 'unset',
                            }}
                                onClick={() => {
                                if(document.getElementById("loaderdv_id")){
                                    document.getElementById("loaderdv_id").style.display="flex";
                                }
                                if(document.getElementById("prv_id")){
                                    document.getElementById("prv_id").style.opacity="0.5";
                                }
                                setTimeout(() => {
                                    this.generatePreview();
                                }, 0)
                            }}>
                                <Translate content={"Preview"}/>
                        </Button>
                        <Button outline theme="secondary"
                                style={{ 
                                    display: 'flex',
                                    border: 'none',
                                    fontWeight: '600',
                                    padding: '16px',
                                    fontSize: '16px',
                                    color: '#FFFFFF',
                                    background: '#333333',
                                    borderRadius: '100px',
                                }}
                                onClick={() => {
                                    setTimeout(() => {
                                        //this.props.showOrderPreviewPopup();
                                        let activePage = this.props.pages[this.props.activePage];
                                        let colors = [];
                                        for (let key in activePage.backgrounds) {
                                            let item = activePage.backgrounds[key]
                                            if (activePage.backgrounds[key].type === 'color') {
                                                colors.push(item.color_code);
                                            }
                                        }

                                        let backgrounds = [];
                                        let pageBackgrounds = activePage.designs;
                                        // console.log(activePage)

                                        for (let key in pageBackgrounds) {
                                            if (!pageBackgrounds.hasOwnProperty(key)) continue;
                                            let bck = pageBackgrounds[key];
                                            backgrounds.push({ key: key, background_image: bck.image });
                                        }

                                        // console.log(colors.length > 0 , backgrounds.length > 0);
                                        //this.props.displaytype = 'viacrop';
                                        if ((colors.length > 0 || backgrounds.length > 0) && this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 && this.props.displaytype == 'shop') {
                                            this.props.hidePopup();
                                            this.props.showBackgroundChangePopup();
                                        }else{
                                            this.props.showOrderPreviewPopup();
                                        }
                                    }, 0)
                                }}
                        >
                            <Translate content={"Continue"}/>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        stage: state.glb.stage,
        pages: state.glb.pages,
        previewUrl: state.glb.previewUrl,
        previews: state.prv.previews,
        isItemDownloading: state.glb.isItemDownloading,
        displaytype: state.glb.displaytype,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },

        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({data: data})),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
        showBackgroundChangePopup: () => dispatch(actionCreators.showBackgroundChangePopup()),
        WhichPopUp: (data) => {
            dispatch(actionCreators.WhichPopUp(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundChangePopup);
