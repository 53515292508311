import * as actionTypes from '../actions/actionTypes';

const initialState = {
	previews: [],
	previewsLoadingCount: 0,
	renderers: [],
};

const preview = (state = initialState, action) => {
	switch(action.type) {
		case actionTypes.GENERATE_PREVIEW: {
			return {
				...state,
				previews: state.previews.concat({ image: action.image, name: 'preview-' + state.previews.length })
			}
		}
		case actionTypes.ADD_PREVIEW_LOADING_COUNTER: {
			return {
				...state,
				previewsLoadingCount: state.previewsLoadingCount + 1
			}
		}
		case actionTypes.ADD_PREVIEW_IMAGE: {
			return {
				...state,
				previewsLoadingCount: state.previewsLoadingCount - 1,
				previews: [{ image: action.image, name: 'preview-' + state.previews.length }]
			}
		}
		case actionTypes.ADD_RENDERERS: {
			return {
				...state,
				renderers: state.renderers.concat(action.renderers)
			}
		}	
		default: return {
			...state,
			previews: state.previews
		}
	}
}

export default preview;