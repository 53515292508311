module.exports = {
    DEFAULT_FONTS: {},
    MAX_SCALE: 200,
    MIN_SCALE: 100,
    DESKTOP_WIDTH: 1279,
    PREVIEW_SIZE: 900,
    MIN_STAGE_SIZE: 592,
    MAX_STAGE_SIZE: 1534,
    GRID_PADDING: 21,
    LOADER_MIN_TIME: 600,
    // IMAGE_MAX_SIZE: 18388608,
    IMAGE_MAX_SIZE: 8388608,
    MAX_IMAGE_SCALE:  1.25,
}