

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';

import globalsReducer from './store/reducers/globals';
import canvasReducer from './store/reducers/canvas';
import canvasCustomMasksReducer from './store/reducers/canvasCustomMasks';
import toolsReducer from './store/reducers/tools';

import previewReducer from './store/reducers/preview';
import Designer from './containers/Designer';
// import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"

const rootReducer = combineReducers({
    glb: globalsReducer,
    cnvMask: canvasCustomMasksReducer,
    cnv: canvasReducer,
    tol: toolsReducer,
    prv: previewReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
    <Provider store={store}>
        <Designer/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
