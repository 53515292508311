// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/canvas-bck.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Canvas_designer-canvas__2f0y3 {\n  flex: 1 1 auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  overflow: hidden;\n  padding: 0 10px 10px 10px;\n  max-width: 596px;\n  max-height: 596px;\n  width: calc(100vh - 281px);\n  height: calc(100vh - 281px); }\n  @media (max-width: 1279px) {\n    .Canvas_designer-canvas__2f0y3 {\n      height: calc(100vh - 258px); } }\n\n.Canvas_canvas__1LL7p {\n  overflow: auto;\n  color: #000000;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  max-width: 596px;\n  max-height: 596px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  scroll-behavior: smooth;\n  border: 2px solid #bdc0be; }\n  @media (min-width: 1280px) {\n    .Canvas_canvas__1LL7p {\n      overflow: auto;\n      border-radius: 4px;\n      box-shadow: inset 0 0 0 4px #f2f4f3; } }\n  @media (max-width: 1279px) {\n    .Canvas_canvas__1LL7p {\n      margin: 0 auto; } }\n\n.Canvas_canvas-wrap__2JKAg {\n  height: 100%;\n  overflow: hidden;\n  top: 0;\n  left: 0; }\n\n.Canvas_canvas-workSpace__1tVEq {\n  width: 100%;\n  height: 100%;\n  cursor: crosshair;\n  background-color: #FFFFFF;\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  .Canvas_canvas-workSpace__1tVEq .Canvas_konvajs-content__1Vadd {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    margin: 0 auto; }\n    .Canvas_canvas-workSpace__1tVEq .Canvas_konvajs-content__1Vadd canvas {\n      border: 1px solid #DFE0DF !important; }\n\n.Canvas_canvas-loader__1r1XT {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  height: 100%;\n  align-items: center; }\n\n.Canvas_canvas-loader__image__5cqnH {\n  width: 300px;\n  height: 300px; }\n", ""]);
// Exports
exports.locals = {
	"designer-canvas": "Canvas_designer-canvas__2f0y3",
	"canvas": "Canvas_canvas__1LL7p",
	"canvas-wrap": "Canvas_canvas-wrap__2JKAg",
	"canvas-workSpace": "Canvas_canvas-workSpace__1tVEq",
	"konvajs-content": "Canvas_konvajs-content__1Vadd",
	"canvas-loader": "Canvas_canvas-loader__1r1XT",
	"canvas-loader__image": "Canvas_canvas-loader__image__5cqnH"
};
module.exports = exports;
