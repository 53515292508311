import * as actionTypes from '../actions/actionTypes';

const initialState = {
    canvasMaskElements: [{
        type: 'line',
        points: [],
        name: 'customShape',
        closed: true,
        tension: 0.001,
        stroke: 'black',
        fill: 'white'
    }],
    isMaskDrawMode: false
}

let checkExistedNames = (elements, newName) => {
    let
        isNameFree,
        freeName,
        checkElementsName = () => {
            isNameFree = true;
            newName = newName + 1;
            elements.forEach((item) => {
                if (item.name && item.name.length > 0 && 'maskEl-' + newName === item.name) {
                    isNameFree = false;
                }
            });
            if (isNameFree) {
                freeName = 'maskEl-' + (newName);
            } else {
                newName = newName + 1;
            }
        };
    do {
        checkElementsName();
    }
    while (!isNameFree);
    return freeName;
};

const canvas = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_MASKS_ELEMENT: {
            let element = {
                x: 50,
                y: 50,
                width: 100,
                height: 100,
                fill: 'white',
                name: action.shape.type === 'borderLimit' ? 'borderLimit' : checkExistedNames(state.canvasMaskElements, 'maskEl-' + action.shape.type + '-' + state.canvasMaskElements.length),
                type: action.shape.type === 'borderLimit' ? 'square' : action.shape.type,
                draggable: true,
                stroke: 'red',
                strokeWidth: 2,
                sides: 3,
                radius: 50,
                transformable: true,
            }

            if (action.shape.points && action.shape.points.length > 0) {
                let newCanvasMaskElements = state.canvasMaskElements.map(item => {
                    if (item.points && item.points.length >= 0) {
                        return {...item, points: [...item.points, action.shape.points[0], action.shape.points[1]]}
                    } else {
                        return item
                    }
                })
                return {
                    ...state,
                    canvasMaskElements: newCanvasMaskElements
                }
            } else {
                return {
                    ...state,
                    canvasMaskElements: element.name === 'borderLimit' ? [element, ...state.canvasMaskElements] : state.canvasMaskElements.concat(element)
                }
            }
        }

        case actionTypes.GET_MASKS_ELEMENTS: {
            return {
                ...state,
                canvasMaskElements: state.canvasMaskElements
            }
        }

        case actionTypes.SET_ACTIVE_MAKS_ELEMENT: {
            return {
                ...state,
                activeMaskElement: action.activeMaskElement
            }
        }

        case actionTypes.TOGGLE_MASK_DRAW_MODE: {
            return {
                ...state,
                isMaskDrawMode: action.isMaskDrawMode
            }
        }

        case actionTypes.SET_MASKS_ELEMENTS: {
            return {
                ...state,
                canvasMaskElements: action.data === 'delete' || action.data.canvasMaskElements.length === 0 ? [] : JSON.parse(action.data.canvasMaskElements)
            }
        }

        default:
            return {
                ...state,
                canvasMaskElements: state.canvasMaskElements
            }
    }
};

export default canvas;
