import React, { Component } from 'react';
import { Circle, Layer, Line, Rect, RegularPolygon, Stage, Text } from 'react-konva'; //TextPath
import ComponentTransformer from './ComponentTransformer/ComponentTransformer';
import URLImage from './URLImage/URLImage';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import loadingImage from '../../assets/images/loading.gif';
import loading1Image from '../../assets/images/loading2.gif';
import fullScreenIcon from '../../assets/images/fullScreen.svg';
import plusIcon from '../../assets/images/plus.svg';
import GoogleFontLoader from 'react-google-font-loader';
import WebFont from 'webfontloader';
import './Canvas.scss';
import stl from './Canvas.module.scss';
import clsx from "clsx";
import CanvasAdditionalLayers from "../CanvasAdditionalLayers/CanvasAdditionalLayers";
import FontFaceObserver from "fontfaceobserver";

import front_side from '../../assets/images/front_side.jpeg';
import back_side from '../../assets/images/back_side.jpeg';

import queryString from 'query-string';

import cropImage from "../../helpes/cropImage";
import TranslateForAlert from "../../helpes/translateForAlert";

import {
    MAX_SCALE,
    MIN_SCALE,
    DESKTOP_WIDTH,
    PREVIEW_SIZE,
    MIN_STAGE_SIZE,
    MAX_STAGE_SIZE,
} from "../../config";

import textTransformer from "../../helpes/textTransformer";
import ErrorBoundary from "./ErrorBoundary";
import { setImgStatusError } from "../../store/actions/index";
import { isMobile, isTablet } from 'react-device-detect';
import { css$1 } from 'react-select/dist/index-f91a2068.cjs.prod';

const params = queryString.parse(window.location.search);
const axios = require("axios");
class Canvas extends Component {


    stage = React.createRef();
    canvas = React.createRef();
    canvasClip = React.createRef();
    state = {
        transformer: true,
        canvasScale: MIN_SCALE,
        STAGE_SIZE: MIN_STAGE_SIZE,
        width: MIN_STAGE_SIZE,
        height: MIN_STAGE_SIZE,
        elementsOut: [""],
        guidelines: [],
        checkedFontFaceLoad: false,
        touchEventAdded: false,
        elementScaling: false,
        selectedFirstElement: false,
        changeElementArray: [],
        changeElementObject: [],
        isFirstStageCopyCreated: false,
        importedFonts: [],
        deletedIconX: -58,
        pencilIconX: 0,
        textdefaultcolor: ["black", false],
        front_image: true,
        back_image: false,
        center_image: false,
        check_count_front: 1,
        check_count_back: 1,
        layerData: [],
        userRemoveFeomPreview: null,


    };
    FrontImage(check_count_val, data, layer) {
        localStorage.setItem('isVisibleMugFace', 1);
        localStorage.setItem('layer_data', layer);
        this.props.onSelectElement(data?.name)
        this.setState({
            front_image: true,
            back_image: false,
            center_image: false,
            check_count_front: 2,
            check_count_back: 1,
        });
        this.checkTextsPosition()
        if (check_count_val === 1) {
            const observer = new MutationObserver(() => {
                const element = document.querySelector('#CheckCountFront');
                if (element) {
                    element.click();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                childList: true,
                subtree: true,
            })
        }
    }
    BackImage(check_count_val, data, layer) {
        localStorage.setItem('isVisibleMugFace', 2);
        localStorage.setItem('layer_data', layer);
        this.props.onSelectElement(data?.name)
        this.setState({
            front_image: false,
            back_image: true,
            center_image: false,
            check_count_back: 2,
            check_count_front: 1,
        });
        this.checkTextsPosition()
        if (check_count_val === 1) {
            const observer = new MutationObserver(() => {
                const element = document.querySelector('#CheckCountBack');
                if (element) {
                    element.click();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                childList: true,
                subtree: true,
            })
        }
    }
    CenterImage(check_count_val, data, layer) {
        localStorage.setItem('isVisibleMugFace', 3);
        localStorage.setItem('layer_data', layer);
        this.props.onSelectElement(data?.name)
        this.setState({
            front_image: false,
            back_image: false,
            center_image: true,
            check_count_front: 2,
            check_count_back: 1,
        });
        this.checkTextsPosition()
        if (check_count_val === 1) {
            const observer = new MutationObserver(() => {
                const element = document.querySelector('#CheckCountCenter');
                if (element) {
                    element.click();
                    observer.disconnect();
                }
            });
            observer.observe(document, {
                childList: true,
                subtree: true,
            })
        }
    }

    multifaceProductCheck() {
    }

    selectFirstLoadElement() {
        // document.body.classList.add('my-test-class')
        if (this.props.stage && (typeof this.props.activeElement === "undefined") && this.props.pages.length > 0 && this.props.allImagesReady) {
            let ImgExists = false;
            this.props.pages[this.props.activePage].canvasElements.forEach(item => {
                if (item.type === 'image') ImgExists = true
            });

            if (!ImgExists) {
                this.props.setItemDownloadingStatus({
                    isItemDownloading: 0
                });
            }


            // const ctx = this.canvas.current.getContext('2d');
            // console.log(ctx.fillStyle = 'black')
            // ctx.globalCompositeOperation = 'xor';

            return this.props.pages[this.props.activePage].canvasElements.map(item => {
                if (item.deletable === false && this.props.mode !== 'ADMIN' && item.name !== 'undefined' || (!item.isGlobalElementUrl)) return null
                if (this.props.stage && this.props.activeElement === undefined) {

                    switch (item.type) {
                        case "text":
                            this.props.onShowTextForm();
                            break;
                        case "image":
                            this.props.onShowUploadForm();
                            break;
                        case "icon":
                            this.props.onShowEmojiForm()
                            break;
                        default:
                            this.props.onShowShapesForm();
                            break;
                    }
                    this.props.onSelectElement(item.name);

                    this.setState(function () {
                        return { selectedFirstElement: true }
                    }, () => {
                        if (this.props.activeElement && ((this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 && this.props.mode !== "ADMIN") || this.props.pages[this.props.activePage].only_fulleditor === 1) && parseInt(params.robot) !== 1 && item.type === 'image') {
                            this.props.showImageChangePopup();
                        }
                    });
                }
                return null
            })
        }
    }

    componentDidMount() {
        const { layerData } = this.state;
        localStorage.setItem('isVisibleMugFace', 1);

        this.props.setItemDownloadingStatus({
            isItemDownloading: 0
        });
        this.selectFirstLoadElement()
        window.addEventListener('message', this.receiveQuickChanges.bind(this), { passive: true });
        window.addEventListener('resize', this.resizeEvents.bind(this), { passive: true });
        this.resizeEvents();
        this.generatePreview(true);
        this.guidelinesCalculating();
    }

    getDistance(p1, p2) {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    }

    setMultitouchListener() {
        if (this.stage.current && !this.state.touchEventAdded) {

            let lastDist = 0;
            this.stage.current.getContent().addEventListener(
                'touchmove',
                (evt) => {
                    let touch1 = evt.touches[0];
                    let touch2 = evt.touches[1];

                    if (touch1 && touch2 && this.props?.activeElement && this.props?.activeElement?.attrs?.transformable) {
                        let dist = this.getDistance(
                            {
                                x: touch1.clientX,
                                y: touch1.clientY,
                            },
                            {
                                x: touch2.clientX,
                                y: touch2.clientY,
                            }
                        );

                        if (!lastDist) {
                            lastDist = dist;
                        }

                        let scale = (((this.props.activeElement?.scaleX() ?? 1) * dist) / lastDist);
                        if (this.props?.activeElement.attrs.type === 'image' || this.props?.activeElement.attrs.type === 'icon') {
                            scale = (dist / lastDist);
                            this.props.onEditElement({
                                width: this.props.activeElement.attrs.width * scale,
                                height: this.props.activeElement.attrs.height * scale,
                                element: this.props.activeElement.attrs.name,
                                activePage: this.props.activePage,
                            });
                        } else if (this.props?.activeElement.attrs.type === 'text') {

                            this.props.onEditElement({
                                fontSize: this.props?.activeElement.attrs.type === 'text' ? this.props?.activeElement.attrs.fontSize * scale : this.props?.activeElement.attrs.fontSize,
                                element: this.props.activeElement.attrs.name,
                                activePage: this.props.activePage,
                            });
                        } else {
                            if (this.props.activeElement?.scaleX() !== undefined) {
                                scale = scale > 0.7 ? scale : 0.7
                                this.props.activeElement.scaleX(scale);
                                this.props.activeElement.scaleY(scale);
                            }
                        }

                        if (!this.state.elementScaling) {
                            this.setState({
                                elementScaling: true
                            });
                        }

                        if (this.props.activeElement) {


                            let rotation = evt.rotation;
                            // This isn't a fun browser!
                            if (!rotation && evt?.touches[1]?.pageY && evt?.touches[0]?.pageY) {
                                rotation = Math.atan2(evt.touches[0]?.pageY - evt.touches[1]?.pageY,
                                    evt.touches[0].pageX - evt.touches[1].pageX) * 180 / Math.PI;
                            }
                            this.props.onEditElement({
                                rotation: rotation,
                                element: this.props.activeElement.attrs.name,
                                activePage: this.props.activePage,
                            });
                        }

                        lastDist = dist;
                    }
                },
                { passive: true },
                false
            );

            this.stage.current.on('touchend', () => {
                lastDist = 0;
                if (this.state.elementScaling) {
                    this.setState({
                        elementScaling: false
                    })
                }
            }, { passive: true },);
            this.setState({ touchEventAdded: true })
        }
    }

    setStageFirstCopy() {

        if (this.props.pages.length > 0 && !this.state.isFirstStageCopyCreated && this.stage.current !== null) {

            this.props.onEditElement({
                historyMode: true,
                activePage: this.props.activePage,
            });

            if (parseInt(params.robot)) {
                let globalUrl, faceId;
                this.stage.current.children[this.props.activePage].children.forEach((item) => {
                    if (item.attrs.isGlobalElementUrl) {
                        this.props.setItemDownloadingStatus({
                            isItemDownloading: 1
                        });
                        globalUrl = item.attrs.faceCropped || item.attrs.imageWithoutBg || item.attrs.image.src;
                        faceId = item.attrs.faceId
                    }
                });

                if (globalUrl !== undefined) {
                    this.image = new window.Image();
                    this.image.src = globalUrl;
                    this.image.addEventListener('load', this.submitChanges.bind(this, this.stage.current, faceId), { passive: true });
                    this.image.addEventListener("error", () => {
                        console.log("Image load error");
                    }, { passive: true });
                }
            }
            this.fontLoader();
            this.setState({
                isFirstStageCopyCreated: true
            })
        }
    }

    submitChanges(stage, faceId) {

        let t0 = performance.now();
        stage.children[this.props.activePage].children.forEach((item) => {
            if ((item.attrs?.elementRepeat || item.attrs?.elementRepeatOval) && item?.attrs?.type === "image" && faceId === item.attrs.faceId && faceId !== undefined && !item.attrs?.isGlobalElementUrl) {

                this.props.onEditElement({
                    historyMode: false,
                    newImgData: {
                        imageUrl: this.image.src,
                        faceCropped: this.image.src,
                        width: (item.attrs.height * item.attrs.scaleY) / ((this.props?.activeElement?.attrs.elementRepeatOval ? 1.208791208791209 : 1.331719128329298)),
                        height: item.attrs.height * item.attrs.scaleY,
                    },
                    scaleX: 1,
                    scaleY: 1,
                    element: item.attrs.name,
                    activePage: this.props.activePage,
                });
            }
        }, { passive: true });
        this.props.setItemDownloadingStatus({
            isItemDownloading: -1
        });
        let t1 = performance.now();
        console.log("Multiface elements created in " + (t1 - t0) + " milliseconds. (admin only can see this)")
    }

    componentDidUpdate(prevProps, prevState) {

        // if (this.props.allImagesReady) console.log('editor is ready');
        const { changeElementArray, changeElementObject } = this.state;
        this.setMultitouchListener();
        this.setStageFirstCopy();
        this.props.setStageObject(this.stage.current);
        if (!this.state.checkedTextsPosition) this.checkTextsPosition();
        if (!this.state.selectedFirstElement) this.selectFirstLoadElement();

        this.guidelinesCalculating();
        this.cursorStyleManager();
        // set text positions and offsets
        if (!this.state.checkedFontFaceLoad && this.props.pages.length > 0) {

            let template = this.props.pages[this.props.activePage];
            // console.log("template:", template)
            for (let font in template.customFonts) {
                this.checkFontFaceObserver(template.customFonts[font].font_family);
                // this.checkFontFaceObserver("WaltDisneyScriptv4.1");
            }
            for (let font in template.googleFonts) {
                this.checkFontFaceObserver(font);
            }
            this.setState({
                checkedFontFaceLoad: true
            });
            this.checkTextsPosition();
        }

        this.resizeStage();
        this.generatePreview();
        if (this.props.saving) {
            // this.resetCanvasScale();
            this.props.onSave();
        }

        // if (this.props.stage?.children[this.props?.activePage]?.children) {getPosition
        //     this.props.stage.children[this.props.activePage].children.map(item => {
        //         if (item.attrs.type === 'text') {
        //             item.offsetY(item.height());
        //         }
        //         return ""
        //     })
        //
        // }
        if (this.props.stage?.children[this.props?.activePage]?.children) {
            this.props.stage.children[this.props.activePage].children.map(item => {
                if (item.attrs.type === 'text') {

                    const { changePosition = false } = item.attrs;
                    item.offsetY(item.height());
                    if (changePosition) {
                        const name = item.getName()
                        const height = item.height();
                        const newY = item.getPosition().y + height;
                        const newX = item.getPosition().x;
                        const width = item.width();
                        const text = item.text();
                        if (!changeElementArray.includes(name)) {
                            item.setPosition({
                                x: newX,
                                y: newY,
                            })
                            changeElementArray.push(name);
                            changeElementObject.push({
                                name,
                                x: newX,
                                y: newY,
                                height,
                                width,
                                text,
                            })
                            item.changeLocalPosition = false;
                        } else {

                            const findIndex = changeElementObject.findIndex(item => item.name === name);
                            const findElement = changeElementObject[findIndex];
                            if (
                                changeElementObject.length
                                && findElement.height !== height
                                && findElement.width === width
                                && findElement.text === text
                            ) {
                                item.setPosition({
                                    x: changeElementObject[findIndex].x,
                                    y: changeElementObject[findIndex].y + height,
                                })
                                changeElementObject[findIndex].height = height;
                                item.changeLocalPosition = false;
                            }
                        }
                    } else {
                        item.offsetY(item.height());
                    }

                }
                return ""
            })
        }

        if (prevState.changeElementArray !== changeElementArray || prevState.changeElementObject !== changeElementObject) {
            this.setState({
                changeElementArray,
                changeElementObject
            })
        }

        if (prevState.layerData !== this.state.layerData) {
            localStorage.setItem('layer_data', this.state.layerData[1]);
        }

    }

    checkFontFaceObserver(font) {
        new FontFaceObserver(font).load(null, 15000).then(() => {
            this.checkTextsPosition();
        }).catch(() => {
            console.log(`Font ${font} failed to load in time.`);
        });
    }

    sendNewQuickPreview(addToCart) {
		console.log('sendNewQuickPreview');
        if (this.props.stage) {
            /* Generate image from clone of stage */
            let stageCopy = this.props.stage.clone();

            /* Remove Transformer before print */
            stageCopy.removeName('Transformer');

            /* Remove also elements which are configure to be remove */
            let stageCopyChildren = stageCopy.getChildren();
            let childrens = stageCopyChildren[0].children;
            let needToDelete = [];
            childrens.forEach(function (child) {
                if (child.attrs.user_removeFromPreview !== undefined && child.attrs?.user_removeFromPreview) {
                    needToDelete.push(child);
                }
            });
            needToDelete.forEach((child) => {
                // child.remove();
                child.destroy();

            });

            /* Get print image size */
            let templateInfo = this.props.pages[this.props.activePage];

            /* Calculate image ratios */
            let templateWidth = parseFloat(templateInfo.width);
            let templateHeight = parseFloat(templateInfo.height);

            let stageWidth = parseFloat(stageCopy.attrs.width);
            let pixelRatio = parseFloat(templateWidth / stageWidth);
            let previewRatio = parseFloat(PREVIEW_SIZE / stageWidth);

            /* Create images */
            let previewImage = stageCopy.toDataURL({ pixelRatio: previewRatio });
            /* Generate preview for template renders */
            for (let i = 0; i < templateInfo.renderers.length; i++) {
                /* Add to cart */
                if (addToCart) {
                    try {
                        window.onbeforeunload = null;
                        /* Generate filename from data */
                        let date = new Date();
                        let filename = this.props.sessionId + "_" + this.props.layerId + "_" + this.props.productId + "_" + date.getTime() + ".jpg";
                        let printCopy = this.props.stage.clone();
                        /* Remove transformer from final print file */
                        let needToDelete = [];

                        printCopy.getChildren()[0].children.forEach(function (child) {
                            if (child.constructor.name === 'Transformer') needToDelete.push(child);
                            if (child.attrs.user_removeFromPrint !== undefined) {
                                if (child.attrs?.user_removeFromPrint) {
                                    needToDelete.push(child);
                                }
                            }
                        });

                        // needToDelete.forEach(child => child.remove());
                        needToDelete.forEach(child => child.destroy());

                        try {
                            // if (templateWidth < 4500 || templateHeight < 4500) {
                            //     printCopy.toDataURL({
                            //         quality: 1,
                            //         pixelRatio: pixelRatio,
                            //         mimeType: "image/jpeg",
                            //         callback: (str) => {
                            //             /* Prepare files for save request */
                            //             let formData = new FormData();
                            //             let config = {headers: {'content-type': 'multipart/form-data'}};
                            //             formData.append('filename', filename);
                            //             formData.append('print_image', str.length > 15 ? str : '');
                            //             formData.append('preview_image', previewImage);
                            //             axios.post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(response => {
                            //                 if (response.data.error) {
                            //                     console.error(response.data.data);
                            //                 } else {
                            //                     /* Send data back to Magento */
                            //                     this.props.onSaveLayer({
                            //                         stage: this.props.stage,
                            //                         magentoUrl: this.props.magentoUrl,
                            //                         statusText: 'Saving...',
                            //                         mode: this.props.mode,
                            //                         sessionId: this.props.sessionId,
                            //                         productId: this.props.productId,
                            //                         layerId: this.props.layerId,
                            //                         printFile: filename,
                            //                         previewFile: filename,
                            //                     });
                            //                 }
                            //                 window.parent.postMessage({eventType: "HIDE_LOADING"}, "*");
                            //             });
                            //         }
                            //     });
                            // } else {
                            let formData = new FormData();
                            let config = { headers: { 'content-type': 'multipart/form-data' } };
                            formData.append('filename', filename);
                            formData.append('print_image', '');
                            formData.append('preview_image', previewImage);
                            axios.post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(response => {
                                if (response.data.error) {
                                    console.error(response.data.data);
                                } else {
                                    /* Send data back to Magento */

                                    if (this.props.stage) {
                                        let needToRemove = []

                                        this.props.stage.children[0].children.forEach(item => {
                                            if (item.attrs.type === 'text') {
                                                if (item.attrs.text === " ") {
                                                    needToRemove.push(item);
                                                }
                                            }
                                        });

                                        needToRemove.forEach((item) => {
                                            this.props.deleteSelectedElement({
                                                element: item.attrs.name,
                                                activePage: this.props.activePage,
                                            });
                                            item.destroy();
                                        });

                                        setTimeout(() => {
                                            // console.log("this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1", this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1)
                                            this.props.onSaveLayer({
                                                newTemplateRatio: {
                                                    widthRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1,
                                                    heightRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio ?? 1
                                                },
                                                stage: this.props.stage,
                                                magentoUrl: this.props.magentoUrl,
                                                statusText: 'Saving...',
                                                mode: this.props.mode,
                                                sessionId: this.props.sessionId,
                                                productId: this.props.productId,
                                                layerId: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                                                printFile: filename,
                                                previewFile: filename,
                                            });
                                        }, 0)
                                    }

                                }
                                window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                            });
                            // }
                        } catch (err) {
                            /* Prepare files for save request */
                            let formData = new FormData();
                            let config = { headers: { 'content-type': 'multipart/form-data' } };
                            formData.append('filename', filename);
                            formData.append('print_image', '');
                            formData.append('preview_image', previewImage);
                            axios.post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(response => {
                                if (response.data.error) {
                                    console.error(response.data.data);
                                } else {
                                    /* Send data back to Magento */
                                    this.props.onSaveLayer({
                                        stage: this.props.stage,
                                        magentoUrl: this.props.magentoUrl,
                                        statusText: 'Saving...',
                                        mode: this.props.mode,
                                        sessionId: this.props.sessionId,
                                        productId: this.props.productId,
                                        layerId: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                                        printFile: filename,
                                        previewFile: filename,
                                    });
                                }
                            }).catch((err) => {
                                console.log(err);
                                console.error('Request : "files for save" - fail')
                                window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                            });

                            window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                        }
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    /* Get render info */
                    let render = templateInfo.renderers[i];
                    let date = new Date();
                    /* Prepare form and data to send to preview generator */
                    let formData = new FormData();
                    let config = { headers: { 'content-type': 'multipart/form-data' } };
                    formData.append('back', render.back);
                    formData.append('mask', render.mask);
                    formData.append('p1', render.p1);
                    formData.append('p2', render.p2);
                    formData.append('p3', render.p3);
                    formData.append('p4', render.p4);
                    formData.append('c', render.c);
                    formData.append('opacity', render.opacity);
                    formData.append('res', "900x900");
                    formData.append('image', previewImage);
                    axios.post(this.props.previewUrl + "/api/preview/index.php?t=" + date.getTime(), formData, config).then(response => {
                        if (response.data.error) {
                            console.error(response.data.data);
                        } else {
                            let previewImage = response.data.data;
                            window.parent.postMessage({
                                eventType: "NEW_PREVIEW",
                                previewData: JSON.stringify({
                                    previewImage: previewImage
                                })
                            }, '*');
                            window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                        }
                    }).catch((err) => {
                        console.log(err);
                        console.error('Preview request fail');
                        window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                    });
                }
            }
        } else {
            TranslateForAlert("There was error during getting files!")
            window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
        }
    }

    receiveQuickChanges(event) {
        if (event.data.eventType === "TEXT_CHANGE") {
            event.data.texts.forEach((item) => {
                this.props.onEditElement({
                    newText: item.text.length > 0 ? item.text : " ",
                    element: item.id,
                    color: item.fill,
                    type: item.type,
                    activePage: this.props.activePage,
                });
                this.checkTextsPosition();
            });
            // this.sendNewQuickPreview(0);
        } else if (event.data.eventType === "UPDATE_TEXTS") {

            event.data.texts.forEach((item) => {
                if (item.text && item.text?.length > 0) {

                    this.props.onEditElement({
                        newText: item.text?.length > 0 ? item.text : " ",
                        element: item.id,
                        color: item.fill,
                        type: item.type,
                        activePage: this.props.activePage,
                    });
                    this.checkTextsPosition(true);
                }
            });
            window.parent.postMessage({ eventType: "OPEN_DESIGNER" }, "*");

        } else if (event.data.eventType === "TEXT_CHANGE_ADD_TO_CART") {

            event.data.texts.forEach((item) => {
                if (item.text && item.text?.length > 0) {

                    this.props.onEditElement({
                        newText: item.text?.length > 0 ? item.text : " ",
                        element: item.id,
                        color: item.fill,
                        type: item.type,
                        activePage: this.props.activePage,
                    });
                    this.checkTextsPosition();
                }
            });
            this.sendNewQuickPreview(1);
        } else if (event.data.eventType === "IMAGE_DREW") {
            if (this.props.hasTemplateChanges) {
                this.sendNewQuickPreview(0);
            }
        } else if (event.data.eventType === "BACKGROUND_COLOR_CHANGE") {
            // Loop over children on canvas page
            this.props.stage.children[this.props.activePage ?? 0].children.forEach((item) => {
                // Identifify correct image
                if (event.data.backgroundColor.name && item.attrs.name === event.data.backgroundColor.name) {

                    let
                        cW = item.attrs.width,
                        cH = item.attrs.height;
                    this.props.onEditElement({
                        data: undefined,
                        bgColor: '#' + event.data.backgroundColor.color,
                        historyMode: true,
                        element: event.data.backgroundColor.name,
                        activePage: this.props.activePage,
                    });

                }
            });
            this.sendNewQuickPreview(0);
        } else if (event.data.eventType === "IMAGE_CHANGE") {


            try {
                // Loop over children on canvas page
                this.props.pages[0].canvasElements.forEach((item) => {
                    // Identifify correct image
                    if (event.data.images.name && item.name === event.data.images.name) {
                        if (item.elementRepeat || item.elementRepeatOval) {
                            axios.get(event.data.images.url).then(() => {
                                this.props.pages[0].canvasElements.forEach((item2) => {
                                    if (item2.type === 'image' && item2.faceId === item.faceId && item2.faceId !== undefined && !item.user_adminAccessOnly) {
                                        let
                                            cW = item2.width,
                                            cH = item2.height,
                                            { url, width, height } = event.data.images;

                                        this.props.onEditElement({
                                            newImgData: {
                                                imageUrl: url,
                                                width: cW,
                                                height: cH,
                                            },
                                            historyMode: false,
                                            imageCrop: cropImage(cW, cH, width, height),
                                            imageWithoutBg: "empty",
                                            element: item2.name,
                                            activePage: this.props.activePage,
                                        });
                                    }
                                });
                            }).catch(() => {
                                console.log("DESIGNER ERROR: event.data.eventType === IMAGE_CHANGE: There was error during getting files!")
                            });
                        } else {


                            // Load params
                            let
                                cW = item.attrs.width,
                                cH = item.attrs.height,
                                { url, width, height } = event.data.images;
                            // Check if changing image isn't background and if yes, remove /thumbs/ from URL
                            /*
                            if (item.attrs.name === 'Background') {
                                url = url.replace('/thumbs', '');
                            }
                            */


                            this.props.onEditElement({
                                newImgData: {
                                    imageUrl: url,
                                    width: cW,
                                    height: cH,
                                },
                                imageCrop: cropImage(cW, cH, width, height),
                                element: item.attrs.name,
                                activePage: this.props.activePage,
                            });

                        }
                    }
                });

                window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
                this.props.onEditElement({
                    hasTemplateChanges: true,
                });

            } catch (err) {
                console.log(err);
                window.parent.postMessage({ eventType: "HIDE_LOADING" }, "*");
            }
        }
    }

    resizeEvents() {
        this.resizeStage();

    }

    elIconsHandle(x, y) {
        if (x - 58 < 0 && this.props?.activeElement?.attrs.align === 'left') {
            let deletedIconX = (-x / this.stage.current.attrs.scaleX)
            if (deletedIconX >= ((this.props?.activeElement?.getWidth() / 2) ?? 0)) deletedIconX = (this.props?.activeElement?.getWidth() / 2)
            this.setState({
                deletedIconX
            })
            // console.log("deletedIconX", deletedIconX)
        } else if (x - this.props?.activeElement?.getWidth() * this.stage.current.attrs.scaleX - 58 * this.stage.current.attrs.scaleX < 0 && this.props?.activeElement?.attrs.align === 'right') {
            let deletedIconX = (-58 + (this.props?.activeElement?.getWidth() / 2 + 58 - (Math.abs(x / this.stage.current.attrs.scaleX) - (this.props?.activeElement?.attrs?.offsetX / 2 ?? 0))))
            if (deletedIconX >= (this.props?.activeElement?.attrs?.offsetX / 2 ?? 0)) deletedIconX = (this.props?.activeElement?.attrs?.offsetX / 2 ?? 0)
            this.setState({
                deletedIconX
            })
            // console.log("deletedIconX", deletedIconX)
        } else if (x - (58 * this.stage.current.attrs.scaleX) - (this.props?.activeElement?.getWidth() * this.stage.current.attrs.scaleX) / 2 < 0 && (this.props?.activeElement?.attrs.align === 'center' || this.props?.activeElement?.attrs.type !== 'text')) {
            let deletedIconX = (-x / this.stage.current.attrs.scaleX + (this.props?.activeElement?.getWidth()) / 2)

            if (deletedIconX >= ((this.props?.activeElement?.getWidth() / 2) ?? 0)) deletedIconX = (this.props?.activeElement?.getWidth() / 2)
            this.setState({
                deletedIconX
            })
            // console.log("deletedIconX", deletedIconX)
        } else if (this.state.deletedIconX !== -58) {
            this.setState({
                deletedIconX: -58
            })
            // console.log("deletedIconX", -58)
        }

        if (this.props?.activeElement?.attrs.align === 'center' || this.props?.activeElement?.attrs.type === 'image') {
            // console.log(this.props?.activeElement?.getWidth(), this.stage.current.attrs.width / this.stage.current.attrs.scaleX, x / this.stage.current.attrs.scaleX)
            if (x / this.stage.current.attrs.scaleX + ((this.props?.activeElement?.getWidth()) / 2) + 33 >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                let pencilIconX = (this.props?.activeElement?.getWidth() + (this.stage.current.attrs.width / this.stage.current.attrs.scaleX - (x / this.stage.current.attrs.scaleX + ((this.props?.activeElement?.getWidth()) / 2) + 25)))

                if (x / this.stage.current.attrs.scaleX >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                    pencilIconX = this.props?.activeElement?.getWidth() / 2 - 25
                }
                this.setState({
                    pencilIconX
                })
            } else if (this.state.pencilIconX !== (this.props?.activeElement?.getWidth() ?? 0) + 25) {
                this.setState({
                    pencilIconX: (this.props?.activeElement?.getWidth() ?? 0) + 25
                })
            }
        }

        if (this.props?.activeElement?.attrs.align === 'left') {
            if (x / this.stage.current.attrs.scaleX + ((this.props?.activeElement?.getWidth())) + 33 >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                let pencilIconX = (this.props?.activeElement?.getWidth() + (this.stage.current.attrs.width / this.stage.current.attrs.scaleX - (x / this.stage.current.attrs.scaleX + this.props?.activeElement?.getWidth() + 25)))
                if (x / this.stage.current.attrs.scaleX + this.props?.activeElement?.getWidth() / 2 >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                    pencilIconX = this.props?.activeElement?.getWidth() / 2 - 25
                }
                this.setState({
                    pencilIconX
                })
                // console.log('if condi++_')

            } else if (this.state.pencilIconX !== (this.props?.activeElement?.getWidth() ?? 0) + 25) {
                this.setState({
                    pencilIconX: (this.props?.activeElement?.getWidth() ?? 0) + 25
                })
                // console.log('else condi++_')

            }
        }

        if (this.props?.activeElement?.attrs.align === 'right') {
            if (x / this.stage.current.attrs.scaleX + 25 >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                let pencilIconX = (this.props?.activeElement?.getWidth() + (this.stage.current.attrs.width / this.stage.current.attrs.scaleX - (x / this.stage.current.attrs.scaleX + 25)))
                if (x / this.stage.current.attrs.scaleX - this.props?.activeElement?.getWidth() / 2 >= this.stage.current.attrs.width / this.stage.current.attrs.scaleX) {
                    pencilIconX = this.props?.activeElement?.getWidth() / 2 - 25
                }
                this.setState({
                    pencilIconX
                })
            } else if (this.state.pencilIconX !== (this.props?.activeElement?.getWidth() ?? 0) + 25) {
                this.setState({
                    pencilIconX: (this.props?.activeElement?.getWidth() ?? 0) + 25
                })
            }
        }
    }


    guidelinesCalculating() {
        if (this.stage.current && this.props.activeElement && this.props.canvasScale === MIN_SCALE) {
            let canvasElements = this.stage.current.children[this.props.activePage]?.children;
            let el = this.props.activeElement;
            if (el.constructor.name === 'Transformer') return null

            let
                _this = this;

            let STAGE_WIDTH = Math.round(this.stage.current.width() / _this.stage.current.scale().x);
            let STAGE_HEIGHT = Math.round(this.stage.current.height() / _this.stage.current.scale().y);

            el.dragBoundFunc(function (pos) {

                // _this.elDragLimit(pos.x, pos.y);
                // _this.elIconsHandle(pos.x, pos.y);
                let x = pos.x, y = pos.y;

                if (pos.x - (_this.props?.activeElement?.attrs.offsetX ?? 0) * _this.stage.current.attrs.scaleX <= -(_this.props?.activeElement?.getWidth() * _this.stage.current.attrs.scaleX / 2)) {
                    x = -((_this.props?.activeElement.getWidth() * _this.stage.current.attrs.scaleX / 2) - (_this.props?.activeElement?.attrs?.offsetX ?? 0) * _this.stage.current.attrs.scaleX)
                } else if (pos.x - (_this.props?.activeElement?.getWidth() * _this.stage.current.attrs.scaleX / 2) >= _this.stage.current.attrs.width) {
                    if (_this.props?.activeElement?.attrs.align === 'right') {
                        x = _this.stage.current.attrs.width + (_this.props?.activeElement?.getWidth() * _this.stage.current.attrs.scaleX / 2)
                    }
                    if (pos.x >= _this.stage.current.attrs.width) {
                        if (_this.props?.activeElement?.attrs.align === 'center') {
                            x = _this.stage.current.attrs.width
                        }
                    }
                } else if (pos.x + (_this.props?.activeElement?.getWidth() * _this.stage.current.attrs.scaleX / 2) >= _this.stage.current.attrs.width) {

                    if (_this.props?.activeElement?.attrs.align === 'left') {
                        x = _this.stage.current.attrs.width - (_this.props?.activeElement?.getWidth() * _this.stage.current.attrs.scaleX / 2)
                    }
                    if (pos.x >= _this.stage.current.attrs.width) {
                        if (_this.props?.activeElement?.attrs.align === 'center') {
                            x = _this.stage.current.attrs.width
                        }
                    }
                }


                if (_this.props?.activeElement?.attrs.type === 'text' && pos.y - (_this.props?.activeElement?.getHeight() - 14 * _this.stage.current.attrs.scaleX) * _this.stage.current.attrs.scaleX / 2 >= _this.stage.current.attrs.height) {
                    y = _this.stage.current.attrs.height + ((_this.props?.activeElement?.getHeight() - 14 * _this.stage.current.attrs.scaleX) * _this.stage.current.attrs.scaleX / 2)
                }

                if (_this.props?.activeElement?.attrs.type === 'text' && pos.y - ((_this.props?.activeElement?.getHeight() + 14 * _this.stage.current.attrs.scaleX) * _this.stage.current.attrs.scaleX / 2) <= 0) {
                    y = ((_this.props?.activeElement?.getHeight() + 14) * _this.stage.current.attrs.scaleX / 2);
                }

                if (_this.props?.activeElement?.attrs.type !== 'text' && pos.y >= _this.stage.current.attrs.height - 14 * _this.stage.current.attrs.scaleX) {
                    y = _this.stage.current.attrs.height - 14 * _this.stage.current.attrs.scaleX;
                }

                if (_this.props?.activeElement?.attrs.type !== 'text' && pos.y <= 14 * _this.stage.current.attrs.scaleX) {
                    y = 14 * _this.stage.current.attrs.scaleX;
                }

                if (pos.x >= _this.stage.current.attrs.width) {
                    if (_this.props?.activeElement?.attrs.type !== 'text') {
                        x = _this.stage.current.attrs.width
                    }
                }

                if (x !== pos.x || y !== pos.y) {
                    return { x, y }
                }


                //guidelines for stage
                let newX = Math.round(pos.x / _this.stage.current.scale().x);
                let newY = Math.round(pos.y / _this.stage.current.scale().y);
                let { offsetX = 0, offsetY = 0 } = el.attrs;

                if (el.attrs.type === "text") {
                    offsetX = Math.round((el.width() / 2))
                    offsetY = Math.round((el.height() / 2))
                    newY = Math.round(pos.y / _this.stage.current.scale().y) - offsetY;
                    newX = Math.round(pos.x / _this.stage.current.scale().x) + offsetX;
                    if (el.attrs.align === 'center') {
                        newX = Math.round(pos.x / _this.stage.current.scale().x);
                    } else if (el.attrs.align === 'right') {
                        newX = Math.round(pos.x / _this.stage.current.scale().x) - offsetX;
                    }
                } else if (el.attrs.type === "square") {
                    offsetY = Math.round(((el.height() * el.scale().y) / 2))
                    offsetX = Math.round(((el.width() * el.scale().x) / 2))
                    newY = Math.round(pos.y / _this.stage.current.scale().y) + offsetY;
                    newX = Math.round(pos.x / _this.stage.current.scale().x) + offsetX;
                } else if (el.attrs.type === "circle") {
                    offsetY = Math.round(((el.height() * el.scale().y) / 2))
                    offsetX = Math.round(((el.width() * el.scale().x) / 2))
                }

                // if image rotated
                if ((el.attrs.type === "image" || el.attrs.type === "icon") && (el.attrs.rotation === 90 || el.attrs.rotation === 270)) {
                    [offsetX, offsetY] = [offsetY, offsetX];
                } else if ((el?.attrs?.rotation === 90 || el?.attrs?.rotation === 270)) {
                    return pos;
                }

                if (el.attrs.type === "line" || el.attrs.type === "triangle") {
                    return pos;
                }

                //X
                if (newX === centerStageWidth) {
                    guidelines.push({
                        points: [centerStageWidth, 0, centerStageWidth, STAGE_HEIGHT],
                        color: "red"
                    });
                }
                //X -
                if (Math.round(newX - offsetX) === centerStageWidth) {
                    guidelines.push({
                        points: [Math.round(newX - offsetX), 0, Math.round(newX - offsetX), STAGE_HEIGHT],
                        color: "red"
                    });
                }
                //X +
                if (Math.round(newX + offsetX) === centerStageWidth) {
                    guidelines.push({
                        points: [Math.round(newX + offsetX), 0, Math.round(newX + offsetX), STAGE_HEIGHT],
                        color: "red"
                    });
                }
                // Y
                if (newY === centerStageHeight) {
                    guidelines.push({
                        points: [0, newY, STAGE_WIDTH, newY],
                        color: "red"
                    });
                }
                // Y +
                if (Math.round(newY + offsetY) === centerStageHeight) {
                    guidelines.push({
                        points: [0, Math.round(newY + offsetY), STAGE_WIDTH, Math.round(newY + offsetY)],
                        color: "red"
                    })
                }
                // Y -
                if (Math.round(newY - offsetY) === centerStageHeight) {
                    guidelines.push({
                        points: [0, Math.round(newY - offsetY), STAGE_WIDTH, Math.round(newY - offsetY)],
                        color: "red"
                    })
                }

                //guidelines (element + elements)
                canvasElements.forEach((itemToCompareWith) => {
                    //TODO: refactor
                    if (itemToCompareWith.attrs.name === el.attrs.name || itemToCompareWith.constructor.name === 'Transformer') return null
                    // if (itemToCompareWith.attrs.type === "text" || el.attrs.type === "text") return null
                    let newX2 = Math.round(itemToCompareWith.attrs.x);
                    let newY2 = Math.round(itemToCompareWith.attrs.y);

                    let offsetX2 = itemToCompareWith.attrs.offsetX ? itemToCompareWith.attrs.offsetX : 0
                    let offsetY2 = itemToCompareWith.attrs.offsetY ? itemToCompareWith.attrs.offsetY : 0

                    if (itemToCompareWith.attrs.type === "text") {
                        offsetX2 = Math.round(itemToCompareWith.width() / 2)
                        offsetY2 = Math.round(itemToCompareWith.height() / 2)
                        newX2 = Math.round(itemToCompareWith.attrs.x) + offsetX2;
                        if (itemToCompareWith.attrs.align === 'center') {
                            newX2 = Math.round(itemToCompareWith.attrs.x);
                        } else if (itemToCompareWith.attrs.align === 'right') {
                            newX2 = Math.round(itemToCompareWith.attrs.x) - offsetX;
                        }
                        newY2 = Math.round(itemToCompareWith.attrs.y) - offsetY2;
                    } else if (itemToCompareWith.attrs.type === "square") {
                        offsetY2 = Math.round(((itemToCompareWith.height() * itemToCompareWith.scale().y) / 2))
                        offsetX2 = Math.round(((itemToCompareWith.width() * itemToCompareWith.scale().x) / 2))
                        newY2 = Math.round(itemToCompareWith.attrs.y / _this.stage.current.scale().y) + offsetY2;
                        newX2 = Math.round(itemToCompareWith.attrs.x / _this.stage.current.scale().x) + offsetX2;
                    } else if (itemToCompareWith.attrs.type === "circle") {
                        offsetY2 = Math.round(((itemToCompareWith.height() * itemToCompareWith.scale().y) / 2))
                        offsetX2 = Math.round(((itemToCompareWith.width() * itemToCompareWith.scale().x) / 2))
                    }
                    let offsetX22 = offsetX2;
                    let offsetY22 = offsetY2;

                    // if image rotated
                    if ((itemToCompareWith?.attrs?.type === "image" || itemToCompareWith?.attrs?.type === "icon") && (itemToCompareWith?.attrs?.rotation === 90 || itemToCompareWith?.attrs?.rotation === 270)) {
                        [offsetX2, offsetY2] = [offsetY2, offsetX2];
                        [offsetX22, offsetY22] = [offsetY22, offsetX22];
                    } else if ((itemToCompareWith?.attrs?.rotation === 90 || itemToCompareWith?.attrs?.rotation === 270)) {
                        return pos;
                    }

                    if (itemToCompareWith.attrs.type === "line" || itemToCompareWith.attrs.type === "triangle") {
                        return pos;
                    }

                    //X
                    if (newX2 === newX) {
                        guidelines.push({
                            points: [newX2, 0, newX2, STAGE_HEIGHT],
                            color: "green"
                        })
                    }

                    //X++
                    if (Math.round(newX2 + offsetX2) === Math.round(newX + offsetX)) {
                        guidelines.push({
                            points: [Math.round(newX2 + offsetX22), 0, Math.round(newX2 + offsetX22), STAGE_HEIGHT],
                            color: "green"
                        })
                    }
                    //X+-
                    if (Math.round(newX2 + offsetX2) === Math.round(newX - offsetX)) {
                        guidelines.push({
                            points: [Math.round(newX2 + offsetX22), 0, Math.round(newX2 + offsetX22), STAGE_HEIGHT],
                            color: "green"
                        })
                    }
                    //X-+
                    if (Math.round(newX2 - offsetX2) === Math.round(newX + offsetX)) {
                        guidelines.push({
                            points: [Math.round(newX2 - offsetX22), 0, Math.round(newX2 - offsetX22), STAGE_HEIGHT],
                            color: "green"
                        })
                    }

                    //X--
                    if (Math.round(newX2 - offsetX2) === Math.round(newX - offsetX)) {
                        guidelines.push({
                            points: [Math.round(newX2 - offsetX22), 0, Math.round(newX2 - offsetX22), STAGE_HEIGHT],
                            color: "green"
                        })
                    }

                    //Y
                    if (Math.round(newY2) === Math.round(newY)) {
                        guidelines.push({
                            points: [0, newY2, STAGE_WIDTH, newY2],
                            color: "green"
                        })
                    }
                    // Y ++
                    if (Math.round(newY2 + offsetY2) === Math.round(newY + offsetY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 + offsetY22), STAGE_WIDTH, Math.round(newY2 + offsetY22)],
                            color: "green"
                        })
                    }
                    // Y +
                    if (Math.round(newY2 + offsetY2) === Math.round(newY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 + offsetY22), STAGE_WIDTH, Math.round(newY2 + offsetY22)],
                            color: "green"
                        })
                    }
                    // Y +-
                    if (Math.round(newY2 + offsetY2) === Math.round(newY - offsetY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 + offsetY22), STAGE_WIDTH, Math.round(newY2 + offsetY22)],
                            color: "green"
                        })
                    }
                    // Y -+
                    if (Math.round(newY2 - offsetY2) === Math.round(newY + offsetY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 - offsetY22), STAGE_WIDTH, Math.round(newY2 - offsetY22)],
                            color: "green"
                        })
                    }
                    // Y--
                    if (Math.round(newY2 - offsetY2) === Math.round(newY - offsetY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 - offsetY22), STAGE_WIDTH, Math.round(newY2 - offsetY22)],
                            color: "green"
                        })
                    }
                    // Y -
                    if (Math.round(newY2 - offsetY2) === Math.round(newY)) {
                        guidelines.push({
                            points: [0, Math.round(newY2 - offsetY22), STAGE_WIDTH, Math.round(newY2 - offsetY22)],
                            color: "green"
                        })
                    }
                })
                if (guidelines.length === 0 && _this.state.guidelines.length === 0) {

                } else {
                    if (_this.props.canvasScale === MIN_SCALE) {
                        _this.setState({
                            guidelines: guidelines
                        })
                    }
                }
                return pos;
            }
            );
            let centerStageWidth = Math.round((STAGE_WIDTH) / 2);
            let centerStageHeight = Math.round((STAGE_HEIGHT) / 2);
            let guidelines = [];
        }
    }


    generatePreview(force = false) {
        if (force && this.props.stage && window.innerWidth >= DESKTOP_WIDTH) {
            let stage = this.props.stage.clone();
            // stage.width(MIN_STAGE_SIZE * this.props.stage.width() / MIN_STAGE_SIZE)
            // stage.height(MIN_STAGE_SIZE * this.props.stage.width() / MIN_STAGE_SIZE)
            //
            // stage.attrs.scaleX = 1;
            // stage.attrs.scaleY = 1;
            this.props.generatePreview({
                pages: this.props.pages,
                activePage: this.props.activePage,
                stage: stage,
                previewUrl: this.props.previewUrl
            });
        }
    }

    transformEnd = () => {
        this.generatePreview(true);
    };
    dragEnd = e => {

        this.props.pages[this.props.activePage].globalWidth = e.target.attrs.x;
        this.props.onEditElement({
            // x: e.target.attrs.x / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),// OLDEST code
            x: this.props.pages[this.props.activePage].globalWidth / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
            // x: this.props.pages[this.props.activePage].globalWidth,  //this works well when editor size is normal
            y: e.target.attrs.y / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
            element: e.target.attrs.name,
            activePage: this.props.activePage,
        });

        // RS-2709 start ========================================================
        // console.log(this.props?.activeElement,"this props are")
        // let selected_X=this.props?.activeElement?.attrs.x
        // let selected_Y=this.props?.activeElement?.attrs.y
        // let offsetX=this.props?.activeElement?.attrs.offsetX
        // let offsetY=this.props?.activeElement?.attrs.offsetY
        // console.log(selected_X,'x are')
        // console.log(selected_Y,'y are')

        // setTimeout(() => {
        //     if (e.target.attrs.x <= 80 && e.target.attrs.y) {
        //         setTimeout(() => {
        //             console.log(selected_X+1.1*offsetX,"selected x 1")
        //         this.props.onEditElement({
        //             x: selected_X+1.1*offsetX,
        //             y: e.target.attrs.y / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
        //             element: e.target.attrs.name,
        //             activePage: this.props.activePage,
        //         });
        //     }, 1000);
        //     }
        //     else if (e.target.attrs.x && e.target.attrs.y <= 80) {
        //         console.log(e.target.attrs.x / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),"selected x 2")
        //         this.props.onEditElement({
        //             x: e.target.attrs.x / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
        //             y: selected_Y+0.75*offsetY,
        //             element: e.target.attrs.name,
        //             activePage: this.props.activePage,
        //         });
        //     }
        //     else if (e.target.attrs.x >= 530 && e.target.attrs.y) {
        //         console.log(selected_X-1.1*offsetX,"selected x 3")
        //         this.props.onEditElement({
        //             x:selected_X-1.1*offsetX,
        //             y: e.target.attrs.y / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
        //             element: e.target.attrs.name,
        //             activePage: this.props.activePage,
        //         });
        //     }
        //     else if (e.target.attrs.x && e.target.attrs.y >= 350) {
        //         console.log( e.target.attrs.x / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),"selected x 4")
        //         this.props.onEditElement({
        //             x: e.target.attrs.x / (this.props.STAGE_SIZE / MIN_STAGE_SIZE),
        //             y: selected_Y-0.75*offsetY,
        //             element: e.target.attrs.name,
        //             activePage: this.props.activePage,
        //         });
        //     }
        // }, 0);

        // RS-2709 end ========================================================

        let item = this.props.activeElement;
        if (item?.attrs?.type === "text") {
            setTimeout(() => {
                switch (item.attrs.align) {
                    case "right":
                        item.x(item.x() + item.width() - item.offsetX())
                        item.offsetX(item.width());
                        break;
                    case "center":
                        item.x(item.x() + ((item.width() / 2) - item.offsetX()))
                        item.offsetX(item.width() / 2);
                        break;
                    default:
                        item.offsetX(0);
                        break;
                }
            }, 50)
        }

        this.setState(() => ({
            guidelines: []
        }));

    };

    handleOnMouseUp = e => {
        if (this.state.guidelines.length > 0) {
            this.setState({
                guidelines: []
            });
        }

        const name = e.target.name();
        const element = this.props.pages[this.props.activePage].canvasElements.find(r => r.name === name);
        this.generatePreview(true);
        // Disable transformer if click on "Stage"

        if (e.target === e.target.getStage()) {
            if (this.props?.pages[this.props.activePage]?.commonEditorForAll == 0) {
                // commented for RS-2517
                // this.props.onSelectElement({elementName: ''});
                this.props.hideToolsMenu();
            }
            return;
        }
        // If click on transformer do nothing
        const clickedOnTransformer = e.target.getParent()?.className === 'Transformer';
        if (clickedOnTransformer) {
            return;
        }

        // Find clicked element by its name
        if ((this.props.mode === 'ADMIN' && element) || element?.changeable) {
            if (this.props.mode === 'ADMIN') console.log("Selected el: ", element, " (only admin can see it)");
            // console.log("Selected el: ", element, " (only admin can see itdddd)");

            if (element.type == "square" || element.type == "circle" || element.type == "triangle" || element.type == "triangle") {
                this.props.onShowShapesForm()
            }

            if (this.props?.activeElement?.attrs?.name !== name) {
                this.props.onSelectElement(name);
                this.props.setSelectedElements([name]);
                if (this.props?.pages[this.props.activePage]?.commonEditorForAll == 0) {
                    switch (element.type) {
                        case "text":
                            this.props.onShowTextForm();
                            break;
                        case "image":
                            this.props.onShowUploadForm();
                            // if ((element.isThisElemEditedByUser !== true && this.props.mode !== "ADMIN") && element.name !== 'Background2' && element.name !== 'Background') {
                            //     this.props.showImageChangePopup();
                            // }
                            break;
                        default:
                            this.props.hideToolsMenu();
                            break;
                    }
                }
            }


        } else {
            this.props.hideToolsMenu();
            this.props.onSelectElement("");
        }

        // if (name === 'Background') {
        //     this.props.onShowDesignsForm();
        //     console.log(1);
        // } else if (name === 'Background2'){
        //     this.props.onShowBackgroundsForm();
        // }

        this.cursorStyleManager();

        // if(this.props?.pages[this.props.activePage]?.commonEditorForAll == 1){
        //     if(this.props.stepname == "bgcolor" || this.props.stepname == "bgdesign"){
        //         //this.props.hideToolsMenu();
        //         this.props.onSelectElement("");
        //     }else if(this.props.stepname == 'mehrCommon' && element.type=='text'){
        //         //this.props.hideToolsMenu();
        //         this.props.onSelectElement("");
        //     }else if(this.props.stepname == 'textspopupCommon' && element.type=='image'){
        //         //this.props.hideToolsMenu();
        //         this.props.onSelectElement("");
        //     }
        // }

    };


    cursorStyleManager() {
        if (this.stage.current) {
            let stageChildren = Array.from(this.stage.current.children);
            if (stageChildren.length > 0) {
                this.stage.current.children[this.props.activePage].children.forEach((item, i) => {
                    let
                        el = this.stage.current.children[this.props.activePage].children[i],
                        showMoveStyle = el?.attrs?.user_transformable || this.props.mode === "ADMIN";

                    if (el.attrs.unselectable) {
                        el.listening(false);
                        el.on('mouseenter', () => {
                            this.stage.current.container().style.cursor = 'crosshair';
                        });
                        el.on('mouseup', () => {
                            this.stage.current.container().style.cursor = 'crosshair';
                        });

                        el.on('mouseleave', () => {
                            this.stage.current.container().style.cursor = 'crosshair';
                        });
                    }
                    el.on('mouseenter', () => {
                        if (this.props?.activeElement?.attrs?.name === el?.attrs?.name && showMoveStyle) {
                            this.stage.current.container().style.cursor = 'move';
                        } else if (showMoveStyle) {
                            this.stage.current.container().style.cursor = 'pointer';
                        } else {
                            this.stage.current.container().style.cursor = 'pointer';
                        }
                    });

                    el.on('mouseup', () => {
                        if (showMoveStyle) {
                            this.stage.current.container().style.cursor = 'move';
                        } else {
                            this.stage.current.container().style.cursor = 'crosshair';
                        }
                    });

                    el.on('mouseleave', () => {
                        this.stage.current.container().style.cursor = 'crosshair';
                    });
                });
            }
        }

    }

    checkBorderLimits(el) {

        let
            layout = this.props?.pages[this.props.activePage],
            {
                x = layout?.borderLimit?.x,
                y = layout?.borderLimit?.y,
                width = layout?.borderLimit?.width,
                height = layout?.borderLimit?.height,
            } = this.props?.isBorderLimitEditing;


        let
            elX = Math.round(el.attrs.x - ((el.attrs.offsetX * (el.attrs.scaleX)) || 0)),
            elY = Math.round(el.attrs.y - (el.attrs.offsetY * (el.attrs.scaleY)) || 0);
        let isElementFit =
            (elX >= Math.round(x)) && (elY >= Math.round(y)) &&
            (elX + el.width() * (el.attrs.scaleX ?? 1)) <= (width + Math.round(x)) &&
            (elY + el.height() * (el.attrs.scaleY ?? 1)) <= (height + Math.round(y))

        if (!isElementFit && el.attrs.name !== 'background' && el.constructor.name !== 'Transformer') {
            TranslateForAlert("You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed")
        }
    }

    resizeStage(stagSize = this.props.STAGE_SIZE,) {

        let stage = this.stage.current;
        if (stage && this.props.pages.length > 0) {
            let width = parseFloat(this.props.pages[this.props.activePage].width),
                height = parseFloat(this.props.pages[this.props.activePage].height);

            let scale = { x: 1, y: 1 };

            if (window.innerWidth < MIN_STAGE_SIZE) {
                stagSize = window.innerWidth >= 320 ? window.innerWidth : 320
                scale = { x: stagSize / MIN_STAGE_SIZE, y: stagSize / MIN_STAGE_SIZE }
            }

            // if (window.innerHeight < 630) {
            //     stagSize = window.innerHeight - 300
            //     scale = {x: stagSize / MIN_STAGE_SIZE, y: stagSize / MIN_STAGE_SIZE}
            // }

            // console.log(stagSize, scale)

            // let mobileZoom = window.innerWidth > 768 || this.props.canvasScale ? 1 : (this.props.canvasScale - 100) / 100


            if (width > height) {
                let newHeight = stagSize / (width / height);
                stage.setAttr('width', stagSize);
                stage.setAttr('height', newHeight);

            } else if (height > width) {
                let newWidth = stagSize / (height / width);
                stage.setAttr('width', newWidth);
                stage.setAttr('height', stagSize);

            } else {
                stage.setAttr('width', stagSize);
                stage.setAttr('height', stagSize);

            }

            stage.scale({
                x: (scale.x) * this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio,
                y: (scale.y) * this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio
            });
            // console.log("this.stage.current",this.stage.current.attrs)
            stage.batchDraw();

        }

    }



    changeTextOffset(item, updateText) {

        // In this case we must find a way to rewrite position X. Now it return wrong position.x
        switch (item.attrs.align) {
            case "right":
                if (item.offsetX() !== 0 && updateText) {
                    item.x(item.x() + item.width() - item.offsetX())
                }
                item.offsetX(item.width());
                break;
            case "center":
                if (item.offsetX() !== 0 && updateText) {
                    item.x(item.x() + (item.width() / 2 - item.offsetX()))
                }
                // if (this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio !== undefined && item.x() === 296 && this.stage.current.attrs.width / this.stage.current.attrs.scaleX > 592){
                //     console.log(item.x() + ((this.stage.current.attrs.width / this.stage.current.attrs.scaleX) - 592)/2)
                //     item.x((this.stage.current.attrs.width / this.stage.current.attrs.scaleX)/2)
                // }

                item.offsetX(item.width() / 2);
                // item.x((this.stage.current.attrs.width / this.props.stage.attrs.scaleX / 2) - (item.width() / 2));
                break;
            default:
                break;
        }

    }

    checkTextsPosition(updateText) {

        if (this.props.stage) {
            this.props.stage.children[this.props.activePage].children.forEach((item) => {
                //console.log(item)
                if (item.attrs.type === 'text') {

                    if (item.attrs.textMaxWidth) item.width(item.attrs.textMaxWidth)

                    let { name, align } = item.attrs;
                    this.props.onEditElement({
                        historyMode: false,
                        align: "left",
                        element: name,
                        activePage: this.props.activePage,
                    });

                    this.props.onEditElement({
                        historyMode: false,
                        align: align,
                        element: name,
                        activePage: this.props.activePage,
                        // x: this.props.mode === 'PRODUCTION' && parseInt(params.robot) !== 1 ? (this.props.stage.width() / this.props.stage.attrs.scaleX / 2) - (item.width() / 2) : item.attrs.x,
                    });
                    this.changeTextOffset(item, updateText);
                }
            });
            if (!this.state.checkedTextsPosition) this.setState({ 'checkedTextsPosition': true })
        }
    }

    checkAllowCanvasScale() {
        return this.props.canvasScale === MIN_SCALE
    }

    changeBorderLimit(e) {
        // this.borderLimit = e;
    }

    fontLoader() {
        let
            importedCustomFontsFamilies = [],
            importedCustomFontsUrls = [],
            importedFonts = [];
        if (Array.isArray(this.props.pages[this.props.activePage].googleFonts) && this.props.pages[this.props.activePage].googleFonts.length > 0) {
            for (let font = 0; font < this.props.pages[this.props.activePage].googleFonts.length; font++) {
                let weights = this.props.pages[this.props.activePage].googleFonts[font].filter((item) => {
                    return (item.length <= 3);
                });
                importedFonts.push(
                    {
                        'font': this.props.pages[this.props.activePage].googleFonts[font],
                        weights: weights
                    });

                this.setState({
                    importedFonts
                })
            }
        } else if (Object.keys(this.props.pages[this.props.activePage].googleFonts).length > 0) {
            for (let font in this.props.pages[this.props.activePage].googleFonts) {

                let weights = this.props.pages[this.props.activePage].googleFonts[font].filter((item) => {
                    return (item.length <= 3);
                });
                importedFonts.push(
                    {
                        font,
                        weights: weights
                    });

                this.setState({
                    importedFonts
                })
            }
        }

        for (let customFont in this.props.pages[this.props.activePage].customFonts) {
            importedCustomFontsFamilies.push(this.props.pages[this.props.activePage].customFonts[customFont].font_family);
            importedCustomFontsUrls.push(this.props.pages[this.props.activePage].customFonts[customFont].css_file);
        }
        let font_file_objec = this.props.pages[this.props.activePage]?.isMergedTemplate?.google_fonts_files['Playfair Display']
        if (font_file_objec != undefined && font_file_objec != null) {
            for (let [key, value] of Object.entries(font_file_objec)) {
                importedCustomFontsUrls.push("https://staging.radbag.de/media/googlefonts/" + `${value}`)
            }
            //   console.log("importedCustomFontsUrls:", importedCustomFontsUrls )
        }

        if (importedCustomFontsFamilies.length > 0) {
            WebFont.load({
                custom: {
                    families: importedCustomFontsFamilies,
                    urls: importedCustomFontsUrls
                }
            });
        }
    }
    removeFromWorkspace_data() {
        if (
            this.props.mode !== 'ADMIN' &&
            params.sid !== 'ADMIN' &&
            this.props.pages[this.props.activePage]?.multipartTemplate === 1
        ) {
            if (this.props.isItemDownloading > 0) {
                const canvasWorkSpace = document.querySelector('.canvas-workSpace');
                if (canvasWorkSpace) {
                    canvasWorkSpace.style.opacity = '0'; // Set initial opacity to 0
                    setTimeout(() => {
                        canvasWorkSpace.style.opacity = '1'; // Reset opacity to 1 after 5 seconds
                    }, 1000);
                }
                return (
                    <div className={stl['canvas-loader']}>
                        <img
                            className={stl['canvas-loader__image']}
                            key="Loading image"
                            src={loadingImage}
                            alt="loading_image"
                        />
                    </div>
                );
            }
        }
    }


    remove1FromWorkspace_data() {
        if (
            this.props.mode !== 'ADMIN' &&
            params.sid !== 'ADMIN' &&
            this.props.pages[this.props.activePage]?.multipartTemplate === 1
        ) {
            if (this.props.isItemDownloading > 0) {
                const canvasWorkSpace = document.querySelector('.canvas-workSpace');
                if (canvasWorkSpace) {
                    canvasWorkSpace.style.opacity = '0'; // Set initial opacity to 0
                    setTimeout(() => {
                        canvasWorkSpace.style.opacity = '1'; // Reset opacity to 1 after 5 seconds
                    }, 1000);
                }
                return (
                    <div className={stl['canvas-loader']}>
                        <img
                            className={stl['canvas-loader__image']}
                            key="Loading image"
                            src={loadingImage}
                            alt="loading_image"
                        />
                    </div>
                );
            }
        }



    }


    handleScaling() {
        const previewWrapElement = document.querySelector(".preview-wrap");
        if (previewWrapElement) {
            const parentWidth = previewWrapElement.parentElement.clientWidth;
            const previewImage = document.querySelector(".imagess");
            const imageWidth = previewImage.naturalWidth;
            const innerHeight = document.querySelector(".preview-inner").offsetHeight;
            previewWrapElement.style.width = `${imageWidth}px`;


            if (parentWidth < imageWidth) {
                let scale = parentWidth / imageWidth;
                previewWrapElement.style.transform = `scale(${scale})`;
                previewWrapElement.style.webkitTransform = `scale(${scale})`;
                previewWrapElement.style.transformOrigin = '0 0';
                previewWrapElement.style.webkitTransformOrigin = '0 0';
                previewWrapElement.style.marginLeft = '0px';
                previewWrapElement.style.height = `${innerHeight * scale}px`;
            } else {
                previewWrapElement.style.transform = "unset";
                previewWrapElement.style.marginLeft = 'auto';
                previewWrapElement.style.height = "";
            }
        } else {
            // console.log("Element with class 'preview-wrap' not found.");
        }
        setTimeout(() => {
            let width = this?.canvas?.current?.hitCanvas?.width;
            let height = this?.canvas?.current?.hitCanvas?.height;

            const canvasContent = document.querySelector('.konvajs-content canvas');
            const konvaContent = document.querySelector('.konvajs-content');

            if (canvasContent && konvaContent) {
                canvasContent.style.setProperty(
                    'height',
                    `calc(${height}px / ${this?.props?.stage?.attrs?.scaleX})`
                );

                canvasContent.style.setProperty(
                    'width',
                    `calc(${width}px / ${this?.props?.stage?.attrs?.scaleX})`
                );

                konvaContent.style.setProperty(
                    'height',
                    `calc(${height}px / ${this?.props?.stage?.attrs?.scaleX})`
                );

                konvaContent.style.setProperty(
                    'width',
                    `calc(${width}px / ${this?.props?.stage?.attrs?.scaleX})`
                );
            }
        }, 0);








    }
    render() {




        let layerData = [];
        let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];
        let datas = 'undefined';

        if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
            datas = this.props.pages[this.props.activePage]?.layerDatas.canvasMaskElements;
        }

        if (parseInt(params.robot) === 1) {
            if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }

            }
        }
        if (parseInt(params.robot) === 0) {
            if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }

            }
        }
        if (parseInt(params.robot2) === 1) {
            if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }

            }
        }


        /* Check if there is any page to show */
        if (this.props.pages.length > 0 && this.state.checkedFontFaceLoad) {

            /* Get active page */
            let canvasElements = this.props.pages[this.props.activePage].canvasElements.map((item) => {

                let commonProperties = {
                    unselectable: item.unselectable ?? false,
                    user_foreground: item.user_foreground ?? false,
                    user_isShapeHeart: item.user_isShapeHeart ?? false,
                    keepRatio: item.keepRatio ?? true,
                    deletable: item.deletable ?? true,
                    type: item.type,
                    x: (item.x * (this.props.STAGE_SIZE / MIN_STAGE_SIZE)),
                    y: (item.y * (this.props.STAGE_SIZE / MIN_STAGE_SIZE)),
                    editable: !!(item.editable),
                    name: item.name,
                    key: item.name,
                    onDragEnd: this.dragEnd,
                    onTransformEnd: this.transformEnd,
                    scaleX: (item.scaleX ? item.scaleX * (this.props.STAGE_SIZE / MIN_STAGE_SIZE) : (this.props.STAGE_SIZE / MIN_STAGE_SIZE)),
                    scaleY: (item.scaleY ? item.scaleY * (this.props.STAGE_SIZE / MIN_STAGE_SIZE) : (this.props.STAGE_SIZE / MIN_STAGE_SIZE)),
                    admin: item.admin,
                    draggable: (this.props.mode === 'ADMIN' ? true : (item.user_transformable === undefined ? true : item.user_transformable)),
                    transformable: (this.props.mode === 'ADMIN' ? true : (item.user_transformable === undefined ? true : item.user_transformable)),
                    changeable: (this.props.mode === 'ADMIN' ? true : (item.user_changeable === undefined ? true : item.user_changeable)),
                    quickEdit: (this.props.mode === 'ADMIN' ? true : (item.user_quickEdit === undefined ? true : item.user_quickEdit)),
                    removeFromPreview: (this.props.mode === 'ADMIN' ? true : (item.user_removeFromPreview === undefined ? true : item.user_removeFromPreview)),
                    user_changeable: item.user_changeable === undefined ? true : item.user_changeable,
                    isCutoutPro: item.isCutoutPro === undefined ? true : item.isCutoutPro,
                    isCutoutProBg: item.isCutoutProBg === undefined ? true : item.isCutoutProBg,
                    user_removeFromWorkspace: item.user_removeFromWorkspace === undefined ? true : item.user_removeFromWorkspace,
                    cartoonTypeValue: item.cartoonTypeValue == '' ? 0 : item.cartoonTypeValue,
                    layer_allocation_data: item.layer_allocation_data === undefined ? true : item.layer_allocation_data,
                    user_quickEdit: item.user_quickEdit === undefined ? true : item.user_quickEdit,
                    user_removeFromPreview: item.user_removeFromPreview === undefined ? true : item.user_removeFromPreview,
                    user_removeFromPrint: item.user_removeFromPrint === undefined ? true : item.user_removeFromPrint,
                    user_adminAccessOnly: item.user_adminAccessOnly === undefined ? false : item.user_adminAccessOnly,
                    user_transformable: item.user_transformable === undefined ? true : item.user_transformable,
                    isGlobalElementUrl: item.isGlobalElementUrl === undefined ? false : item.isGlobalElementUrl,
                    quick_edit_labels: item.quick_edit_labels === undefined ? '' : item.quick_edit_labels,
                    store_view_values: item.store_view_values === undefined ? '' : item.store_view_values,
                    quick_edit_color: item.quick_edit_color === undefined ? false : item.quick_edit_color,
                    quick_edit_font: item.quick_edit_font === undefined ? '' : item.quick_edit_font,
                    quick_edit_stroke: item.quick_edit_stroke === undefined ? '' : item.quick_edit_stroke,
                    quick_edit_strokeColor: item.quick_edit_strokeColor === undefined ? '' : item.quick_edit_strokeColor,
                    rotation: item.rotation,
                    changePosition: item.changePosition || false,
                };

                try {
                    switch (commonProperties.type) {
                        case 'text':
                            let text;
                            try {
                                text = textTransformer(decodeURI(item.data || item.text), item.user_textTransform?.value) ?? " ";
                            } catch (e) {
                                text = textTransformer((item.data || item.text), item.user_textTransform?.value) ?? " "
                            }
                            if (text === 'undefined' || text === 'UNDEFINED') return null
                            // console.log('item: ', item.fill)
                            if (this.state.textdefaultcolor[1] == false) {
                                if (item.fill) {
                                    this.setState({
                                        textdefaultcolor: [item.fill, true]
                                    });
                                }
                            }
                            let defaultColor = "black";
                            defaultColor = this.state.textdefaultcolor[0]; //"yellow";
                            // console.log("COLOR==>>", defaultColor)

                            return (<ErrorBoundary key={item.name}>
                                <Text
                                    {...commonProperties}
                                    text={text}   //Giving Value to Canvus
                                    user_textTransform={item.user_textTransform || { value: 'none', label: 'None' }}
                                    wrap={"char"}
                                    letterSpacing={item.letterSpacing || 0}
                                    flipX={item.flipX || false}
                                    flipY={item.flipY || false}
                                    elementId={item.elementId ?? 0}
                                    stroke={item.colorFontStroke ?? defaultColor}
                                    strokeWidth={item.fontStrokeWidth ?? 0}
                                    fill={item.fill ? item.fill : defaultColor}
                                    textLimit={item.textLimit || 0}
                                    // x={(item.x * (this.props.STAGE_SIZE / MIN_STAGE_SIZE)) }
                                    // y={(item.y * (this.props.STAGE_SIZE / MIN_STAGE_SIZE))}
                                    // height={300}
                                    // top={500}
                                    textMaxWidth={item.textMaxWidth || 0}
                                    align={item.align ?? "center"}
                                    fontSize={item.fontSize || 32}
                                    fontFamily={item.fontFamily}
                                    fontStyle={item.fontStyle || "normal"}
                                    shadowEnabled={item.shadowEnabled || false}
                                    shadowColor={item.shadowColor ?? defaultColor}
                                    shadowOffset={{ x: 4, y: 4 }}
                                    scaleX={item.scaleX && item.scaleX > 0.5 ? item.scaleX : 1}
                                    scaleY={item.scaleY && item.scaleX > 0.5 ? item.scaleY : 1}
                                />
                            </ErrorBoundary>)

                        case 'line':
                            return (<Line
                                {...commonProperties}
                                points={[5, 10, 15, 20, 25, 30, 35, 40]}
                                stroke={item.fill || "#f5a623"}
                                tension={3}
                            />);
                        case 'square':
                            return (<Rect
                                {...commonProperties}
                                width={((item.background || item.name === 'Background2') ? MIN_STAGE_SIZE : !isNaN(item?.width) ? (item?.width ?? 50) : 50)}
                                height={((item.background || item.name === 'Background2') ? MIN_STAGE_SIZE : !isNaN(item?.height) ? (item?.height ?? 50) : 50)}
                                fill={item.fill || 'blue'}
                            />);

                        case 'circle':
                            return (<Circle
                                {...commonProperties}
                                radius={50}
                                fill={item.fill || 'red'}
                            />);
                        case 'triangle':
                            return (<RegularPolygon
                                {...commonProperties}
                                sides={3}
                                radius={50}
                                fill={item.fill || 'green'}
                            />);
                        case 'image':
                            if (item.width === undefined || item.height === undefined) {
                                item.width = 100;
                                item.height = 100;
                            }
                            //console.log( item.smallBackground_image,'=======',item.data)

                            return (<URLImage
                                {...commonProperties}
                                uploadedImages={this.props.uploadedImages}
                                setImgStatusError={this.props.setImgStatusError}
                                bgColor={item.bgColor}
                                user_isShapeHeart={item.user_isShapeHeart ?? false}
                                id_template={params.id_template}
                                multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                activePage={this.props.activePage}
                                onEditElement={this.props.onEditElement}
                                naturalWidth={item.naturalWidth}
                                activeElement={this.props.activeElement}
                                pages={this.props.pages}
                                imageWithoutBg={item.imageWithoutBg}
                                smallBackground_image={item.smallBackground_image}
                                naturalHeight={item.naturalHeight}
                                background={item.background}
                                x={item.background ? 0 : (item.x === 0) ? item.width : (item.x * (this.props.STAGE_SIZE / MIN_STAGE_SIZE))}
                                width={(item.background ? this.props.STAGE_SIZE : item.width)}
                                height={(item.background ? this.props.STAGE_SIZE : item.height)}
                                widthDiff={item.widthDiff}
                                heightDiff={item.heightDiff}
                                src={parseInt(params.robot) ? item.data : (item.smallBackground_image && item.smallBackground_image.length > 0 ? item.smallBackground_image : item.data)}
                                faceCropped={item.faceCropped}
                                rotation={item.rotation}
                                cropHeight={item.cropHeight}
                                cropWidth={!isNaN(item.cropWidth) ? item.cropWidth : 0}
                                cropX={!isNaN(item.cropX) ? item.cropX : 0}
                                cropY={item.cropY}
                                newBoxX={item.newBoxX}
                                newBoxY={item.newBoxY}
                                newBoxWidth={item.newBoxWidth}
                                newBoxHeight={item.newBoxHeight}
                                elementRepeat={item.elementRepeat || false}
                                elementRepeatOval={item.elementRepeatOval || false}
                                faceId={item.faceId}
                                setItemDownloadingStatus={this.props.setItemDownloadingStatus}
                                isItemDownloading={this.props.isItemDownloading}
                            />);
                        case 'icon':
                            if (item.width === undefined || item.height === undefined) {
                                item.width = 100;
                                item.height = 100;
                            }
                            return (<URLImage
                                {...commonProperties}
                                setImgStatusError={this.props.setImgStatusError}
                                activeElement={this.props.activeElement}
                                background={item.background}
                                x={item.background ? 0 : (item.x === 0) ? item.width : item.x * (this.props.STAGE_SIZE / MIN_STAGE_SIZE)}
                                width={(item.background ? this.props.STAGE_SIZE : item.width)}
                                height={(item.background ? this.props.STAGE_SIZE : item.height)}
                                src={item.data}
                                rotation={item.rotation}
                                cropHeight={item.cropHeight}
                                cropWidth={item.cropWidth}
                                // cropX={item.cropX}
                                // cropY={item.cropY}
                                newBoxX={item.newBoxX}
                                newBoxY={item.newBoxY}

                                newBoxWidth={item.newBoxWidth}
                                setItemDownloadingStatus={this.props.setItemDownloadingStatus}
                                newBoxHeight={item.newBoxHeight}
                            />);
                        default:
                            return null;
                    }
                } catch (e) {
                    return null
                }
            });

            setTimeout(() => {
                this.cursorStyleManager();
            }, 8000)
            let filterLayer2Data = this.props.pages[this.props.activePage].canvasElements
                .filter((item) => item.layer_allocation_data === layerData[2])
                .map((item) => {
                    return item
                })
            let filterLayer1Data = this.props.pages[this.props.activePage].canvasElements
                .filter((item) => item.layer_allocation_data === layerData[1])
                .map((item) => {
                    return item
                })


            let filterLayer3Data = this.props.pages[this.props.activePage].canvasElements
                .filter((item) => item.layer_allocation_data === layerData[3])
                .map((item) => {
                    return item
                })

            const { front_image, back_image, isLayerVisible, center_image } = this.state;

            let userRemoveFeomPreview = null;
            // whitsapce remove from front end start
            if (
                this.props.stage &&
                this.props.mode !== 'ADMIN' &&
                params.sid !== 'ADMIN' &&
                this.props.pages[this.props.activePage]?.multipartTemplate === 1 && parseInt(params.robot) !== 1
            ) {
                let stageCopy = this.props.stage.clone();

                stageCopy.getChildren()[0].children.forEach(function (child) {
                    if (child.attrs.user_removeFromWorkspace && child.attrs?.user_removeFromWorkspace === true) {
                        if (!stageCopy.attrs.className || !stageCopy.attrs.className.includes('new_canvas-workSpace')) {
                            let elements = document.getElementsByClassName('canvas-workSpace');
                            for (let i = 0; i < elements.length; i++) {
                                elements[i].classList.add('new_canvas-workSpace');
                            }
                        }
                        userRemoveFeomPreview = child.attrs.user_removeFromWorkspace;
                    }
                });

                let canvasElement = this.props.pages[this.props.activePage].canvasElements;
                canvasElement.map((item) => {
                    if (item.user_removeFromWorkspace && item?.user_removeFromWorkspace === true && item?.type) {
                        switch (item.type) {
                            case 'square':
                                item.width = 0;
                                break;
                            case 'image':
                            case 'icon':
                            case 'triangle':
                            case 'circle':
                                item.width = 0;
                                break;
                            default:
                                break;
                        }
                    }
                    return item;
                });

                stageCopy.getChildren()[0].children.forEach(function (child) {
                    if (child.attrs.user_removeFromWorkspace && child.attrs?.user_removeFromWorkspace === true) {
                        if (!stageCopy.attrs.className || !stageCopy.attrs.className.includes('new_canvas-border')) {
                            let element = document.getElementsByClassName('canvas_main');
                            for (let i = 0; i < element.length; i++) {
                                element[i].classList.add('new_canvas-border');
                            }
                        }
                    }
                });


            }

            window.addEventListener('onload', this.handleScaling());
            window.onload = this.handleScaling();
            window.addEventListener('resize', this.handleScaling());

            return (
                <>
                    {this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1 && parseInt(params.robot) !== 1 ?
                        ////////////////////////////RS-3070 start//////////////////////////////////////////////////////
                        <>
                            {
                                images[1] && images[2] ?
                                    <div className="preview-wrap" style={{ maxWidth: "unset" }}>
                                        <div className="preview-inner">
                                            {front_image && (
                                                <div className="preview-front">
                                                    <img src={images[1]} alt='' className='imagess' />
                                                    <div className='canvas_main'
                                                        style={{
                                                            position: images[1] ? "absolute" : "relative",
                                                            top: images[1] ? "50%" : "0%",
                                                            left: images[1] ? "51%" : "0%",
                                                            transform: images[1] ? "translate(-50%, -50%)" : "none"
                                                        }}
                                                    >
                                                        <div className={stl.canvas} id="canvas"
                                                            style={{
                                                                height: canvasHeight[1],
                                                                width: canvasWidth[1],
                                                            }}
                                                        >
                                                            <div className={stl['canvas-wrap']} style={{

                                                                width: canvasWidth[1],
                                                                height: !isNaN(canvasHeight[1]) ? canvasHeight[1] - 5 : 0,
                                                                position: this.checkAllowCanvasScale() ? "relative" : "absolute",
                                                            }}>
                                                                {/* Background bubble image remove */}
                                                                {userRemoveFeomPreview === true ? this.removeFromWorkspace_data()
                                                                    : this.remove1FromWorkspace_data()}
                                                                <Stage
                                                                    className={'canvas-workSpace'}
                                                                    onTouchStart={this.handleOnMouseUp}
                                                                    onMouseUp={this.handleOnMouseUp}
                                                                    onExport={((this.props.exportImage) ? this.onExport(this) : null)}
                                                                    onExportJson={((this.props.exportJson) ? this.onExportJson(this) : null)}
                                                                    onSaveConfig={((this.props.saveConfig) ? this.onSaveConfig(this) : null)}
                                                                    ref={this.stage}
                                                                    style={{
                                                                        left: `${-canvasX[1]}px`,
                                                                        top: `${-canvasY[1] / 2}px`,
                                                                    }}
                                                                >
                                                                    {this.state.importedFonts.length > 0 ?
                                                                        <GoogleFontLoader fonts={this.state.importedFonts} /> : null}
                                                                    <Layer
                                                                        ref={this.canvas}
                                                                        stroke={"blue"}
                                                                        strokeWidth={2}
                                                                        visible={isLayerVisible}
                                                                    >
                                                                        {this.state.importedFonts.length > 0 ?
                                                                            canvasElements
                                                                            : null}
                                                                        {this.state.guidelines.length > 0 ? this.state.guidelines.map((line, i) => {
                                                                            return <Line
                                                                                key={i}
                                                                                points={line?.points}
                                                                                dash={[2, 2]}
                                                                                stroke={line?.color || "red"}
                                                                            />
                                                                        }) : null}

                                                                        {!this.props.isShowImageCropPopup && this.state.transformer ?
                                                                            <ComponentTransformer
                                                                                selectedElements={this.props.selectedElements}
                                                                                isShowOrderPreviewPopup={this.props.isShowOrderPreviewPopup}
                                                                                multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                                                                onShowTextEditPopup={this.props.onShowTextEditPopup}
                                                                                showImageChangePopup={this.props.showImageChangePopup}
                                                                                showFaceCropPopup={this.props.showFaceCropPopup}
                                                                                onSelectElement={this.props.onSelectElement}
                                                                                elementScaling={this.state.elementScaling}
                                                                                canvasScale={this.props.canvasScale || MIN_SCALE}
                                                                                onEditElement={this.props.onEditElement}
                                                                                mode={this.props.mode}
                                                                                activeElement={this.props.activeElement}
                                                                                setActiveElement={this.props.onSelectElement.bind(this)}
                                                                                deleteSelectedElement={this.props.deleteSelectedElement.bind(this)}
                                                                                activePage={this.props.activePage}
                                                                                selectedShapeName={this.props.selectedElement}
                                                                                stage={this.props.stage}
                                                                                deletedIconX={this.state.deletedIconX}
                                                                                pencilIconX={this.state.pencilIconX}
                                                                                hideToolsMenu={this.props.hideToolsMenu}
                                                                                robot={params.robot}
                                                                                pages={this.props.pages}
                                                                                ShowTextareaMobilePopup={this.props.ShowTextareaMobilePopup}
                                                                                params={params}
                                                                            />
                                                                            : null
                                                                        }
                                                                    </Layer>
                                                                </Stage>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                            {back_image && (
                                                <div className="preview-back">
                                                    <img src={images[2]} alt='' className='imagess' />
                                                    <div className='canvas_main'
                                                        style={{
                                                            position: images[1] ? "absolute" : "relative",
                                                            top: images[1] ? "50%" : "0%",
                                                            left: images[1] ? "51%" : "0%",
                                                            transform: images[1] ? "translate(-50%, -50%)" : "none"
                                                        }}
                                                    >
                                                        <div className={stl.canvas} id="canvas"
                                                            style={{
                                                                height: canvasHeight[2],
                                                                width: canvasWidth[2],
                                                            }}
                                                        >
                                                            <div className={stl['canvas-wrap']} style={{

                                                                width: canvasWidth[2],
                                                                height: canvasHeight[2] - 5,
                                                                position: this.checkAllowCanvasScale() ? "relative" : "absolute",
                                                            }}>
                                                                {this.removeFromWorkspace_data()}
                                                                <Stage
                                                                    className={'canvas-workSpace'}
                                                                    onTouchStart={this.handleOnMouseUp}
                                                                    onMouseUp={this.handleOnMouseUp}
                                                                    onExport={((this.props.exportImage) ? this.onExport(this) : null)}
                                                                    onExportJson={((this.props.exportJson) ? this.onExportJson(this) : null)}
                                                                    onSaveConfig={((this.props.saveConfig) ? this.onSaveConfig(this) : null)}
                                                                    ref={this.stage}
                                                                    style={{
                                                                        left: `${-canvasX[2]}px`,
                                                                        // top: `${-canvasY[2] / 2}px`,
                                                                    }}
                                                                >
                                                                    {this.state.importedFonts.length > 0 ?
                                                                        <GoogleFontLoader fonts={this.state.importedFonts} /> : null}
                                                                    <Layer
                                                                        ref={this.canvas}
                                                                        stroke={"blue"}
                                                                        strokeWidth={2}
                                                                        visible={isLayerVisible}


                                                                    >

                                                                        {canvasElements}
                                                                        {this.state.guidelines.length > 0 ? this.state.guidelines.map((line, i) => {
                                                                            return <Line
                                                                                key={i}
                                                                                points={line?.points}
                                                                                dash={[2, 2]}
                                                                                stroke={line?.color || "red"}
                                                                            />
                                                                        }) : null}

                                                                        {!this.props.isShowImageCropPopup && this.state.transformer ?
                                                                            <ComponentTransformer
                                                                                selectedElements={this.props.selectedElements}
                                                                                isShowOrderPreviewPopup={this.props.isShowOrderPreviewPopup}
                                                                                multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                                                                onShowTextEditPopup={this.props.onShowTextEditPopup}
                                                                                showImageChangePopup={this.props.showImageChangePopup}
                                                                                showFaceCropPopup={this.props.showFaceCropPopup}
                                                                                onSelectElement={this.props.onSelectElement}
                                                                                elementScaling={this.state.elementScaling}
                                                                                canvasScale={this.props.canvasScale || MIN_SCALE}
                                                                                onEditElement={this.props.onEditElement}
                                                                                mode={this.props.mode}
                                                                                activeElement={this.props.activeElement}
                                                                                setActiveElement={this.props.onSelectElement.bind(this)}
                                                                                deleteSelectedElement={this.props.deleteSelectedElement.bind(this)}
                                                                                activePage={this.props.activePage}
                                                                                selectedShapeName={this.props.selectedElement}
                                                                                stage={this.props.stage}
                                                                                deletedIconX={this.state.deletedIconX}
                                                                                pencilIconX={this.state.pencilIconX}
                                                                                hideToolsMenu={this.props.hideToolsMenu}
                                                                                robot={params.robot}
                                                                                pages={this.props.pages}
                                                                                ShowTextareaMobilePopup={this.props.ShowTextareaMobilePopup}
                                                                                params={params}
                                                                            />
                                                                            : null
                                                                        }
                                                                    </Layer>
                                                                </Stage>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                            {center_image && (
                                                <div className="preview-front">
                                                    <img src={images[1]} alt='' />
                                                    <div className='canvas_main'
                                                        style={{
                                                            position: images[1] ? "absolute" : "relative",
                                                            top: images[1] ? "50%" : "0%",
                                                            left: images[1] ? "51%" : "0%",
                                                            transform: images[1] ? "translate(-50%, -50%)" : "none"
                                                        }}
                                                    >
                                                        <div className={stl.canvas} id="canvas"
                                                            style={{
                                                                height: canvasHeight[3],
                                                                width: canvasWidth[3],
                                                            }}
                                                        >
                                                            <div className={stl['canvas-wrap']} style={{

                                                                width: canvasWidth[3],
                                                                height: canvasHeight[3] - 5,
                                                                position: this.checkAllowCanvasScale() ? "relative" : "absolute",
                                                            }}>
                                                                {this.removeFromWorkspace_data()}
                                                                <Stage
                                                                    className={'canvas-workSpace'}
                                                                    onTouchStart={this.handleOnMouseUp}
                                                                    onMouseUp={this.handleOnMouseUp}
                                                                    onExport={((this.props.exportImage) ? this.onExport(this) : null)}
                                                                    onExportJson={((this.props.exportJson) ? this.onExportJson(this) : null)}
                                                                    onSaveConfig={((this.props.saveConfig) ? this.onSaveConfig(this) : null)}
                                                                    ref={this.stage}
                                                                    style={{
                                                                        left: `${-canvasX[3]}px`,
                                                                        top: `${-canvasY[3] / 2 + 30}px`,
                                                                    }}
                                                                >
                                                                    {this.state.importedFonts.length > 0 ?
                                                                        <GoogleFontLoader fonts={this.state.importedFonts} /> : null}
                                                                    <Layer
                                                                        ref={this.canvas}
                                                                        stroke={"blue"}
                                                                        strokeWidth={2}
                                                                        visible={isLayerVisible}

                                                                    >

                                                                        {canvasElements}
                                                                        {this.state.guidelines.length > 0 ? this.state.guidelines.map((line, i) => {
                                                                            return <Line
                                                                                key={i}
                                                                                points={line?.points}
                                                                                dash={[2, 2]}
                                                                                stroke={line?.color || "red"}
                                                                            />
                                                                        }) : null}

                                                                        {!this.props.isShowImageCropPopup && this.state.transformer ?
                                                                            <ComponentTransformer
                                                                                selectedElements={this.props.selectedElements}
                                                                                isShowOrderPreviewPopup={this.props.isShowOrderPreviewPopup}
                                                                                multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                                                                onShowTextEditPopup={this.props.onShowTextEditPopup}
                                                                                showImageChangePopup={this.props.showImageChangePopup}
                                                                                showFaceCropPopup={this.props.showFaceCropPopup}
                                                                                onSelectElement={this.props.onSelectElement}
                                                                                elementScaling={this.state.elementScaling}
                                                                                canvasScale={this.props.canvasScale || MIN_SCALE}
                                                                                onEditElement={this.props.onEditElement}
                                                                                mode={this.props.mode}
                                                                                activeElement={this.props.activeElement}
                                                                                setActiveElement={this.props.onSelectElement.bind(this)}
                                                                                deleteSelectedElement={this.props.deleteSelectedElement.bind(this)}
                                                                                activePage={this.props.activePage}
                                                                                selectedShapeName={this.props.selectedElement}
                                                                                stage={this.props.stage}
                                                                                deletedIconX={this.state.deletedIconX}
                                                                                pencilIconX={this.state.pencilIconX}
                                                                                hideToolsMenu={this.props.hideToolsMenu}
                                                                                robot={params.robot}
                                                                                pages={this.props.pages}
                                                                                ShowTextareaMobilePopup={this.props.ShowTextareaMobilePopup}
                                                                                params={params}
                                                                            />
                                                                            : null
                                                                        }
                                                                    </Layer>
                                                                </Stage>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            )}
                                            <div className='preview-btn'>
                                                {images[1] && (
                                                    <span className='front-btn' id="CheckCountFront" onClick={this.FrontImage.bind(this, this.state.check_count_front, filterLayer1Data[0], layerData[1])}>
                                                        <img src={images[1]} alt='' style={{ width: "100%", height: "60px" }} />
                                                    </span>
                                                )}
                                                {images[2] && (
                                                    <span className='back-btn' id="CheckCountBack" onClick={this.BackImage.bind(this, this.state.check_count_back, filterLayer2Data[0], layerData[2])}>
                                                        <img src={images[2]} alt='' style={{ width: "100%", height: "60px" }} />
                                                    </span>
                                                )}
                                                {images[3] && (
                                                    <span className='center-btn' id="CheckCountCenter" onClick={this.CenterImage.bind(this, this.state.check_count_front, filterLayer3Data[0], layerData[3])}>
                                                        <img src={images[3]} alt='' style={{ width: "100%", height: "60px" }} />
                                                    </span>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={'designer-canvas active 222222'}>
                                        <div className={stl.canvas} id="canvas"
                                            style={{
                                                height: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1) + 5,
                                            }}
                                        >
                                            <div className={stl['canvas-wrap']} style={{

                                                width: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1),
                                                height: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1),
                                                position: this.checkAllowCanvasScale() ? "relative" : "absolute",
                                            }}>

                                                {this.props.isItemDownloading > 0 ? <div className={stl['canvas-loader']}>
                                                    <img className={stl['canvas-loader__image']} key="Loading image" src={loadingImage}
                                                        alt={"loading_image"} />
                                                </div> : null}

                                                <CanvasAdditionalLayers
                                                    setActiveMaskElement={this.props.setActiveMaskElement}
                                                    activeMaskElement={this.props.activeMaskElement}
                                                    setMasksElements={this.props.setMasksElements}
                                                    addMasksElement={this.props.addMasksElement}
                                                    setCustomMaskEditingStatus={this.props.setCustomMaskEditingStatus}
                                                    changeBorderLimit={this.changeBorderLimit}
                                                    canvasScale={this.props.canvasScale}
                                                    setBorderLimitEditingStatus={this.props.setBorderLimitEditingStatus.bind(this)}
                                                    isBorderLimitEditing={this.props.isBorderLimitEditing}
                                                    isCustomMaskEditing={this.props.isCustomMaskEditing}
                                                    activePage={this.props.activePage}
                                                    activeElement={this.props.activeElement}
                                                    showBorders={this.props.showGuideline}
                                                    width={(window.innerHeight - 281 + 40) < window.innerWidth ? (window.innerHeight - 281 + 40) : window.innerWidth}
                                                    height={(window.innerHeight - 281 + 40) < window.innerWidth ? (window.innerHeight - 281 + 40) : window.innerWidth}
                                                    showGrid={this.props.showGrid}
                                                    stageSize={this.props.STAGE_SIZE}
                                                    pages={this.props.pages}
                                                    stage={this.props.stage}
                                                    isMaskDrawMode={this.props.isMaskDrawMode}
                                                    canvasMaskElements={this.props.canvasMaskElements}
                                                />

                                                <Stage
                                                    className={'canvas-workSpace'}
                                                    onTouchStart={this.handleOnMouseUp}
                                                    onMouseUp={this.handleOnMouseUp}
                                                    onExport={((this.props.exportImage) ? this.onExport(this) : null)}
                                                    onExportJson={((this.props.exportJson) ? this.onExportJson(this) : null)}
                                                    onSaveConfig={((this.props.saveConfig) ? this.onSaveConfig(this) : null)}
                                                    ref={this.stage}>
                                                    {this.state.importedFonts.length > 0 ?
                                                        <GoogleFontLoader fonts={this.state.importedFonts} /> : null}
                                                    <Layer
                                                        ref={this.canvas}
                                                        stroke={"blue"}
                                                        strokeWidth={2}
                                                    // draggable={true}
                                                    // onDragEnd={this.dragEnd}

                                                    >
                                                        {canvasElements}
                                                        {this.state.guidelines.length > 0 ? this.state.guidelines.map((line, i) => {
                                                            return <Line
                                                                key={i}
                                                                points={line?.points}
                                                                dash={[2, 2]}
                                                                stroke={line?.color || "red"}
                                                            />
                                                        }) : null}

                                                        {!this.props.isShowImageCropPopup && this.state.transformer ?
                                                            <ComponentTransformer
                                                                selectedElements={this.props.selectedElements}
                                                                isShowOrderPreviewPopup={this.props.isShowOrderPreviewPopup}
                                                                multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                                                onShowTextEditPopup={this.props.onShowTextEditPopup}
                                                                showImageChangePopup={this.props.showImageChangePopup}
                                                                showFaceCropPopup={this.props.showFaceCropPopup}
                                                                onSelectElement={this.props.onSelectElement}
                                                                elementScaling={this.state.elementScaling}
                                                                canvasScale={this.props.canvasScale || MIN_SCALE}
                                                                onEditElement={this.props.onEditElement}
                                                                mode={this.props.mode}
                                                                activeElement={this.props.activeElement}
                                                                setActiveElement={this.props.onSelectElement.bind(this)}
                                                                deleteSelectedElement={this.props.deleteSelectedElement.bind(this)}
                                                                activePage={this.props.activePage}
                                                                selectedShapeName={this.props.selectedElement}
                                                                stage={this.props.stage}
                                                                deletedIconX={this.state.deletedIconX}
                                                                pencilIconX={this.state.pencilIconX}
                                                                hideToolsMenu={this.props.hideToolsMenu}
                                                                robot={params.robot}
                                                                pages={this.props.pages}
                                                                ShowTextareaMobilePopup={this.props.ShowTextareaMobilePopup}
                                                                params={params}
                                                            />
                                                            : null
                                                        }
                                                    </Layer>
                                                </Stage>
                                            </div>
                                        </div>
                                    </div>
                            }

                        </>
                        ////////////////////////////RS-3070 end//////////////////////////////////////////////////////
                        :
                        <>
                            <div className={'designer-canvas active 222222'}>
                                <div className={stl.canvas} id="canvas"
                                    style={{
                                        height: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1) + 5,
                                    }}
                                >
                                    <div className={stl['canvas-wrap']} style={{

                                        width: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1),
                                        height: this.props.STAGE_SIZE * (this.props.stage?.attrs?.scaleX || 1),
                                        position: this.checkAllowCanvasScale() ? "relative" : "absolute",
                                    }}>

                                        {this.props.isItemDownloading > 0 ? <div className={stl['canvas-loader']}>
                                            <img className={stl['canvas-loader__image']} key="Loading image" src={loadingImage}
                                                alt={"loading_image"} />
                                        </div> : null}

                                        <CanvasAdditionalLayers
                                            setActiveMaskElement={this.props.setActiveMaskElement}
                                            activeMaskElement={this.props.activeMaskElement}
                                            setMasksElements={this.props.setMasksElements}
                                            addMasksElement={this.props.addMasksElement}
                                            setCustomMaskEditingStatus={this.props.setCustomMaskEditingStatus}
                                            changeBorderLimit={this.changeBorderLimit}
                                            canvasScale={this.props.canvasScale}
                                            setBorderLimitEditingStatus={this.props.setBorderLimitEditingStatus.bind(this)}
                                            isBorderLimitEditing={this.props.isBorderLimitEditing}
                                            isCustomMaskEditing={this.props.isCustomMaskEditing}
                                            activePage={this.props.activePage}
                                            activeElement={this.props.activeElement}
                                            showBorders={this.props.showGuideline}
                                            width={(window.innerHeight - 281 + 40) < window.innerWidth ? (window.innerHeight - 281 + 40) : window.innerWidth}
                                            height={(window.innerHeight - 281 + 40) < window.innerWidth ? (window.innerHeight - 281 + 40) : window.innerWidth}
                                            showGrid={this.props.showGrid}
                                            stageSize={this.props.STAGE_SIZE}
                                            pages={this.props.pages}
                                            stage={this.props.stage}
                                            isMaskDrawMode={this.props.isMaskDrawMode}
                                            canvasMaskElements={this.props.canvasMaskElements}
                                        />

                                        <Stage
                                            className={'canvas-workSpace'}
                                            onTouchStart={this.handleOnMouseUp}
                                            onMouseUp={this.handleOnMouseUp}
                                            onExport={((this.props.exportImage) ? this.onExport(this) : null)}
                                            onExportJson={((this.props.exportJson) ? this.onExportJson(this) : null)}
                                            onSaveConfig={((this.props.saveConfig) ? this.onSaveConfig(this) : null)}
                                            ref={this.stage}>
                                            {this.state.importedFonts.length > 0 ?
                                                <GoogleFontLoader fonts={this.state.importedFonts} /> : null}
                                            <Layer
                                                ref={this.canvas}
                                                stroke={"blue"}
                                                strokeWidth={2}
                                            // draggable={true}
                                            // onDragEnd={this.dragEnd}

                                            >
                                                {canvasElements}
                                                {this.state.guidelines.length > 0 ? this.state.guidelines.map((line, i) => {
                                                    return <Line
                                                        key={i}
                                                        points={line?.points}
                                                        dash={[2, 2]}
                                                        stroke={line?.color || "red"}
                                                    />
                                                }) : null}

                                                {!this.props.isShowImageCropPopup && this.state.transformer ?
                                                    <ComponentTransformer
                                                        selectedElements={this.props.selectedElements}
                                                        isShowOrderPreviewPopup={this.props.isShowOrderPreviewPopup}
                                                        multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                                        onShowTextEditPopup={this.props.onShowTextEditPopup}
                                                        showImageChangePopup={this.props.showImageChangePopup}
                                                        showFaceCropPopup={this.props.showFaceCropPopup}
                                                        onSelectElement={this.props.onSelectElement}
                                                        elementScaling={this.state.elementScaling}
                                                        canvasScale={this.props.canvasScale || MIN_SCALE}
                                                        onEditElement={this.props.onEditElement}
                                                        mode={this.props.mode}
                                                        activeElement={this.props.activeElement}
                                                        setActiveElement={this.props.onSelectElement.bind(this)}
                                                        deleteSelectedElement={this.props.deleteSelectedElement.bind(this)}
                                                        activePage={this.props.activePage}
                                                        selectedShapeName={this.props.selectedElement}
                                                        stage={this.props.stage}
                                                        deletedIconX={this.state.deletedIconX}
                                                        pencilIconX={this.state.pencilIconX}
                                                        hideToolsMenu={this.props.hideToolsMenu}
                                                        robot={params.robot}
                                                        pages={this.props.pages}
                                                        ShowTextareaMobilePopup={this.props.ShowTextareaMobilePopup}
                                                        params={params}
                                                    />
                                                    : null
                                                }
                                            </Layer>
                                        </Stage>
                                    </div>
                                </div>
                            </div>
                        </>
                    }



                </>
            );
        } else {
            return (
                <div className={stl["canvas-loader"]}>
                    <img className={stl['canvas-loader__image']} key="Loading image" src={loadingImage}
                        alt={"loading_image"} />
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        isShowOrderPreviewPopup: state.tol.showOrderPreviewPopup,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        pages: state.glb.pages,
        allImagesReady: state.glb.allImagesReady,
        hasTemplateChanges: state.glb.hasTemplateChanges,
        previewsLoadingCount: state.prv.previewsLoadingCount,
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        isItemDownloading: state.glb.isItemDownloading,
        activePage: state.glb.activePage,
        previewUrl: state.glb.previewUrl,
        lastUpdate: state.glb.lastUpdate,
        designerMode: state.glb.designerMode,
        canvasElements: state.glb.canvasElements,
        selectedElement: state.glb.selectedElement,
        magentoUrl: state.glb.magentoUrl,
        designId: state.glb.designId,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
        sessionId: state.glb.sessionId,
        productId: state.glb.productId,
        uploadedImages: state.glb.uploadedImages,
        layerId: state.glb.layerId,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        mode: state.glb.mode,
        showGrid: state.glb.showGrid,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
        isMaskDrawMode: state.cnvMask.isMaskDrawMode,
        activeMaskElement: state.cnvMask.activeMaskElement,
        previews: state.prv.previews,
        showGuideline: state.glb.showGuideline,
        selectedElements: state.glb.selectedElements,
        stepname: state.glb.stepname,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        onShowTextForm: () => {
            dispatch(actionCreators.showTextForm());
        },
        onShowEmojiForm: () => {
            dispatch(actionCreators.showEmojiForm());
        },
        onShowUploadForm: () => {
            if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
            dispatch(actionCreators.showUploadForm());
        },
        onShowShapesForm: () => {
            // dispatch(actionCreators.setActiveElement(""));
            dispatch(actionCreators.showShapesForm());
        },
        onShowBackgroundsForm: () => {
            dispatch(actionCreators.showBackgroundsForm());
        },
        onShowDesignsForm: () => {
            dispatch(actionCreators.showDesignsForm());
        },
        setSelectedElements: (elements) => dispatch(actionCreators.setSelectedElements(elements)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        onShowTextEditPopup: () => dispatch(actionCreators.showTextEditPopup()),
        addMasksElement: (data) => dispatch(actionCreators.addMasksElement(data)),
        setMasksElements: (data) => dispatch(actionCreators.setMasksElements(data)),
        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        deleteSelectedElement: (data) => dispatch(actionCreators.deleteSelectedElement(data)),
        setImgStatusError: (data) => dispatch(actionCreators.setImgStatusError(data)),
        setBorderLimitEditingStatus: (data) => dispatch(actionCreators.setBorderLimitEditingStatus(data)),
        setCustomMaskEditingStatus: (data) => dispatch(actionCreators.setCustomMaskEditingStatus(data)),
        onSaveLayer: (data) => dispatch(actionCreators.saveConfiguration(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        setStageObject: (stage) => dispatch(actionCreators.setStage(stage)),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({ data: data })),
        showOrderPreviewPopup: (data) => dispatch(actionCreators.showOrderPreviewPopup({ data: data })),
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
        setActiveMaskElement: (data) => dispatch(actionCreators.setActiveMaskElement(data)),
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({ data: data })),
        ShowTextareaMobilePopup: () => dispatch(actionCreators.ShowTextareaMobilePopup()),

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
