import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";
import { FormInput } from "shards-react";

class PositionProperty extends Component {

    textLimit = React.createRef();

    handleFontSizeChange(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    textLimit: parseInt(e.target.value),
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.textLimit.current) this.textLimit.current.value = attrs.textLimit || 0;
    }

    render() {
        return (
            <div className="designer-right-panel-item designer-right-panel-item__fontStyle">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>
                        <Translate content={"Letter limit"}/>
                    </span>
                </div>
                <div className={"designer-right-panel-inputNumber"}>
                    <FormInput type="number"
                               size="sm"
                               defaultValue={(this.textLimit.current ? this.textLimit.current.value : (this.props.activeElement.attrs.textLimit || 0))}
                               innerRef={this.textLimit}
                               onChange={this.handleFontSizeChange.bind(this)}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,
        pages: state.glb.pages,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
