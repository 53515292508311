import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import Translate from "react-translate-component";
import { Button, FormInput } from "shards-react";

class QuickEditLabelsPopup extends Component {

	state = {
		update: false
    };

	updateLabels = () => {
		let update = !this.state.update;
		this.setState({update: update});
		let quickEditLabels = {};
		let inputs = document.getElementsByClassName("quick-edit-label-input");
		for(let i = 0; i < inputs.length; i++) {
			let storeId = inputs[i].name.replace('quick-edit-label-','');
			quickEditLabels[storeId] = inputs[i].value;
		}
		this.props.updateElementLabels(quickEditLabels);
	}

    saveChange() {
		let quickEditLabels = [];
		let inputs = document.getElementsByClassName("quick-edit-label-input");
		for(let i = 0; i < inputs.length; i++) {
			let inputName = inputs[i].name;
			let storeId = inputName.replace('quick-edit-label-','');
			let label = inputs[i].value;
			quickEditLabels.push({store_id: storeId, label: label});
		}
        if ((this.props?.activeElement?.attrs?.elementRepeat || this.props?.activeElement?.attrs?.elementRepeatOval) && this.props?.activeElement?.attrs?.type === "image") {
            this.props.stage.children[this.props.activePage].children.map((item) => {
                if((item.attrs.elementRepeat || item.attrs.elementRepeatOval) && item.attrs?.faceId === this.props?.activeElement?.attrs?.faceId ) {
                    this.props.onEditElement({
                        quick_edit_labels: quickEditLabels,
                        element: item.attrs.name,
                        activePage: this.props.activePage,
                    });
                }
                return null
            });
        } else {
            this.props.onEditElement({
                quick_edit_labels: quickEditLabels,
                element: this.props.selectedElement,
                activePage: this.props.activePage,
            });
        }
    }

    render() {
		// Generate fields
      let storeViewsFields = this.props.storeViews.map((item) =>
			<div key={item.id} className="quick-edit-label-field">
				<div className="quick-edit-label-field-label">
					<label>{item.store_name}:</label>
				</div>
				<div className="quick-edit-label-field-input">
     <FormInput type="text"
                size="sm"
				className="quick-edit-label-input"
                placeholder="Fill quick edit label"
                value={this.props.selectedElementLabels[item.id]}
                onChange={(event)=>this.updateLabels(event)}
                name={'quick-edit-label-' + item.id}
                key={'quick-edit-label-' + item.id}
     />
				</div>
			</div>
		);

        return (this.props.showQuickEditLabelsPopup ? (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>
                <div className="text-form-popup-content-inner">
                    <div className="text-form-popup-content-title">
                        Edit labels visible in Magento quick editor per store view
                    </div>
					{storeViewsFields}
                    <div className="text-form-popup-content-buttons">
                      <Button outline theme="secondary" onClick={() => this.props.hidePopup()}>
                        <Translate content={"Cancel"}/>
                      </Button>
                      <Button theme="secondary" onClick={this.saveChange.bind(this)}>
                        OK
                      </Button>
                    </div>
					<br /><br />
                </div>
            </div>
        ) : null)
    }
}

const mapStateToProps = state => {
    return {
        selectedElement: state.glb.selectedElement,
        selectedElementLabels: state.glb.selectedElementLabels,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
		storeViews: state.glb.storeViews,
        showQuickEditLabelsPopup: state.tol.showQuickEditLabelsPopup,
        stage: state.glb.stage,
        pages: state.glb.pages,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
			dispatch(actionCreators.hidePopup());
        },
		updateElementLabels: (data) => {
            dispatch(actionCreators.setElementLabels(data));
		},
        onUpdateElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickEditLabelsPopup);
