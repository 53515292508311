import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from "../../store/actions";
import Switch from "react-switch";
import Translate from "react-translate-component";
import "./Properties.scss"

import Rotate from "./PropertiesComponents/Rotate";
import ImageSize from "./PropertiesComponents/ImageSize";
import Position from "./PropertiesComponents/Position";
import ChangeImg from "./PropertiesComponents/ChangeImg";
import CropImg from "./PropertiesComponents/CropImg";
import FontStroke from "./PropertiesComponents/FontStroke";
import ColorFontStroke from "./PropertiesComponents/ColorFontStroke";
import ShadowTextColor from "./PropertiesComponents/ShadowTextColor";
import AlightText from "./PropertiesComponents/AlightText";
import Color from "./PropertiesComponents/Color";
import ColorQuick from "./PropertiesComponents/ColorQuick";
import FontQuick from "./PropertiesComponents/FontQuick";
import FontSize from "./PropertiesComponents/FontSize";
import FontFamily from "./PropertiesComponents/FontFamily";
import FontStyle from "./PropertiesComponents/FontStyle";
import EditText from "./PropertiesComponents/EditText";
import LetterSpacing from "./PropertiesComponents/LetterSpacing";
import FlipText from "./PropertiesComponents/FlipText";
import TextLimit from "./PropertiesComponents/TextLimit";
import TextMaxWidth from "./PropertiesComponents/TextMaxWidth";
import pencilIcon from '../../assets/images/pencil.svg';
import TextTransform from "./PropertiesComponents/TextTransform";
import FaceCrop from "./PropertiesComponents/FaceCrop";
import ChangeSize from "./PropertiesComponents/ChangeSize";
import FaceId from "./PropertiesComponents/FaceId";
import ElementId from "./PropertiesComponents/ElementId";
import DeleteBtn from "./PropertiesComponents/DeleteBtn";
import {FormInput} from "shards-react";

class PropertiesResolver extends Component {
    constructor(props) {
        super(props);
        this.COMMON_PROPERTIES = [DeleteBtn, Position, ChangeSize];
        this.SHAPES = [...this.COMMON_PROPERTIES, Color];
        this.PROPERTIES = {
            image: [...this.COMMON_PROPERTIES, ImageSize, Rotate, ChangeImg, CropImg, FaceCrop, FaceId],
            icon: [...this.COMMON_PROPERTIES, Rotate],
            text: [
                ...this.COMMON_PROPERTIES, ElementId, EditText, FontFamily, FontQuick, FontStyle, Color, ColorQuick, FontSize, AlightText, FontStroke,
                ColorFontStroke, LetterSpacing, FlipText, ShadowTextColor
            ],
            circle: [...this.SHAPES, ColorQuick],
            triangle: [...this.SHAPES, ColorQuick],
            square: [...this.SHAPES, ColorQuick],
            line: [...this.SHAPES, ColorQuick],
            background: [ChangeImg]
        }
    }

    toggleClass(property) {
        this.setState({
            [property]: !this.state[property]
        });
    }
    handleAdminAccessChange(propertyName, e) {
        this.props.onEditElement({
            [propertyName]: e,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    [propertyName]: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    [propertyName]: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        if (propertyName === 'elementRepeat' || propertyName ===  'elementRepeatOval') {
            let saveName = this.props.activeElement.attrs.name
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(saveName);
            }, 0);
        }
    }

    handleGlobalElementUrlChange(propertyName, e) {
        this.props.onEditElement({
            [propertyName]: e,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });
        if (propertyName === 'elementRepeat' ||  propertyName ===  'elementRepeatOval') {
            let saveName = this.props.activeElement.attrs.name
            this.props.onSelectElement("");
            setTimeout(() => {
                this.props.onSelectElement(saveName);
            }, 0);
        }
    }

    handlePropertyChange(propertyName, e) {console.log('data',propertyName,e);
        this.props.onEditElement({
            [propertyName]: e,
            faceId: this.props.activeElement.attrs.faceId ?? 0,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

        // Check if isCutoutPro active and try to activate isCutoutProBg then isCutoutPro deactivated and vice versa
        if(this.props.mode === "ADMIN" && propertyName === "isCutoutPro"){
            this.props.onEditElement({
                isCutoutProBg: false,
                isCutoutPro: e,
                faceId: this.props.activeElement.attrs.faceId ?? 0,
                element: this.props.activeElement.attrs.name,
                activePage: this.props.activePage
            });
        }else if(this.props.mode === "ADMIN" && propertyName === "isCutoutProBg"){
            this.props.onEditElement({
                isCutoutPro: false,
                isCutoutProBg: e,
                faceId: this.props.activeElement.attrs.faceId ?? 0,
                element: this.props.activeElement.attrs.name,
                activePage: this.props.activePage
            });
        }

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    [propertyName]: e,
                    faceId: this.props.activeElement.attrs.faceId ?? 0,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    [propertyName]: e,
                    faceId: this.props.activeElement.attrs.faceId ?? 0,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

        // TODO: remove setTimeout
        if (propertyName === 'elementRepeat' || propertyName === 'elementRepeatOval') {
            if(e){
                let saveName = this.props.activeElement.attrs.name
                this.props.onSelectElement("");
                setTimeout(() => {
                    this.props.onSelectElement(saveName);
                    this.props.showFaceCropPopup();
                }, 0);
            } else{
                let saveName = this.props.activeElement.attrs.name
                this.props.onSelectElement("");
                setTimeout(() => {
                    this.props.onSelectElement(saveName);
                }, 0);
            }
        }
    }

    handelCartoonTypeValue(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if (this.props.selectedElement.includes(item.name)) {
                this.props.onEditElement({
                    cartoonTypeValue: e.target.value,
                    element: item.name,
                    activePage: this.props.activePage,
                });
            }
        });
    }

    componentDidMount() {
        const {propertyFaceCropSizeAllowed, propertyFaceCropZoomAllowed, propertyFaceCropRotateAllowed} = this.props.pages[this.props?.activePage ?? 0];
        this.props.setUserAllowedProperties({
            'propertyFaceCropSizeAllowed': propertyFaceCropSizeAllowed ?? true,
            'propertyFaceCropZoomAllowed': propertyFaceCropZoomAllowed ?? true,
            'propertyFaceCropRotateAllowed': propertyFaceCropRotateAllowed ?? true,
        });
    }

    render() {
        let NoSelected = <div className="designer-right-panel">
            <div className="designer-right-panel-placeholder">
                <Translate
                    content={"Start creating your masterpiece by browsing our new content in the panels on the left"}/>
            </div>
        </div>;

        if (this.props.activeElement !== undefined) {
            let
                elementAttrs = this.props.activeElement.attrs,
                Properties = this.PROPERTIES[elementAttrs.type];


            if (elementAttrs.user_changeable || this.props.mode === 'ADMIN') {

                return (
                    <div
                        className={"designer-right-panel" + (elementAttrs.type === "text" ? " designer-right-panel-minWidth " : "")}>

                        {/*User properties*/}
                        {Properties.map((Item, i) =>
                            <Item toggleClass={this.toggleClass} key={i}/>
                        )}

                        {
                            ((this.props.mode === 'ADMIN' && this.props.activeElement?.attrs.type === 'text') || (this.props.propertyTextShadowAllowed) && this.props.activeElement?.attrs.type === 'text') ? 
                            <div className="designer-right-panel-item designer-right-panel-item--admin">
                                <div className={"designer-right-panel-item__titleFlex"}>
                                    <Switch className={"custom-checkbox"}
                                            onChange={this.handlePropertyChange.bind(this, "shadowEnabled")}
                                            checked={(!!(elementAttrs.shadowEnabled))}/>
                                    <Translate content={"Shadow"}/>
                                </div>
                            </div> : null
                        }

                        {/*ADMIN properties*/}
                        {((this.props.mode === 'ADMIN') ?
                            <React.Fragment>
                                {elementAttrs.type === 'text' ?
                                    <>
                                        <TextTransform/>
                                        <TextLimit/>
                                        <TextMaxWidth/>
                                        {/*<ShadowTextColor toggleClass={this.toggleClass}/>*/}
                                    </> : null
                                }

                                {(elementAttrs.type === 'image') ? 
                                    <>
                                        <div className="designer-right-panel-item designer-right-panel-item--admin cutout-pro-cartoon-type">
                                            <div className={"designer-right-panel-item__titleFlex cartoon-type-custm1"}>
                                                <Switch className={"custom-checkbox"}
                                                        onChange={this.handlePropertyChange.bind(this, "isCutoutPro")}
                                                        checked={!!elementAttrs.isCutoutPro}/>
                                                Cutout Pro Face
                                            </div>
                                            <div className={"designer-right-panel-item__titleFlex cartoon-type-custm1"}>
                                                <Switch className={"custom-checkbox"}
                                                        onChange={this.handlePropertyChange.bind(this, "isCutoutProBg")}
                                                        checked={!!elementAttrs.isCutoutProBg}/>
                                                Cutout Pro Bg
                                            </div>                                        
                                            <div className={"designer-right-panel-item__titleFlex cartoon-type-custm2"}>
                                                <span className={"text"}>Cartoon Type</span>
                                                <div className={"designer-right-panel-inputNumber"}>
                                                    <FormInput size="sm"
                                                        className="form-control cartoon-type-value"
                                                        onChange={this.handelCartoonTypeValue.bind(this)}
                                                        defaultValue={this.props.activeElement.attrs.cartoonTypeValue ?? 0}
                                                        type="text"/>
                                                </div>
                                            </div>    
                                        </div>                                 
                                    </> : null
                                }

                                {(elementAttrs.type === 'image' || elementAttrs.name === "background" || elementAttrs.name === "Background") && !elementAttrs.elementRepeatOval ?
                                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                                        <div className={"designer-right-panel-item__titleFlex"}>
                                            <Switch className={"custom-checkbox"}
                                                    onChange={this.handlePropertyChange.bind(this, "elementRepeat")}
                                                    checked={!!elementAttrs.elementRepeat}/>
                                            Face Element
                                        </div>
                                    </div> : null
                                }
                                {(elementAttrs.type === 'image' || elementAttrs.name === "background" || elementAttrs.name === "Background") && !elementAttrs.elementRepeat ?
                                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                                        <div className={"designer-right-panel-item__titleFlex"}>
                                            <Switch className={"custom-checkbox"}
                                                    onChange={this.handlePropertyChange.bind(this, "elementRepeatOval")}
                                                    checked={!!elementAttrs.elementRepeatOval}/>
                                            Round Face Element
                                        </div>
                                    </div> : null
                                }

                                {(elementAttrs.type === 'image' || elementAttrs.name === "background" || elementAttrs.name === "Background") && (!!elementAttrs.elementRepeat || !!elementAttrs.elementRepeatOval)?
                                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                                        <div className={"designer-right-panel-item__titleFlex"}>
                                            <Switch className={"custom-checkbox"}
                                                    onChange={this.handlePropertyChange.bind(this, "user_isShapeHeart")}
                                                    checked={!!elementAttrs.user_isShapeHeart}/>
                                            to Heart
                                        </div>
                                    </div> : null
                                }

                                {(elementAttrs.type === 'image') ?
                                    <div className="designer-right-panel-item designer-right-panel-item--admin">
                                        <div className={"designer-right-panel-item__titleFlex"}>
                                            <Switch className={"custom-checkbox"}
                                                    onChange={this.handleGlobalElementUrlChange.bind(this, "isGlobalElementUrl")}
                                                    checked={!!elementAttrs.isGlobalElementUrl}/>
                                            Set this element as a global url for images
                                        </div>
                                    </div> : null
                                }

                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_changeable")}
                                                checked={!!elementAttrs.user_changeable}/>
                                        Editable
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_transformable")}
                                                checked={!!elementAttrs.user_transformable}/>
                                        Transformable
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_quickEdit")}
                                                checked={elementAttrs.user_quickEdit}/>
                                        Quick edit
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_removeFromPreview")}
                                                checked={(!!(elementAttrs.user_removeFromPreview))}/>
                                        Remove from preview image
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_removeFromWorkspace")}
                                                checked={(!!(elementAttrs.user_removeFromWorkspace))}/>
                                        Remove from frontend WorkSpace
                                    </div>
                                </div>

                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_removeFromPrint")}
                                                checked={(!!(elementAttrs.user_removeFromPrint))}/>
                                        Remove from print
                                    </div>
                                </div>

                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handleAdminAccessChange.bind(this, "user_adminAccessOnly")}
                                                checked={(!!(elementAttrs.user_adminAccessOnly))}/>
                                        Admin access only
                                    </div>
                                </div>

                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "unselectable")}
                                                checked={(!!(elementAttrs.unselectable))}/>
                                        Unselectable
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "user_foreground")}
                                                checked={(!!(elementAttrs.user_foreground))}/>
                                        Always in foreground
                                    </div>
                                </div>
                                <div className="designer-right-panel-item designer-right-panel-item--admin">
                                    <div className={"designer-right-panel-item__titleFlex"}>
                                        <Switch className={"custom-checkbox"}
                                                onChange={this.handlePropertyChange.bind(this, "deletable")}
                                                checked={!!elementAttrs.deletable}/>
                                        Deletable
                                    </div>
                                </div>

                                {(elementAttrs.user_quickEdit) ?
                                    <div className="designer-right-panel-item center-align"
                                    >
                                        <div className={"designer-right-panel-item__titleFlex"}
                                             onClick={this.props.onShowQuickEditLabelsPopup}>
                                            <img src={pencilIcon} alt=""/>
                                            <span className={"text"}>
													Edit Element Labels
												</span>
                                        </div>
                                    </div>
                                    : null
                                }
                            </React.Fragment>
                            : null)}
                    </div>
                );
            } else {
                return NoSelected
            }

        } else {
            return NoSelected
        }
    }
}

const mapStateToProps = state => {
    return {
        selectedElement: state.glb.selectedElement,
        stage: state.glb.stage,
        activePage: state.glb.activePage,
        activeElement: state.glb.activeElement,
        storeViews: state.glb.storeViews,
        mode: state.glb.mode,
        pages: state.glb.pages,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        selectedElements: state.glb.selectedElements,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserAllowedProperties: (data) => {
            dispatch(actionCreators.userAllowedProperties(data));
        },
        setNewElementIndex: (data) => dispatch(actionCreators.setNewElementIndex(data)),
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onShowQuickEditLabelsPopup: () => dispatch(actionCreators.showQuickEditLabelsPopup()),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(PropertiesResolver);
