import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";

class PositionProperty extends Component {
    render() {
        if (this.props.activeElement.attrs?.elementRepeat || this.props.activeElement.attrs?.elementRepeatOval) return null
        return (
            <div
                className={clsx("designer-right-panel-item")} onClick={this.props.showImageCropPopup}>
                <div className={"designer-right-panel-item__titleFlex"}>
                    <FontAwesomeIcon icon="crop-alt"/>
                    <span className={"text"}>
                        <Translate content={"Crop Image"}/>
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showImageCropPopup: () => dispatch(actionCreators.showImageCropPopup()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
