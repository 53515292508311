import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import letterSpacingIcon from '../../../assets/images/letterSpacing.svg';
import Slider from 'react-input-slider';
import Translate from "react-translate-component";
import { FormInput } from "shards-react";

class PositionProperty extends Component {

    handleLetterSpacingChange(e) {

        this.setState({
                x: (e && e.x) || (e && e.target && e.target.value) || 0
        });


        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    letterSpacing: parseInt(((e && e.target && e.target.value) || (e && e.x)) || 0),
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });

    }

    state = {
        x: this.props.activeElement.attrs.letterSpacing || 0
    };

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.letterSpacing) this.letterSpacing.value = attrs.letterSpacing || 0;
    }

    render() {
        return (<>
                <div className="designer-right-panel-item designer-right-panel-item_letterSpacing">
                    <div className={"designer-right-panel-item__titleFlex"}>
                        <img src={letterSpacingIcon} alt=""/>
                        <span className={"text"}>
                            <Translate content={"Letter spacing"}/>
                        </span>
                    </div>
                    <div className={"designer-right-panel-inputNumber"}>
                        <div className={"designer-right-panel-item_letterSpacingRange"}>
                            <Slider
                                axis="x"
                                xmax={20}
                                x={this.state.x}
                                onChange={(x) => this.handleLetterSpacingChange(x)}
                                styles={{
                                    track: {
                                        width: 110,
                                        backgroundColor: '#A7AAA8',
                                        height: 1
                                    },
                                    active: {
                                        backgroundColor: '#222C28'
                                    },
                                    thumb: {
                                        width: 21,
                                        height: 21,
                                        border: "1px solid #222C28"
                                    }
                                }}
                            />
                        </div>
                        <FormInput type="number"
                                   size="sm"
                                   max={20}
                                   min={0}
                                   value={this.state.x}
                                   onChange={this.handleLetterSpacingChange.bind(this)}
                        />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        pages: state.glb.pages,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
