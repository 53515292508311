import * as actionTypes from '../actions/actionTypes';

const initialState = {
    canvasElements: [],
    activeCanvasElement: 'no element selected :-)',
    copiedCanvasElement: undefined
}

const canvas = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADD_CANVAS_ELEMENT: {

            let element = null;
            if (action.elementType === 'text') {

            } else if (action.elementType === 'image') {
                element = {
                    type: 'image',
                    x: 0,
                    y: 0,
                    width: action.width,
                    height: action.height,
                    background: action.background,
                    name: 'element-' + state.canvasElements.length,
                    image: action.imageUrl,
                    editable: action.editable
                }
            } else if (action.elementType === 'shape') {
                switch (action.shape) {
                    case 'line' :
                        element = {
                            type: 'line',
                            name: 'element-' + state.canvasElements.length,
                            background: false,
                            editable: true,
                            x: 50,
                            y: 50
                        };
                        break;
                    case 'circle' :
                        element = {
                            type: 'circle',
                            name: 'element-' + state.canvasElements.length,
                            background: false,
                            editable: true,
                            x: 50,
                            y: 50
                        };
                        break;
                    case 'square' :
                        element = {
                            type: 'square',
                            name: 'element-' + state.canvasElements.length,
                            background: false,
                            editable: true,
                            x: 50,
                            y: 50,
                            width: 100,
                            height: 100
                        };
                        break;
                    case 'triangle' :
                        element = {
                            type: 'triangle',
                            name: 'element-' + state.canvasElements.length,
                            background: false,
                            editable: true,
                            x: 50,
                            y: 50
                        };
                        break;
                    default :
                        break;
                }
            }
            return {
                ...state,
                canvasElements: state.canvasElements.concat(element)
            }
        }
        case actionTypes.GET_CANVAS_ELEMENT:
            return {
                ...state,
                canvasElements: state.canvasElements
            }
        case actionTypes.UPDATE_CANVAS_ELEMENT:
            return {
                ...state,
                canvasElements: action.data,
            }
        case actionTypes.SET_ACTIVE_CANVAS_ELEMENT:
            return {
                ...state,
                activeCanvasElement: action.element
            }
        case actionTypes.COPIED_CANVAS_ELEMENT: {
            return {
                ...state,
                copiedCanvasElement: action.element
            }
        }
        default:
            return {
                ...state,
                canvasElements: state.canvasElements,
                activeCanvasElement: state.activeCanvasElement
            }
    }
};

export default canvas;
