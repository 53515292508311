import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import clsx from "clsx";
import Translate from "react-translate-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBold} from "@fortawesome/free-solid-svg-icons";

import {DEFAULT_FONTS} from "../../../config";

const WEIGHTS = {
    100: "Thin",
    300: "Light",
    400: "Legular",
    500: "Medium",
    700: "Bold",
    900: "Black"
};

class PositionProperty extends Component {
    state = {
        fontTypeDropdown: false,
        fontStyle: []
    };

    componentDidMount() {
        let fonts = this.props.pages[this.props.activePage].googleFonts;
        fonts = (Object.keys(fonts).length > 0 ? fonts : DEFAULT_FONTS);
        let fontStyle = fonts[this.props.activeElement.attrs.fontFamily];

        if (fontStyle) {
            fontStyle = fontStyle.filter(item => item.length <= 3);
            fontStyle = fontStyle.map((item) => {
                return  {
                    fontNameWeight: WEIGHTS[item],
                    fontWeightValue: item
                }
            });

            this.setState({
                fontStyle
            });
        }
    }

    hanleChangeFontStyle(fontStyle) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditTextElement({
                    fontStyle: fontStyle,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    render() {
        if (this.state.fontStyle.length > 0) {
            return (
                <div
                    className={clsx("designer-right-panel-item", "designer-right-panel-item__column", "designer-right-panel-item__fontType", this.state.fontTypeDropdown && "active")}>
                    <div className={"designer-right-panel-item__titleFlex "}
                         onClick={this.props.toggleClass.bind(this, "fontTypeDropdown")}>
                        {/*<img src={textIcon} alt=""/>*/}
                        <FontAwesomeIcon icon={faBold}/>
                        <span className={"text"}>
                        <Translate content={"Font Style"}/>
                    </span>
                        <div
                            className={"selected-font"}
                            style={{ fontFamily: this.props.activeElement.attrs.fontFamily + ', sans-serif', fontWeight : this.props.activeElement.attrs.fontStyle}}
                        > {this.props.activeElement.attrs.fontStyle ? WEIGHTS[this.props.activeElement.attrs.fontStyle] : "Regular"}</div>
                    </div>
                    <div className={"dropdownContent font-family-selector"}>
                        {
                            this.state.fontStyle.map((item, i) => {
                                return (
                                    <div
                                        style={{ fontFamily: this.props.activeElement.attrs.fontFamily + ', sans-serif', fontWeight : item.fontWeightValue}}
                                        className={((this.props.activeElement.attrs.fontStyle === item.fontWeightValue) ? 'active-font' : '')}
                                        key={i} onClick={this.hanleChangeFontStyle.bind(this, item.fontWeightValue)}>
                                        {item.fontNameWeight}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            );
        } else {
            return null
        }

    }
}

const mapStateToProps = state => {
    return {
        selectedElement: state.glb.selectedElement,
        stage: state.glb.stage,
        activePage: state.glb.activePage,
        pages: state.glb.pages,
        activeElement: state.glb.activeElement,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        onEditTextElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
