import React from 'react'

const FlipXSVG = props => (
    <svg width={18} height={10} {...props}>
        <title>{'EB293EEA-094F-4A06-8638-2E5B6B9381AF'}</title>
        <g fill="none" fillRule="evenodd">
            <path
                d="M1.357 4.152L8 0v10L1.357 5.848a1 1 0 010-1.696z"
                fill="#222C28"
            />
            <path
                d="M10.25 9.549V.45l6.26 3.913a.75.75 0 010 1.272l-6.26 3.913z"
                stroke="#222C28"
                strokeWidth={0.5}
                fill="#FFF"
            />
        </g>
    </svg>
)

export default FlipXSVG