export default {
    "Revert": "Annuler",
    "Retry": "Refaire",
    "Face crop":"Détourer le visage",
    "Remove background":"Enlever l'arrière-plan",//"Enlever l'arrière plan",
    "Loading":"Ça charge...",
    "Background not recognized":"Arrière plan non reconnu",
    "Background already removed":"Arrière-plan déjà supprimé",
    "settings": "Paramètres",
    "gridlines": "Grilles",
    "layers": "Couches",
    "image": "Mes fichiers",
    "Text": "Texte",
    "shape": "Eléments",
    "emoji": "Emoji",
    "done": "Terminé",
    "view": "Texte & Images",
    "Custom Text": "Name/Caption",
    "Close": "Annuler",
    "Position": "Position",
    "Rotate": "Pivoter",
    "Change Image": "Changer photo",
    "Crop Image": "Découpez",
    "Upload Images": "Ajouter des photos",
    "Add Text": "Ajouter du texte",
    "Cancel": "Annuler",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Cliquez sur une partie du design pour la modifier",
    "Shapes": "Formes configurables",
    "Add a line": "Ajouter une ligne",
    "Add a rectangle": "Ajouter un carré",
    "Add a circle": "Ajouter un cercle",
    "Add a triangle": "Ajouter un triangle",
    "Color": "Couleur",
    "Edit Text": "Modifiez le texte",
    "Font type": "Police de caractère",
    "Font Style": "Style",
    "Size": "Taille",
    "Justification": "Alignement",
    "Letter spacing": "Espace entre les lettres",
    "Flip": "Retourner",
    "Save" : "Enregistrer",
    "Preview": "Voir le produit",
    "Editor": "Editor",
    "Uploads": "Mes fichiers",
    "Letter limit": "Nombre maximum de caractères",
    "Recently Used Colors": "Couleurs utilisées récemment",
    "Drag and drop your images here!": "Déplacez vos photos ici",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed" : "Vous avez placé un élément en dehors de la zone imprimable. Veuillez confirmer que vous comprenez que cela ne sera pas imprimé complètement ou correctement.",
    "There was error during getting files!": "Une erreur s`est produite lors du téléchargement de vos photos. Veuillez actualiser la page et réessayer.",
    "Your Image is too big to upload! The max file size is 8 MB": "Votre image est trop grande pour être téléchargée ! Votre fichier ne doit pas excéder la taille de 8 Mo",
    "Your image is too small": "Votre image est trop petite pour être imprimée en bonne qualité. Le mieux serait d'en choisir une autre.",
    "Reset changes": "Réinitialiser",
	"Stroke": "Contour",
	"Stroke color": "Couleur du contour",
	"Delete": "Supprimer",
    "BackgroundsTools": "Arrière-plans",
    "ColorsTools": "Couleurs",
    "ImagesTools" : "Images",
    "Shadow color": "Couleurs ombre",
    "Shadow": "Ombre",
    "Information before ordering": "Veuillez tout vérifier puis confirmer, car votre produit sera imprimé tel qu'il apparaît ici. Pour en savoir plus, consultez notre FAQ.",
    "Add to cart": "Ajouter au panier",
    "More": "Plus",
    "edit Image": "Modifier",
    "Add_Image": "Ajouter une photo",
    "Cut": "Découpez",
    "Change": "Télécharger une photo",
    "Edit": "Modifiez le texte",
    "Font": "Police de caractère",
    "Font Size": "Taille",
    "Letter Space": "Espacement",
    "Text Color": "Couleur",
    "New text": "Ajouter du texte",
    "Align": "Alignement",
    "Flip X": "Inverser",
    "Oops, didn't you forget something?": "Oups, Vous n’avez pas oublié quelque chose?On dirait que vous n’avez pas tout personnalisé. Veuillez revoir votre article et remplacer les images de référence (à moins que vous ne préfériez celles-ci ?).",
    "Use original image": "Garder la photo d'origine", //"Utiliser la photo d'origine",
    "Continue": "Continuer",
    "Wird freigestellt": "Nous enlevons l'arrière-plan...",//"Enlever le fond...",
    
    "texts_lang": 'Modifier le texte',
    "Design_anpassen_lang": "Modifier le design",
    "Hintergundfarbe_anpassen_lang": "Modifier l'arrière-plan",
    "Farbe_anpassen_lang": "Modifier l'arrière-plan",
    "Hintergrund_anpassen_lang": "Modifier l'arrière-plan",
    "Weiter_lang": "Continuer",
    "Text anpassen_lang":"Modifier le texte",
    "Mehr_anpassen_lang" :"Modifier les éléments",
    "Bild_hinzufüge_lang":"Ajouter und photo",
    "alert_error_message": "Oh non ! Ce format d'image n'est malheureusement pas supporté. Veuillez utiliser l'un des formats suivants : jpg, png, jpeg ou jfif. Merci !",
    "back_button": "Retour",
    "textarea_popup_text": "Pour modifier le texte, il suffit de cliquer dans le champ texte. En cliquant sur le crayon, vous pouvez formater le texte.",
    "bild_popup_text": "Utilisez ce bouton pour télécharger vos photos.",
    "Color_Btn_Text": "Couleur",
    "Shadow_Btn_Text": "Ombre",
    "Stroke_Btn_Text": "Contour",
    "upload_photos_Btn_Text": "Télécharger des photos",
    "Upload_Btn_Text": "Télécharger",
    "Upload_More_Photos_Btn_Text": "Télécharger d'autres photos",
    "Edit_Text_Btn_Text": "Modifier le texte",
    "Save_Btn_Text": "Enregistrer les modifications",
    "Change_Text_Btn_Text": "Modifier le texte",
    "Design_Btn_Text": "Design",
    "Face_cropper_loading_text": "Ça charge...",

}