export default (text, format) => {
    const capitalize = (str, lower = true) =>
        (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

    if (format === "uppercase") {
        text = text.toUpperCase();
    } else if (format === "lowercase") {
        text = text.toLowerCase();
    } else if (format === "capitalize") {
        text = capitalize(text);
    }
    return text;
}
