import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import Switch from "react-switch";

import "./../Properties.scss"

class FontQuick extends Component {

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
    }

    setProperties(attrs) {
        if (this.quick_edit_font) this.quick_edit_font.value = attrs.quick_edit_font;
    }

    handleChangeableClick(e) {
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.elementId === this.props.activeElement.attrs.elementId
                && this.props.activeElement.attrs.elementId !== 0
                && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
                this.props.onEditElement({
                    quick_edit_font: e,
                    element: item.name,
                    activePage: this.props.activePage
                });
            }
        });
    }

    render() {
        if (this.props.mode === 'ADMIN') {
            return (
                <div className="designer-right-panel-item designer-right-panel-item--admin">
                    <div className={"designer-right-panel-item__titleFlex"}>
                        <Switch className={"custom-checkbox"}
                                onChange={this.handleChangeableClick.bind(this)}
                                checked={this.props.activeElement.attrs.quick_edit_font}/>
                        Allow change font
                    </div>
                </div>
            );
        } else {
            return null
        }
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        pages: state.glb.pages,
        selectedElements: state.glb.selectedElements,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(FontQuick);
