import * as actionTypes from './actionTypes';
import { PREVIEW_SIZE } from "../../config";

const axios = require("axios");

export const showPreview = (image) => {
    return {
        type: actionTypes.ADD_PREVIEW_IMAGE,
        image: image
    }
};

export const addPreviewLoading = () => {
    return {
        type: actionTypes.ADD_PREVIEW_LOADING_COUNTER
    }
};

export const addRenderers = (renderers) => {
    return {
        type: actionTypes.ADD_RENDERERS,
        renderers: renderers
    }
}

export const disableTransformer = () => {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        element: ''
    }
}

export const generatePreview = (previewData) => {
    return dispatch => {
        /* Send request for preview generation */
        if (previewData.stage) {
            /* Generate image from clone of stage => because of remove Transformer */
            let stageCopy = previewData.stage.clone();
            stageCopy.removeName('Transformer');

            // remove items from preview
            let needToDelete = [];
            stageCopy.getChildren()[0].children.forEach(function (child) {
                if (child.attrs.user_removeFromPreview !== undefined && child.attrs?.user_removeFromPreview) {
                    needToDelete.push(child);
                }
            });
            // needToDelete.forEach(child => child.remove())
            needToDelete.forEach(child => child.destroy())

            let previewRatio = parseFloat(PREVIEW_SIZE / parseInt(stageCopy.attrs.width));
            let previewImg = stageCopy.toDataURL({pixelRatio: previewRatio});

            let activePage = previewData.pages[previewData.activePage];
            /* Read params from active page renderers */

            for (let i = 0; i < activePage.renderers.length; i++) {
                /* Read data about renderer */
                let renderer = activePage.renderers[i];
                /* Prepare form and data to send to preview generator */
                let formData = new FormData();
                let config = {headers: {'content-type': 'multipart/form-data'}};
                formData.append('back', renderer.back);
                formData.append('mask', renderer.mask);
                formData.append('p1', renderer.p1);
                formData.append('p2', renderer.p2);
                formData.append('p3', renderer.p3);
                formData.append('p4', renderer.p4);
                formData.append('c', renderer.c);
                formData.append('opacity', renderer.opacity);
                formData.append('res', "900x900");
                formData.append('image', previewImg);
                dispatch(addPreviewLoading());

                axios.post(previewData.previewUrl + "/api/preview/index.php", formData, config).then(response => {
                    if (response.data.error) {
                        /* Show error */
                        console.error(response.data.data);
                    } else {
                        dispatch(showPreview(response.data.data));
                    }
                }).catch(()=>{
                    dispatch(showPreview(''));
                });

            }
        }
    }
};

export const addPreviewImage = () => {
    return {
        type: actionTypes.ADD_PREVIEW_IMAGE
    }
};