export default function cropImage(cW, cH, width, height) {
    let
        newWidth,
        newHeight,
        newCropY = 0,
        newCropX = 0;

    if (cW > cH && height > width) {
        newWidth = width;
        newHeight = width / (cW / cH);
        newCropY = Math.abs(height - newHeight) / 2;
    } else if (cW < cH && width > height) {
        if (width / height > cH / cW) {
            newHeight = height;
            newWidth = height / (cH / cW);
            newCropX = Math.abs(newWidth - width) / 2
        } else {
            newWidth = height / (cH / cW);
            newHeight = height;
            newCropX = Math.abs(newWidth - width) / 2
        }
    } else if (cW > cH && width > height) {
        if (cW / cH > width / height) {
            newWidth = width;
            newHeight = width / (cW / cH);
            newCropY = Math.abs(height - newHeight) / 2
        } else {
            newHeight = height;
            newWidth = height * (cW / cH);
            newCropX = Math.abs(width - newWidth) / 2
        }
    } else if (cH > cW && height > width) {

        if (cH / cW > height / width) {
            newWidth = height / (cH / cW);
            newHeight = height;
            newCropX = Math.abs(width - newWidth) / 2
        } else {
            newWidth = width;
            newHeight = width * (cH / cW);
            newCropY = Math.abs(height - newHeight) / 2
        }
    } else if (cW > cH && width === height) {
        newWidth = height;
        newHeight = height / (cW / cH);
        newCropY = Math.abs(height - newHeight) / 2
    } else if (cW < cH && width === height) {
        newWidth = width / (cH / cW);
        newHeight = height;
        newCropX = Math.abs(width - newWidth) / 2
    } else if (cW === cH && width > height) {
        newWidth = height;
        newHeight = height;
        newCropX = Math.abs(height - width) / 2
    } else if (cW === cH && width < height) {
        newWidth = width;
        newHeight = width;
        newCropY = Math.abs(height - width) / 2
    } else {
        newWidth = width;
        newHeight = height;
    }

    return {
        width: newWidth,
        height: newHeight,
        x: newCropX,
        y: newCropY
    }
}
