import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import stl from "./ToolsViews.module.scss";
import * as actionCreators from '../../../store/actions/index';
import Dropzone from '../../Dropzone/Dropzone';
import Progress from '../../Progress/Progress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Translate from "react-translate-component";
import TranslateForAlert from '../../../helpes/translateForAlert'

import { DESKTOP_WIDTH, IMAGE_MAX_SIZE } from "../../../config";
import cropImage from "../../../helpes/cropImage";
// import { Button } from "shards-react";
// import new_img_icon from "../../../assets/images/Frame 208.svg";

import minus_img_icon from "../../../assets/images/icons_designer_30_cropped.png";
import plus_img_icon from "../../../assets/images/icons_designer_31_cropped.png";

import rotate_img_icon from "../../../assets/images/icons_designer_05.png";
import zoom_img_icon from "../../../assets/images/icons_designer_08.png";
import cut_img_icon from "../../../assets/images/icons_designer_07.png";
import change_img_icon from "../../../assets/images/icons_designer_01.png";
// import close_btn from "../../../assets/images/icons_designer_24.png";
import down_arrow_btn from "../../../assets/images/dropdown.svg";
import clsx from "clsx";
import queryString from "query-string";
// import { FormInput } from 'shards-react';

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import { isMobile, isTablet } from 'react-device-detect';


const IMAGE_MIN_WIDTH = 50;
const IMAGE_MIN_HEIGHT = 50;
const STAGE_SIZE = 800;

const params = queryString.parse(window.location.search);

let isfirebox = false;

if (params.magento_url !== undefined && params.magento_url.includes('firebox') && queryString.parse(window.location.search).lang === 'en') {
    isfirebox = true;
}


class CommonImgUploader1 extends Component {
    render() {
        if (isMobile) {
            return (
                <CustomScroll className="customscrollbar-1" heightRelativeToParent="540px">
                    {this.props.children}
                </CustomScroll>
            )
        } else {
            return (
                <Scrollbars style={{ height: 540 }} className="scrollbar-1">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}

class CommonImgUploader2 extends Component {
    render() {
        if (isMobile) {
            return (
                <CustomScroll className="customscrollbar-2" heightRelativeToParent="540px">
                    {this.props.children}
                </CustomScroll>
            )
        } else {
            return (
                <Scrollbars style={{ height: 540 }} className="scrollbar-2">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}

class ImgUploader extends Component {

    constructor(props) {
        super(props);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.dropzone = React.createRef();
        // this.clearInt = this.clearInt.bind(this);

        this.imagesList = React.createRef();
        this.state = {
            isFirstImgLoaded: false,
            files: [],
            filesDimensions: [],
            uploading: false,
            uploadProgress: {},
            filesList: [],
            successfullUploaded: false,
            waitingForUpload: true,
            uploadLength: 0,
            uploaderResponse: {},
            newImgUploader: false,
            elRotationOpened: false,
            elZoomOpened: false,
            interval: null,
            loading_wait: false,
        };
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.showImageUploadForm && (this.state.newImgUploader)) {

            this.setState({
                newImgUploader: false
            })
        }
    }

    ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height) {
        setTimeout(() => {
            let needRerender = false;
            this.props.pages[0].canvasElements.map((item) => {
                if (((imgAttrs?.faceId > 0 && item?.faceId > 0 && imgAttrs?.faceId > 0 && imgAttrs?.faceId === item?.faceId && item.type === 'image' && imgAttrs.name !== item.name) && imgAttrs?.elementRepeat !== true && imgAttrs?.elementRepeatOval !== true) || this.props.selectedElements.includes(item.name) && item.type === 'image' && imgAttrs.type === 'image') {
                    if (item.data !== fullImageUrl) {
                        this.props.onEditImageElement({
                            imageWithoutBg: 'empty',
                            isThisElemEditedByUser: true,
                            newImgData: {
                                imageUrl: fullImageUrl,
                                width: cW,
                                height: cH,
                            },
                            imageCrop: cropImage(cW, cH, width, height),
                            element: item.name,
                            activePage: 0,
                        });
                        // needRerender = true
                    }
                }
            });
            if (needRerender) {
                console.log("needRerender");
                this.ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height);
            }
        }, 0);
    }

    selectUploadedImage(imgUrl, index, filesDimensions) {
        let layerData = [];
        // let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];

        if (parseInt(params.robot) === 1) {
            let configuration = localStorage.getItem("configuration");
            let data = JSON.parse(configuration);

            // Check if data is an array and not empty
            if (Array.isArray(data) && data.length > 0) {
                // Extract all layer_name values
                layerData = data.map((item) => item.layer_name);
                // images = data.map((item) => item.layer_image);
                canvasHeight = data.map((item) => item.height);
                canvasWidth = data.map((item) => item.width);
                canvasX = data.map((item) => item.x);
                canvasY = data.map((item) => item.y);
            }
            // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")

        }
        if (parseInt(params.robot) === 0 || parseInt(params.robot2) === 1) {
            let data = this.props.pages[this.props.activePage]?.canvasMaskElements;
            // console.log(data,"data")
            let canvasMaskElements = [];

            if (data) {
                try {
                    canvasMaskElements = JSON.parse(data);
                } catch (error) {
                    // console.error('Error parsing JSON data:', error);
                    // You can handle the error here or take appropriate actions.
                }
            }

            // The rest of your code remains unchanged.
            let layers = canvasMaskElements.flat();
            layerData = layers.map((item) => item.layer_name);
            // images = layers.map((item) => item.layer_image);
            canvasHeight = layers.map((item) => item.height);
            canvasWidth = layers.map((item) => item.width);
            canvasX = layers.map((item) => item.x);
            canvasY = layers.map((item) => item.y);
            // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
        }



        const layerDatas = localStorage.getItem('layer_data');

        // enable loader
        this.setState({
            loading_wait: true
        });
        //&& this.props?.pages[this.props.activePage]?.commonChangeCurrentImage == 0
        if (this.props?.pages[this.props.activePage]?.commonEditorForAll === 1) {
            this.commonImguploadCrops(imgUrl, index, filesDimensions);
        } else {
            this.props.setItemDownloadingStatus({
                isItemDownloading: 1
            });

            setTimeout(() => {
                if (this.props.isShowImageChangePopup) {
                    /* Change thumb to full image URL */
                    let fullImageUrl = imgUrl.replace('/thumbs', '/resized');
                    console.log("uploaded IMAGE: ", fullImageUrl)
                    let timeStart = +new Date();

                    let imgAttrs = this.props.activeElement?.attrs;
                    axios.get(fullImageUrl).then(() => {

                        let
                            cW = imgAttrs?.width || imgAttrs?.image?.width,
                            cH = imgAttrs?.height || imgAttrs?.image?.height,
                            width = filesDimensions.width,
                            height = filesDimensions.height;

                        if (width === null || height === null || imgAttrs === undefined) {
                            TranslateForAlert("There was error during getting files!")
                        } else {

                            if (width >= IMAGE_MIN_WIDTH && height >= IMAGE_MIN_HEIGHT) {
                                if ((imgAttrs?.elementRepeat || imgAttrs?.elementRepeatOval) && imgAttrs?.type === "image") {
                                    this.props.pages[0].canvasElements.map((item) => {
                                        // (item.attrs?.elementRepeat && imgAttrs?.faceId !== undefined && imgAttrs?.faceId === item.attrs?.faceId) ||
                                        if (item.name === imgAttrs.name) {
                                            this.props.onEditImageElement({
                                                isThisElemEditedByUser: true,
                                                imageWithoutBg: 'empty',
                                                newImgData: {
                                                    imageUrl: fullImageUrl,
                                                    width: item.width,
                                                    height: item.height,
                                                },
                                                historyMode: false,
                                                element: item.name,
                                                activePage: 0,
                                            });
                                            this.props.holdUrlData({ HoldURl: Array(imgAttrs.name, fullImageUrl, item.width, item.height) });
                                        }
                                        return null
                                    });
                                    let timer = (+new Date() - timeStart) + 500

                                    setTimeout(() => {
                                        this.props.showFaceCropPopup();
                                        console.log("AMA10")
                                    }, timer);

                                    this.props.onSelectElement(imgAttrs?.name);
                                }
                                // else if (this.props.HoldURl != "" && imgAttrs?.type === "text") {
                                //     this.props.holdUrlData({ HoldURl: fullImageUrl });
                                //         // this.props.pages[0].canvasElements.map((item) => {
                                //         //     // (item.attrs?.elementRepeat && imgAttrs?.faceId !== undefined && imgAttrs?.faceId === item.attrs?.faceId) ||
                                //         //     if (item.name === imgAttrs.name) {
                                //         //         this.props.onEditImageElement({
                                //         //             isThisElemEditedByUser: true,
                                //         //             imageWithoutBg: 'empty',
                                //         //             newImgData: {
                                //         //                 imageUrl: fullImageUrl,
                                //         //                 width: item.width,
                                //         //                 height: item.height,
                                //         //             },
                                //         //             historyMode: false,
                                //         //             element: item.name,
                                //         //             activePage: 0,
                                //         //         });
                                //         //     }
                                //         //     return null
                                //         // });
                                //     let timer = (+new Date() - timeStart) + 500

                                //     setTimeout(() => {
                                //         this.props.showFaceCropPopup();
                                //     }, timer);

                                //     //this.props.onSelectElement(imgAttrs?.name);
                                // } 
                                else {
                                    this.props.onEditImageElement({
                                        imageWithoutBg: 'empty',
                                        isThisElemEditedByUser: true,
                                        newImgData: {
                                            imageUrl: fullImageUrl,
                                            width: cW,
                                            height: cH,
                                        },
                                        imageCrop: cropImage(cW, cH, width, height),
                                        element: imgAttrs.name,
                                        activePage: this.props.activePage,
                                    });

                                    // Added for RS-2547 && RS-2622
                                    setTimeout(() => {
                                        if (this.props?.activeElement?.attrs?.isCutoutPro || this.props?.activeElement?.attrs?.isCutoutProBg || (params.comic_product === 'face' && params.admin_edit === 1) || (params.comic_product === 'bg' && params.admin_edit === 1)) {
                                            // disable loader
                                            this.setState({
                                                loading_wait: false
                                            });
                                            this.props.hidePopup();
                                            this.props.ShowFaceCropperPopupNew();
                                        } else {
                                            this.props.hidePopup();
                                        }
                                    }, 500);
                                    // Added for RS-2547 && RS-2622 End

                                    this.ImgRerender(imgAttrs, fullImageUrl, cW, cH, width, height);

                                    return null
                                }
                            } else {
                                TranslateForAlert("Your image is too small");
                            }
                        }
                    });

                    // commented for RS-2547
                    // this.props.hidePopup();

                    // this.props.onSelectElement('');
                    // this.setState({
                    //     newImgUploader: false
                    // })

                    // turn off loader

                } else if (this.props.showImageUploadForm) {
                    /* Change thumb to full image URL */
                    let fullImageUrl = imgUrl;
                    fullImageUrl = fullImageUrl.replace('/thumbs', '/resized');
                    const naturalWidth = filesDimensions.width;
                    const naturalHeight = filesDimensions.height;

                    if (naturalWidth >= IMAGE_MIN_WIDTH && naturalHeight >= IMAGE_MIN_HEIGHT) {
                        let {
                            width,
                            height
                        } = this.checkImgSize(filesDimensions, STAGE_SIZE / 3);

                        this.props.addElementToPage({
                            type: 'image',
                            data: fullImageUrl,
                            imgSize: { width, height },
                            width,
                            height,
                            naturalWidth,
                            naturalHeight,
                            activePage: this.props.activePage
                        });


                        setTimeout(() => {
                            // this.props.onSelectElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)
                            let canvasElements = this.props.pages[0].canvasElements;
                            canvasElements = canvasElements.filter(i => {
                                return i;
                            });

                            let name = canvasElements[canvasElements.length - 1].name;
                            this.props.onSelectElement(name)
                            if ((this.props?.activeElement?.attrs?.isCutoutPro && this.props.mode === "ADMIN") || (this.props?.activeElement?.attrs?.isCutoutProBg && this.props.mode === "ADMIN")) {
                                // Disable Cutout pro Face and Cutout pro Bg option when adding new Image
                                this.props.onEditElement({
                                    isCutoutPro: false,
                                    isCutoutProBg: false,
                                    faceId: this.props.activeElement.attrs.faceId ?? 0,
                                    element: this.props.activeElement.attrs.name,
                                    activePage: this.props.activePage
                                });
                            }
                            if (this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate === 1) {

                                let layerX = [];
                                let layerY = [];
                                for (let i = 1; i <= 10; i++) {
                                    switch (i) {
                                        case 1:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        case 2:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        case 3:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        case 4:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        case 5:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        case 6:
                                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                                            break;
                                        default:
                                            break;
                                    }
                                }

                                // Accessing the values:
                                let layer1X = layerX[1]; let layer1Y = layerY[1];
                                let layer2X = layerX[2]; let layer2Y = layerY[2];
                                let layer3X = layerX[3]; let layer3Y = layerY[3];
                                let layer4X = layerX[4]; let layer4Y = layerY[4];
                                let layer5X = layerX[5]; let layer5Y = layerY[5];
                                let layer6X = layerX[6]; let layer6Y = layerY[6];

                                const layerDataCoordinates = {
                                    ...(layerData[1] && { [layerData[1]]: { x: layer1X, y: layer1Y } }),
                                    ...(layerData[2] && { [layerData[2]]: { x: layer2X, y: layer2Y } }),
                                    ...(layerData[3] && { [layerData[3]]: { x: layer3X, y: layer3Y } }),
                                    ...(layerData[4] && { [layerData[4]]: { x: layer4X, y: layer4Y } }),
                                    ...(layerData[5] && { [layerData[5]]: { x: layer5X, y: layer5Y } }),
                                    ...(layerData[6] && { [layerData[6]]: { x: layer6X, y: layer6Y } }),
                                };

                                if (layerDatas in layerDataCoordinates) {
                                    const { x, y } = layerDataCoordinates[layerDatas];
                                    if (this.props.activeElement) {
                                        this.props.activeElement.x(x);
                                        this.props.activeElement.y(y);
                                        if (isTablet === true) {
                                            // console.log(isTablet, "istablet")
                                            this.props.activeElement.x(x);
                                            this.props.activeElement.y(y);
                                        } else if (isMobile === true) {
                                            // console.log(isMobile, "isMobile")
                                            this.props.activeElement.x(x);
                                            this.props.activeElement.y(y);
                                        }
                                    }
                                }
                            }

                            // disable loader
                            this.setState({
                                loading_wait: false
                            });


                        }, 50)

                        // }
                        if (window.innerWidth <= DESKTOP_WIDTH) {
                            this.props.hideToolsMenu();
                            this.props.toolsMenuVisibleToggle({ mode: false });
                        }
                    } else {
                        TranslateForAlert("Your image is too small");
                    }
                }
            }, 1500);

            setTimeout(() => {
                this.props.setItemDownloadingStatus({
                    isItemDownloading: -1
                });
            }, 4500)
        }
    }

    commonImguploadCrops(imgUrl, index, filesDimensions) {
        this.props.setItemDownloadingStatus({
            isItemDownloading: 1
        });
        setTimeout(() => {
            if (this.props.isShowImageChangePopup) {
                /* Change thumb to full image URL */
                let fullImageUrl = imgUrl.replace('/thumbs', '/resized');
                let timeStart = +new Date();

                let imgAttrs = this.props.activeElement?.attrs;
                axios.get(fullImageUrl).then(() => {

                    let
                        // cW = imgAttrs?.width || imgAttrs?.image?.width,
                        // cH = imgAttrs?.height || imgAttrs?.image?.height,
                        width = filesDimensions.width,
                        height = filesDimensions.height;

                    if (width === null || height === null) {
                        //if (width === null || height === null || imgAttrs === undefined) {
                        TranslateForAlert("There was error during getting files!")
                    } else {
                        if (width >= IMAGE_MIN_WIDTH && height >= IMAGE_MIN_HEIGHT) {
                            this.props.onEditImageElement({
                                isThisElemEditedByUser: true,
                                imageWithoutBg: 'empty',
                                newImgData: {
                                    imageUrl: fullImageUrl,
                                    width: width,
                                    height: height,
                                },
                                historyMode: false,
                                //element: item.name,
                                activePage: 0,
                            });
                            let timer = (+new Date() - timeStart) + 500
                            this.props.holdUrlData({ HoldURl: Array('', fullImageUrl, width, height, index) });
                            setTimeout(() => {
                                this.props.showFaceCropPopup();
                                console.log("AMA11")
                            }, timer);

                            if (this.props?.pages[this.props.activePage]?.commonChangeCurrentImage === 1) {
                                this.props.onSelectElement(imgAttrs?.name);
                            }

                        } else {
                            TranslateForAlert("Your image is too small");
                        }
                    }
                });

                this.props.hidePopup();
                // this.props.onSelectElement('');
                // this.setState({
                //     newImgUploader: false
                // })

                // turn off loader

            } else if (this.props.showImageUploadForm) {
                /* Change thumb to full image URL */
                let fullImageUrl = imgUrl;
                fullImageUrl = fullImageUrl.replace('/thumbs', '/resized');
                const naturalWidth = filesDimensions.width;
                const naturalHeight = filesDimensions.height;

                if (naturalWidth >= IMAGE_MIN_WIDTH && naturalHeight >= IMAGE_MIN_HEIGHT) {
                    let {
                        width,
                        height
                    } = this.checkImgSize(filesDimensions, STAGE_SIZE / 3);

                    this.props.addElementToPage({
                        type: 'image',
                        data: fullImageUrl,
                        imgSize: { width, height },
                        width,
                        height,
                        naturalWidth,
                        naturalHeight,
                        activePage: this.props.activePage
                    });


                    setTimeout(() => {
                        this.props.onSelectElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)
                    }, 50)

                    // }
                    if (window.innerWidth <= DESKTOP_WIDTH) {
                        this.props.hideToolsMenu();
                        this.props.toolsMenuVisibleToggle({ mode: false });
                    }
                } else {
                    TranslateForAlert("Your image is too small");
                }
            }
        }, 1500);

        setTimeout(() => {
            this.props.setItemDownloadingStatus({
                isItemDownloading: -1
            });
        }, 4500)
    }

    checkImgSize(fileDimension, halfStageSize) {
        if (fileDimension) {
            let { width, height } = fileDimension;
            if (height > halfStageSize) {
                width = halfStageSize / (height / width);
                height = halfStageSize;
            } else if (width > halfStageSize) {
                height = halfStageSize / (width / height);
                width = halfStageSize;
            }
            return { width, height };
        }
    }

    handleElemRotation(rotateValue = 0) {
        // Updated code
        let item = this.props.activeElement;
        this.props.onEditElement({
            rotation: (item.rotation() !== undefined ? item.rotation() + rotateValue : 0),
            element: item.attrs.name,
            activePage: this.props.activePage
        });

        // this.props.stage.children[this.props.activePage].children.forEach((item) => {
        //     if ((item.attrs.faceId === this.props.activeElement.attrs.faceId
        //         && this.props.activeElement.attrs.faceId !== 0
        //         && this.props.activeElement.attrs.elementRepeatOval !== true
        //         && this.props.activeElement.attrs.elementRepeat !== true
        //         && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.attrs.name)) {                
        //     }
        // });
    }

    // handleSizeChange(val) {
    //     let {type} = this.props.activeElement.attrs;
    //     if (type === 'text') {
    //         this.interval = setInterval(() => {
    //             this.props.onEditElement({
    //                 fontSize: parseFloat(this.props.activeElement.attrs.fontSize + val),
    //                 element: this.props.activeElement.attrs.name,
    //                 activePage: this.props.activePage
    //             })

    //             let item = this.props.activeElement;
    //             setTimeout(() => {
    //                 switch (item.attrs.align) {
    //                     case "right":
    //                         item.x(item.x() + item.width() - item.offsetX())
    //                         item.offsetX(item.width());
    //                         break;
    //                     case "center":
    //                         item.x(item.x() + (item.width() / 2 - item.offsetX()))
    //                         item.offsetX(item.width() / 2);
    //                         break;
    //                     default:
    //                         item.offsetX(0);
    //                         break;
    //                 }
    //             }, 55)

    //         }, 70);

    //     } else if (type === 'icon' || type === 'image') {

    //         this.interval = setInterval(() => {
    //             if(this.props.activeElement.attrs.elementRepeatOval !== true
    //                 && this.props.activeElement.attrs.elementRepeat !== true){
    //                     this.props.onEditElement({
    //                         width: (parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) > 25 ? parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) : 25),
    //                         height: (parseFloat(this.props.activeElement.attrs.height + val) > 25 ? parseFloat(this.props.activeElement.attrs.height + val) : 25),
    //                         element: this.props.activeElement.attrs.name,
    //                         activePage: this.props.activePage,
    //                     })
    //                 }
    //             // this.props.pages[0].canvasElements.forEach((item) => {
    //             //     if ((item.faceId === this.props.activeElement.attrs.faceId
    //             //         && this.props.activeElement.attrs.faceId !== 0
    //             //         && this.props.activeElement.attrs.elementRepeatOval !== true
    //             //         && this.props.activeElement.attrs.elementRepeat !== true
    //             //         && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {                        
    //             //     }
    //             // });
    //         }, 50);
    //     } else {
    //         this.interval = setInterval(() => {
    //             this.props.onEditElement({
    //                 scaleX: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
    //                 scaleY: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
    //                 element: this.props.activeElement.attrs.name,
    //                 activePage: this.props.activePage,
    //             })
    //         }, 50);
    //     }
    // }

    getInterval(e, fn, timeInterval) {
        return e.target
            ? fn()
            : this.setState({ interval: setInterval(() => fn(), timeInterval) })
    }

    handleImageChangeSize(event, val) {
        event.preventDefault()
        event.stopPropagation()
        if (event.button === 0 || event.button === undefined) {
            let dd = 0;
            this.getInterval.call(this, val, () => {
                // Updated code
                dd = dd + 1; console.log('--dd-->', dd)
                if (dd >= 20) {
                    this.setState({ interval: clearInterval(this.state.interval) })
                    dd = 0;
                }

                // let item = this.props.activeElement;
                this.props.onEditElement({
                    width: (parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) > 25 ? parseFloat(this.props.activeElement.attrs.width + this.props.activeElement.attrs.width / this.props.activeElement.attrs.height * val) : 25),
                    height: (parseFloat(this.props.activeElement.attrs.height + val) > 25 ? parseFloat(this.props.activeElement.attrs.height + val) : 25),
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage,
                })
                // setTimeout(() => {
                //     switch (item.attrs.align) {
                //         case "right":
                //             item.x(item.x() + item.width() - item.offsetX())
                //             item.offsetX(item.width());
                //             break;
                //         case "center":
                //             item.x(item.x() + (item.width() / 2 - item.offsetX()))
                //             item.offsetX(item.width() / 2);
                //             break;
                //         default:
                //             item.offsetX(0);
                //             break;
                //     }
                // }, 55)
            }, 70)
        }
    }

    onFilesAdded(files) {
        let leng = this.state.files.length;
        console.log('files_new', files);
        files.forEach((FILE) => {
            console.log("Check This: ", FILE.name.split(".", -1));
            let arr = [];
            arr = FILE.name.split(".");
            let FileExtension = arr[arr.length - 1];
            console.log("last index: ", arr, FileExtension);
            if (FILE.size > IMAGE_MAX_SIZE && this.props.mode !== "ADMIN") {
                // alert('File ' + FILE.name + ' is too big! Max file size ' + IMAGE_MAX_SIZE / 1048576 + 'MB');                
                TranslateForAlert("Your Image is too big to upload! The max file size is 8 MB")
            }
            else if (FileExtension !== "png" && FileExtension !== "jpg" && FileExtension !== "jpeg" && FileExtension !== "jfif" && FileExtension !== "PNG" && FileExtension !== "JPG" && FileExtension !== "JPEG" && FileExtension !== "JFIF") {
                // alert("Upload not possible, only image format jpg, png, jpeg, jfif allowed.")
                TranslateForAlert("alert_error_message")
            }
            else {
                this.setState(prevState => ({
                    files: prevState.files.filter(item => item.name !== FILE.name).concat(FILE)
                }), () => {
                });
            }
        })

        if (leng < this.state.files.length) this.uploadFiles();
    }

    async uploadFiles() {
        this.setState({ uploadProgress: {}, uploading: true, isFirstImgLoaded: true });
        const promises = [];
        this.state.files.forEach(file => {
            // promises.push(this.sendRequest(file));

            promises.push(file);

        });
        if (promises.length === 1) {
            this.sendRequest(promises[0])
        }
        if (promises.length > 1) {
            this.state.files.forEach(file => {
                promises.push(this.sendRequest2(file));
            });
        }

        try {
            await Promise.all(promises);
            this.setState({ successfullUploaded: true, uploading: false, files: [] });
        } catch (e) {

            TranslateForAlert("There was error during getting files!")
        }
    }

    handlePropertyChange(e, status) {
        this.props.setUserAllowedProperties({
            [e]: status
        });
    }


    sendRequest(file) {
        // console.log("file:::", file)

        // enable loader
        this.setState({
            loading_wait: true
        });

        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            let width = file.width, height = file.height;

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                    this.setState({ uploadProgress: copy });
                }
            });

            req.upload.addEventListener("load", (data) => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });

                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(req.response);
            });

            const formData = new FormData();
            formData.append("mode", this.props.mode);
            formData.append("sid", this.props.sessionId);
            formData.append("file", file, file.name);

            formData.append("width", width);
            formData.append("height", height);

            req.onreadystatechange = () => {
                if (req.readyState === 4) {

                    if (this.props.mode !== "ADMIN" && params.robot !== 1) {
                        this.selectUploadedImage(this.props.uploaderUrl + "/visitor/filename/" + this.props.sessionId + "/thumbs/" + JSON.parse(req.response)?.files?.[0], 0, JSON.parse(req.response)?.filesDimensions?.[0]);
                    } else {
                        // disable loader
                        this.setState({
                            loading_wait: false
                        });
                    }
                    // refreshUploadedImages without request
                    this.props.refreshUploadedImages({
                        mode: this.props.mode,
                        uploaderUrl: this.props.uploaderUrl,
                        sessionId: this.props.sessionId,
                        dataAfterImgAdded: req.response
                    });
                }
            }

            req.open("POST", this.props.uploaderUrl + "/upload/" + this.props.sessionId);
            req.send(formData);
        });
    }
    sendRequest2(file) {
        // console.log("file:::", file)
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            let width = file.width, height = file.height;

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100
                    };
                    this.setState({ uploadProgress: copy });
                }
            });

            req.upload.addEventListener("load", (data) => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });

                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(req.response);
            });

            const formData = new FormData();
            formData.append("mode", this.props.mode);
            formData.append("sid", this.props.sessionId);
            formData.append("file", file, file.name);

            formData.append("width", width);
            formData.append("height", height);

            req.onreadystatechange = () => {
                if (req.readyState === 4) {

                    if (this.props.mode !== "ADMIN" && params.robot !== 1) {
                        // this.selectUploadedImage(this.props.uploaderUrl + "/visitor/filename/" + this.props.sessionId + "/thumbs/" + JSON.parse(req.response)?.files?.[0], 0, JSON.parse(req.response)?.filesDimensions?.[0]);
                    }
                    // refreshUploadedImages without request
                    this.props.refreshUploadedImages({
                        mode: this.props.mode,
                        uploaderUrl: this.props.uploaderUrl,
                        sessionId: this.props.sessionId,
                        dataAfterImgAdded: req.response
                    });
                }
            }

            req.open("POST", this.props.uploaderUrl + "/upload/" + this.props.sessionId);
            req.send(formData);
        });
    }


    componentDidMount() {
        if (!this.props.showImageUploadForm) this.showUploadedFiles();
    }

    showUploadedFiles() {
        this.props.refreshUploadedImages({
            mode: this.props.mode,
            uploaderUrl: this.props.uploaderUrl,
            sessionId: this.props.sessionId,
        })
    }

    renderProgress(file) {
        const uploadProgress = this.state.uploadProgress[file.name];
        if (this.state.uploading || this.state.successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    <FontAwesomeIcon className="CheckIcon" icon="check-circle"
                        style={{ opacity: uploadProgress && uploadProgress.state === "done" ? 0.5 : 0 }} />
                </div>
            );
        }
    }

    onChange(e) {
        this.setState({ file: e.target.files[0] });
    }

    render() {
        return (this.state.newImgUploader || this.props.isShowImageChangePopup || this.props.imageuploadvar === "trueImage") ? ((this.props.showImageUploadForm && this.state.newImgUploader) || this.props.isShowImageChangePopup || this.props.imageuploadvar === "trueImage" ? (
            <>
                <CommonImgUploader1>
                    <div className={this.props.uploadedImages.length > 0 ? 'designer-tools-uploader image_coloum_two' : 'designer-tools-uploader cstm_design_uploader'}>
                        {/* Show loader till get API response */}
                        {
                            this.state.loading_wait ?
                                <div className="loading-cust-wait">
                                    <div className="loading-text">
                                        <Translate content={"Face_cropper_loading_text"} />
                                    </div>
                                </div>
                                : null
                        }

                        <div className="uploader-form-content">
                            {/* New changes only for Mobile when no images uploaded */}
                            {
                                window.innerWidth <= 616 && this.props.uploadedImages.length === 0 ? <>
                                    <div className="cstm_upload_btn_outer">
                                        <div className="content_wrap_upload_btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z" />
                                            </svg>
                                            <Translate content={"bild_popup_text"} />
                                        </div>
                                    </div>
                                </> : null
                            }
                            {/* New changes only for Mobile when no images uploaded End */}

                            {/* Upload btn will show only in case of Admin here */}
                            {this.props.mode === 'ADMIN' ?
                                <>
                                    <Dropzone
                                        multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                        ref={this.dropzone}
                                        onFilesAdded={this.onFilesAdded.bind(this)}
                                        disabled={this.state.uploading && this.state.successfullUploaded}
                                        check_image_count={this.props.uploadedImages.length} />

                                    <div className="Files" style={{ padding: "0 5px" }}>
                                        {this.state.files.map(file => {
                                            return (
                                                <div key={file.name} className="Row">
                                                    <span className="Filename">{file.name}</span>
                                                    {this.renderProgress(file)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </> : null
                            }
                            {/* Upload btn will show only in case of Admin here */}

                            <div className={stl.uploader__files + ' withoutPopupUploading'} ref={this.imagesList}>
                                {this.props.uploadedImages.map((image, i) => {
                                    return (
                                        <img key={image.key}
                                            className={clsx([stl.uploader__img, this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 ? stl.uploader__img_round : ""])}
                                            src={image.url}
                                            alt="alt" width="100"
                                            onClick={this.selectUploadedImage.bind(this, image.url, i, this.props.uploadedImages[i]?.filesDimensions)} />
                                    );
                                })}
                            </div>

                            {/* Upload btn will show only in case of Frontend here */}
                            {this.props.mode !== 'ADMIN' ?
                                <>
                                    <Dropzone
                                        multiface_elements_admin_only={this.props.pages[this.props.activePage].multiface_elements_admin_only}
                                        ref={this.dropzone}
                                        onFilesAdded={this.onFilesAdded.bind(this)}
                                        disabled={this.state.uploading && this.state.successfullUploaded}
                                        check_image_count={this.props.uploadedImages.length} />

                                    <div className="Files" style={{ padding: "0 5px" }}>
                                        {this.state.files.map(file => {
                                            return (
                                                <div key={file.name} className="Row">
                                                    <span className="Filename">{file.name}</span>
                                                    {this.renderProgress(file)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </> : null
                            }
                            {/* Upload btn will show only in case of Frontend here end */}
                        </div>

                        {/* {this.props.pages[this.props.activePage].multiface_elements_admin_only === 1 ?
                                <div className={stl.uploader__title} style={{'paddingLeft': 0, 'paddingBottom': '5px'}}>
                                    <Translate content={"Uploads"}/>
                                </div> : null
                            } */}
                    </div>
                </CommonImgUploader1>
                {/* {
                                this.props.showImageUploadForm && !this.props.isShowImageChangePopup ? (
                                    <div className={stl.tools__btn}>
                                        <Button outline theme="secondary" size="sm" onClick={() => {
                                            this.setState({
                                                newImgUploader: false
                                            })
                                        }}>

                                            <Translate content={"Close"}/>
                                        </Button>
                                    </div>
                                ) : null
                            } */}
            </>
        ) : null
        )
            : this.props.showImageUploadForm ?
                <CommonImgUploader2>
                    {/* Rotate btn functionality on Mobile */}
                    {this.state.elRotationOpened && !this.props.enable_submenu_var ? <>
                        {window.innerWidth <= 616 ? <>
                            <div className="image-control-wrapper cust_menu_submenu_mobile">
                                <div className="submenu_back_arrow">
                                    <img src={down_arrow_btn} alt="" onClick={() => {
                                        this.setState({ elRotationOpened: false })
                                    }} />
                                </div>
                                <div className="rotate_btn_mobile_wrap">
                                    <img src={minus_img_icon} alt=""
                                        onClick={this.handleElemRotation.bind(this, -5)} />
                                    <span className='form-control form-control-sm cs-number-input'>
                                        {this.props?.activeElement?.rotation() !== undefined ? Math.round(this.props.activeElement.rotation()) : 0}
                                    </span>
                                    <img src={plus_img_icon} alt=""
                                        onClick={this.handleElemRotation.bind(this, +5)} />
                                </div>
                            </div>
                        </> : null}
                    </> : null}

                    {/* Zoom btn functionality on Mobile */}
                    {this.state.elZoomOpened && !this.props.enable_submenu_var ? <>
                        {window.innerWidth <= 616 ? <>
                            <div className="image-control-wrapper cust_menu_submenu_mobile">
                                <div className="submenu_back_arrow">
                                    <img src={down_arrow_btn} alt="" onClick={() => {
                                        this.setState({ elZoomOpened: false })
                                    }} />
                                </div>
                                <div className="zoom_btn_mobile_wrap">
                                    <img src={minus_img_icon} alt=""
                                        onContextMenu={(e) => e.preventDefault()}
                                        onTouchStart={(e) => { e.stopPropagation(); this.handleImageChangeSize(e, -1) }}
                                        onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                        // onMouseDown={(e) => this.handleImageChangeSize(e, -1)}
                                        onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                        onMouseLeave={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                    />

                                    <img src={plus_img_icon} alt=""
                                        onContextMenu={(e) => e.preventDefault()}
                                        onTouchStart={(e) => { e.stopPropagation(); this.handleImageChangeSize(e, 1) }}
                                        onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                        // onMouseDown={(e) => this.handleImageChangeSize(e, 1)}
                                        onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                        onMouseLeave={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                    />
                                </div>
                            </div>
                        </> : null}
                    </> : null}

                    {((this.state.elRotationOpened && window.innerWidth <= 616) || (this.state.elZoomOpened && window.innerWidth <= 616)) && !this.props.enable_submenu_var ? null : <>
                        <div className={stl.imgMenu + ' cs-image-upload input-wrap-nw image-control-wrapper btn-div-wrapper'}>
                            {this.props.activeElement?.attrs?.type === "image" ? <>
                                <button id="OpenImageChangePopup" className="image-control-wrap" onClick={() => {
                                    this.props.showImageChangePopup();
                                }}>
                                    <img src={change_img_icon} alt="" />
                                    {isfirebox ? 'Upload Photos' :
                                        <Translate content={"Change"} />
                                    }
                                </button>

                                <div className={clsx(this.state.elRotationOpened && stl.active) + ' image-control-wrap'}>
                                    <button onClick={() => {
                                        this.setState({
                                            elRotationOpened: window.innerWidth > 616 ? !this.state.elRotationOpened : true,
                                            elZoomOpened: false
                                        })
                                        this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                    }}>
                                        <img src={rotate_img_icon} alt="" />
                                        <Translate content={"Rotate"} />
                                    </button>

                                    {!this.props.enable_submenu_var ?
                                        <div className={stl.elRotationMenu}>
                                            <img src={minus_img_icon} alt=""
                                                onClick={this.handleElemRotation.bind(this, -5)} />
                                            <span className='form-control form-control-sm cs-number-input'>
                                                {this.props.activeElement.rotation() !== undefined ? Math.round(this.props.activeElement.rotation()) : 0}
                                            </span>
                                            <img src={plus_img_icon} alt=""
                                                onClick={this.handleElemRotation.bind(this, +5)} />
                                        </div>
                                        : null}
                                </div>

                                {(!this.props?.activeElement?.attrs?.isCutoutPro && !this.props?.activeElement?.attrs?.isCutoutProBg) || this.props.mode === "ADMIN" ?
                                    <button className="image-control-wrap" onClick={() => {
                                        let imgAttrs = this.props.activeElement?.attrs;
                                        if ((imgAttrs?.elementRepeat || imgAttrs?.elementRepeatOval) && imgAttrs?.type === "image") {
                                            this.props.showFaceCropPopup();
                                        } else {
                                            this.props.showImageCropPopup()
                                        }
                                    }}>
                                        <img src={cut_img_icon} alt="" />

                                        {isfirebox ? 'Crop' :
                                            <Translate content={"Cut"} />
                                        }
                                    </button>
                                    : null
                                }

                                <div className={clsx(this.state.elZoomOpened && stl.active) + ' image-control-wrap'}>
                                    <button onClick={() => {
                                        this.setState({
                                            elZoomOpened: window.innerWidth > 616 ? !this.state.elZoomOpened : true,
                                            elRotationOpened: false,
                                        })
                                        this.props.MenuBtnEnableSubmenu({ enable_submenu_var: false });
                                    }}>
                                        <img src={zoom_img_icon} alt="" />
                                        <span>Zoom</span>
                                    </button>

                                    {!this.props.enable_submenu_var ?
                                        <div className={stl.elZoomMenu + " zoom_btn_desktop_cust"}>

                                            <img src={minus_img_icon} alt=""
                                                onContextMenu={(e) => e.preventDefault()}
                                                onTouchStart={(e) => { e.stopPropagation(); this.handleImageChangeSize(e, -1) }}
                                                onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                onMouseDown={(e) => this.handleImageChangeSize(e, -1)}
                                                onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                onMouseLeave={() => this.setState({ interval: clearInterval(this.state.interval) })} />



                                            <img src={plus_img_icon} alt=""
                                                onContextMenu={(e) => e.preventDefault()}
                                                onTouchStart={(e) => { e.stopPropagation(); this.handleImageChangeSize(e, 1) }}
                                                onTouchEnd={(e) => { e.preventDefault(); this.setState({ interval: clearInterval(this.state.interval) }) }}
                                                onMouseDown={(e) => this.handleImageChangeSize(e, 1)}
                                                onMouseUp={() => this.setState({ interval: clearInterval(this.state.interval) })}
                                                onMouseLeave={() => this.setState({ interval: clearInterval(this.state.interval) })} />
                                        </div>
                                        : null}
                                </div>
                            </> : null}

                            {/* { 
                            this.props.toolImagesAllowed || this.props.mode === "ADMIN" ? <button onClick={() => {
                                this.setState({
                                    newImgUploader: !this.state.newImgUploader
                                })
                            }}>
                                <img src={new_img_icon} alt=""/>
                                {isfirebox ? 'Additional Photo' : 
                                    <Translate content={"Add_Image"}/>
                                }
                            </button> : null
                        } */}


                        </div>
                    </>}
                </CommonImgUploader2>
                : null
    }
}

const mapStateToProps = state => {
    return {
        isItemDownloading: state.glb.isItemDownloading,
        activePage: state.glb.activePage,
        uploadedImages: state.glb.uploadedImages,
        designerMode: state.glb.designerMode,
        sessionId: state.glb.sessionId,
        mode: state.glb.mode,
        showImageUploadForm: state.tol.showImageUploadForm,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        activeElement: state.glb.activeElement,
        stage: state.glb.stage,
        pages: state.glb.pages,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        selectedElements: state.glb.selectedElements,
        HoldURl: state.glb.HoldURl,
        imageuploadvar: state.glb.imageuploadvar,
        enable_submenu_var: state.glb.enable_submenu_var,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        hidePopup: () => {
            dispatch(actionCreators.hidePopup());
        },

        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        refreshUploadedImages: (params) => dispatch(actionCreators.refreshUploadedImages(params)),
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
            dispatch(actionCreators.hidePopup());
        },
        setUserAllowedProperties: (data) => {
            dispatch(actionCreators.userAllowedProperties(data));
        },
        showImageCropPopup: () => dispatch(actionCreators.showImageCropPopup()),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => {
            document.querySelectorAll("body")[0].style.overflow = 'auto'
            dispatch(actionCreators.hideToolsMenu())
        },
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data)),
        showFaceCropPopup: () => dispatch(actionCreators.showFaceCropPopup()),
        holdUrlData: (data) => {
            dispatch(actionCreators.holdUrlData(data))
        },
        MehrImageUpload: (data) => {
            dispatch(actionCreators.MehrImageUpload(data));
        },

        MenuBtnEnableSubmenu: (data) => {
            dispatch(actionCreators.MenuBtnEnableSubmenu(data))
        },
        ShowFaceCropperPopupNew: () => dispatch(actionCreators.ShowFaceCropperPopupNew()),
    };
}
    ;

export default connect(mapStateToProps, mapDispatchToProps)(ImgUploader);
