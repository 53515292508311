import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import {Image, Layer, Rect, Stage, Transformer} from "react-konva";
import Translate from "react-translate-component";
import stl from './popups.module.scss'
import {post} from "axios";
import loadingImage from "../../assets/images/loading.gif";
import stlCanvas from '../Canvas/Canvas.module.scss';
import {Button} from "shards-react";
import queryString from "query-string";
import {isBrowser, isMobile } from 'react-device-detect';

let
    STAGE_SIZE = Math.min(window.innerWidth - 120, window.innerHeight - 200),
    PADDING_STAGE = 0;

const params = queryString.parse(window.location.search);

class ImageCropPopup extends Component {
    transformer = React.createRef();
    stage = React.createRef();
    rect = React.createRef();

    state = { 
        activeElement: undefined,
        crop: {},
        x: 0,
        y: 0,
        width: Math.min(window.innerWidth - 120, window.innerHeight - 200),
        height: Math.min(window.innerWidth - 120, window.innerHeight - 200),
        rotateChanged: true,
        oldImgSrc: undefined,
        isCroped :false,
        loading_wait: false,
        imgSource: "",
    };

    componentDidMount() {
        
        if (this.props.activeElement) {
            this.setState({
                activeElement: this.props.activeElement,
            });
        }

        window.addEventListener('resize', () => {
            this.checkRatio();
        });
        this.checkRatio();
        this.checkNode();
    }

    prepareData() {
        this.rect.current.remove();
        let config = {headers: {'content-type': 'multipart/form-data'}};

        let stageCopy = this.stage.current.clone();

        /* Remove Transformer before print */
        stageCopy.removeName('Transformer');
        let url = stageCopy.toDataURL();
        let data = JSON.stringify({imgCropedData: url});
        post('https://uploader.radbag.de/uploadCropFile', data, config).then(response => {
            url = response.data.imgCropedData;
            this.submitChanges(url)
        }).catch(() => {
            console.warn('Error during cropped image saving.')
        });
    }

    submitChanges(url, hide) {        
        let {width, height} = this.props.activeElement.attrs;
        let {newBoxWidth, newBoxHeight} = this.state.crop;
        console.log("this.state.crop: ", this.state.crop)
        let
            newMin = Math.min(newBoxWidth, newBoxHeight),
            newMax = Math.max(newBoxWidth, newBoxHeight);
        let tempx = 0;  
        let new_width;
        let new_height;
        if(newBoxWidth == newBoxHeight){
            new_width = /*200; */newBoxWidth == newBoxHeight ? (width * (newMax / newMin)) : width; 
            new_height = /*200; */newBoxHeight == newBoxWidth ?  (width * (newMax / newMin)):height; 
            
        }else{
            new_width  = newBoxWidth > newBoxHeight ? (height * (newMax / newMin))-tempx : width;
            new_height = newBoxHeight > newBoxWidth ? (width * (newMax / newMin))-tempx : height;
        }
        

        if(this.state.isCroped ){
            this.props.onEditImageElement({
                // rotation: 0,
                width: /*new_width,*/newBoxWidth > newBoxHeight ? (height * (newMax / newMin))-tempx : width,
                height:/* new_height,*/newBoxHeight > newBoxWidth ? (width * (newMax / newMin))-tempx : height,
                imageCrop: this.state.isCroped ? this.state.crop : {},
                element: this.props.activeElement.attrs.name,
                activePage: this.props.activePage,
            });
       }else{
            this.props.onEditImageElement({
                // rotation: 0,
                // width: new_width,
                // height:new_height,
                imageCrop: this.state.crop,
                // isCroped :false
            });
      }

     
   
   
        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {
                    if(newBoxWidth == newBoxHeight){
                        new_width = /*200; */newBoxWidth == newBoxHeight ? (width * (newMax / newMin)) : width;
                        new_height = /*200; */newBoxHeight == newBoxWidth ?  (width * (newMax / newMin)):height; 
                        
                    }else{
                        new_width  = newBoxWidth > newBoxHeight ? (height * (newMax / newMin))-tempx : width;
                        new_height = newBoxHeight > newBoxWidth ? (width * (newMax / newMin))-tempx : height;
                    }
                    if(this.state.isCroped){
                        this.props.onEditImageElement({
                            // rotation: 0,
                            width: /*new_width,*/ newBoxWidth > newBoxHeight ? (height * (newMax / newMin))-tempx : width,
                            height: /*new_height,*/ newBoxHeight > newBoxWidth ? (width * (newMax / newMin))-tempx : height,
                            imageCrop: this.state.isCroped ? this.state.crop : {},
                            element: item.name,    
                            activePage: this.props.activePage,
                        });
                    }else{
                        this.props.onEditImageElement({
                            // rotation: 0,
                            // width: new_width,
                            // height:new_height,
                            imageCrop:this.state.crop,

                            // isCroped :false
                        });
                    }

                    
            }
        });


        // this.props.activeElement.attrs.rotation = 0

        // this.props.onEditImageElement({
        //     rotation: 0,
        //     element: this.props.activeElement.attrs.name,
        //     activePage: this.props.activePage,
        // });
        
        //******************************//
        // Added for RS-2547
        //******************************//
        if((this.props?.activeElement?.attrs?.isCutoutPro && this.props.mode !== "ADMIN") || (this.props?.activeElement?.attrs?.isCutoutProBg && this.props.mode !== "ADMIN") || (params.comic_product === 'face' && params.admin_edit == 1) || (params.comic_product === 'bg' && params.admin_edit == 1)){
            // Start loader
            this.setState({
                loading_wait: true
            });

            // Create Image Element
            var create_image = document.createElement("img");
            create_image.src = this.props.activeElement?.attrs?.image?.src;
            create_image.crossOrigin="anonymous";

            // Create Canvas element
            var c = document.createElement("canvas");
            var ctx = c.getContext("2d");
            c.width = this.state.crop.width;
            c.height = this.state.crop.height;

            setTimeout(()=>{
                // Draw Image with all the value and generate data url(blob)
                ctx.drawImage(create_image,this.state.crop.x,this.state.crop.y,this.state.crop.width,this.state.crop.height,0,0,this.state.crop.width,this.state.crop.height );
                // document.body.appendChild(c);
                const dataURL =  c.toDataURL();
                // console.log('dataURL',dataURL);

                // Call API to upload Image on server and get server Image Path
                let config = {headers: {'content-type': 'multipart/form-data'}};
                let data = JSON.stringify({imgCropedData: dataURL});
                let cropped_server_image = null;
                post('https://uploader.radbag.de/uploadCropFile', data, config).then(response => {
                    cropped_server_image = response.data.imgCropedData;
                    console.log('cropped_server_image==>>>',cropped_server_image,response);
                    
                    // Check if cropped server image available
                    if (cropped_server_image) {
                        // ======================================RS-3038 start===========================================================
                        // Call CartoonSelfie(Comic) API to get comic cutout
                        let comic_config = { headers: { 'content-type': 'multipart/form-data' } };
                        let comic_data   = JSON.stringify({ imageUrl: cropped_server_image, cartoonType: (params.cartoon_type) ? params.cartoon_type : this.props?.activeElement?.attrs?.cartoonTypeValue });
                        post( 
                            'https://uploader.radbag.de/cartoonSelfie', comic_data, comic_config).then(response => {
                                if (response.data.error) {
                                    /* Show error */
                                    console.error(response.data.data);
                                } else {
                                    console.log('cartoonSelfie',response.data,this);
                                    localStorage.setItem("FaceCropCutoutImageComic", response.data.imageUrl);
                                    console.log('Comic',response.data.imageUrl,response);
                                    
                                    // Check If comic image available in response and Face Cutout selected in Admin
                                    // if((cropped_server_image && this.props?.activeElement?.attrs?.isCutoutPro) || (cropped_server_image && params.comic_product === 'face' && params.admin_edit == 1)){
                                        if((response.data.imageUrl && this.props?.activeElement?.attrs?.isCutoutPro) || (response.data.imageUrl && params.comic_product === 'face' && params.admin_edit == 1)){
                                        // Call faceCutout(Face) API to get exact face cutout using comic cutout
                                        let face_config = { headers: { 'content-type': 'multipart/form-data' } };
                                        // let face_data   = JSON.stringify({ imageUrl: cropped_server_image});
                                        let face_data   = JSON.stringify({ imageUrl: response.data.imageUrl});
                                        post( 
                                        'https://uploader.radbag.de/faceCutout', face_data, face_config).then(response => {
                                            if (response.data.error) {
                                                /* Show error */
                                                console.error(response.data.data);
                                            } else {
                                                // Set Image path in local storage
                                                localStorage.setItem("FaceCropCutoutImageFinal", response.data.imageUrl);
                                                console.log('Final',response.data.imageUrl,response);
                                                
                                                // Close the loader
                                                this.setState({
                                                    loading_wait: false,
                                                });
    
                                                // Check If Image found in response set the image in workspace
                                                if(!this.state.loading_wait && response.data.imageUrl){  
                                                    let imgAttrs = this.props.activeElement?.attrs;
                                                    this.props.onEditImageElement({
                                                        element: imgAttrs.name,
                                                        activePage: this.props.activePage,
                                                        imageCrop: {},
                                                        newImgData: {
                                                            imageUrl: response.data.imageUrl,
                                                            width: imgAttrs.width,
                                                            height: imgAttrs.height,
                                                        },
                                                    });   
                                                    // Hide Popup
                                                    this.props.hidePopup();              
                                                    // Old flow
                                                    // this.props.ShowFaceCutoutPopup(); 
                                                }
                                            }                                                
                                        }).catch(() => {
                                            this.setState({
                                                loading_wait: false,
                                            });
                                        });
                                    }
                                    // Check If comic image available in response and Face Cutout Bg selected in Admin
                                    // else if((cropped_server_image && this.props?.activeElement?.attrs?.isCutoutProBg) || (cropped_server_image && params.comic_product === 'bg' && params.admin_edit == 1)){
                                        else if((response.data.imageUrl && this.props?.activeElement?.attrs?.isCutoutProBg) || (response.data.imageUrl && params.comic_product === 'bg' && params.admin_edit == 1)){
                                        // Call faceCutout(Face) API to get exact face cutout using comic cutout
                                        let face_config = { headers: { 'content-type': 'multipart/form-data' } };
                                        // let face_data   = JSON.stringify({ imageUrl: cropped_server_image});
                                        let face_data   = JSON.stringify({ imageUrl: response.data.imageUrl});
                                        post( 
                                        'https://uploader.radbag.de/bgRemove', face_data, face_config).then(response => {
                                            if (response.data.error) {
                                                /* Show error */
                                                console.error(response.data.data);
                                            } else {
                                                // Set Image path in local storage
                                                localStorage.setItem("FaceCropCutoutImageRemoveBg", response.data.imageUrl);
                                                // console.log('RemoveBg',response.data.imageUrl,response);
                                                
                                                // Close the loader
                                                this.setState({
                                                    loading_wait: false,
                                                });
    
                                                // Check If Image found in response set the image in workspace
                                                if(!this.state.loading_wait && response.data.imageUrl){  
                                                    let imgAttrs = this.props.activeElement?.attrs;
                                                    this.props.onEditImageElement({
                                                        element: imgAttrs.name,
                                                        activePage: this.props.activePage,
                                                        imageCrop: {},
                                                        newImgData: {
                                                            imageUrl: response.data.imageUrl,
                                                            width: imgAttrs.width,
                                                            height: imgAttrs.height,
                                                        },
                                                    });   
                                                    // Hide Popup
                                                    this.props.hidePopup();              
                                                    // Old flow
                                                    // this.props.ShowFaceCutoutPopup(); 
                                                }
                                            }                                                
                                        }).catch(() => {
                                            this.setState({
                                                loading_wait: false,
                                            });
                                        });
                                    }else{
                                        console.log('error',response.data);
                                    }                            
                                }
                            }).catch(() => {
                                this.setState({
                                    loading_wait: false,
                                });
                            });  
                             // =============================RS-3038 end=============================================================                         
                    }else{
                        console.warn('Error! Cropped Image Path not found',response.data)    
                    }
                }).catch(() => {
                    console.warn('Error during cropped image saving.');
                    this.setState({
                        loading_wait: false,
                    });
                });
            },1000)
        }else{
            if (hide) this.props.hidePopup();
        } 
        // End for RS-2547 

        // commented for RS-2547
        // if (hide) this.props.hidePopup();
    }

    checkNode() {
        let
            transformer = this.transformer.current,
            activeElement = this.state.activeElement || this.props.activeElement;
        transformer.attachTo(this.rect.current);
        let
            width = this.props.activeElement.attrs.image.width,
            height = this.props.activeElement.attrs.image.height;
        if (activeElement) {
            this.rect.current.dragBoundFunc((newBox) => {
                this.setState({isCroped:true});
                let
                    newBoxWidth = this.state.crop.newBoxWidth || this.props.activeElement.attrs.width / 2,
                    newBoxHeight = this.state.crop.newBoxHeight || this.props.activeElement.attrs.height / 2,
                    newBoxX = this.state.crop.newBoxX,
                    newBoxY = this.state.crop.newBoxY,
                    ifX = ((newBox.x >= (PADDING_STAGE / 2)) && ((newBox.x + newBoxWidth) <= (this.state.width - PADDING_STAGE / 2))),
                    ifY = ((newBox.y >= (PADDING_STAGE / 2)) && ((newBox.y + newBoxHeight) <= (this.state.height - PADDING_STAGE / 2)));

                newBox.x = ((this.state.width - PADDING_STAGE / 2) - (newBox.x + newBoxWidth)) > 0 ? newBox.x : newBoxX
                newBox.y = ((this.state.height - PADDING_STAGE / 2) - (newBox.y + newBoxHeight)) > 0 ? newBox.y : newBoxY

                newBox.x = (newBox.x >= (PADDING_STAGE / 2) ? newBox.x : PADDING_STAGE / 2)
                newBox.y = (newBox.y >= (PADDING_STAGE / 2) ? newBox.y : PADDING_STAGE / 2)
                //
                newBoxX = (newBoxX >= (PADDING_STAGE / 2) ? newBoxX : PADDING_STAGE / 2)
                newBoxY = (newBoxY >= (PADDING_STAGE / 2) ? newBoxY : PADDING_STAGE / 2)

                this.setState({
                    crop: {
                        ...this.state.crop,
                        x: ifX ? ((width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100)) : this.state.crop.x,
                        y: ifY ? ((height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100)) : this.state.crop.y,
                        width: (width / 100) * (newBoxWidth / ((this.state.width - PADDING_STAGE) * 0.01)),
                        height: (height / 100) * (newBoxHeight / ((this.state.height - PADDING_STAGE) * 0.01)),
                        newBoxX: (ifX ? ((width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100)) : this.state.crop.x) * ((this.state.width * 100 / this.props.activeElement.attrs.image.width) * 0.01),
                        newBoxY: (ifY ? ((height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100)) : this.state.crop.y) * ((this.state.height * 100 / this.props.activeElement.attrs.image.height) * 0.01),
                        newBoxWidth: newBoxWidth,
                        newBoxHeight: newBoxHeight
                    }
                });

                return {
                    x: (ifX ? newBox.x : newBoxX), //
                    y: (ifY ? newBox.y : newBoxY), //
                }
            })
            transformer.boundBoxFunc((oldBox, newBox) => {
                this.setState({isCroped:true});
                console.log("isCroped True")
                if (newBox.width > this.state.width - PADDING_STAGE || newBox.width - PADDING_STAGE < 10) {
                    return oldBox;
                } else if (newBox.height > this.state.height - PADDING_STAGE || newBox.height - PADDING_STAGE < 10) {
                    return oldBox;
                } else {
                    console.log("isCroped ELSE ")
                    this.setState({
                        crop: {
                            x: (width / 100) * (newBox.x - PADDING_STAGE / 2) / ((this.state.width - PADDING_STAGE) / 100),
                            y: (height / 100) * (newBox.y - PADDING_STAGE / 2) / ((this.state.height - PADDING_STAGE) / 100),
                            width: (width / 100) * (newBox.width / ((this.state.width - PADDING_STAGE) * 0.01)),
                            height: (height / 100) * (newBox.height / ((this.state.height - PADDING_STAGE) * 0.01)),
                            newBoxX: newBox.x,
                            newBoxY: newBox.y,
                            newBoxWidth: newBox.width,
                            newBoxHeight: newBox.height
                        }
                    });

                    return newBox;
                }
            });
        }
    }

    checkRatio() {
        let
        width = this.props.activeElement?.attrs?.image?.width,
        height = this.props.activeElement?.attrs?.image?.height;
    let
        newBoxWidth,
        newBoxHeight,
        newBoxX = this.props.activeElement.attrs.cropX * ((this.state.width * 100 / this.props.activeElement.attrs.image.width) * 0.01),
        newBoxY = this.props.activeElement.attrs.cropY * ((this.state.height * 100 / this.props.activeElement.attrs.image.height) * 0.01)

    STAGE_SIZE = Math.min(window.innerWidth - 120, window.innerHeight - 200);

    if (width > height) {
        let newHeight = STAGE_SIZE / (width / height);
        this.setState({
            width: STAGE_SIZE,
            height: newHeight,
            y: ((STAGE_SIZE - newHeight) / 2),
        })
        newBoxWidth = this.props.activeElement.attrs.cropWidth * (((STAGE_SIZE - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.width) * 0.01)
        newBoxHeight = this.props.activeElement.attrs.cropHeight * (((newHeight - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.height) * 0.01)
    } else if (width < height) {
        let newWidth = STAGE_SIZE / (height / width);
        this.setState({
            width: newWidth,
            height: STAGE_SIZE,
            x: (STAGE_SIZE - newWidth) / 2,
        });
        newBoxWidth = this.props.activeElement.attrs.cropWidth * (((newWidth - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.width) * 0.01)
        newBoxHeight = this.props.activeElement.attrs.cropHeight * (((STAGE_SIZE - PADDING_STAGE) * 100 / this.props.activeElement.attrs.image.height) * 0.01)
    } else {
        if (this.props.activeElement.attrs.width > this.props.activeElement.attrs.height || this.props.activeElement.attrs.width < this.props.activeElement.attrs.height) {
        } else {
            newBoxWidth = STAGE_SIZE - PADDING_STAGE;
            newBoxHeight = STAGE_SIZE - PADDING_STAGE;
        }
    }
        this.setState({      
            crop: {
                x: this.props.activeElement.attrs.cropX,
                y: this.props.activeElement.attrs.cropY,
                width: this.props.activeElement.attrs.cropWidth,
                height: this.props.activeElement.attrs.cropHeight,
                newBoxX: this.props.activeElement.attrs.newBoxX || newBoxX,
                newBoxY: this.props.activeElement.attrs.newBoxY || newBoxY,
                newBoxWidth,
                newBoxHeight
            }
        });
 
        ///////////////////////////////////RS-3048 start /////////////////////////////
        // if(isMobile){
        //     this.setState({      
        //         crop: {
        //             x: this.props.activeElement.attrs.cropX,
        //             y: this.props.activeElement.attrs.cropY,
        //             width: this.props.activeElement.attrs.cropWidth,
        //             height: this.props.activeElement.attrs.cropHeight,
        //             newBoxX: this.props.activeElement.attrs.newBoxX || newBoxX+10,
        //             newBoxY: this.props.activeElement.attrs.newBoxY+20 || newBoxY+20,
        //             newBoxWidth:newBoxWidth-20,
        //             newBoxHeight:newBoxHeight-40
        //         }
        //     });
        //  }
        //  if(isBrowser){
        //     this.setState({      
        //         crop: {
        //             x: this.props.activeElement.attrs.cropX,
        //             y: this.props.activeElement.attrs.cropY,
        //             width: this.props.activeElement.attrs.cropWidth,
        //             height: this.props.activeElement.attrs.cropHeight,
        //             newBoxX: this.props.activeElement.attrs.newBoxX || newBoxX+40,
        //             newBoxY: this.props.activeElement.attrs.newBoxY+20 || newBoxY+20,
        //             newBoxWidth:newBoxWidth-80,
        //             newBoxHeight:newBoxHeight-40
        //         }
        //     });
        //  }
        ///////////////////////////////////RS-3048 end /////////////////////////////

        
       
    }

    render() {
        // console.log("STAGE_SIZE:", STAGE_SIZE," this.state.width - PADDING_STAGE / 2==>",this.state.width - PADDING_STAGE / 2)
        // console.log("HEIGHT: ",this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? STAGE_SIZE : this.state.width - PADDING_STAGE / 2)
        // console.log("WIDTH: ", this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? STAGE_SIZE : this.state.width - PADDING_STAGE / 2)
        
        let NEW_STAGE_SIZE = this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? STAGE_SIZE : this.state.width - PADDING_STAGE / 2;
        
        // console.log("this.stage::", this.stage)
        // console.log("IMAGE:::==>", this.props.activeElement.attrs)
        // console.log("Image Width: ", this.state.width - PADDING_STAGE / 2)
        // console.log("Image height: ", this.state.height - PADDING_STAGE / 2)
        console.log("commonEditorForAll ? : ", this.props?.pages[this.props.activePage]?.commonEditorForAll)
        // code for stage dimensions
        if(NEW_STAGE_SIZE<0){
            let NEW_STAGE_WIDTH = this.props.activeElement.attrs.naturalWidth
            let NEW_STAGE_HEIGH =  this.props.activeElement.attrs.naturalHeight
        }else{
            let NEW_STAGE_WIDTH = this.props.activeElement.attrs.naturalWidth
            let NEW_STAGE_HEIGH =  this.props.activeElement.attrs.naturalHeight
        }
        // code for image diamensions

        let IMAGE_NEW_WIDTH = this.state.width - PADDING_STAGE / 2;
        let IMAGE_NEW_HEIGHT = this.state.height - PADDING_STAGE / 2;

        if(IMAGE_NEW_WIDTH<0){  
            IMAGE_NEW_WIDTH = this.props.activeElement.attrs.naturalWidth
        }
        if(IMAGE_NEW_HEIGHT<0){
            IMAGE_NEW_HEIGHT = this.props.activeElement.attrs.naturalHeight
        }

        return (
            <div className="text-form-popup-content">
                <div className="text-form-popup-overlay" onClick={this.props.hidePopup}/>
                <div className="text-form-popup-content-inner text-form-popup-content-inner__imgCrop cstm-imgCrop-content-inner"
                     style={{transform: `rotate(${this.props.activeElement.attrs.rotation})`}}>

                    {this.state.rotateChanged ? null :
                        <div className={stlCanvas["canvas-loader"]} style={{"position": "absolute", "z-index": "1"}}>
                            <img className={stlCanvas['canvas-loader__image']} key="Loading image" src={loadingImage}
                                 alt={"loading_image"}/>
                        </div>}
                    
                    {/* Show loader till get API response */}
                    {
                        this.state.loading_wait ?
                            <div className="loading-cust-wait">
                                <div className="loading-text">
                                    <Translate content={"Face_cropper_loading_text"} />
                                </div>
                            </div>
                        : null
                    }

                    <div className="text-form-popup-content-title">
                       <Translate content={"Crop Image"}/>
                    </div>
                    <div className="canvas_imgCrop_wrapper">                      

                    <Stage
                        ref={this.stage}
                        y={0}
                        x={0} 
                        className={stl['canvas-crop-popup ']}
                        width={NEW_STAGE_SIZE <0 ? this.props.activeElement.attrs.naturalWidth : NEW_STAGE_SIZE}
                        height={ NEW_STAGE_SIZE <0 ? this.props.activeElement.attrs.naturalHeight  : NEW_STAGE_SIZE }
                        // width={this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? STAGE_SIZE : this.state.width - PADDING_STAGE / 2}
                        // width = {NEW_STAGE_SIZE <0 ? this.props.activeElement.attrs.naturalWidth : NEW_STAGE_SIZE }
                        // height={this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? STAGE_SIZE : this.state.height - PADDING_STAGE / 2}
                        // height = {NEW_STAGE_SIZE <0 ? this.props.activeElement.attrs.naturalHeight : NEW_STAGE_SIZE }
                    >
                        <Layer>
                            <Image
                                // rotation={this.state.rotateChanged ? 0 : this.props.activeElement.attrs.rotation}
                                // offsetX={(this.state.width - PADDING_STAGE / 2) / 2}
                                // offsetY={(this.state.height - PADDING_STAGE / 2) / 2}
                                // x={(PADDING_STAGE / 2) + (this.state.width - PADDING_STAGE / 2) / 2}
                                // y={(PADDING_STAGE / 2) + (this.state.height - PADDING_STAGE / 2) / 2}

                                x={PADDING_STAGE / 2}
                                y={PADDING_STAGE / 2}
                                
                                // width={this.state.width - PADDING_STAGE / 2}
                                // height={this.state.height - PADDING_STAGE / 2}

                                width = {IMAGE_NEW_WIDTH}
                                height = {IMAGE_NEW_HEIGHT} 
                                image={this.props.activeElement.attrs.image}
                                draggable={false}
                                opacity={1}
                                fill={"green"} 
                            />
                            <Rect 
                                x={/*0*/this.state.crop.newBoxX || this.props.activeElement.attrs.newBoxX || PADDING_STAGE / 2}
                                y={/*0*/this.state.crop.newBoxY || this.props.activeElement.attrs.newBoxY || PADDING_STAGE / 2}
                                width={this.props.activeElement.attrs.newBoxWidth || this.state.crop.newBoxWidth || this.props.activeElement.attrs.width}
                                height={this.props.activeElement.attrs.newBoxHeight || this.state.crop.newBoxHeight || this.props.activeElement.attrs.height}
                                
                                // width={this.state.width - PADDING_STAGE / 2}
                                // height={this.state.height - PADDING_STAGE / 2}

                                // width={this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? 
                                //     this.props.activeElement.attrs.newBoxWidth || this.state.crop.newBoxWidth || this.props.activeElement.attrs.width / 2
                                //     :
                                //     this.state.width - PADDING_STAGE / 2
                                    
                    
                                // }
                                // height={this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ? 
                                //     this.props.activeElement.attrs.newBoxHeight || this.state.crop.newBoxHeight || this.props.activeElement.attrs.height / 2
                                //     :
                                //     this.state.height - PADDING_STAGE / 2
                                // }
                                draggable={true}
                                transformable={true}
                                fill={"blue"}
                                opacity={.1}
                                ref={this.rect}
                            />
                            <Transformer
                                anchorSize={18}
                                rotateEnabled={false}
                                ignoreStroke={false}
                                draggable={false}
                                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
                                ref={this.transformer}
                            />
                        </Layer>
                    </Stage>
                    </div>
                    <div className="text-form-popup-content-buttons">
                        <Button outline theme="secondary" onClick={() => {
                            this.props.hidePopup()
                            document.querySelector('#OpenImageChangePopup').click();
                        }
                            }>
                            <Translate content={"Cancel"}/>
                        </Button>
                        <Button className={'custom-secondary'} theme="secondary" onClick={this.submitChanges.bind(this, undefined, true)}>
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        isShowImageCropPopup: state.tol.showImageCropPopup,
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        selectedElements: state.glb.selectedElements,
        isShowFaceCutoutPopup: state.tol.ShowFaceCutoutPopup,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data))
        },
        onEditImageElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
        ShowFaceCutoutPopup: () => dispatch(actionCreators.ShowFaceCutoutPopup()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCropPopup);
