import React, {Component} from 'react';
import {Circle, Layer, Line, Rect, RegularPolygon, Stage, Transformer} from 'react-konva';
import stl from './CanvasAdditionalLayers.module.scss'
import { GRID_PADDING } from "../../config"
import clsx from "clsx";

class CanvasAdditionalLayers extends Component {
    state = {
        width: this.props.stageSize,
        height: this.props.stageSize,
        x: 0,
        y: 0,
        customShapePoints: []
    };

    layerCustomMask = React.createRef();
    stageCustomMask = React.createRef();
    transformerCustomMask = React.createRef();
    stageSize = this.props.stageSize;

    componentDidMount() {
        this.checkRatio();
        this.resizeStage();
        window.addEventListener('resize', () => {
            this.checkRatio();
            this.resizeStage();
        });
        this.checkNode()

        let saveBorderLimit = (e) => {
            this.props.setBorderLimitEditingStatus({
                isBorderLimitEditing: {
                    status: this.props.isBorderLimitEditing.status,
                    x: e.target.attrs.x || this.props.isBorderLimitEditing?.x || 0,
                    y: e.target.attrs.y || this.props.isBorderLimitEditing?.y || 0,
                    width: e.target.attrs.width * e.target.attrs.scaleX || this.props.isBorderLimitEditing?.width,
                    height: e.target.attrs.height * e.target.attrs.scaleY || this.props.isBorderLimitEditing?.height
                }
            });
        }
        this.refs.stage.children[0].children[0].on('transformend', saveBorderLimit);
        this.refs.stage.children[0].children[0].on('dragend', saveBorderLimit);

        this.saveCustomMask(this.props.pages[0].canvasMaskElements)
    }

    saveCustomMask(data) {
        if (this.props?.setCustomMaskEditingStatus) {
            this.props.setCustomMaskEditingStatus({
                isCustomMaskEditing: {
                    status: this.props?.isCustomMaskEditing?.status,
                    canvasMaskElements: data ?? this.props?.canvasMaskElements
                }
            });
        }

        if (this.props?.setMasksElements) {
            let elements = this.stageCustomMask?.current?.clone().children[0].children.filter(item => {
                    return item.attrs.name !== 'customMaskBackground' && item.constructor.name !== 'Transformer'
                }
            )
            elements = elements.map(i => i.attrs);

            elements = JSON.stringify(elements)

            this.props.setMasksElements({
                canvasMaskElements: data ?? elements ?? this.props?.canvasMaskElements
            });
        }


        // console.log(JSON.stringify(this.stageCustomMask?.current?.clone().removeName('Transformer').children[0].children.filter(item => item.attrs.name !== 'customMaskBackground')) , this.props?.canvasMaskElements)
    }

    removeColor(color) {
        if (this.stageCustomMask?.current) {

            var canvas = this.stageCustomMask?.current?.content?.firstChild,
                ctx = canvas.getContext("2d");


            var selectColor = [255, 255, 255];
            var canvasData = ctx.getImageData(0, 0, canvas.width, canvas.height),
                pix = canvasData.data;

            for (var i = 0, n = pix.length; i < n; i += 4) {
                if (pix[i] === color[0] && pix[i + 1] === color[1] && pix[i + 2] === color[2]) {
                    pix[i + 3] = 0;
                }
            }

            ctx.putImageData(canvasData, 0, 0);
        }
    }

    componentDidUpdate() {
        this.resizeStage();
        this.checkNode();
        if ((this.transformerCustomMask.current && this.props?.canvasMaskElements.filter(i => i.name === this.transformerCustomMask?.current.node()?.attrs?.name).length === 0) || (this.transformerCustomMask.current && this.props?.canvasMaskElements.length === 0)) {
            this.transformerCustomMask.current.detach();
        }
    }

    resizeStage() {
        let stage = this.refs.stage;
        if (stage) {
            let container = stage.container();
            // Get container size
            let containerSize = {
                width: container.clientWidth,
                height: container.clientHeight
            };

            // Odd size can cause blurry picture due to subpixel rendering
            if (containerSize.width % 2 !== 0) containerSize.width--;
            if (containerSize.height % 2 !== 0) containerSize.height--;

            // Resize stage
            stage.size(containerSize);

            // Scale stage
            let scaleX = containerSize.width / this.state.width;
            let scaleY = containerSize.height / this.state.height;

            let minRatio = Math.min(scaleX, scaleY);
            let scale = {x: minRatio, y: minRatio};

            stage.scale(scale);

            // Center stage


            let stagePos = {
                x: (containerSize.width - this.stageSize * minRatio) * 0.5,
                y: (containerSize.height - this.stageSize * minRatio) * 0.5
            };

            stage.position(stagePos);
            // Redraw stage
            stage.batchDraw();
        }
    }

    checkRatio() {
        let
            {width, height} = this.props;
        if (width > height) {
            let newHeight = this.stageSize / (width / height);
            this.setState({
                width: this.stageSize,
                height: newHeight,
                y: (this.stageSize - newHeight) / 2,
            })
        } else if (width < height) {
            let newWidth = this.stageSize / (height / width);
            this.setState({
                width: newWidth,
                height: this.stageSize,
                x: (this.stageSize - newWidth) / 2,
            });
        }
    }

    gridLines() {
        let
            {stageSize} = this.props,
            countWidth = stageSize / GRID_PADDING,
            countHeight = stageSize / GRID_PADDING,
            lines = [];
        for (let i = 0; countWidth > i; i++) {
            lines.push([Math.round(i * GRID_PADDING) + 0.5, 0, Math.round(i * GRID_PADDING) + 0.5, parseFloat(stageSize)]);
        }
        for (let i = 0; countHeight > i; i++) {
            lines.push([0, Math.round(i * GRID_PADDING), parseFloat(stageSize), Math.round(i * GRID_PADDING)]);
        }
        return (
            lines.map((i, index) => (
                <Line
                    points={i}
                    key={index}
                    stroke={"rgba(0,0,0,.4)"}
                    dash={[1, 1]}/>
            ))
        );
    }

    handleOnMouseUp = e => {
        const transformerCustomMask = this.transformerCustomMask.current;

        // Disable transformer if click on "Stage"
        if (e.target === e.target.getStage()) {
            transformerCustomMask.detach();
            return;
        }


        // If click on transformer do nothing
        if (e.target.getParent()?.className === 'Transformer') {
            return;
        }

        const name = e.target.name();

        const element = this.stageCustomMask.current.children[0].children.filter(r => r?.attrs?.name === name);
        if (element[0] && !this.props.isMaskDrawMode && name !== "customMaskBackground") {

            this.props.setActiveMaskElement(element[0].attrs.name)
            if (element[0].attrs.type !== 'line') {
                transformerCustomMask.enabledAnchors();
                transformerCustomMask.attachTo(element[0]);
            }

        } else if (!this.props.isMaskDrawMode) {
            transformerCustomMask.detach();
            this.props.setActiveMaskElement('')
        }
        if (this.props?.canvasMaskElements.length === 0) {

            if(transformerCustomMask){ transformerCustomMask.detach();}
            this.props.setActiveMaskElement('')
        }
        this.saveCustomMask()
    };

    getCanvasMaskElements() {
        let isAnyLines = false;
        let elements = [];

        elements = this.props.canvasMaskElements.map((item) => {
            let commonProperties = {
                x: 50,
                y: 50,
                width: 100,
                height: 100,
                fill: 'white',
                draggable: true,
                sides: 3,
                radius: 50,
                transformable: true,
                ...item,
                key: item?.name ?? 'customShape'
            }

            if (item.points) {
                isAnyLines = true;

                return (<Line
                    type={'line'}
                    draggable={false}
                    transformable={true}
                    name={'customShape'}
                    points={item.points}
                    closed={true}
                    tension={0.001}
                    stroke={'blue'}
                    key={item.name}
                    onTransformEnd={this.handleOnMouseUp.bind(this)}
                    fill={'white'}/>);
            }

            switch (item.type) {
                case 'square':

                    let isBorderLimitExisted = this.stageCustomMask?.current?.children[0].children.some(i => {
                        return i.attrs.name === 'borderLimit'
                    });
                    let points = this.stageCustomMask?.current?.children[0]?.children[2]?.attrs?.points?.length;
                    let hideCustomMask = isBorderLimitExisted && this.stageCustomMask?.current?.children[0].children.length <= 3 && (points === undefined || points <= 6)

                    return (<Rect
                        {...commonProperties}
                        fill={item.name === 'borderLimit' && !hideCustomMask ? 'black' : 'white'}
                        stroke={item.name === 'borderLimit' ? 'red' : 'blue'}
                        strokeWidth={item.name === 'borderLimit' ? 2 : 1}
                        onTransformEnd={this.handleOnMouseUp.bind(this)}
                        // name={item.name}
                    />);
                case 'circle':
                    return (<Circle
                        {...commonProperties}
                        strokeWidth={1}
                        onTransformEnd={this.handleOnMouseUp.bind(this)}
                        stroke={'blue'}
                    />);
                case 'triangle':
                    return (<RegularPolygon
                        {...commonProperties}
                        sides={3}
                        radius={5}
                        strokeWidth={50}
                        onTransformEnd={this.handleOnMouseUp.bind(this)}
                        stroke={'blue'}
                    />);
                default:
                    return null;
            }
        });

        if (!isAnyLines) {
            return (<Line
                type={'line'}
                draggable={false}
                transformable={false}
                points={[]}
                closed={true}
                tension={0.001}
                stroke={'blue'}
                key={'customShape'}
                name={'customShape'}
                fill={'white'}/>);
        }


        return elements;
    }

    checkNode() {
        if (this.props.pages[this.props.activePage].borderLimit?.width > 0 && this.refs.stage?.children[0]?.children[0] && this.refs.stage?.children[0]?.children[1]) {
            let
                transformer = this.refs.stage.children[0].children[1],
                rect = this.refs.stage.children[0].children[0];

            if (this.props.isBorderLimitEditing?.status && !this.props.isMaskDrawMode) {
                transformer.enabledAnchors();
                transformer.attachTo(rect);
            } else if(transformer){
                transformer.detach();
            }
        }


        if (this.stageCustomMask.current && this.props.stage) {

            this.stageCustomMask.current.scale({
                x: this.props.stage.scale().x / (this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1),
                y: this.props.stage.scale().y / (this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio ?? 1)
            })

            if (!this.props.isCustomMaskEditing?.status) {
                setTimeout(() => {
                    this.removeColor([255, 255, 255]);
                }, 0)
            }
            if (this.transformerCustomMask.current && this.props.isCustomMaskEditing?.status) {

                this.transformerCustomMask.current.boundBoxFunc((oldBox, newBox) => {
                    let canvasMaskElements = this.props.canvasMaskElements.map((item, i) => {
                        if (item.name === this.props.activeMaskElement && item.type === 'square') {
                            item.width = newBox.width;
                            item.scaleX = 1
                            item.height = newBox.height
                            item.scaleY = 1
                        }
                        return item
                    });

                    canvasMaskElements = JSON.stringify(canvasMaskElements)

                    this.props.setCustomMaskEditingStatus({
                        isCustomMaskEditing: {
                            status: this.props?.isCustomMaskEditing?.status,
                            canvasMaskElements: canvasMaskElements
                        }
                    });
                    return newBox;
                });
            }
        }
    }

    addPointToCustomShape(e) {
        let stage = e.currentTarget.getStage().getPointerPosition();

        this.props.addMasksElement({type: 'line', points: [stage.x, stage.y]})
    }

    render() {
        let points = this.stageCustomMask?.current?.children[0]?.children[2]?.attrs?.points?.length;


        let isBorderLimitExisted = this.stageCustomMask?.current?.children[0].children.some(i => {
            return i.attrs.name === 'borderLimit'
        });
        let hideCustomMask = isBorderLimitExisted && this.stageCustomMask?.current?.children[0].children.length <= 3 && (points === undefined || points <= 4)

        return (
            <>
                <Stage
                    className={stl['canvas-guideline']}
                    style={this.props.isBorderLimitEditing.status ? {} : {pointerEvents: "none"}}
                    ref={"stage"}
                >
                    <Layer>
                        {this.props.pages[this.props.activePage].borderLimit?.width > 0 && !isBorderLimitExisted ? <Rect
                                width={this.props.pages[this.props.activePage].borderLimit?.width || ((this.state.width || this.state.STAGE_SIZE) - 4)}
                                height={this.props.pages[this.props.activePage].borderLimit?.height || ((this.state.height || this.state.STAGE_SIZE) - 4)}
                                x={this.props.pages[this.props.activePage].borderLimit?.x}
                                y={this.props.pages[this.props.activePage].borderLimit?.y}
                                changeable={true}
                                transformable={true}
                                stroke={"red"}
                                draggable={true}
                                ref={this.borderLimit}
                            />
                            : null
                        }

                        <Transformer
                            anchorSize={18}
                            rotateEnabled={false}
                            ignoreStroke='false'
                            draggable={false}
                            ref={this.transformer}
                        />

                        {this.props.showGrid ? this.gridLines() : null}
                        {this.props.showBorders && this.state.width > this.state.height ? ((
                                <>
                                    <Rect
                                        fill={"rgba(0,0,0,.5)"}
                                        shadowOpacity={1}
                                        x={0}
                                        y={0}
                                        width={this.stageSize}
                                        height={Math.abs(this.state.width - this.state.height) / 2}
                                    />
                                    <Rect
                                        fill={"rgba(0,0,0,.5)"}
                                        shadowOpacity={1}
                                        x={0}
                                        y={this.stageSize - Math.abs(this.state.width - this.state.height) / 2}
                                        width={this.stageSize}
                                        height={Math.abs(this.state.width - this.state.height)}
                                    />
                                </>
                            )
                        ) : null}
                    </Layer>
                </Stage>

                <Stage
                    style={this.props.isCustomMaskEditing?.status ? {} : {pointerEvents: "none"}}
                    onTouchStart={this.handleOnMouseUp}
                    onMouseUp={this.handleOnMouseUp}
                    ref={this.stageCustomMask}
                    className={clsx(stl['canvas-guideline'], stl['canvas-custom-mask'], this.props.canvasScale === 100 ? "" : stl['hide-custom-mask'])}
                    scaleX={this.props.stage?.attrs?.scaleX}
                    scaleY={this.props.stage?.attrs?.scaleY}
                    width={this.props.stage?.width() || 0}
                    height={this.props.stage?.height() || 0}
                    onTransformEnd={this.saveCustomMask}
                    onDragEnd={this.saveCustomMask}
                    onClick={(e) => {
                        if (this.props.isMaskDrawMode) {
                            this.addPointToCustomShape(e)
                        }
                    }}
                >

                    <Layer ref={this.layerCustomMask}>
                        {this.props.isCustomMaskEditing?.status || ((points !== undefined && points >= 4) || this.getCanvasMaskElements()?.length > 1) ?
                            <Rect
                                fill={hideCustomMask ? '' : 'black'}
                                x={0}
                                name={"customMaskBackground"}
                                y={0}
                                scaleX={this.stageCustomMask.current?.scaleX}
                                scaleY={this.stageCustomMask.current?.scaleY}
                                width={this.stageCustomMask.current?.width() || 0}
                                height={this.stageCustomMask.current?.height() || 0}
                            /> : null}

                        {this.getCanvasMaskElements()}
                        {this.props.isCustomMaskEditing?.status && !this.props.isMaskDrawMode ?
                            <Transformer
                                anchorSize={18}
                                rotateEnabled={true}
                                ignoreStroke='false'
                                draggable={true}
                                ref={this.transformerCustomMask}
                            /> : null}
                    </Layer>
                </Stage>
            </>
        );
    }
}

export default CanvasAdditionalLayers;


