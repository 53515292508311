import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

import ImgUploader from './ToolsViews/ImgUploader';
import Text from "./ToolsViews/Text";
import Shapes from "./ToolsViews/Shapes";
import ElemenstList from "./ToolsViews/ElementsList";
import Emoji from "./ToolsViews/Emoji";
import Backgrounds from "./ToolsViews/Backgrounds";
import Backgrounds2 from "./ToolsViews/Backgrounds3";

import expand_more from '../../assets/images/expand-more.png';
import emoji_img from '../../assets/images/icons_designer_02.png';
import shape_icon from '../../assets/images/icons_designer_04.png';
import layers_icon from '../../assets/images/icons_designer_03.png';
import new_img_icon from "../../assets/images/icons_designer_01.png";

// import emoji_img_hover from '../../assets/images/Frame157Emoji.svg';
// import shape_icon_hover from '../../assets/images/Frame185Shape.svg';
// import layers_icon_hover from '../../assets/images/Frame186Layer.svg';
import change_img_icon_hover from "../../assets/images/Frame144changeImg.svg";
// import new_img_icon_hover from "../../assets/images/Frame208Img.svg";

import stl from "./Tools.module.scss"
import clsx from "clsx";
import Translate from "react-translate-component";

import { DESKTOP_WIDTH } from "../../config";
import Switch from "react-switch";
import ShapesCustomMasks from "./ToolsViews/ShapesCustomMasks";
import change_img_icon from "../../assets/images/Frame 144.svg";

// import ImgUploaderCommon from './ToolsViews/ImgUploaderCommon';
import { Button } from "shards-react";
import TranslateForAlert from "../../helpes/translateForAlert";

var queryString = require('querystring');
const params = queryString.parse(window.location.search);
let isfirebox = false;

if (params.magento_url !== undefined && params.magento_url.includes('firebox') && queryString.parse(window.location.search).lang === 'en') {
    isfirebox = true;
}


class Tools extends Component {
    state = {
        toolsMoreShow: false,
        MehrBildSubMenu: false
    }

    showOnMobileToggle() {
        this.props.onSelectElement('');

        this.props.toolsMenuVisibleToggle({ mode: !this.props.ifToolsMenuVisible });

    }

    handleSubmenuAllowed() {
        this.props.userAllowedTools({
            [this.activeTool()]: !this.props[this.activeTool()]
        });
    }

    handlePropertyChange(e, status) {
        this.props.setUserAllowedProperties({
            [e]: status
        });
    }

    activeTool() {
        if (this.props.showTextForm) {
            return 'toolTextAllowed'
        } else if (this.props.showImageUploadForm) {
            return 'toolImagesAllowed'
        } else if (this.props.showEmojiForm) {
            return 'toolEmojiAllowed'
        } else if (this.props.showShapesForm) {
            return 'toolShapesAllowed'
        }
    }

    componentWillUnmount() {
        document.querySelectorAll("body")[0].style.overflow = 'auto'
    }

    componentDidMount() {
        // console.log("this.props.stepname",this.props?.pages[this.props.activePage]?.multiface_elements_admin_only);
        // Check if desktop view then trigger click function to open first sub menu
        if (this.props?.pages[this.props.activePage]?.commonEditorForAll === 0 && this.props?.pages[this.props.activePage]?.multiface_elements_admin_only === 0) {
            setTimeout(() => {
                document.querySelector('.OpenFirstMenuDefault').click();
            }, 100);
        }
        const { toolTextAllowed, toolImagesAllowed, toolEmojiAllowed, toolShapesAllowed } = this.props.pages[0]

        this.props.userAllowedTools({
            'toolTextAllowed': toolTextAllowed ?? true,
            'toolImagesAllowed': toolImagesAllowed ?? true,
            'toolEmojiAllowed': toolEmojiAllowed ?? true,
            'toolShapesAllowed': toolShapesAllowed ?? true,
        });

        const {
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextShowAllowed,
            propertyTextFontAllowed
        } = this.props.pages[this.props?.activePage ?? 0]

        this.props.setUserAllowedProperties({
            'propertyTextStrokeAllowed': propertyTextStrokeAllowed ?? true,
            'propertyTextStrokeColorAllowed': propertyTextStrokeColorAllowed ?? true,
            'propertyTextShadowAllowed': propertyTextShadowAllowed ?? true,
            'propertyTextShadowColorAllowed': propertyTextShadowColorAllowed ?? true,
            'propertyTextColorAllowed': propertyTextColorAllowed ?? true,
            'propertyTextShowAllowed': propertyTextShowAllowed ?? true,
            'propertyTextFontAllowed': propertyTextFontAllowed ?? true,
        });

        //For new straightline editor start...
        this.newEditorStepProcess();
        //For new straightline editor end...
    }

    ifShowAllItems() {
        return this.props.ifToolsMenuVisible && !this.props.showEmojiForm && !this.props.showShapesForm && !this.props.showActiveElementsList && !this.props.showImageUploadForm && !this.props.showTextForm
    }

    checkForNotChangedImages() {
        let areAllImagesChanged = this.props.pages[0].canvasElements.find((i) => {
            console.log(i)
            return i?.type === 'image' && i?.unselectable === false && i?.isThisElemEditedByUser === false && i.name !== "Background2" && i.name !== "Background"
        })
        if (areAllImagesChanged) {
            if (params.magento_url !== undefined && params.magento_url.includes('firebox') && queryString.parse(window.location.search).lang === 'en') {
                TranslateForAlert("Oops, didn't you forget something for firebox?")
            } else {
                TranslateForAlert("Oops, didn't you forget something?")
            }

            this.props.onSelectElement(areAllImagesChanged.name);
            this.props.onShowUploadForm();
        } else {
            setTimeout(() => {
                this.props.showOrderPreviewPopup()
            }, 50)
        }
    }

    renderToolsBtn = (backgrounds, colors, pageBackgrounds) => {

        let imageExisted = this.props.pages[this.props.activePage].canvasElements.find((r) => {
            return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
        });

        let commonActiveclsEmoji = "";
        let commonActiveclsShapes = "";
        let commonActiveclsElementsList = "";
        if (this.props.showEmojiForm) {
            commonActiveclsEmoji = 'commonActiveclsEmojiCls';
        }
        if (this.props.showShapesForm) {
            commonActiveclsShapes = 'commonActiveclsShapesCls';
        }
        if (this.props.showActiveElementsList) {
            commonActiveclsElementsList = 'commonActiveclsElementsListCls';
        }

        let mehrimgitemactive1 = "";
        let mehrimgitemactive2 = "";
        let mehrimgitemactive3 = "";
        if (this.props.imageuploadvar === "trueImage") {
            mehrimgitemactive1 = 'upload_cstm--non-active';
            mehrimgitemactive2 = 'upload_cstm--active';
        } else {
            mehrimgitemactive1 = '';
            mehrimgitemactive2 = '';
        }

        // Add class to disable the active mehr btn
        if (this.state.MehrBildSubMenu && this.props.showTextForm) {
            mehrimgitemactive3 = "text_active"
        } else {
            mehrimgitemactive3 = ""
        }

        return (

            <>
                {this.props?.pages[this.props.activePage]?.commonEditorForAll === 0 ?
                    <>
                        <div className="bottom_menu_btn_wrapper">
                            <div className="bottom_menu_btn_layer1">
                                {!this.props.isCustomMaskEditing?.status && (/*this.props.toolImagesAllowed  ||*/ imageExisted || this.props.mode === "ADMIN") ? (
                                    <button
                                        className={clsx(stl.tools__item, this.props.showImageUploadForm && !this.state.MehrBildSubMenu && stl["tools__item--active"]) + ' ' + mehrimgitemactive1 + ' OpenFirstMenuDefault'}
                                        onClick={() => {
                                            if (this.props.showImageUploadForm) {
                                                // this.props.hideToolsMenu()
                                                this.props.MenuBtnEnableSubmenu({ enable_submenu_var: true });
                                            } else {
                                                let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                                    return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                                });
                                                if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                                this.props.onShowUploadForm()
                                            }
                                            // this.props.showImageChangePopup()

                                            this.setState({
                                                toolsMoreShow: false,
                                                MehrBildSubMenu: false
                                            });
                                            this.props.MehrImageUpload({ imageuploadvar: "" });
                                        }}>

                                        <span className={stl.tools__itemText}>
                                            {isfirebox ? 'Edit Image' :
                                                <Translate content={"image"} />
                                            }
                                        </span>
                                    </button>
                                ) : null}

                                {((colors.length > 0 || pageBackgrounds.length > 0) && !this.props.isCustomMaskEditing?.status) ?
                                    <button
                                        className={clsx(stl.tools__item, this.props.showBackgroundsForm && stl["tools__item--active"]) + " OpenFirstMenuDefault"}
                                        onClick={() => {
                                            if (this.props.showBackgroundsForm) {
                                                // this.props.hideToolsMenu()
                                            } else {
                                                this.props.onShowBackgroundsForm()
                                            }
                                            this.setState({
                                                toolsMoreShow: false,
                                                MehrBildSubMenu: false
                                            });
                                            this.props.MehrImageUpload({ imageuploadvar: "" });
                                        }}>

                                        <span className={stl.tools__itemText}>
                                            {isfirebox ? 'Edit Background' :
                                                <Translate content={"BackgroundsTools"} />
                                            }
                                        </span>
                                    </button>
                                    : null}

                                {((backgrounds.length > 1 && !this.props.isCustomMaskEditing?.status && this.props.mode !== 'ADMIN') || (backgrounds.length > 0 && !this.props.isCustomMaskEditing?.status && this.props.mode === 'ADMIN') ?
                                    <button
                                        className={clsx(stl.tools__item, this.props.showDesignsForm && stl["tools__item--active"]) + " OpenFirstMenuDefault"}
                                        onClick={() => {
                                            if (this.props.showDesignsForm) {
                                                // this.props.hideToolsMenu()
                                            } else {
                                                this.props.onShowDesignsForm()
                                            }
                                            this.setState({
                                                toolsMoreShow: false,
                                                MehrBildSubMenu: false
                                            });
                                            this.props.MehrImageUpload({ imageuploadvar: "" });
                                        }}>
                                        <span className={stl.tools__itemText}>
                                            {isfirebox ? 'Edit Design' :
                                                <Translate content={"Design_Btn_Text"} />
                                            }
                                        </span>
                                    </button>
                                    : null)}

                                {((this.props.toolTextAllowed) || this.props.mode === 'ADMIN') && !this.props.isCustomMaskEditing?.status ? (
                                    <button
                                        className={clsx(stl.tools__item, this.props.showTextForm && stl["tools__item--active"]) + " OpenFirstMenuDefault " + this.props.showTextForm}
                                        onClick={() => {
                                            if (this.props.showTextForm) {
                                                // this.props.hideToolsMenu()
                                                this.props.MenuBtnEnableSubmenu({ enable_submenu_var: true });
                                            } else {
                                                let isText = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                                    return r.type === "text" && r.changeable
                                                });
                                                if (isText && this.props.activeElement?.attrs.type !== "text") this.props.onSelectElement(isText.name);
                                                this.props.onShowTextForm()
                                            }
                                            this.setState({
                                                toolsMoreShow: false,
                                                MehrBildSubMenu: false
                                            });
                                            this.props.MehrImageUpload({ imageuploadvar: "" });
                                            setTimeout(() => {
                                                if (document.getElementById("elementtxtid_0")) {
                                                    const textareaFirst = document.getElementById("elementtxtid_0");
                                                    const endMove = textareaFirst.value.length;
                                                    textareaFirst.setSelectionRange(endMove, endMove);
                                                    textareaFirst.focus();
                                                }
                                            }, 100);
                                        }}>
                                        <span className={stl.tools__itemText}>
                                            {isfirebox ? 'Edit Text' :
                                                <Translate content={"Text"} />
                                            }
                                        </span>
                                    </button>
                                ) : null}

                                {!this.props.isCustomMaskEditing?.status ? (
                                    <>
                                        <button
                                            className={clsx(stl.tools__item, this.state.MehrBildSubMenu && stl["tools__item--active"] || this.state.toolsMoreShow && !this.props.showTextForm && !this.props.showImageUploadForm && stl["tools__item--active"] || this.props.showEmojiForm && !this.props.showTextForm && !this.props.showImageUploadForm && stl["tools__item--active"] || this.props.showShapesForm && !this.props.showTextForm && !this.props.showImageUploadForm && stl["tools__item--active"] || this.props.showActiveElementsList && !this.props.showTextForm && !this.props.showImageUploadForm && stl["tools__item--active"]) + ' ' + mehrimgitemactive2 + ' BackBtnMobileMehr ' + mehrimgitemactive3}
                                            onClick={() => {
                                                if (!this.state.toolsMoreShow) {
                                                    this.props.hideToolsMenu()
                                                    this.setState({
                                                        toolsMoreShow: true,
                                                        MehrBildSubMenu: false
                                                    });
                                                } else {
                                                    this.setState({
                                                        toolsMoreShow: true,
                                                        MehrBildSubMenu: false
                                                    });
                                                }
                                                // this.props.hideToolsMenu()
                                                // this.setState({
                                                //     toolsMoreShow: !this.state.toolsMoreShow,
                                                //     MehrBildSubMenu:false
                                                // });
                                                this.props.MehrImageUpload({ imageuploadvar: "" });

                                            }}>
                                            <span className={stl.tools__itemText}>
                                                <Translate content={"More"} />
                                            </span>
                                        </button>
                                        <img
                                            className={clsx(stl.tools__itemTextExpand, this.state.toolsMoreShow && stl['tools__itemTextExpand--active'])}
                                            src={expand_more} alt=""
                                            onClick={() => {
                                                this.props.hideToolsMenu()
                                                this.setState({
                                                    toolsMoreShow: !this.state.toolsMoreShow
                                                });
                                            }} />
                                    </>

                                ) : null}
                            </div>
                            {/* <div className="bottom_menu_btn_layer2">
                            {this.props.pages[this.props.activePage]?.renderers.length === 0 ? null :
                                <button onClick={this.props.showMobilePreview} className={'secondary-button'}>
                                    <Translate content={"Preview"}/>
                                </button>
                            }
                            
                            {this.props.statusText?.length > 0 ?
                                <div className="canvas-status" id="canvas-status">
                                    {this.props.statusText}
                                </div>
                                :
                                (this.props.mode === 'PRODUCTION' && parseInt(params.robot) !== 1) ?
                                    <Button className="cst-green generateprintfile" 
                                            onClick={() => {
                                                this.props.resetCanvasZoom()

                                                this.checkForNotChangedImages()

                                            }}>
                                        <Translate content={"done"}/>
                                    </Button>
                                : null }
                        </div> */}
                        </div>

                    </>
                    :
                    <>
                        {this.props.stepname === "mehrCommon" ?
                            <div className={clsx(this.state.toolsMoreShow, 'meharCommon-submenu-cls straightline_meharMenu')}>
                                {!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed || imageExisted) ? (
                                    // <button
                                    //     className={clsx(stl.tools__item, this.props.showImageUploadForm && stl["tools__item--active"])}
                                    //     onClick={() => {
                                    //         if (this.props.showImageUploadForm) {
                                    //             this.props.hideToolsMenu()
                                    //         } else {
                                    //             let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                    //                 return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                    //             });
                                    //             if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                    //             this.props.onShowUploadForm()
                                    //         }
                                    //         // this.props.showImageChangePopup()

                                    //         this.setState({
                                    //             toolsMoreShow: false
                                    //         });
                                    //     }}>

                                    //     <span className={stl.tools__itemText}>
                                    //                 <Translate content={"image"}/>
                                    //     </span>
                                    // </button>
                                    <>
                                        {/* <ImgUploaderCommon mode={'123'} uploaderUrl={this.props.uploaderUrl}/> */}
                                        <button
                                            className={clsx(stl.tools__item, this.props.showEmojiForm)}
                                            onClick={() => {

                                                this.props.pages[this.props.activePage].commonChangeCurrentImage = 0;
                                                let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                                    return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                                });
                                                if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                                this.props.showImageChangePopup();
                                                // this.setState({
                                                //     newImgUploader: !this.state.newImgUploader
                                                // })
                                                document.body.classList.add('InnnerPopupOpen');

                                            }}>
                                            <span className={stl.tools__itemText}>
                                                <img className={'emoji-svg'} src={new_img_icon} alt="" />
                                                <img className={'emoji-svg-hover'} src={new_img_icon} alt="" />
                                                <span><Translate content={"Bild_hinzufüge_lang"} /></span>
                                                {/* <Translate content={"Add_Image"}/> */}
                                            </span>
                                        </button>
                                    </>
                                ) : null}
                                {this.props.toolEmojiAllowed && !this.props.isCustomMaskEditing?.status ? (
                                    <button
                                        className={clsx(stl.tools__item, this.props.showEmojiForm, commonActiveclsEmoji) + " BackBtnMobileMehr"}
                                        onClick={() => {
                                            this.setState({
                                                toolsMoreShow: false
                                            });
                                            if (this.props.showEmojiForm) {
                                                this.props.hideToolsMenu()
                                            } else {
                                                this.props.onShowEmojiForm()
                                            }
                                        }}>

                                        <span className={stl.tools__itemText}>
                                            <img className={'emoji-svg'} src={emoji_img} alt="" />
                                            <img className={'emoji-svg-hover'} src={emoji_img} alt="" />
                                            <Translate content={"emoji"} />
                                        </span>
                                    </button>
                                ) : null}

                                {(this.props.toolShapesAllowed) || this.props.mode === 'ADMIN' ? (
                                    <button
                                        className={clsx(stl.tools__item, this.props.showShapesForm, commonActiveclsShapes)}
                                        onClick={() => {
                                            this.setState({
                                                toolsMoreShow: false
                                            });
                                            if (this.props.showShapesForm) {
                                                this.props.hideToolsMenu()
                                            } else {
                                                this.props.onShowShapesForm()
                                            }
                                        }}>
                                        <span className={stl.tools__itemText}>
                                            <img className={'emoji-svg'} src={shape_icon} alt="" />
                                            <img className={'emoji-svg-hover'} src={shape_icon} alt="" />
                                            <Translate content={"shape"} />
                                        </span>
                                    </button>
                                ) : null}

                                {!this.props.isCustomMaskEditing?.status ? <button
                                    className={clsx(stl.tools__item, this.props.showActiveElementsList, commonActiveclsElementsList)}
                                    onClick={() => {
                                        this.setState({
                                            toolsMoreShow: false
                                        });
                                        if (this.props.showActiveElementsList) {
                                            this.props.hideToolsMenu()
                                        } else {
                                            this.props.onShowElementsList()
                                        }
                                    }}>
                                    <span className={stl.tools__itemText}>
                                        <img className={'emoji-svg'} src={layers_icon} alt="" />
                                        <img className={'emoji-svg-hover'} src={layers_icon} alt="" />
                                        <Translate content={"layers"} />
                                    </span>
                                </button> : null}

                                {!this.props.isCustomMaskEditing?.status && imageExisted ? (
                                    <>
                                        <button
                                            className={clsx(stl.tools__item, this.props.showEmojiForm)}
                                            onClick={() => {

                                                this.props.clickedOnImageCrop({ isClickedOnImageCrop: true });


                                                this.props.pages[this.props.activePage].commonChangeCurrentImage = 1;
                                                let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                                    return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                                });
                                                if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                                this.props.holdUrlData({ HoldURl: Array('', this.props.activeElement?.attrs.image.currentSrc, this.props.activeElement?.attrs.width, this.props.activeElement?.attrs.height, 0) });
                                                console.log("HoldURl:::---->", this.props.activeElement?.attrs.image.currentSrc, this.props.activeElement?.attrs.width, this.props.activeElement?.attrs.height, 0)
                                                // console.log("Tools.js Props H", this.props.activeElement?.attrs.height)
                                                // console.log("Tools.js Props H", this.props.activeElement?.attrs.width)

                                                this.props.onEditElement({
                                                    historyMode: false,
                                                    newImgData: {
                                                        width: /*800,*/ this.props.activeElement?.attrs.naturalWidth,
                                                        height:/*300,*/ this.props.activeElement?.attrs.naturalHeight,
                                                    },
                                                    scaleX: 1,
                                                    scaleY: 1,
                                                    activePage: this.props.activePage,
                                                });

                                                setTimeout(() => {
                                                    this.props.showFaceCropPopup();
                                                }, 100);

                                                //this.props.showImageChangePopup();
                                                //this.props.onShowUploadForm();
                                            }}>
                                            <span className={stl.tools__itemText}>
                                                <img className={'emoji-svg'} src={change_img_icon} alt="" />
                                                <img className={'emoji-svg-hover'} src={change_img_icon_hover} alt="" />
                                                <Translate content={"Change Image"} />
                                            </span>
                                        </button>
                                    </>
                                ) : null}
                            </div>
                            :
                            ""
                        }
                    </>
                }
            </>
        )
    }

    newEditorStepProcess() { //console.log('---*****--->',this.props.stepname);

        if (this.props?.pages[this.props.activePage]?.commonEditorForAll === 1) {
            this.props.hideToolsMenu();
            this.props.onSelectElement("");

            //For background BGdesign start...
            if (this.props.stepname === "bgdesign") {
                console.log("this.props.stepname == bgdesign", this.props)

                if (this.props.ShowDesignsForm) {
                    this.props.hideToolsMenu()
                } else {
                    this.props.onShowDesignsForm()
                }
                this.setState({
                    toolsMoreShow: false
                });
            }
            //For background BGdesign end...
            //For background color start...

            if (this.props.stepname === "bgcolor") {

                if (this.props.showBackgroundsForm) {
                    this.props.hideToolsMenu()
                } else {
                    this.props.onShowBackgroundsForm()
                }
                this.setState({
                    toolsMoreShow: false
                });
            }
            //For background color end...

            //For Text start...
            if (this.props.stepname === "textspopupCommon") {
                console.log("this.props.stepname == textspopupCommon", this.props)

                this.props.onShowTextForm()
                //this.props.hideToolsMenu()
                this.setState({
                    toolsMoreShow: false
                });
                setTimeout(() => {
                    if (document.getElementById("elementtxtid_0")) {
                        const textareaFirst = document.getElementById("elementtxtid_0");
                        const endMove = textareaFirst.value.length;
                        textareaFirst.setSelectionRange(endMove, endMove);
                        textareaFirst.focus();
                    }
                }, 100);

                // Added condition to make first element selected on workspace(RS-2409)
                if (this.props.pages[0].commonEditorForAll === 1 && window.innerWidth <= 616) {
                    setTimeout(() => {
                        let canvasElements = this.props.pages[0].canvasElements;
                        canvasElements = canvasElements.filter(i => {
                            return i.deletable && i.type === 'text'
                        });
                        this.props.onSelectElement(canvasElements[0].name);
                    }, 0);
                }
                // End
            }
            //For Text end...
            //For Mehr start...
            if (this.props.stepname === "mehrCommon") {
                console.log("this.props.stepname == mehrCommon111", this.props)

                this.props.hideToolsMenu()
                this.setState({
                    toolsMoreShow: true
                });
                // this.setState({
                //     toolsMoreShow: !this.state.toolsMoreShow
                // });
            }
            //For Mehr end...
        }
    }
    continueForNextSTep(getThis) {
        let IsTextPopupCommon = false;
        console.log("this.props.stepname:", this.props.stepname)
        this.props.hideToolsMenu();
        this.props.onSelectElement("");
        if (this.props.stepname === "bgcolor") {
            this.props.pages[0].canvasElements.forEach((i) => {
                if (i.type === "text" && i.changeable) {
                    IsTextPopupCommon = true
                }
            });
            if (IsTextPopupCommon == true) {
                this.props.CommonEditorStep({ stepname: 'textspopupCommon' });
                setTimeout(() => {
                    this.newEditorStepProcess();
                }, 100);
            } else {
                setTimeout(() => {
                    this.props.showOrderPreviewPopup()
                }, 100);
            }
        } else if (this.props.stepname == 'textspopupCommon') {
            if (!this.props.isCustomMaskEditing?.status) {
                this.props.CommonEditorStep({ stepname: 'mehrCommon' });
                setTimeout(() => {
                    this.newEditorStepProcess();
                }, 100);
            } else {
                setTimeout(() => {
                    this.props.showOrderPreviewPopup()
                }, 100);
            }
        }
        else if (this.props.stepname === 'mehrCommon') {
            setTimeout(() => {
                this.props.showOrderPreviewPopup()
            }, 100);
        }
        else if (this.props.stepname === "bgdesign") {
            this.props.pages[0].canvasElements.forEach((i) => {
                if (i.type === "text" && i.changeable) {
                    IsTextPopupCommon = true
                }
            });
            if (IsTextPopupCommon === true) {
                // ***********this code is responsible for skipping background color step if it is not allowed from by admin
                let activePage = this.props.pages[this.props.activePage];
                let colors = [];
                let backgrounds = [];
                let bgdesign = [];

                for (let key in activePage.backgrounds) {
                    let item = activePage.backgrounds[key]
                    if (activePage.backgrounds[key].type === 'color') {
                        colors.push(item.color_code);
                    }
                    if (activePage.backgrounds[key].type === 'image') {
                        backgrounds.push(item);
                    }
                }
                for (let key in activePage.designs) {
                    bgdesign.push(activePage.designs[key].type);
                }

                if (this.props.pages[this.props.activePage].multiface_elements_admin_only === 0 && (colors.length > 0 || backgrounds.length > 0)) {
                    this.props.onShowBackgroundsForm()
                }
                // ***********
                // console.log("this.props.showBackgroundsForm: ", this.props.showBackgroundsForm , "colors.length:", colors.length,"backgrounds.length:", backgrounds.length )
                if ((this.props.showBackgroundsForm || this.props.pages[this.props.activePage].multiface_elements_admin_only === 0) && (colors.length > 0 || backgrounds.length > 0)) {
                    this.props.CommonEditorStep({ stepname: 'bgcolor' });
                } else {
                    this.props.CommonEditorStep({ stepname: 'textspopupCommon' });
                }

                setTimeout(() => {
                    this.newEditorStepProcess();
                }, 100);
            } else {
                setTimeout(() => {
                    this.props.showOrderPreviewPopup()
                }, 100);
            }
        }
    }

    render() {
        // Used this variable while skip the step Ebenen in the straightlined editor
        let imageExisted_check = this.props.pages[this.props.activePage].canvasElements.find((r) => {
            return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
        });

        let activePage = this.props.pages[this.props.activePage];
        // Load page
        let backgrounds = [];
        let pageBackgrounds = activePage.designs;
        for (let key in pageBackgrounds) {
            if (!pageBackgrounds.hasOwnProperty(key)) continue;
            backgrounds.push(key);
        }

        let colors = [];
        for (let key in activePage.backgrounds) {
            let item = activePage.backgrounds[key]
            if (activePage.backgrounds[key].type === 'color') {
                colors.push(item.color_code);
            }
        }
        let bgdesigns = [];
        for (let key in activePage.designs) {
            let item = activePage.designs[key]
            bgdesigns.push(item.name);

        }

        // let toolsCount = 2;
        // if (this.props.pages[0].toolTextAllowed || this.props.pages[0].toolTextAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolImagesAllowed || this.props.pages[0].toolImagesAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolEmojiAllowed || this.props.pages[0].toolEmojiAllowed === undefined) toolsCount = toolsCount + 1
        // if (this.props.pages[0].toolShapesAllowed || this.props.pages[0].toolShapesAllowed === undefined) toolsCount = toolsCount + 1
        // if (backgrounds.length > 0) toolsCount = toolsCount + 1
        // if (colors.length > 0) toolsCount = toolsCount + 1
        // if (this.props.mode === 'ADMIN') toolsCount = 6 + (backgrounds.length > 0 ? 1 : 0)


        let subMenuActiveType;
        if (this.props.showTextForm) {
            subMenuActiveType = "texts"
        } else if (this.props.showImageUploadForm) {
            subMenuActiveType = "images"
        } else if (this.props.showEmojiForm) {
            subMenuActiveType = "emoji"
        } else if (this.props.showShapesForm) {
            subMenuActiveType = "shapes"
        }

        let BackItem = [];
        let classneweditorset = "";
        if (this.props?.pages[this.props.activePage]?.commonEditorForAll === 1) {
            classneweditorset = 'neweditorWrapper'
            let IsTextPopupCommon = false;
            const newEditorStepTextArray = [];
            const newEditorBackTextArray = [];
            this.props.pages[0].canvasElements.forEach((i) => {
                if (i.type === "text" && i.changeable) {
                    IsTextPopupCommon = true;
                }
            });

            if (backgrounds.length > 0) {
                newEditorStepTextArray.push('bgdesign');
                newEditorBackTextArray.push('design');
                newEditorBackTextArray.push(<Translate content={"Hintergundfarbe_anpassen_lang"} />);

            }
            // **
            // if(bgdesigns.length>0){
            //     // newEditorStepTextArray.push('bgdesign');
            //     // newEditorBackTextArray.push('design');
            //     newEditorBackTextArray.push(<Translate content={"Hintergundfarbe_anpassen_lang"}/>); 
            // }
            //** 
            if (colors.length > 0) {
                newEditorStepTextArray.push('bgcolor');
                newEditorBackTextArray.push(<Translate content={"Hintergundfarbe_anpassen_lang"} />);
            }

            if (IsTextPopupCommon === true) {
                newEditorStepTextArray.push('textspopupCommon');
                newEditorBackTextArray.push(<Translate content={"texts_lang"} />);
            }
            if (!this.props.isCustomMaskEditing?.status) {
                newEditorStepTextArray.push('mehrCommon');
                newEditorBackTextArray.push(<Translate content={"Mehr_anpassen_lang"} />);
            }
            //console.log(backgrounds.length,'====',colors.length)
            // console.log(newEditorStepTextArray,'===',newEditorBackTextArray)
            newEditorStepTextArray.map((step, i) => {
                if (this.props.stepname === step && i > 0) {
                    BackItem.push([newEditorStepTextArray[i - 1], newEditorBackTextArray[i - 1]]);
                    return false;
                }
            });
            //console.log('=====>',BackItem)
        }

        let mehrDivClsName = "";
        if (this.props?.pages[this.props.activePage]?.commonEditorForAll === 1) {
            if (this.props.showActiveElementsList || this.props.showShapesForm || this.props.showEmojiForm) {
                mehrDivClsName = 'mehrDivCls mehrDivClsEmojiETC';
            } else {
                mehrDivClsName = 'mehrDivCls';
            }
        }
        return (
            <>
                <div ref={"tools"} className={clsx(stl.tools, stl["tools--active"], classneweditorset)}>
                    <div
                        className={clsx(stl.tools__content, this.props.ifToolsMenuVisible ? stl["tools__content--reverse"] : stl["tools__content--color"]) + " cstm_main_menu_tabs"} id={this.props?.pages[this.props.activePage]?.commonEditorForAll === 1 ? "StraightlinedEditorActiveSubMenu" : null}>
                        {(this.props.ifToolsMenuVisible ? (
                            // <div className="tools--active--menu tools--active--menu--elements">
                            <div
                                className={clsx(stl["tools--menu--elements"], backgrounds.length > 0 && stl["tools--menu--elements-bg"])}>
                                {this.renderToolsBtn(backgrounds, colors, pageBackgrounds)}
                            </div>
                        ) : (
                            this.renderToolsBtn(backgrounds, colors, pageBackgrounds)
                        ))}
                    </div>

                    {/* Add condition for multiple sub menu shows once */}
                    {
                        (this.props.showTextForm && this.state.toolsMoreShow) || (this.props.showImageUploadForm && this.state.toolsMoreShow) ? null :
                            <>
                                <div className={clsx(stl.tools__more, this.state.toolsMoreShow && stl.tools__more_active) + " Mehrbtnwrapper"}>
                                    {/* {console.log("this.props.toolImagesAllowed:::", this.props.toolImagesAllowed)} */}

                                    {/* To fix straightline editor issue RS-2309(4) */}
                                    {this.props?.pages[this.props.activePage]?.commonEditorForAll === 1 ? null :
                                        <>

                                            {!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed || this.props.mode === 'ADMIN') ? <button //old: there was && condition befrore braces(
                                                className={clsx(stl.tools__item, this.props.showActiveElementsList && stl["tools__item--active"])}

                                                onClick={() => {
                                                    this.props.MehrImageUpload({ imageuploadvar: "trueImage" });
                                                    if (this.props.showImageUploadForm) {
                                                        this.props.hideToolsMenu()
                                                    } else {
                                                        let isImage = this.props.pages[this.props.activePage].canvasElements.find((r) => {
                                                            return r.type === "image" && r.name !== "Background2" && r.name !== "Background" && r.unselectable !== true
                                                        });
                                                        if (isImage && this.props.activeElement?.attrs.type !== "image") this.props.onSelectElement(isImage.name);
                                                        this.props.onShowUploadForm()
                                                    }
                                                    this.setState({
                                                        toolsMoreShow: false,
                                                        MehrBildSubMenu: true
                                                    });
                                                    // this.setState({
                                                    //     newImgUploader: !this.state.newImgUploader
                                                    // })
                                                }}>
                                                <span className={stl.tools__itemText}>
                                                    <img src={new_img_icon} alt="" />
                                                    {isfirebox ? 'Additional Photo' :
                                                        <Translate content={"Add_Image"} />
                                                    }
                                                </span>
                                            </button> : null
                                            }

                                            {((this.props.toolEmojiAllowed) || this.props.mode === 'ADMIN') && !this.props.isCustomMaskEditing?.status ? (
                                                <button
                                                    className={clsx(stl.tools__item, this.props.showEmojiForm && stl["tools__item--active"])}
                                                    onClick={() => {
                                                        this.setState({
                                                            toolsMoreShow: false
                                                        });
                                                        this.props.onShowEmojiForm()
                                                    }}>

                                                    <span className={stl.tools__itemText}>
                                                        <img src={emoji_img} alt="" />
                                                        <Translate content={"emoji"} />
                                                    </span>
                                                </button>
                                            ) : null}

                                            {(this.props.toolShapesAllowed) || this.props.mode === 'ADMIN' ? (
                                                <button
                                                    className={clsx(stl.tools__item, this.props.showShapesForm && stl["tools__item--active"])}
                                                    onClick={() => {
                                                        this.setState({
                                                            toolsMoreShow: false
                                                        });
                                                        this.props.onShowShapesForm()
                                                    }}>
                                                    <span className={stl.tools__itemText}>
                                                        <img src={shape_icon} alt="" />
                                                        <Translate content={"shape"} />
                                                    </span>
                                                </button>
                                            ) : null}

                                            {!this.props.isCustomMaskEditing?.status ? <button
                                                className={clsx(stl.tools__item, this.props.showActiveElementsList && stl["tools__item--active"])}
                                                onClick={() => {
                                                    this.setState({
                                                        toolsMoreShow: false
                                                    });
                                                    this.props.onShowElementsList()
                                                }}>
                                                <span className={stl.tools__itemText}>
                                                    <img src={layers_icon} alt="" />
                                                    <Translate content={"layers"} />
                                                </span>
                                            </button> : null}
                                        </>}
                                </div>
                            </>
                    }

                    <div className={this.state.MehrBildSubMenu ? 'cs-scroll1 neweditorbtnCls MehrImageWrapper' : 'cs-scroll1 neweditorbtnCls'} id={this.props?.pages[this.props.activePage]?.commonEditorForAll === 1 ? "StraightlinedEditorActive" : null}>

                        <div className={mehrDivClsName + "toolsMoreShow " + this.state.toolsMoreShow + " MehrBildSubMenu " + this.state.MehrBildSubMenu + " showTextForm " + this.props.showTextForm + " showImageUploadForm " + this.props.showImageUploadForm}>
                            {
                                (this.props.isShowImageChangePopup && !this.props.showImageUploadForm) || (this.state.MehrBildSubMenu && this.props.showTextForm) ? null :
                                    <ImgUploader mode={'123'} uploaderUrl={this.props.uploaderUrl} />
                            }
                            <ElemenstList EditorType={this.props?.pages[this.props.activePage]?.commonEditorForAll} />
                            <Text />
                            <Shapes EditorType={this.props?.pages[this.props.activePage]?.commonEditorForAll} />
                            <Emoji
                                EditorType={this.props?.pages[this.props.activePage]?.commonEditorForAll} />
                            <ShapesCustomMasks />

                            {!this.props.isCustomMaskEditing?.status ? <>
                                <Backgrounds />
                                <Backgrounds2 />
                            </> : null}


                            {(this.props.showTextForm || this.props.showImageUploadForm || this.props.showEmojiForm || this.props.showShapesForm) && this.props.mode === 'ADMIN' && !this.props.isCustomMaskEditing?.status ?

                                <div className={stl.adminSubmenu}>
                                    <div>
                                        <Switch className={"custom-checkbox"}
                                            checked={this.props[this.activeTool()]}
                                            onChange={this.handleSubmenuAllowed.bind(this)} />
                                        Allowed to show {subMenuActiveType}
                                    </div>

                                    {subMenuActiveType === "texts" ? <div>
                                    <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextShowAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextShowAllowed')} />
                                                Allowed to Show New texts
                                            </div>
                                        </div>
                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextColorAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextColorAllowed')} />
                                                Allowed color change for new texts
                                            </div>
                                        </div>

                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextFontAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextFontAllowed')} />
                                                Allowed font change for new texts
                                            </div>
                                        </div>

                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextStrokeAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextStrokeAllowed')} />
                                                Allowed stroke for new texts
                                            </div>
                                        </div>

                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextStrokeColorAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextStrokeColorAllowed')} />
                                                Allowed stroke color for new texts
                                            </div>
                                        </div>

                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextShadowColorAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextShadowColorAllowed')} />
                                                Allowed shadow color for texts
                                            </div>
                                        </div>
                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyTextShadowAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyTextShadowAllowed')} />
                                                Allowed shadow for texts
                                            </div>
                                        </div>
                                    </div> : null}

                                    {subMenuActiveType === "images" ? <div>
                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyFaceCropSizeAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropSizeAllowed')} />
                                                Allowed face crop size change
                                            </div>
                                        </div>
                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}
                                            >
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyFaceCropZoomAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropZoomAllowed')} />
                                                Allowed face crop zoom change
                                            </div>
                                        </div>
                                        <div className="designer-right-panel-item" style={{ "border": "none" }}>
                                            <div className={"designer-right-panel-item__titleFlex"}>
                                                <Switch className={"custom-checkbox"}
                                                    checked={this.props.propertyFaceCropRotateAllowed}
                                                    onChange={this.handlePropertyChange.bind(this, 'propertyFaceCropRotateAllowed')} />
                                                Allowed face crop rotate change
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                                : null}
                        </div>

                        {this.props?.pages[this.props.activePage]?.commonEditorForAll === 1 && window.innerWidth > 616 ?
                            <div style={{ "textAlign": "right" }} className={"NewEditor-custom-button"}>

                                {BackItem.length > 0 ? <button type="button"
                                    onClick={() => {
                                        this.props.CommonEditorStep({ stepname: BackItem[0][0] });
                                        setTimeout(() => {
                                            this.newEditorStepProcess();
                                        }, 100);
                                    }}
                                    //  className={"btn btn-outline-secondary"}><span>{BackItem[0][1]}</span>
                                    className={"btn btn-outline-secondary"}><span><Translate content={"back_button"} /></span>
                                </button>
                                    : ""}
                                <button type="button"
                                    //  onClick={this.saveDesigner.bind(this)}
                                    //  onClick={() => {this.props.showOrderPreviewPopup()}}
                                    //  onClick={this.continueForNextSTep.bind(this)}
                                    onClick={(!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed || imageExisted_check)) || (this.props.toolEmojiAllowed && !this.props.isCustomMaskEditing?.status) || ((this.props.toolShapesAllowed) || this.props.mode === 'ADMIN') ? this.continueForNextSTep.bind(this) : () => { this.props.showOrderPreviewPopup() }}
                                    className={"custom-secondary btn btn-secondary"}><span><Translate content={"Weiter_lang"} /></span></button>
                            </div>
                            :
                            ""
                        }
                    </div>
                </div>

                {/* Bottom fixed Button for Advance editor */}
                {this.props?.pages[this.props.activePage]?.commonEditorForAll === 0 ?
                    <div className="bottom_menu_btn_layer2">
                        {this.props.pages[this.props.activePage]?.renderers.length === 0 ? null :
                            <button onClick={this.props.showMobilePreview} className={'secondary-button'}>
                                <Translate content={"Preview"} />
                            </button>
                        }

                        {this.props.statusText?.length > 0 ?
                            <div className="canvas-status" id="canvas-status">
                                {this.props.statusText}
                            </div>
                            :
                            (this.props.mode === 'PRODUCTION' && parseInt(params.robot) !== 1) ?
                                <Button className="cst-green generateprintfile"
                                    onClick={() => {
                                        this.props.resetCanvasZoom()

                                        this.checkForNotChangedImages()

                                    }}>
                                    <Translate content={"done"} />
                                </Button>
                                : null}
                    </div>
                    : null}
                {/* Bottom fixed Button for Advance editor End */}


                {/* Bottom fixed Button for Straightlined editor */}
                {this.props?.pages[this.props.activePage]?.commonEditorForAll === 1 && window.innerWidth <= 616 ?
                    <div className={"NewEditor-custom-button NewEditor-custom-button-2"}>

                        {BackItem.length > 0 ? <button type="button"
                            onClick={() => {
                                this.props.CommonEditorStep({ stepname: BackItem[0][0] });
                                setTimeout(() => {
                                    this.newEditorStepProcess();
                                }, 100);
                            }}
                            //  className={"btn btn-outline-secondary"}><span>{BackItem[0][1]}</span>
                            className={"btn btn-outline-secondary"}><span><Translate content={"back_button"} /></span>
                        </button>
                            : ""}
                        <button type="button"
                            //  onClick={this.saveDesigner.bind(this)}
                            //  onClick={() => {this.props.showOrderPreviewPopup()}}
                            //  onClick={this.continueForNextSTep.bind(this)}
                            onClick={(!this.props.isCustomMaskEditing?.status && (this.props.toolImagesAllowed || imageExisted_check)) || (this.props.toolEmojiAllowed && !this.props.isCustomMaskEditing?.status) || ((this.props.toolShapesAllowed) || this.props.mode === 'ADMIN') ? this.continueForNextSTep.bind(this) : () => { this.props.showOrderPreviewPopup() }}
                            className={"custom-secondary btn btn-secondary"}><span><Translate content={"Weiter_lang"} /></span></button>
                    </div>
                    :
                    ""
                }
                {/* Bottom fixed Button for Straightlined editor End */}
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        showTextForm: state.tol.showTextForm,
        showImageUploadForm: state.tol.showImageUploadForm,
        showEmojiForm: state.tol.showEmojiForm,
        showShapesForm: state.tol.showShapesForm,
        mode: state.glb.mode,
        pages: state.glb.pages,
        activePage: state.glb.activePage,
        showBackgroundsForm: state.tol.showBackgroundsForm,
        showDesignsForm: state.tol.showDesignsForm,
        showActiveElementsList: state.tol.showActiveElementsList,
        ifToolsMenuVisible: state.tol.ifToolsMenuVisible,
        stage: state.glb.stage,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        activeElement: state.glb.activeElement,
        isShowImageChangePopup: state.tol.showImageChangePopup,
        toolTextAllowed: state.tol.toolTextAllowed,
        toolEmojiAllowed: state.tol.toolEmojiAllowed,
        toolShapesAllowed: state.tol.toolShapesAllowed,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        propertyTextShowAllowed: state.tol.propertyTextShowAllowed,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        HoldURl: state.glb.HoldURl,
        stepname: state.glb.stepname,
        imageuploadvar: state.glb.imageuploadvar,
        isClickedOnImageCrop: state.glb.isClickedOnImageCrop,
        enable_submenu_var: state.glb.enable_submenu_var,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        onEditTextElement: (data) => {
            dispatch(actionCreators.hidePopup());
            dispatch(actionCreators.editElementAttrs(data));
        },
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
        toolsMenuVisibleToggle: (mode) => {
            dispatch(actionCreators.toolsMenuVisibleToggle(mode));
        },
        userAllowedTools: (data) => {
            dispatch(actionCreators.userAllowedTools(data));
        },
        onShowTextForm: () => {
            dispatch(actionCreators.showTextForm());
        },
        onShowTextPopup: () => dispatch(actionCreators.showTextPopup()),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        onShowEmojiForm: () => {
            dispatch(actionCreators.showEmojiForm());
        },
        onShowBackgroundsForm: () => {
            dispatch(actionCreators.showBackgroundsForm());
        },
        onShowDesignsForm: () => {
            dispatch(actionCreators.showDesignsForm());
        },
        onShowUploadForm: () => {
            if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
            dispatch(actionCreators.showUploadForm());
        },
        hideToolsMenu: () => {
            dispatch(actionCreators.hideToolsMenu());
        },
        onShowElementsList: () => {
            if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
            dispatch(actionCreators.hideToolsMenu());
            dispatch(actionCreators.showElementsList());
        },
        onShowShapesForm: () => {
            dispatch(actionCreators.showShapesForm());
        },
        hidePopup: () => {
            document.querySelectorAll("body")[0].style.overflow = 'auto'
            dispatch(actionCreators.hidePopup())
        },
        setUserAllowedProperties: (data) => {
            dispatch(actionCreators.userAllowedProperties(data));
        },
        showOrderPreviewPopup: () => dispatch(actionCreators.showOrderPreviewPopup()),
        showFaceCropPopup: (data) => dispatch(actionCreators.showFaceCropPopup({ data: data })),
        holdUrlData: (data) => {
            dispatch(actionCreators.holdUrlData(data))
        },
        CommonEditorStep: (data) => {
            dispatch(actionCreators.CommonEditorStep(data));
        },
        MehrImageUpload: (data) => {
            dispatch(actionCreators.MehrImageUpload(data));
        },
        clickedOnImageCrop: (data) => {
            dispatch(actionCreators.clickedOnImageCrop(data));
        },

        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data))
        },

        MenuBtnEnableSubmenu: (data) => {
            dispatch(actionCreators.MenuBtnEnableSubmenu(data))
        },
    };
}
    ;

export default connect(mapStateToProps, mapDispatchToProps)(Tools);
