import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import stl from "./ToolsViews.module.scss";
import rectangle from "../../../assets/images/shape_1.png";
import line from "../../../assets/images/Line-18.png";
import circle from "../../../assets/images/circle.png";
import triangle from "../../../assets/images/triangle.png";

// import colorText_icon from "../../../assets/images/colorFont_icon.svg";
import { DESKTOP_WIDTH } from "../../../config";
import { Button } from "shards-react";
// import minus_img_icon from "../../../assets/images/icons_designer_30_cropped.png";
// import plus_img_icon from "../../../assets/images/icons_designer_31_cropped.png";
import clsx from "clsx";
// import zoom_img_icon from "../../../assets/images/Frame 135.png";

import down_arrow_btn from "../../../assets/images/dropdown.svg";

import CustomScroll from 'react-custom-scroll';
import { Scrollbars } from 'react-custom-scrollbars';
import {isMobile, isTablet } from 'react-device-detect';
import { SketchPicker } from 'react-color';
// import { PhotoshopPicker } from 'react-color';
// import Translate from "react-translate-component";
// import Color from "../../Properties/PropertiesComponents/Color";
import close_btn from "../../../assets/images/iconfinder_Close_6351931 2.png";
import color_picker_icon from "../../../assets/images/icons_designer_32.png";
import queryString from 'query-string';



const params = queryString.parse(window.location.search);


class CommonShapes extends Component {
    render() {
        if (isMobile) {
            return (
                <CustomScroll className="customscrollbar-11" heightRelativeToParent="560px">
                    {this.props.children}
                </CustomScroll>
            )
        } else {
            return (
                <Scrollbars style={{ height: 400 }} className="scrollbar-11">
                    {this.props.children}
                </Scrollbars>
            )
        }
    }
}

class Shapes extends Component {
    state = {
        background: '#fff',
        Defaultbackground: "fff",
        shapeColorSlider: false,
        shapeColorPicker: false,
    }

    toggleClass(property) {
        this.setState({
            [property]: !this.state[property]
        });
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
        console.log(color.hex, "----COLOR-----: ", this.state.background)
        this.handleColorChange(color)
    };
    handleChangeComplete_new = (color) => {
        this.setState({ background: color });
        console.log("COLOR--****---: ", this.state.background)
        this.handleColorChange_new(color)
    };
    constructor(props) {
        super(props);

        this.clearInt = this.clearInt.bind(this);

        this.state = {
            elZoomOpened: false,
            elColorOpened: true,
            textColorsListRecently: [],
            textColorsListMobile: ["#f44336", "#e81e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4",
                "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722",
                "#795548", "#9e9e9e", "#607d8b", "#000000", "#ffffff"],
            colorDropdown: true, //window.innerWidth > 616 ? false : true,
            currentColor: '000000',
            newColor: '',
        }
    }


    addElementToPage(data) {
        let layerData = [];
        let images = [];
        let canvasHeight = [];
        let canvasX = [];
        let canvasWidth = [];
        let canvasY = [];

        let datas = 'undefined';
		
		if(typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
			datas = this.props.pages[this.props.activePage]?.layerDatas.canvasMaskElements;
		}
        
        

        if (parseInt(params.robot) === 1) {
            if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }
        if (parseInt(params.robot) === 0 || parseInt(params.robot2) === 1) {
            if (typeof this.props.pages[this.props.activePage]?.layerDatas !== 'undefined') {
                let layerAllData = JSON.parse(datas);
                if (Array.isArray(layerAllData) && layerAllData.length > 0) {
                    // console.log(layerAllData, "datas");
                    layerData = layerAllData.map((item) => item.layer_name);
                    images = layerAllData.map((item) => item.layer_image);
                    canvasHeight = layerAllData.map((item) => item.height);
                    canvasWidth = layerAllData.map((item) => item.width);
                    canvasX = layerAllData.map((item) => item.x);
                    canvasY = layerAllData.map((item) => item.y);
                    // console.log(layerData, images, canvasHeight, canvasWidth, canvasX, canvasY, "data")
                }
        } 
        }
        const layerDatas = localStorage.getItem('layer_data');
        // this.setState({ 
        //     Defaultbackground: this.props?.activeElement?.attrs?.fill,
        //     background: undefined,
        // });
        this.props.addElementToPage({
            type: 'shape',
            data: data,
            activePage: this.props.activePage
        });

        setTimeout(() => {
            const canvasElementsLength = this.props.pages[0]['canvasElements'].length;
            console.log('Get Length set name===========>', canvasElementsLength);
            {
                this.props.EditorType === 1 ?
                    this.props.setActiveElement(this.props.stage.children[this.props.activePage].children[this.props.stage.children[this.props.activePage].children.length - 2].attrs.name)
                    :
                    this.props.setActiveElement("element-0-" + canvasElementsLength);
            }
            if (this.props.mode !== 'ADMIN' && params.sid !== "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate === 1) {

                let layerX = [];
                let layerY = [];
                for (let i = 1; i <= 10; i++) {
                    switch (i) {
                        case 1:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 2:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 3:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 4:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 5:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        case 6:
                            layerX[i] = canvasX[i] + (canvasWidth[i] / 2);
                            layerY[i] = canvasY[i] + (canvasHeight[i] / 2);
                            break;
                        default:
                            break;
                    }
                }

                // Accessing the values:
                let layer1X = layerX[1]; let layer1Y = layerY[1];
                let layer2X = layerX[2]; let layer2Y = layerY[2];
                let layer3X = layerX[3]; let layer3Y = layerY[3];
                let layer4X = layerX[4]; let layer4Y = layerY[4];
                let layer5X = layerX[5]; let layer5Y = layerY[5];
                let layer6X = layerX[6]; let layer6Y = layerY[6];

                const layerDataCoordinates = {
                    ...(layerData[1] && { [layerData[1]]: { x: layer1X, y: layer1Y } }),
                    ...(layerData[2] && { [layerData[2]]: { x: layer2X, y: layer2Y } }),
                    ...(layerData[3] && { [layerData[3]]: { x: layer3X, y: layer3Y } }),
                    ...(layerData[4] && { [layerData[4]]: { x: layer4X, y: layer4Y } }),
                    ...(layerData[5] && { [layerData[5]]: { x: layer5X, y: layer5Y } }),
                    ...(layerData[6] && { [layerData[6]]: { x: layer6X, y: layer6Y } }),
                };

                if (layerDatas in layerDataCoordinates) {
                    const { x, y } = layerDataCoordinates[layerDatas];
                    if (this.props.activeElement) {
                        this.props.activeElement.x(x);
                        this.props.activeElement.y(y);
                        if (isTablet===true) {
                            // console.log(isTablet,"istablet")
                            this.props.activeElement.x(x);
                            this.props.activeElement.y(y);
                        } else if (isMobile===true) {
                            // console.log(isMobile,"isMobile")
                            this.props.activeElement.x(x);
                            this.props.activeElement.y(y);
                        }
                    }
                }
            }
        }, 50)

        if (window.innerWidth <= DESKTOP_WIDTH) {
            this.props.hideToolsMenu();
            this.props.toolsMenuVisibleToggle({ mode: false });
            console.log('datadata::', data)
            if (data === "square" || "circle" || "triangle" || "line") {
                this.setState({
                    shapeColorSlider: true,
                })
            } else {
                this.setState({
                    shapeColorSlider: false,
                })
            }

        }
    }

    handleSizeChange(val) {
        if (this.props.activeElement) {
            this.interval = setInterval(() => {
                this.props.onEditElement({
                    scaleX: (this.props.activeElement.attrs.scaleX + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleX + val / 10 : 0.5,
                    scaleY: (this.props.activeElement.attrs.scaleY + val / 10) > 0.5 ? this.props.activeElement.attrs.scaleY + val / 10 : 0.5,
                    element: this.props.activeElement.attrs.name,
                    activePage: this.props.activePage,
                })
            }, 50);
        }
    }

    clearInt(interval) {
        return clearInterval(interval);
    }

    handleColorChange(color) {
        this.props.onEditElement({
            color: color.hex,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

    }

    handleColorChange_new(color) {
        this.props.onEditElement({
            color: color,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });

    }

    // Color slider for Mobile
    handleChangeCompleteMobile = (color) => {
        this.setState({ background: color });
        console.log("COLOR Mobile: ", this.state.background)
        //this.handleColorChangeMobile(color)
        this.handleColorChange(color)
    }
    // Color slider for Mobile End
    // Color slider for Mobile
    handleColorChangeMobile(color) {
        //console.log(color,'-------------------', this.props.pages[0].canvasElements)
        this.props.pages[0].canvasElements.forEach((item, i) => {
            console.log(i, 'item:=====>>:', item.name)
            // if (i!=0) {
            //     if ((item.elementId === this.props.activeElement.attrs.elementId
            //         && this.props.activeElement.attrs.elementId !== 0
            //         && this.props.activeElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
            //             this.props.onEditElement({
            //             color: color,
            //             element: item.name,
            //             activePage: this.props.activePage
            //         });
            //     }
            //     if ((item.elementId === this.props.activeElement.attrs.elementId
            //         && this.props.activeElement.attrs.elementId !== 0
            //         && this.props.coloractiveElement.attrs.elementId !== undefined) || this.props.selectedElements.includes(item.name)) {
            //             this.props.onEditElement({
            //             color: color,
            //             element: item.name,
            //             activePage: this.props.activePage
            //         });


            //     }
            //     this.props.onEditElement({
            //         color: color,
            //         element: item.name,
            //         activePage: this.props.activePage
            //     });
            // }
            console.log('test234');


        });

        this.setState({
            newColor: color
        })
    }
    // Color slider for Mobile End

    render() {
        // console.log(" state filling: ",this.props.Defaultbackground )
        // console.log("Final background:", this.props?.background)

        // console.log("DEFaoultclor, Props:",this.state.Defaultbackground, this.props?.activeElement?.colorKey)

        let activePage = this.props.pages ? this.props.pages[this.props.activePage] : false
        let colors = [];
        if (activePage) {
            for (let key in activePage.colors) {
                if (!activePage.colors.hasOwnProperty(key)) continue;
                let color = activePage.colors[key];
                colors.push(color.color_code);
            }
        }





        return (
            (this.props.showShapesForm && !this.props.isCustomMaskEditing.status) || this.state.shapeColorSlider ?
                // <CustomScroll   heightRelativeToParent="180px">
                <CommonShapes>
                    {
                        this.state.shapeColorPicker && window.innerWidth <= 616 ?
                            <>
                                <div className="shape-color-picker-wrapper">
                                    <div className={clsx(stl.textMenu__closeBtn) + " cross_btn_mobile_cust"}>
                                        <img src={close_btn} alt="" onClick={() => {
                                            this.setState({ shapeColorPicker: false })
                                        }} />
                                    </div>
                                    <SketchPicker
                                        color={this.props?.activeElement?.attrs?.fill}
                                        onChangeComplete={this.handleChangeComplete}
                                    />
                                </div>
                            </>
                            : null
                    }

                    <div className="designer-tools-uploader cstm-shapes-wrapper">
                        <div className={stl.shapes + " custome_elementbtn_wrapper "}>

                            {window.innerWidth <= 616 ? <>
                                <div className={"submenu_back_arrow"}>
                                    {
                                        this.props.EditorType === 1 ? <>
                                            <img src={down_arrow_btn} alt=""
                                                onClick={() => {
                                                    this.props.hideToolsMenu()
                                                    this.setState({
                                                        shapeColorSlider: false,
                                                        toolsMoreShow: true
                                                    });
                                                    this.props.setActiveElement("");
                                                }} />
                                        </> : <>
                                            <img src={down_arrow_btn} alt=""
                                                onClick={() => {
                                                    this.setState({
                                                        shapeColorSlider: false,
                                                    })
                                                    // Deselect the ActiveElement
                                                    this.props.setActiveElement("");
                                                    document.querySelector('.BackBtnMobileMehr').click();
                                                }} />
                                        </>
                                    }
                                </div>
                            </> : null}

                            {(this.props.activeElement && window.innerWidth <= 616 && (this.props.activeElement.attrs.type === "square" || this.props.activeElement.attrs.type === "circle" || this.props.activeElement.attrs.type === "triangle" || this.props.activeElement.attrs.type === "line")) || this.state.shapeColorSlider ?
                                <>
                                    <div className="special_color_btn_slide_with_colors cstm_shape_color_slider">
                                        {this.state.textColorsListMobile.map((item) => {
                                            return (
                                                <div className={this.props?.activeElement?.attrs.fill === item ? "color_text_wrapper active" : "color_text_wrapper"}>
                                                    <div key={item}
                                                        className="color_text"
                                                        style={{ 'backgroundColor': item }}
                                                        onClick={() => { this.handleChangeComplete_new(item) }}
                                                    >
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className={this.state.shapeColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                            <img src={color_picker_icon} alt=""
                                                onClick={() => {
                                                    this.setState({
                                                        shapeColorPicker: !this.state.shapeColorPicker
                                                    })
                                                }}>
                                            </img>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="element_buttons">
                                        <Button outline
                                            theme="secondary"
                                            onClick={this.addElementToPage.bind(this, "square")}>
                                            <img src={rectangle} alt="" />
                                        </Button>
                                        <Button outline
                                            theme="secondary"
                                            onClick={this.addElementToPage.bind(this, "circle")}>
                                            <img src={circle} alt="" />
                                        </Button>
                                        <Button outline
                                            theme="secondary"
                                            onClick={this.addElementToPage.bind(this, "triangle")}>
                                            <img src={triangle} alt="" />

                                        </Button>
                                        <Button outline
                                            theme="secondary"
                                            onClick={this.addElementToPage.bind(this, "line")}>
                                            <img src={line} alt="" />
                                        </Button>
                                    </div>
                                </>
                            }

                            {(this.props.activeElement && window.innerWidth > 616 && (this.props.activeElement.attrs.type === "square" || this.props.activeElement.attrs.type === "circle" || this.props.activeElement.attrs.type === "triangle" || this.props.activeElement.attrs.type === "line")) ? <>
                                <div className="special_color_btn_slide_with_colors cstm_shape_color_slider desktop">
                                    {this.state.textColorsListMobile.map((item) => {
                                        return (
                                            <div className={this.props?.activeElement?.attrs.fill === item ? "color_text_wrapper active" : "color_text_wrapper"}>
                                                <div key={item}
                                                    className="color_text"
                                                    style={{ 'backgroundColor': item }}
                                                    onClick={() => { this.handleChangeComplete_new(item) }}
                                                >
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className={this.state.shapeColorPicker ? "color_Picker_responsive active" : "color_Picker_responsive"}>
                                        <img src={color_picker_icon} alt=""
                                            onClick={() => {
                                                this.setState({
                                                    shapeColorPicker: !this.state.shapeColorPicker
                                                })
                                            }}>
                                        </img>
                                    </div>
                                </div>
                            </> : null}

                            {
                                this.state.shapeColorPicker && window.innerWidth > 616 ?
                                    <>
                                        <div className="shape-color-picker-wrapper desktop">
                                            <SketchPicker
                                                color={this.props?.activeElement?.attrs?.fill}
                                                onChangeComplete={this.handleChangeComplete}
                                            />
                                        </div>
                                    </>
                                    : null
                            }

                        </div>
                    </div>

                    {/* </CustomScroll> */}

                </CommonShapes>
                : null
        );
    }
}

const mapStateToProps = state => {
    return {
        activePage: state.glb.activePage,
        showShapesForm: state.tol.showShapesForm,
        stage: state.glb.stage,
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addElementToPage: (canvasElement) => dispatch(actionCreators.addElementToPage(canvasElement)),
        addTextToPage: (canvasElement) => {
            dispatch(actionCreators.addElementToPage(canvasElement));
            dispatch(actionCreators.hidePopup());
        },
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        setActiveElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        hideToolsMenu: () => dispatch(actionCreators.hideToolsMenu()),
        toolsMenuVisibleToggle: (data) => dispatch(actionCreators.toolsMenuVisibleToggle(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Shapes);
