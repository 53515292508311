import * as actionTypes from '../actions/actionTypes';

const initialState = {
    showTextForm: false,
    showEmojiForm: false,
    showTextPopup: false,
    showImagePopup: false,
    showProperties: false,
    showShapesForm: false,
    showBackgroundsForm: false,
    showDesignsForm: false,
    showImageUploadForm: false,
    showImageCropPopup: false,
    showImageChangePopup: false,
    showFaceCropPopup: false,
    showOrderPreviewPopup: false,
    showActiveElementsList: false,
    showQuickEditLabelsPopup: false,
    ifToolsMenuVisible: false,
    toolImagesAllowed: true,
    toolShapesAllowed: true,
    toolEmojiAllowed: true,
    toolTextAllowed: true,
    propertyTextStrokeAllowed: true,
    propertyTextStrokeColorAllowed: true,
    propertyTextShadowColorAllowed: true,
    propertyTextShadowAllowed: true,
    propertyTextColorAllowed: true,
    propertyTextShowAllowed:true,
    propertyTextFontAllowed: true,
    propertyFaceCropRotateAllowed: true,
    propertyFaceCropZoomAllowed: true,
    propertyFaceCropSizeAllowed: true,
    faceCropPopupData: '',
    showBackgroundChangePopup: false,
    showTextChangePopup: false,
    ShowTextareaMobilePopup: false,
    ShowFaceCutoutPopup:false,
    ShowFaceCropperPopupNew:false,
};

const tools = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_TEXT_FORM:
            return {
                ...state,
                showTextForm: true,
                showShapesForm: false,
                showImageUploadForm: false,
                showBackgroundsForm: false,
                showDesignsForm: false,
                showEmojiForm: false,
                showActiveElementsList: false
            };
        case actionTypes.TOOLS_MENU_SHOW:
            return {
                ...state,
                ifToolsMenuVisible: action.mode
            };
        case actionTypes.SHOW_ELEMENTS_LIST:
            return {
                ...state,
                showActiveElementsList: true,
            };
        case actionTypes.SHOW_EMOJI_FORM:
            return {
                ...state,
                showEmojiForm: true,
                showTextForm: false,
                showShapesForm: false,
                showImageUploadForm: false,
                showBackgroundsForm: false,
                showDesignsForm: false,
                showActiveElementsList: false
            };
        case actionTypes.SHOW_UPLOAD_FORM:
            return {
                ...state,
                showImageUploadForm: true,
                showBackgroundsForm: false,
                showDesignsForm: false,
                showTextForm: false,
                showShapesForm: false,
                showEmojiForm: false,
                showActiveElementsList: false,
            };
        case actionTypes.SHOW_BACKGROUNDS_FORM:
            return {
                ...state,
                showDesignsForm: false,
                showBackgroundsForm: true,
                showImageUploadForm: false,
                showTextForm: false,
                showShapesForm: false,
                showEmojiForm: false,
                showActiveElementsList: false,
            };
        case actionTypes.SHOW_DESIGNS_FORM:
            return {
                ...state,
                showDesignsForm: true,
                showBackgroundsForm: false,
                showImageUploadForm: false,
                showTextForm: false,
                showShapesForm: false,
                showEmojiForm: false,
                showActiveElementsList: false,
            };
        case actionTypes.SHOW_SHAPES_FORM:
            return {
                ...state,
                showShapesForm: true,
                showTextForm: false,
                showImageUploadForm: false,
                showBackgroundsForm: false,
                showDesignsForm: false,
                showEmojiForm: false,
                showActiveElementsList: false,
            };
        case actionTypes.HIDE_TOOLS_MENU:
            return {
                ...state,
                showTextForm: false,
                showShapesForm: false,
                showImageUploadForm: false,
                showBackgroundsForm: false,
                showDesignsForm: false,
                showEmojiForm: false,
                showActiveElementsList: false,
            };
        case actionTypes.HIDE_POPUP:
            return {
                ...state,
                showTextPopup: false,
                showTextEditPopup: false,
                showImagePopup: false,
                showImageCropPopup: false,
                showImageChangePopup: false,
                showFaceCropPopup: false,
                showOrderPreviewPopup: false,
                showQuickEditLabelsPopup: false,
                showBackgroundChangePopup: false,
                showTextChangePopup: false,
                ShowTextareaMobilePopup: false,
                ShowFaceCutoutPopup: false,
                ShowFaceCropperPopupNew:false,
            };
        case actionTypes.SHOW_TEXT_EDIT_POPUP:
            return {
                ...state,
                showTextEditPopup: true,
            };
        case actionTypes.SHOW_TEXT_POPUP:
            return {
                ...state,
                showTextPopup: true,
            };
        case actionTypes.SHOW_QUICK_EDIT_LABELS_POPUP:
            return {
                ...state,
                showQuickEditLabelsPopup: true,
            };
        case actionTypes.SHOW_IMAGE_CROP_POPUP:
            return {
                ...state,
                showImageCropPopup: true,
            };
        case actionTypes.SHOW_FACE_CROP_POPUP:
            return {
                ...state,
                showFaceCropPopup: true,
                faceCropPopupData: action.data ?? ''
            };
        case actionTypes.SHOW_BACKGROUND_CHANGE_POPUP:
            return {
                ...state,
                showBackgroundChangePopup: true,
            };
        case actionTypes.SHOW_TEXT_CHANGE_POPUP:
            return {
                ...state,
                showTextChangePopup: true,
            };
        case actionTypes.SHOW_ORDER_PREVIEW_POPUP:
            return {
                ...state,
                showOrderPreviewPopup: true,
            };
        case actionTypes.SHOW_IMAGE_CHANGE_POPUP:
            return {
                ...state,
                showImageChangePopup: true,
            };
        case actionTypes.SHOW_IMAGE_CHANGE_POPUP_TEXTAREA:
            return {
                ...state,
                ShowTextareaMobilePopup: true,
            };
            
        case actionTypes.SHOW_FACE_CUTOUT_POPUP:
            return {
                ...state,
                ShowFaceCutoutPopup: true,
            };
            
            case actionTypes.SHOW_FACE_CROPPER_POPUP_NEW:
                return {
                    ...state,
                    ShowFaceCropperPopupNew: true,
                };

        case actionTypes.TOOLS_USER_ALLOWED:
            const {toolImagesAllowed = state.toolImagesAllowed, toolShapesAllowed = state.toolShapesAllowed, toolEmojiAllowed = state.toolEmojiAllowed, toolTextAllowed = state.toolTextAllowed} = action;
            return {
                ...state,
                toolImagesAllowed,
                toolShapesAllowed,
                toolEmojiAllowed,
                toolTextAllowed,
            };

        case actionTypes.TOOLS_PROPERTIES_USER_ALLOWED:
            const {
                propertyTextStrokeAllowed = state.propertyTextStrokeAllowed,
                propertyTextStrokeColorAllowed = state.propertyTextStrokeColorAllowed,
                propertyTextShadowColorAllowed = state.propertyTextShadowColorAllowed,
                propertyTextShadowAllowed = state.propertyTextShadowAllowed,
                propertyTextColorAllowed = state.propertyTextColorAllowed,
                propertyTextShowAllowed = state.propertyTextShowAllowed,
                propertyTextFontAllowed = state.propertyTextFontAllowed,
                propertyFaceCropRotateAllowed = state.propertyFaceCropRotateAllowed,
                propertyFaceCropZoomAllowed = state.propertyFaceCropZoomAllowed,
                propertyFaceCropSizeAllowed = state.propertyFaceCropSizeAllowed,
            } = action;

            return {
                ...state,
                propertyFaceCropRotateAllowed,
                propertyFaceCropZoomAllowed,
                propertyFaceCropSizeAllowed,
                propertyTextStrokeAllowed,
                propertyTextStrokeColorAllowed,
                propertyTextShadowColorAllowed,
                propertyTextShadowAllowed,
                propertyTextColorAllowed,
                propertyTextShowAllowed,
                propertyTextFontAllowed,
            };

        default:
            return {
                ...state
            }
    }
};

export default tools;