import React, {Component} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";

import RotateIcon from '../../../assets/images/Rotate.svg';
import RotateIcon1 from '../../../assets/images/Rotate1.svg';
import Translate from "react-translate-component";
import { FormInput } from "shards-react";

class Rotate extends Component {

    state = {
        rotateDropdown: false
    };

    rotation = React.createRef();

    handleElemRotation(rotateValue = 0) {

        this.props.pages[0].canvasElements.forEach((item) => {
            if ((item.faceId === this.props.activeElement.attrs.faceId
                && this.props.activeElement.attrs.faceId !== 0
                && this.props.activeElement.attrs.elementRepeatOval !== true
                && this.props.activeElement.attrs.elementRepeat !== true
                && this.props.activeElement.attrs.faceId !== undefined) || this.props.selectedElements.includes(item.name)) {

                this.props.onEditElement({
                    rotation: parseInt((rotateValue.target && rotateValue.target.value) || parseInt(rotateValue) + (item.rotation ? item.rotation : 0)),
                    element: item.name,
                    activePage: this.props.activePage
                });
                this.setProperties({rotation: parseInt((rotateValue.target && rotateValue.target.value) || parseInt(rotateValue) + (item.rotation ? item.rotation : 0))});
            }
        });

    }

    setProperties(attrs) {
        if (this.rotation.current !== undefined && this.rotation.current !== null) this.rotation.current.value = attrs.rotation ?? 0;
    }

    componentWillUnmount() {
        document.querySelectorAll(".properties-column")[0].classList.remove("active")
    }

    componentDidMount() {
        this.setProperties(this.props.activeElement.attrs);
    }

    componentDidUpdate() {
        this.setProperties(this.props.activeElement.attrs);
        let parentWrapper = document.querySelectorAll(".properties-column")[0];
        let parentWrapperActive = document.querySelectorAll(".properties-column.active");
        if ( !parentWrapperActive.length ) {
            if (this.state.rotateDropdown) {
                parentWrapper.scrollTo(0, 0);
                parentWrapper.classList.add("active")
            } else {
                parentWrapper.classList.remove("active")
            }
        }
    }

    render() {
        if (!this.props.propertyFaceCropRotateAllowed && (this.props.activeElement.attrs.elementRepeat || this.props.activeElement.attrs.elementRepeatOval)) return null
        return (
            <div
                className={clsx("designer-right-panel-item", "designer-right-panel-item_rotate", "designer-right-panel-item__column", this.state.rotateDropdown && "active")}>
                <div className={"designer-right-panel-item__titleFlex"}>

                    <div className={"designer-right-panel-item__titleFlex"}
                         onClick={this.props.toggleClass.bind(this, "rotateDropdown")}>
                        <FontAwesomeIcon icon="undo-alt" className={"designer-rotate-icon"}/>
                        <span className={"text"}>
                        <Translate content={"Rotate"}/>
                    </span>
                    </div>
                    <div className={"designer-rotate-btns dropdownContent"}>
                        <button
                            onClick={this.handleElemRotation.bind(this, +15)}
                            className={"designer-right-panel-btn"}>
                            <img style={{transform: 'scaleY(-1) scaleX(-1)'}} src={RotateIcon1} alt=""/>
                        </button>
                        <button
                            onClick={this.handleElemRotation.bind(this, -15)}
                            className={"designer-right-panel-btn"}>
                            <img style={{transform: 'scaleY(-1) scaleX(1)'}} src={RotateIcon} alt=""/>
                        </button>
                        <div className={"designer-right-panel-inputNumber"}>
                            <FormInput type="number"
                                       size="sm"
                                       innerRef={this.rotation}
                                       defaultValue={this.props.activeElement.attrs.rotation}
                                       onChange={this.handleElemRotation.bind(this)}
                            />
                        </div>

                        <div className={'designer-rotate-close'}
                             onClick={this.props.toggleClass.bind(this, "rotateDropdown")}>
                            {/*<FontAwesomeIcon icon="plus"/>*/}
                            <Translate content={"done"}/>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        pages: state.glb.pages,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        selectedElements: state.glb.selectedElements,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Rotate);
