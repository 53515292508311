import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import connect from "react-redux/es/connect/connect";
import clsx from "clsx";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";

class PositionProperty extends Component {
    render() {
        return (
            <div
                className={clsx("designer-right-panel-item")}>
                <div className={"designer-right-panel-item__titleFlex"}
                     onClick={this.props.showImageChangePopup}>
                    <FontAwesomeIcon icon="image"/>
                    <span className={"text"}>
                        <Translate content={"Change Image"}/>
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,};
};

const mapDispatchToProps = dispatch => {
    return {
        showImageChangePopup: () => dispatch(actionCreators.showImageChangePopup()),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
