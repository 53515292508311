export default {
    "Revert": "Fortryd",
    "Retry": "Fremad",
    "Face crop":"Billedbeskæring",
    "Remove background":"Fjern baggrund",
    "Loading":"Loading...",
    "Background not recognized":"Baggrund ikke genkendt",
    "Background already removed":"Baggrund allerede fjernet",
    "settings": "Indstillinger",
    "gridlines": "Vis gridlinier",
    "layers": "Lag",
    "image": "Billede",
    "Text": "Skrive",
    "shape": "Form",
    "emoji": "Emoji",
    "done": "Færdig",
    "view": "Tekst og billeder",
    "Custom Text": "Tekst",
    "Close": "Annuller",
    "Position": "Position",
    "Rotate": "Roter",
    "Change Image": "Ændre billede",
    "Crop Image": "Beskær billede",
    "Upload Images": "Upload billeder",
    "Add Text": "Tilføj tekst",
    "Cancel": "Annuller",
    "Start creating your masterpiece by browsing our new content in the panels on the left": "Begynd at lave dit mesterværk, ved at bruge vores nye indhold i panelet til venstre",
    "Shapes": "Konfigurer form",
    "Add a line": "Tilføj en linje",
    "Add a rectangle": "Tilføj en rektangel",
    "Add a circle": "Tilføj en cirkel",
    "Add a triangle": "Tilføj en triangle",
    "Color": "Farve",
    "Edit Text": "Rediger tekst",
    "Font type": "Skriftstype",
    "Font Style": "Tekst style",
    "Size": "Størrelse",
    "Justification": "Justering",
    "Letter spacing": "Tegnafstand",
    "Flip": "Vend",
    "Save" : "Gem",
    "Preview": "Forhåndsvisning",
    "Editor": "Editor",
    "Uploads": "Uploads",
    "Letter limit": "Karakterbegrænsning",
    "Recently Used Colors": "Tidligere Anvendte Farver ",
    "Drag and drop your images here!": "Drag and drop dine billeder her!",
    "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed" : "Du har placeret et objekt uden for det printbare område. Bekræft venligst, at du er indforstået med, at det kan printes delvist eller ukorrekt.",
    "There was error during getting files!": "Der opstod en fejl ved uploading af dine billeder. Refresh venligst siden og prøv igen.",
    "Your Image is too big to upload! The max file size is 8 MB": "Dit billede fylder for meget til at blive uploadet! Den maksimale størrelse er 8 MB",
    "Your image is too small": "Dit billede er for lille til, at det kan printes i en tilfredsstillende kvalitet. Vælg venligst et andet.",
    "Reset changes": "Nulstil",
	"Stroke": "Strøg",
	"Stroke color": "Strøg farve",
	"Delete": "Fjern",
    "BackgroundsTools": "Baggrunde",
    "ColorsTools": "Farver",
    "ImagesTools" : "Billeder",
    "Shadow color": "Skygge-farve",
    "Shadow": "Skygge",
    "Information before ordering": "Venligst gennemse det hele igen, før du tjekker ud. Alt vil blive lavet, præcis som det er vist i forhåndsvisningen. Tjek? Tjek? Ellers så tjek vores FAQ",
    "Add to cart": "Tilføj til varekurven",
    "More": "Mere",
    "edit Image": "Tilpas",
    "Add_Image": "Tilføj billede",
    "Cut": "Beskær billede",
    "Change": "Upload billede",
    "Edit": "Rediger tekst",
    "Font": "Skriftstype",
    "Font Size": "Størrelse",
    "Letter Space": "Opsætning",
    "Text Color": "Farve",
    "New text": "Tilføj tekst",
    "Align": "Justering",
    "Flip X": "Vend",
    "Oops, didn't you forget something?": "Whoopsi, har du ikke glemt noget? Du har ikke personliggjort det hele, gennemse venligst dit design og erstat pladsholderen (eller kan du godt lide dem?) ",
    "Use original image": "Brug originalt billede",
    "Continue": "Videre",
    "Wird freigestellt": "Fjern baggrund...",
    "texts_lang": "Tilpas tekst",
    "Design_anpassen_lang": "Tilpas design",
    "Hintergundfarbe_anpassen_lang": "Tilpas baggrund",
    "Farbe_anpassen_lang": "Tilpas baggrund",
    "Hintergrund_anpassen_lang": "Tilpas baggrund",
    "Weiter_lang": "Videre",
    "Text_anpassen_lang":"Tilpas tekst",
    "Mehr_anpassen_lang" :"Tilpas elementerne",
    "Bild_hinzufüge_lang":"Tilføj billede",
    "alert_error_message": "Åh nej! Desværre understøttes dette billedformat ikke. Brug venligst et af følgende formater: jpg, png, jpeg eller jfif.",
    "back_button": "Tilbage",
    "textarea_popup_text": "Hvis du vil ændre teksten, skal du blot klikke i tekstfeltet. Ved at klikke på blyanten kan du formatere teksten.",
    "bild_popup_text": "Brug denne knap til at uploade dine fotos.",
    "Color_Btn_Text": "Farve",
    "Shadow_Btn_Text": "Skygge",
    "Stroke_Btn_Text": "Strøg",
    "upload_photos_Btn_Text": "Uploade fotos",
    "Upload_Btn_Text": "Upload",
    "Upload_More_Photos_Btn_Text": "Upload flere billeder",
    "Edit_Text_Btn_Text": "Rediger tekst",
    "Save_Btn_Text": "Tag over",
    "Change_Text_Btn_Text": "Ændre tekst",
    "Design_Btn_Text": "Design",
    "Face_cropper_loading_text": "Indlæser...",

}