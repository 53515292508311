import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./ProductPreview.scss"
import fullScreenIcon from '../../assets/images/fullScreen_black.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DESKTOP_WIDTH} from "../../config";
import stl from "../Canvas/Canvas.module.scss";
import loadingImage from "../../assets/images/loading.gif";
import * as actionCreators from "../../store/actions";
import Translate from "react-translate-component";
import clsx from "clsx";

class ProductPreview extends Component {
    state = {
        showPreview: false,
        showFullScreen: false,
    }

    componentDidMount() {

        this.generatePreview();

        if (window.innerWidth >= DESKTOP_WIDTH) {
        } else {
            this.setState({
                showPreview: true,
                showFullScreen: true
            })
            this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})

        }
    }

    generatePreview() {
        if (this.props.stage && window.innerWidth >= DESKTOP_WIDTH) {
            let stage = this.props.stage.clone();
            this.props.generatePreview({
                pages: this.props.pages,
                activePage: this.props.activePage,
                stage: stage,
                previewUrl: this.props.previewUrl
            });
            this.props.setPreviewUpdateStatus({previewShouldBeChanged: false})
        }
    }

    togglePreviewShow() {
        if (!this.state.showPreview) {
            this.generatePreview();
        } else {
            this.props.setPreviewUpdateStatus({previewShouldBeChanged: true})
        }

        this.setState({
            showPreview: !this.state.showPreview,
        });
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.previewShouldBeChanged && this.state.showPreview) {
            this.setState({
                showPreview: false,
            });
        }
    }

    handleFullScreenToggle() {
        if (window.innerWidth >= DESKTOP_WIDTH) {
            this.setState({
                showFullScreen: !this.state.showFullScreen,
            })
        } else {
            this.props.closeMobilePreview();
            this.setState({
                showFullScreen: !this.state.showFullScreen
            })
        }
    }

    render() {

        const {showPreview, showFullScreen} = this.state;

        const {previews: previewsProps, previewsLoadingCount, previewShouldBeChanged, showProductPreview} = this.props;

        let previews = previewsProps.map((item) => {
            return (
                <img key={item.name} src={item.image} alt={'loading error...'}/>
            )
        });

        if (this.props.pages[this.props.activePage].renderers.length === 0) return null

        return (
            <React.Fragment>
                <div
                    className={"product-preview" + (showProductPreview ? " show" : "")}>
                    <div className="product-preview-overlay" onClick={this.props.closeMobilePreview.bind(this)}/>
                    <div className="product-preview__wrap">
                        <div className="product-preview__header">
                            {/*{!showFullScreen ? (*/}
                            {/*  <button*/}
                            {/*    onClick={this.togglePreviewShow.bind(this)}*/}
                            {/*    className={"product-preview__btn"}*/}
                            {/*    type={"button"}>*/}
                            {/*      {showPreview && !previewShouldBeChanged ?*/}
                            {/*        <FontAwesomeIcon icon="minus"/> :*/}
                            {/*        <FontAwesomeIcon icon="plus"/>}*/}
                            {/*  </button>*/}
                            {/*) : null}*/}
                            <div className="product-preview__title">
                                <Translate content={"Preview"}/>
                            </div>

                            <button className={"product-preview__btn"} type={"button"}>
                                <FontAwesomeIcon onClick={this.props.closeMobilePreview.bind(this)}
                                                 style={{fontSize: '16px'}} icon="times"/>
                            </button>
                        </div>

                        {showProductPreview && previewsProps.length > 0 && previewsLoadingCount <= 0 && !previewShouldBeChanged ? <div className="product-preview__inner">
                                <div className="product-preview__img-wrap">
                                    {previews}
                                </div>
                            </div>
                         : <div className={clsx("canvasLoaderPreview", stl['canvas-loader'])}>
                            <img className={stl['canvas-loader__image']}
                                 key="Loading image"
                                 src={loadingImage}
                                 alt={"loading_image"}/>
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    componentWillReceiveProps() {
        window.parent.postMessage({data: "image", images: this.props.previews}, "*");
    }

}

const mapStateToProps = state => {
    return {
        pages: state.glb.pages,
        previews: state.prv.previews,
        previewsLoadingCount: state.prv.previewsLoadingCount,
        stage: state.glb.stage,
        previewShouldBeChanged: state.glb.previewShouldBeChanged,
        activePage: state.glb.activePage,
        previewUrl: state.glb.previewUrl,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setPreviewUpdateStatus: (data) => {
            dispatch(actionCreators.setPreviewUpdateStatus(data));
        },
        generatePreview: (previewData) => dispatch(actionCreators.generatePreview(previewData)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPreview);
