import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";

import CombinedShapeIcon from '../../../assets/images/CombinedShape.svg';
import CombinedShapeIcon2 from '../../../assets/images/CombinedShape2.svg';
import CombinedShapeIcon3 from '../../../assets/images/CombinedShape3.svg';
import Translate from "react-translate-component";

class PositionProperty extends Component {
    handleAlightTextChange(align) {
        this.props.onEditElement({
            align: align,
            element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage
        });


        switch (align) {
            case "right":
                this.props.activeElement.offsetX(this.props.activeElement.width());
                this.props.activeElement.x(this.props.stage.width() / this.props.stage.attrs.scaleX);
                break;
            case "center":
                this.props.activeElement.offsetX(this.props.activeElement.width() / 2);
                this.props.activeElement.x((this.props.stage.width() / this.props.stage.attrs.scaleX) / 2);
                break;
            default:
                this.props.activeElement.offsetX(0).x(0);
        }
    }

    render() {
        return (
            <div className="designer-right-panel-item designer-right-panel-item_justification">
                <div className={"designer-right-panel-item__titleFlex"}>
                    <span className={"text"}>
                        <Translate content={"Justification"}/>
                    </span>
                </div>
                <div className={"designer-right-panel-item__checkboxes"}>
                    {/*<Button outline*/}
                    {/*        theme="secondary"*/}
                    {/*        onClick={this.handleAlightTextChange.bind(this, "left")}>*/}
                    {/*    <img src={CombinedShapeIcon} alt=""/>*/}
                    {/*</Button>*/}
                    {/*<Button outline*/}
                    {/*        theme="secondary"*/}
                    {/*        onClick={this.handleAlightTextChange.bind(this, "center")}>*/}
                    {/*    <img src={CombinedShapeIcon2} alt=""/>*/}
                    {/*</Button>*/}
                    {/*<Button outline*/}
                    {/*        theme="secondary"*/}
                    {/*        onClick={this.handleAlightTextChange.bind(this, "right")}>*/}
                    {/*    <img src={CombinedShapeIcon3} alt=""/>*/}
                    {/*</Button>*/}
                    <button onClick={this.handleAlightTextChange.bind(this, "left")}
                            className={"designer-right-panel-btn"}>
                        <img src={CombinedShapeIcon} alt=""/>
                    </button>
                    <button onClick={this.handleAlightTextChange.bind(this, "center")}
                            className={"designer-right-panel-btn"}>
                        <img src={CombinedShapeIcon2} alt=""/>
                    </button>
                    <button onClick={this.handleAlightTextChange.bind(this, "right")}
                            className={"designer-right-panel-btn"}>
                        <img src={CombinedShapeIcon3} alt=""/>
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        activePage: state.glb.activePage,
        mode: state.glb.mode,
        stage: state.glb.stage,
        pages: state.glb.pages,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onEditElement: (data) => dispatch(actionCreators.editElementAttrs(data))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
