import React, {Component} from 'react';
import connect from "react-redux/es/connect/connect";
import * as actionCreators from "../../../store/actions/index";
import Translate from "react-translate-component";
import pencilIcon from '../../../assets/images/pencil.svg';

class PositionProperty extends Component {
    render() {
        return (
            <div className="designer-right-panel-item center-align"
                 onClick={this.props.onShowTextEditPopup}>
                <div className={"designer-right-panel-item__titleFlex"}>
                    <img src={pencilIcon} alt=""/>
                    <span className={"text"}>
                        <Translate content={"Edit Text"}/>
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onShowTextEditPopup: () => dispatch(actionCreators.showTextEditPopup()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PositionProperty);
