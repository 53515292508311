import React, {Component} from 'react'
import './Dropzone.css'
import Translate from "react-translate-component";
import {Button, FormInput} from "shards-react";
import TranslateForAlert from '../../helpes/translateForAlert';	

class Dropzone extends Component {

    constructor(props) {
        super(props);
        this.state = {hightlight: false};
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this)
    }

    openFileDialog() {
        this.fileInputRef.current.click();
        this.fileInputRef.current.focus();
    }

    onFilesAdded(evt) {
        let _URL = window.URL || window.webkitURL;
        let files = Array.from(evt?.target?.files || evt);

        if (this.props.onFilesAdded) {
            for (let i = 0; files.length > i; i++) {
                let arr = files[i].name.split(".");
                let FileExtension = arr[arr.length-1];
                console.log('FileExtension',FileExtension);
                if(FileExtension === "png" || FileExtension === "jpg" || FileExtension === "jpeg" || FileExtension === "jfif" || FileExtension === "PNG" || FileExtension === "JPG" || FileExtension === "JPEG" || FileExtension === "JFIF"){
                    let img = new Image();
                    img.src = _URL.createObjectURL(files[i]);
                    img.onload = () => {
                        files[i].width = img.width;
                        files[i].height = img.height;
                        this.props.onFilesAdded(files);
                    };
                }else{
                    this.props.onFilesAdded(files);
                    // TranslateForAlert("alert_error_message");
                }
            }
            this.forceUpdate()
        }
    }

    onDragOver(evt) {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({hightlight: true})
    }

    onDragLeave() {
        this.setState({hightlight: false})
    }

    onDrop(event) {
        event.preventDefault();
        if (this.props.disabled) return;
        this.onFilesAdded(event.dataTransfer.files);
        this.setState({hightlight: false})
    }

    render() {
        return (
            <div>
                <div
                    className={'Dropzone' + ((this.state.hightlight) ? ' Highlight' : '') + ( this.props.multiface_elements_admin_only === 1 ? " DropzoneFaces" : '') }
                    onDragOver={this.onDragOver}
                    onDragLeave={this.onDragLeave}
                    onDrop={this.onDrop}
                    onClick={this.openFileDialog}
                    style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
                >
                    <div className= {"dropzone-upload-button" + (this.props.multiface_elements_admin_only === 1 ? " dropzone-upload-button-faceUpload" : '')}>
                        <Button outline theme="secondary">
                            {
                                this.props.check_image_count > 0 ? 
                                <Translate content={"Upload_More_Photos_Btn_Text"}/> 
                                :                                    
                                    <Translate content={"Upload_Btn_Text"}/>
                            }                            
                        </Button>
                    </div>

                    <FormInput
                        innerRef={this.fileInputRef}
                        className="FileInput"
                        type="file"
                        multiple
                        onChange={this.onFilesAdded}/>

                    {this.props.multiface_elements_admin_only === 1 ? null :
                        <span className="dropzone-info">
                            <Translate content={"Drag and drop your images here!"}/>
                        </span>
                    }

                </div>
            </div>
        )
    }
}

export default Dropzone
